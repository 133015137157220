/*

Add all routes for this plugin here.

*/

import Login from './Views/Login';
import Register from './Views/Register';
import Forgot from './Views/Forgot';


export default [

    //a single sidebar route
    {
        path: "/login",            //url
        name: "Auth",             //sidebar name
        icon: "ni ni-shop text-primary",    //sidebar icon
        component: Login,           //component to show in route
        layout: "/auth",                   //which layout to use
        renderOn: 'auth'                   //where to render this component
    },

    {
        path: "/register",            //url
        name: "Auth",             //sidebar name
        icon: "ni ni-shop text-primary",    //sidebar icon
        component: Register,           //component to show in route
        layout: "/auth",                   //which layout to use
        renderOn: 'auth'                   //where to render this component
    },

    {
        path: "/forgot",            //url
        name: "Auth",             //sidebar name
        icon: "ni ni-shop text-primary",    //sidebar icon
        component: Forgot,           //component to show in route
        layout: "/auth",                   //which layout to use
        renderOn: 'auth'                   //where to render this component
    },

]