import { customerCardAdd, customerCardSetDefaultSource, customerCharge, customerCreate, customerCreateAndCharge, customerSubscribe } from '../../universal/functions/stripe';
import { createCustomerObject, createPaymentObject } from './helpers/createObjects';






export const processStripe = (token, state) => {
    return new Promise (async resolve => {

        //set the base response
        let r = {
            error:                  null,
            new_customer:           false, //for testing
            one_time:               true
        }

        //make sure the type is specified
        if(state.one_time !== true && state.one_time !== false) {
            r.error = 'Internal Error, param 2 requires one_time property set to either true or false.'
            resolve(r);
            return;
        }

        //set default value for state.user if one is not given
        state.user = state.user ? state.user : {};
        
        const one_time = state.one_time; //used inside this function
        const stripe_cus_id = state.user.stripe_cus_id;
       

        //one of these may be null depending if this is charging subscription or on etime
        const amount = state.amount;
        const stripe_plan_id = state.stripe_plan_id;

        if(one_time) {

            if(!state.description) {
                r.error = 'Internal Error, 2nd param requires description property.'
                resolve(r);
                return;
            }

            if(amount === null) {
                r.error = 'Internal Error, 2nd param requires an amount property.'
                resolve(r);
                return;
            }

        } else {

            if(!stripe_plan_id) {
                r.error = 'Internal Error, 2nd param requires stripe_plan_id property.'
                resolve(r);
                return;
            }

        }

        //this means we have a customer id or a new token
        if(token && token.id) {

            let paymentObject = createPaymentObject(state, token.id, amount);
            let customerObject = createCustomerObject(state, token.id)
           
            if(!stripe_cus_id) { //if we don't already have a customer create one

                r.new_customer = true
               
                if(one_time) {

                    if(amount >= 100) { //if the amount to be charged is greater than $1 USD

                        const newCustomerCharge = await customerCreateAndCharge(customerObject, paymentObject, { capture: true, deleteCustomerOnPaymentFailure: true });
            
                        if(newCustomerCharge.success) {
    
                            r.customer = newCustomerCharge.customer;
                            r.payment = newCustomerCharge.payment;
                           
                        } else {
    
                            r.error = newCustomerCharge.message;
                            resolve(r); return;
                            
                        }
            
                    } else { //create a customer for free one time payment object | aka no payment
            
                        const createCustomer = await customerCreate(customerObject)
    
                        if(createCustomer.success) {
            
                            r.customer = createCustomer.customer;
    
                        } else {
    
                            r.error = createCustomer.message;
                            resolve(r); return;
    
                        }
            
                    }

                } else { //subscribe them to the plan

                    r.one_time = false;

                    const createCustomer = await customerCreate(customerObject)
    
                    if(createCustomer.success) {
        
                        const subscribed =  await customerSubscribe({customer_id: createCustomer.customer.id, plan_id: stripe_plan_id})
                        
                        if(subscribed.success) {

                            r.customer = createCustomer.customer;
                            r.subscription = subscribed.response;

                        } else {

                            r.error = subscribed.message;
                            resolve(r); return;

                        }

                    } else {

                        r.error = createCustomer.message;
                        resolve(r); return;

                    }

                }

            } else { //charge existing customer adding a new card


                let addCard = await customerCardAdd(stripe_cus_id, token.id);
            
                if(addCard.success) {

                    let addedCard = addCard.card
                    let setCard = await customerCardSetDefaultSource(stripe_cus_id, addedCard.id);

                    if(setCard.success) {

                        r.customer = setCard.customer

                        if(one_time) {

                            if(amount >= 100) {

                                //then charge this new source
                                paymentObject.customer_id = setCard.customer.id
                                const charge = await customerCharge(paymentObject);
        
                                if(charge.success)  {
        
                                    r.payment = charge.payment
                                  
                                } else {
    
                                    r.error = charge.message;
                                    resolve(r); return;
    
                                }
                                
                            } 

                        } else {

                            r.one_time = false;

                            //subscribe customer to new plan with this card
                            const subscribed =  await customerSubscribe({customer_id: stripe_cus_id, plan_id: stripe_plan_id})

                            if(subscribed.success) {
    
                                r.subscription = subscribed.response;
    
                            } else {
    
                                r.error = subscribed.message;
                                resolve(r); return;
    
                            }

                        }

                    } else {

                        r.error = setCard.message;
                        resolve(r); return;

                    }

                } else {

                    r.error = addCard.message;
                    resolve(r); return;

                }

            }
        
        } else { //we are charging an existing 

            let paymentObject = createPaymentObject(state, '', amount);
           
            if(stripe_cus_id) {  //if not token given make sure we have a stripe cus id

                if(one_time) {

                    if(amount >= 100) {
    
                        paymentObject.customer_id = stripe_cus_id
                        const charge = await customerCharge(paymentObject);
        
                        if(charge.success)  {
        
                            r.payment = charge.success
                            r.customer = {id: stripe_cus_id} // for testing
        
                        } else {
    
                            r.error = charge.message;
                            resolve(r); return;
    
                        }
        
                    }

                } else {

                    r.one_time = false;

                     //subscribe customer to new plan with this card
                     const subscribed =  await customerSubscribe({customer_id: stripe_cus_id, plan_id: stripe_plan_id})
                        
                     if(subscribed.success) {

                         r.subscription = subscribed.response;

                     } else {

                         r.error = subscribed.message;
                         resolve(r); return;

                     }

                }
                
            } else {

                r.error = 'Internal Error, no token or id passed.';
                resolve(r); return;

            }

        }

        //success
        resolve(r); 
        return;
      
    })
}