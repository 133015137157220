/*
Documentation

Need to add if user is adding new card and has id
Need to add if user is using existing card

*/

import './scss/style.scss';

import PropTypes from 'prop-types';
import React from "react";
import ReactBSAlert from "react-bootstrap-sweetalert";
import { Elements, StripeProvider } from 'react-stripe-elements';
import { Col, FormGroup, Input, Row, Table } from "reactstrap";
import CheckoutForm from './components/CheckoutForm';
import { processStripe } from './functions/processStripe';

const required_form_fields = [
    'given_name',
    'family_name',
    'email',
    'address_line_1',
    'city',
    'state',
    'postal_code',
    'address_line_1',
]

const shouldLog = true;

const debugLog = text => {
    if(shouldLog) {
        console.log(text)
    }
}


class Gateway extends React.Component {

    constructor(props) {
        super(props);

        let user = props.user;

        //if we don\'t have a user set them to an object
        if(!user) {
            user = {}
        }

        const state = {
            ...props,
            given_name:         user.given_name,
            family_name:        user.family_name,
            email:              user.email,
            address_line_1:     user.address_line_1,
            address_line_2:     user.address_line_2,
            city:               user.city,
            state:              user.state,
            postal_code:        user.postal_code,
            couponSuccess:      false,
            couponUsed:         false
        };

      
        this.state = state
      }

    toggleShowCardEntry = () => {
        this.setState({showCardEntry: !this.state.showCardEntry})
    }

    onInputChange = (e, stateName) => {

        let newState = Object.assign({}, this.state);
        newState[stateName] = e.target.value;

        if (stateName === "checkbox") {
          if (e.target.value) {
            newState.checkboxState = "valid";
          } else {
            newState.checkboxState = "invalid";
          }
        } else {
          if (e.target.value === "") {
            newState[stateName + "State"] = "invalid";
          } else {
            newState[stateName + "State"] = "valid";
          }
        }

        this.setState({
           ...newState
        });
    };

    fireAlert = (params) => {
        this.setState({
          alert: (
            <ReactBSAlert
              success={params.error ? false : true}
              danger={!params.error ? false : true}
              style={{ display: "block", marginTop: "-100px" }}
              title={params.error ? 'Error' : 'Success'}
              onConfirm={() => this.hideAlert()}
              onCancel={() => this.hideAlert()}
              confirmBtnBsStyle="success"
              confirmBtnText="Ok"
              btnSize=""
            >
              {params.text}
            </ReactBSAlert>
          )
        });
    };
    
    hideAlert = () => {
        this.setState({
          alert: null
        });
    };

    validateFields = () => {
        return new Promise(resolve => {
            let newState = Object.assign({}, this.state);

            //assume we have no errors
            let errors = 0;

            required_form_fields.forEach((field) => {

                //check each required field
                if(newState[field]) {
                    newState[field + "State"] = 'valid'; 
                } else {
                    newState[field + "State"] = 'invalid'; 
                    errors++
                }

            })

            this.setState({ ...newState }, () => {

                resolve(errors)

            });
        })
    }

    submit = async (token)  =>{

        const errors = await this.validateFields();

        console.log(token)

        if(!errors) {

            this.setState({loading: 'Verifying Information'})

            const one_time = this.props.one_time;

            //required on all
            let processObject = { 
                one_time,
                user: {
                    given_name: this.state.given_name,
                    family_name: this.state.family_name,
                    email: this.state.email,
                    address_line_1: this.state.address_line_1,
                    address_line_2: this.state.address_line_2,
                    city: this.state.city,
                    state: this.state.state,
                    postal_code: this.state.postal_code,
                    stripe_cus_id: this.state.user.stripe_cus_id
                }
             }

            if(one_time) {

                processObject.amount = this.state.amount;
                processObject.description = this.state.description;

            } else {

                processObject.stripe_plan_id = this.state.stripe_plan_id;

            }


            const processed = await processStripe(token, processObject);

            if(processed.error == null) {

                //send back success response
                this.props.onSuccess({
                    ...processed,
                    couponUsed: this.state.couponUsed,
                    user: {
                        given_name: this.state.given_name,
                        family_name: this.state.family_name,
                        email: this.state.email,
                        address_line_1: this.state.address_line_1,
                        address_line_2: this.state.address_line_2,
                        city: this.state.city,
                        state: this.state.state,
                        postal_code: this.state.postal_code,
                    }
                })
               
            } else {

                this.fireAlert({
                    error: true,
                    text: <span>{processed.error}</span>
                })

            }

            this.setState({loading: null})
 
        } // end error checking internal

    }

    onClaimDiscount = async () => {

        this.setState({couponError: null})
        this.setState({couponSuccess: null})

        const coupons = this.props.coupons;

        if(coupons && coupons.length) {

            const found_coupon = coupons.find(c => c.name === this.state.code && c.subscription_key);

            if(found_coupon) {

                if(found_coupon.times_used  < found_coupon.total_uses) {

                    this.setState({amount: found_coupon.amount, couponSuccess: true, couponUsed: found_coupon})
                    
                } else {

                    this.setState({couponError: 'This discount has expired.'})

                }

            } else {

                this.setState({couponError: 'Invalid discount code.'})

            }

        } else {

            //we should not get here as the coupon input should render
            //even so create a fallback incase
            this.setState({couponError: 'Invalid discount code.'})

        }

    }


    render() {

        const amount = this.state.amount;

        return (
            <>
             
            {this.state.alert}

            {this.state.loading && (
                <div className="circleLoader">
                    <div className="loader-wrapper">
                        <div className="loader"></div>
                        <p>{this.state.loading}</p>
                    </div>
                </div>
            )}
            
            <Row className="AdOns-payments">
            
                <Col lg="7" className="col-left shadow">
                    
                    <div className="col-wrapper">

                        <Row className="mb-4">
                            <Col md={4}  sm={6} xs={12}>
                                <img className="w-100 logo" src={this.state.logo} alt="" />   
                            </Col>
                            
                            <Col md={8}  sm={6} xs={12} className="text-right icon-container">
                                <img className="w-50 text-right ml-auto icons" src={require('assets/img/app/PaymentsIcons.png')}  alt="" />
                            </Col>
                        </Row>

                        <h2 className="display-4">Payment Details</h2>

                        <FormGroup>
                            <label className="form-control-label" htmlFor="email"> Contact Information </label>
                            
                            <Input
                                value={this.state.email || ''}
                                placeholder="Email"
                                type="text"
                                valid={ this.state.emailState === "valid" }
                                invalid={ this.state.emailState === "invalid" }
                                onChange={e => this.onInputChange(e, "email") }  
                            />
                        </FormGroup>

                        <FormGroup>

                            <label className="form-control-label" htmlFor="name"> Name / Address On Card </label>
                            
                            <Row>
                                <Col md={6} sm={12}>
                                    <Input
                                        value={this.state.given_name || ''}
                                        placeholder="First Name"
                                        type="text"
                                        valid={ this.state.given_nameState === "valid" }
                                        invalid={ this.state.given_nameState === "invalid" }
                                        onChange={e => this.onInputChange(e, "given_name") }  
                                    />
                                </Col>

                                <Col md={6} sm={12}>
                                    <Input
                                        value={this.state.family_name || ''}
                                        placeholder="Last Name"
                                        type="text"
                                        valid={ this.state.family_nameState === "valid" }
                                        invalid={ this.state.family_nameState === "invalid" }
                                        onChange={e => this.onInputChange(e, "family_name") }  
                                    />
                                </Col>
                            </Row>

                            <div className="mt-3"></div>

                            <Input
                                value={this.state.address_line_1 || ''}
                                placeholder="Address"
                                type="text"
                                valid={ this.state.address_line_1State === "valid" }
                                invalid={ this.state.address_line_1State === "invalid" }
                                onChange={e => this.onInputChange(e, "address_line_1") }  
                            />
                            <div className="mt-3"></div>

                            <Input
                                value={this.state.address_line_2 || ''}
                                placeholder="Address Line 2"
                                type="text"
                                valid={ this.state.address_line_2State === "valid" }
                                invalid={ this.state.address_line_2State === "invalid" }
                                onChange={e => this.onInputChange(e, "address_line_2") }  
                            />

                            <div className="mt-3"></div>

                            <Row>

                                <Col lg={4} sm={12}>
                                    <Input
                                        value={this.state.city || ''}
                                        placeholder="City"
                                        type="text"
                                        valid={ this.state.cityState === "valid" }
                                        invalid={ this.state.cityState === "invalid" }
                                        onChange={e => this.onInputChange(e, "city") }  
                                    />
                                </Col>
                                
                                <Col lg={4} sm={12}>
                                    <Input
                                        value={this.state.state || ''}
                                        placeholder="State"
                                        type="text"
                                        valid={ this.state.stateState === "valid" }
                                        invalid={ this.state.stateState === "invalid" }
                                        onChange={e => this.onInputChange(e, "state") }  
                                    />
                                </Col>

                                <Col lg={4} sm={12}>
                                    <Input
                                        value={this.state.postal_code || ''}
                                        placeholder="Zip"
                                        type="text"
                                        valid={ this.state.postal_codeState === "valid" }
                                        invalid={ this.state.postal_codeState === "invalid" }
                                        onChange={e => this.onInputChange(e, "postal_code") }  
                                    />
                                </Col>

                            </Row>
                        </FormGroup>

                        {this.props.coupons && this.props.coupons.length && (
                            <FormGroup>

                                <label className="form-control-label" htmlFor="name"> Discount Code </label>

                                <Input
                                    value={this.state.code || ''}
                                    placeholder="Enter Code"
                                    type="text"
                                    valid={ this.state.codeState === "valid" }
                                    invalid={ this.state.codeState === "invalid" }
                                    onChange={e => this.onInputChange(e, "code") }  
                                />

                                {this.state.couponError && (
                                    <p className="text-danger small mb-0 mt-2">{this.state.couponError}</p>
                                )}
                                {this.state.couponSuccess && (
                                    <p className="text-success small mb-0 mt-2">Discount Applied</p>
                                )}
                                <button className="btn btn-info btn-sm mt-3" onClick={this.onClaimDiscount}>Claim Discount</button>
                            </FormGroup>
                        )}

                        <FormGroup>


                            {this.state.user.stripe_cus_id && !this.state.showCardEntry ? (
                                <>
                                    <hr />

                                    <div className="text-right">
                                        <button className="btn btn-success " onClick={this.submit}>Charge My Card ${(this.state.amount / 100).toFixed(2)}</button>
                                    </div>
                                    <div className="text-right mt-2" >
                                        <a className="text-muted small" href="#nothing" onClick={this.toggleShowCardEntry}>Add New Card</a>
                                    </div>

                                </>
                            ) : (
                                <StripeProvider apiKey={this.state.stripe_public_key}>

                                <div className="stripe">

                                    <label className="form-control-label" htmlFor="name"> Credit / Debit Card </label>

                                    <Elements>
                                        <CheckoutForm 
                                            submit={(token) => this.submit(token, amount)}
                                            formStyles={{color: 'white'}}
                                            buttonClasses="btn btn-s btn-success mt-3 text-right"
                                            buttonText={"Complete Purchase $" + (this.state.amount / 100).toFixed(2)}
                                        />
                                    </Elements>
                                    
                                    {this.state.user.stripe_cus_id && (
                                        <div className="text-right mt-2" >
                                            <a className="text-muted small " href="#nothing" onClick={this.toggleShowCardEntry}>Charge Card On File</a>
                                        </div>
                                    )}

                                </div>

                                </StripeProvider> 
                             )} 
                            
                        </FormGroup>

                    </div>

                    
                </Col>

                <Col lg="5" className="col-right bg-secondary">

                    <div className="col-wrapper">

                        <Table>
                            <tbody>
                                {this.state.items && this.state.items.length && this.state.items.map((i, index) => (
                                    <tr key={index}>
                                        <td style={styles.td}>{i.name}</td>
                                        <td className="text-right" style={styles.td}>{i.amount}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>

                        <hr style={{marginTop: '1rem', marginBottom: '1rem'}}/>

                        <Table>
                            <tbody>
                                <tr>
                                    <td style={styles.td}>Total</td>
                                    <td className="text-right" style={styles.td}>${(this.state.amount / 100 ).toFixed(2)}</td>
                                </tr>
                            </tbody>
                        </Table>

                    </div>

                </Col>
            </Row>

                      
            </>
        );
    }
}

const styles = {
    td: {
        fontSize: 16,
        padding: '6px',
        borderTop: 'none'
    }
}
 
Gateway.propTypes = {
    user:                       PropTypes.object,
   
    amount:                     PropTypes.number,
    items:                      PropTypes.array.isRequired,

    logo:                       PropTypes.string.isRequired,
    stripe_public_key:          PropTypes.string.isRequired,
    user_id:                    PropTypes.string,


    onSuccess:                  PropTypes.func.isRequired,
    one_time:                   PropTypes.bool.isRequired,
    stripe_plan_id:             PropTypes.string,

    coupons:                    PropTypes.array,

};


  


export default Gateway

/*
Documentation

user prop types: {
    given_name
    family_name
    email
    address_line_1
    address_line_2
    city
    state
    postal_code
    stripe_cus_id
}

example usage

<Gateway
    stripe_public_key=          {keys.STRIPE_PUBLISHABLE_KEY}
    logo=                       {keys.LOGO}
    user_id=                    {this.props.viewing_user._id}
    user=                       {this.props.viewing_user}
    one_time=                   {true}

    //if one_time amount and description are required
    amount=                     {39700}
    description=                 "Online Course"
    //if not one_time stripe_plan_id is required
    stripe_plan_id=             {''}

    //add any coupons here
    coupons=                    {this.state.coupons}

    items=                      {items}
    onSuccess={                 (response) => this.onSuccess(response) }
/>

*/