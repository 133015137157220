import { aosCall } from './database'

export default async () => {

    const websiteData = await aosCall({
        method: 'get',
        url: '/api/live/site/get/all',
    });

    if(websiteData.success) {
        

        if(websiteData.response && websiteData.response.length) {

            const data = websiteData.response

            //if we have a website title
            const title = data.find(d => d.key === 'title');

            if(title) {
                document.title = title.value;
            }
           
           

        }

    }



}