/*

This file is a test component for this plugin

*/
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Badge, Card, CardImgOverlay, CardText, CardTitle, Col, Container, Row } from "reactstrap";
import Header from "../components/Header";
import { buildModules } from '../functions/modules';
import { trimDescription } from '../functions/text';
import Payments from "./Payments";







class ModuleLessons extends Component {


    state = {

    };


    componentDidMount = () => {
        this.fetchData(this.props);
    }

    componentWillReceiveProps = async (nextProps) => {
        this.fetchData(nextProps);
    }

    fetchData = async (props) => {

        // const found_module = compileModules({
        //     modules: props.modules,
        //     lessons: props.lessons,
        //     module_id: this.props.match.params.module_id,
           
        // })

        // this.setState({module: found_module})


        const app_users = props.app_users
        const params = {
            modules: props.modules,
            lessons: props.lessons,
            module_id: this.props.match.params.module_id,
            comments: props.comments,
            lessons_finished: props.lessons_finished,
            app_users: app_users,
            viewing_user: props.viewing_user
        }



        let module = await buildModules(params);
        module = module[0]

        this.setState({module})



    }

   
    render() {

        const found_module = this.state.module


        return (
            <>
              <Payments props={this.props} >
            <Header icon={true} title={<span>{found_module && found_module.name} </span>} breadcrumb_1={found_module && found_module.lessons ? found_module.lessons.length + ' Lessons' : null}/>

            <Container className="mt--6" fluid>

                <Row>

                   {found_module && found_module.lessons && found_module.lessons.length ?(
                       found_module.lessons.map((l, index) => (
                        <Col key={l._id} xl="6" l="6" md="6" sm="12">
                   
                            <Link to={"/dashboard/lesson/" + l.module_id + '/' + l._id}>

                            <Card 
                                className="bg-dark text-white border-0" 
                                style={{
                                    backgroundPosition: 'center', 
                                    backgroundSize: 'cover', 
                                    height: 200, 
                                    backgroundImage: 'url("'+l.picture+'")'
                                }}>

                                    <CardImgOverlay className="d-flex align-items-center" style={l.finished ? styles.overlay : styles.overlay_not_finished} >
                                        <div>
                                        <CardTitle className="h2 text-white mb-0">Lesson {index + 1}</CardTitle>
                                      
                                        <CardText>
                                            {l.name} 
                                        </CardText>
                                        <span className="text-sm font-weight-bold text-white" style={styles.text}>
                                        {trimDescription(l.description)}
                                        </span>
                                        </div>
                                    </CardImgOverlay>

                                    {l.finished && <Badge style={styles.pill} color="secondary" pill>Finished</Badge>}

                                </Card>

                            </Link>
                            
                        </Col>
                       ))
                   ): null}

                    
                </Row>
            </Container>
            </Payments>
            </>

        )

    }

}

const styles = {
    finished: {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        background: 'linear-gradient(to bottom left, rgba(255,255,255,.5), rgba(255,255,255,.1)) ',
        borderRadius: 5,
        zIndex: 1000000
    },

    pill: {
        position: 'absolute',
        top: 15,
        right: 15
    },

    overlay: {
        background: 'rgba(0,0,0,.7)',
        borderRadius: 5
    },

    overlay_not_finished: {
        background: 'rgba(0,0,0,.2)',
        borderRadius: 5
    },

    text: {
        maxWidth: 500,

    }

}


const mapStateToProps = state => {
    return {
        modules: state.CoursePlatform.modules,
        lessons: state.CoursePlatform.lessons,
        comments: state.CoursePlatform.comments,
        lessons_finished: state.CoursePlatform.lessons_finished,
        viewing_user: state.SYSTEM.viewing_user,
        app_users: state.SYSTEM.app_users,
      
    };
};

  
export default connect(mapStateToProps )(ModuleLessons);  