// import React from "react";
// import { connect } from 'react-redux';
// import Coupons from '../../../ad-ons/payments/Coupons';

// class AdmCoupons extends React.Component {

//     state = {}


//     render() {

//         return (

//             <Coupons 
//                 type="charge"
//                 subscription_key="CoursePlatform_standard"
//             />
                
//         );
//     }
// }



// //not sure why this needs to be in connect but it does
// export default connect('')(AdmCoupons);

/*

This file is a test component for this plugin

*/
import React, { Component } from 'react';
import ReactBSAlert from "react-bootstrap-sweetalert";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import { Link } from 'react-router-dom';
import { Button, Card, CardBody, CardHeader, Col, Container, FormGroup, Input, Row } from "reactstrap";
import { aosAction, aosCall } from '../../../database';
import Header from "../components/Header";
import { connect } from 'react-redux';
import moment from 'moment';



const pagination = paginationFactory({
  page: 1,
  alwaysShowAllBtns: true,
  showTotal: true,
  withFirstAndLast: false,
  sizePerPageRenderer: ({ options, currSizePerPage, onSizePerPageChange }) => (
    <div className="dataTables_length" id="datatable-basic_length">
      <label>
        Show{" "}
        {
          <select
            name="datatable-basic_length"
            aria-controls="datatable-basic"
            className="form-control form-control-sm"
            onChange={e => onSizePerPageChange(e.target.value)}
          >
            <option value="10">10</option>
            <option value="25">25</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </select>
        }{" "}
        entries.
      </label>
    </div>
  )
});

const { SearchBar } = Search;



let required_form_fields = [
    'name',
    'total_uses',
    
]

class Coupons extends React.Component {


        state = {
            coupons: null,
            identifier_system_static: this.props.type === 'subscription' ? 'stripe_plan_id' : 'amount'
        };


        columns = [
        {
            dataField: "name",
            text: "Coupon Name",
            formatter: (cell, row) => {
                return row.coupon ? row.coupon.name : 'No Coupon Found'
            },
            filterValue: (cell, row) => row.coupon ? row.coupon.name : 'No Coupon Found'


        },{
            dataField: "user",
            text: "User",
            formatter: (cell,row) => {
                return row.user ? row.user.given_name + ' ' + row.user.family_name: "No User Found"
            },
            filterValue: (cell, row) => row.user ? row.user.given_name + ' ' + row.user.family_name: "No User Found"

        },{
            dataField: "amount",
            text: "Amount",
            formatter: (cell, row) => {
                return row.amount ? '$' + (row.amount / 100).toFixed(2) : 'no amount found'
            }
        },{
            dataField: "created_at",
            text: "Date Used",
            formatter: (cell, row ) => {
                return moment.unix(row.created_at).format('MMM Do, YYYY - h:mm A')
            }
        },
    ]


    componentDidMount = async () => {

        required_form_fields.push(this.props.type === 'subscription' ? 'stripe_plan_id' : 'amount')

        const getCoupons = await aosCall({
            method: 'get',
            url: '/api/system_coupons_used/all',      
        });

        console.log(getCoupons)


        if(getCoupons.success){
            
            this.setState({coupons: getCoupons.data})

        } else {
            console.log(getCoupons)
        }


    }

    render() {

        const coupons = this.state.coupons;
        const identifier = this.props.type === 'subscription' ? 'Stripe Plan Id' : 'Amount (In Cents)'
        const identifier_system = this.props.type === 'subscription' ? 'stripe_plan_id' : 'amount'


        return (
            <>

            {this.state.alert}
            
            <Header 
                icon={true} 
                title={<Link to="/admin/CoursePlatform/modules"><i className="mr-2 fas fa-arrow-left"></i>Coupons</Link>} 
                breadcrumb_1="My Coupons"  
               
            />

            <Container className="mt--6 mb-5" fluid>
          
                {coupons && coupons.length ? (
                    <Card>
                        <CardHeader>
                            <Row className="align-items-center">
                                <Col xs="8">
                                    <h3 className="mb-0">My Coupons</h3>
                                </Col>
                            </Row>
                        </CardHeader>

                        <CardBody>

                        <div className="table-vertical-align">
        
                            <ToolkitProvider
                                data={coupons}
                                keyField="_id"
                                columns={this.columns}
                                search
                                exportCSV
                            >
                                {props => (
                                    <>
                                    <div
                                        id="datatable-basic_filter"
                                        className="dataTables_filter px-4 pb pt-3 pb-2"
                                        style={{width: '100%'}}
                                    >
                                        <Row>

                                            <Col>
                                                <label>
                                                    Search:
                                                    <SearchBar
                                                        className="form-control-sm"
                                                        placeholder=""
                                                        {...props.searchProps}
                                                    />
                                                </label>
                                            </Col>



                                        </Row>
                                    </div>
                                    <div className="pb-4 table-responsive">
                                
                                    <BootstrapTable
                                        {...props.baseProps}

                                        bootstrap4={true}
                                        pagination={pagination}
                                        bordered={false}
                                    />
                                    </div>
                                    </>
                                )}
                                </ToolkitProvider>
                            </div>
                        </CardBody>
                    </Card>
                ) : (
                    <Card>
                        <CardBody>
                            <p className="mb-0">No coupons have been used yet.</p>
                        </CardBody>
                    </Card>
                )}
            </Container>


            </>

        )

    }
 
}

export default connect('')(Coupons);
  
  


