/*

Add all routes for this plugin here.

*/

import Home from './Views/Home';
import TermsOfService from './Views/TermsOfService';



export default [

    {
        path: "/terms-of-service",
        component: TermsOfService,
        layout: "",
        renderOn: 'site'
    },


    {
        path: "/",
        component: Home,
        layout: "",
        renderOn: 'site'
    },


]