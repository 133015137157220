/*

Add all routes for this plugin here.

*/

import SystemLogs from './Views/SystemLogs';



export default [
  {

      divider: 'Developers',
      path: "/AdminSystemLogs/logs",
      name: "SystemLogs",
      icon: "fas fa-code text-danger",    //sidebar icon
      component: SystemLogs,           //component to show in route
      renderOn: "admin",                   //which layout to use
      layout: "/admin",
      show_in_sidebar: true
  },


]