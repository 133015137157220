// import  keys  from '../../../keys';
import Dropzone from "dropzone";
import React from "react";
import { connect } from 'react-redux';
import { Col, Modal, Row } from "reactstrap";
import { aosCall } from '../../database';
import keys from '../../keys';
import ReactCrop from 'react-image-crop';



class Editor  extends React.Component {

    state = {
        images: [],
        crop: this.props.crop ?  this.props.crop : null,
        imageHeight: 200 //default image height

    }

    getImageHeight = () => {

        const windowWidth = window.innerWidth;
        const modalWidth = windowWidth * .9;
        const imageContainer = modalWidth - 680;
        const imageHeight = imageContainer / 6;

        this.setState({imageHeight})


    }

     //you setState the crop in here you should return false.
    onImageLoaded = image => {
        this.imageRef = image;
    };
    
      onCropComplete = crop => {
        this.makeClientCrop(crop);
      };
    
      onCropChange = (crop, percentCrop) => {
        // You could also use percentCrop:
        // this.setState({ crop: percentCrop });
        this.setState({ crop });
      };
    
      async makeClientCrop(crop) {
        if (this.imageRef && crop.width && crop.height) {
          const croppedImageUrl = await this.getCroppedImg(
            this.imageRef,
            crop,
            "newFile.jpeg"
          );
  
          
          

          this.setState({ croppedImageUrl });
        }
      }
    
      getCroppedImg(image, crop, fileName) {
        const canvas = document.createElement("canvas");
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        canvas.width = crop.width;
        canvas.height = crop.height;
        const ctx = canvas.getContext("2d");
    
        ctx.drawImage(
          image,
          crop.x * scaleX,
          crop.y * scaleY,
          crop.width * scaleX,
          crop.height * scaleY,
          0,
          0,
          crop.width,
          crop.height
        );
    
        return new Promise((resolve, reject) => {
          try {
              canvas.toBlob(blob => {
                  if (!blob) {
                    //reject(new Error('Canvas is empty'));
                    console.error("Canvas is empty");
                    return;
                  }
                  blob.name = fileName;
                  window.URL.revokeObjectURL(this.fileUrl);
                  this.fileUrl = window.URL.createObjectURL(blob);
                  resolve(this.fileUrl);
                }, "image/jpeg");
          } catch(e) {
              this.onCropComplete()
          }
        });
      }
  

      onCropped = async () => {

        this.state.Dropzone.removeFile(this.state.original)

        let response = await fetch(this.state.croppedImageUrl);
        let data = await response.blob();
        let metadata = {
            type: 'image/jpeg'
        };


        let file = new File([data], this.state.original.name, metadata);


        // this.state.Dropzone.uploadFiles([file])
        this.state.Dropzone.addFile(file);

        this.setState({
            hasFiles: [{
                file,
                url: this.state.croppedImageUrl,
            
            }],
            src: null
        })

       
      }

    componentDidMount = async () => {

        //get images from cloudinary and set the state
        const images = await aosCall({
            method: 'get',
            url: '/api/cloudinary/images/get/all'
        })

        this.getImageHeight()
        window.addEventListener("resize",  this.getImageHeight.bind(this));


        this.setState({current_images: images.response})
    }



    componentWillReceiveProps = (nextProps) => {

        if(nextProps.show !== this.props.show) {
            this.setState({show: nextProps.show})
        }
        
    }

    componentDidUpdate = () => {


        try {

        //set dropzone as the state so it can be accessed late when we want to upload the file
            this.setState({
                Dropzone: new Dropzone(document.getElementById("dropzone-single"), {
                url: keys.API_URL + '/api/cloudinary/upload',
                withCredentials: true,
                thumbnailWidth: null,
                thumbnailHeight: null,
                resizeWidth: 1280, 
                resizeHeight: 1280,
                resizeMethod: 'contain', 
                resizeQuality: 1.0,
                previewsContainer: document.getElementsByClassName( "dz-preview-single" )[0],
                previewTemplate: document.getElementsByClassName("dz-preview-single")[0].innerHTML,
                maxFiles: 20,
                autoQueue: false,
                parallelUploads: 100,
                // acceptedFiles: "image/*",
            
        })}, () => {

                //when a file is successfully uploaded
                this.state.Dropzone.on('success', (file, responseText) => {
                    this.setState({
                        hasFiles: false,
                        current_images: [
                            {
                                url: responseText.url
                            },
                        
                            ...this.state.current_images,
                            
                            
                        ]
                    })

                    this.state.Dropzone.removeAllFiles()
                })

                //when file is added add it to preview
                this.state.Dropzone.on("addedfile", async (original) => {


                    if(this.state.Dropzone.files && this.state.Dropzone.files.length) {

                       
                    
                        const read = new FileReader();
                        read.readAsDataURL(original);

                    
                        read.onloadend = (file) => {

                            if(!this.props.canSelectMultiple && this.props.crop) {


                                if(this.state.original) {

                                    if(this.state.original.name === original.name) {
                                        this.setState({  original: original })
                                    }  else {
                                        this.setState({ src: read.result, original: original })
                                       
                                    }

                                } else {
                                
                                    this.setState({ src: read.result, original: original })
                                }
                              
                                
                            } else {

                                const files = this.state.hasFiles;

                                if(!files) {
                                    this.setState({hasFiles: [{
                                        file: original,
                                        url: read.result
                                    }]})
                                } else {
                                    this.setState({hasFiles: [{
                                        file: original,
                                        url: read.result
                                    }, ...this.state.hasFiles]})
                                }

                            }


                            

                        }

                        
                    } else {
                        this.setState({hasFiles: false})
                    }
                
                });

            })

        document.getElementsByClassName("dz-preview-single")[0].innerHTML = "";
        
        } catch(e) {

            //do nothing for now

        }

    }

    onUpload = () => {

        const Dropzone = this.state.Dropzone

        if(Dropzone.files && Dropzone.files.length) {

            //send off the files
            Dropzone.files.forEach(async (f, index) => {

                Dropzone.enqueueFile( Dropzone.files[index]);
                
            })

        } else {
            console.log('new file')
        }
        
    }

    onRemove = (file, index) => {

        //remove from the dropzone
        this.state.Dropzone.removeFile(file)

        //remove from preview
        if(this.state.hasFiles && this.state.hasFiles.length) {

            let currentFiles = [...this.state.hasFiles]

            currentFiles = currentFiles.filter((f, i) => i !== index)

            this.setState({hasFiles: currentFiles})
        }

       

    }

    toggleModal = () => {

        this.setState({show: !this.state.show})

    }

    onSelect = (url) => {


        let images = [...this.state.images]

        if(images.includes(url)) {

            if(this.props.canSelectMultiple) {

                images = images.filter(i => i !== url);

            } else {

                images = [];

            }

            
        } else {

            if(this.props.canSelectMultiple ) {
                images.push(url)
            } else {
                images = [url];
            }

            
        }

        this.setState({images})

    }

    getDataUrlFromUrl = async (url, callback) => {
        let blob = await fetch(url).then(r => r.blob());
        let dataUrl = await new Promise(resolve => {
          let reader = new FileReader();
          reader.onload = () => resolve(reader.result);
          reader.readAsDataURL(blob);
        });

        callback(dataUrl)

    }

    onFinish = () => {

        //if we have set a crop ratio make sure the image selected meets the dimensions, if not bring up a cropper.
        if(this.state.images && this.state.images.length === 1 && this.props.crop) {

           this.getImageDimensions(this.state.images[0], async (width, height) => {

                const ratio = width / height;

                if(ratio !== 1) {

                    let response = await fetch(this.state.images[0]);
                    let data = await response.blob();
                    let metadata = {
                        type: 'image/jpeg'
                    };
            
            
                    let file = new File([data], new Date(), metadata);

                    this.getDataUrlFromUrl(this.state.images[0], (dataUrl) => {

                        this.setState({ src: dataUrl, original: file, images: [] })
                    })
            

                 


                } else {
                    this.props.onMediaSelect(this.state.images)
                    this.props.onMediaToggle();
                }



           })

        } else {

          if(this.state.images && this.state.images.length) {
            this.props.onMediaSelect(this.state.images)
            this.props.onMediaToggle();
          }

        }

        

    }

    getImageDimensions = (url, callback) => {
        var img = new Image();
        img.src = url;
        img.onload = function() { callback(this.width, this.height); }
    }

    getImageMarkup = (url) => {

        const isSelected = this.state.images.includes(url);
    
        return (
            // <Col key={url} lg={2} md={3} sm={3} xs={6} className="mb-3">
            <div style={{width: ((this.state.imageHeight / 2) * (3 / 2)), minWidth: 66, height: this.state.imageHeight / 2, minHeight: 44, padding: 7.5, marginBottom: 7.5}}>
                <div  
                    className={isSelected ? "w-100 border-danger" : "rounded w-100"} 
                    style={{
                        boxShadow: isSelected ? 
                            '0 15px 35px rgba(50, 50, 93, 0.2), 0 5px 15px rgba(0, 0, 0, 0.17)' : 
                            '0 4px 5px 0 rgba(0,0,0,0.14), 0 1px 10px 0 rgba(0,0,0,0.12), 0 2px 4px -1px rgba(0,0,0,0.3)',

                        minHeight: 44,
                        height: this.state.imageHeight / 2,
                        backgroundImage: 'url("'+url+'")', 
                        backgroundSize: 'cover', 
                        backgroundPosition: 'center',
                        border: isSelected ? 'solid 5px' : null
                    }} 
                    onClick={() => this.onSelect(url)}
                >

                </div>
            </div>
            // </Col>
        )
    }
    render() {

      
        const current_images = this.state.current_images

        const { crop,  src } = this.state;


        
        return (
        <>

            <ReactCrop src="path/to/image.jpg" />

            {src && (
                <div style={{overflow: 'scroll', position: 'fixed', top: 0, left: 0, right: 0, bottom: 0, background: 'rgba(0,0,0,.9)', zIndex: 999999999999}}>
                    <div className="mt-5 mb-5 text-center" style={{width: '90%', maxWidth: 1280, marginLeft: 'auto', marginRight: 'auto' }}>
                        <ReactCrop
                            src={src}
                            crop={crop}
                            onImageLoaded={this.onImageLoaded}
                            onComplete={this.onCropComplete}
                            onChange={this.onCropChange}
                        />

                    <div className=" mt-3">
                    <button className="btn btn-success" onClick={this.onCropped} >Crop Image</button>
                    </div>

                    </div>

                </div>
            )}
            



            <Modal style={{maxWidth: '90%', margin: 'auto', paddingTop: 50, paddingBottom: 50}} className="modal-full modal-dialog-centered" size="lg"  isOpen={true} toggle={() => this.props.onMediaToggle()}  >
                <div className="modal-body">

                   <Row>
                       <Col md={8} sm={12}>
                            <h1>Image Picker</h1>
                            <p> Select or upload an image. (Max 20 images per upload)</p>
                       </Col>
                       <Col md={4} sm={12} className="text-right">
                            <button onClick={this.onFinish} className="btn btn-success">Select Image(s)</button>
                       </Col>
                   </Row>

                    <hr/>

                    <Row>

                        <Col sm={3} className="mb-3" style={{borderRight: 'solid 1px #eee'}}>
                           
                            <div className="dropzone dropzone-single mb-3" id="dropzone-single">
                                <div className="fallback">
                                    <div className="custom-file">
                                    <input
                                        className="custom-file-input"
                                        id="projectCoverUploads"
                                        type="file"
                                    />
                                    <label
                                        className="custom-file-label"
                                        htmlFor="projectCoverUploads"
                                    >
                                        Choose file
                                    </label>
                                    </div>
                                </div>
                                <div className="dz-preview dz-preview-single">
                                    <div className="dz-preview-cover">
                                    <img
                                        alt="..."
                                        className="dz-preview-img"
                                        data-dz-thumbnail=""
                                    />
                                    </div>
                                </div>
                            </div>

                           {this.state.hasFiles && (
                                <button className="btn btn-block btn-success" onClick={() => this.onUpload()} >Upload</button>
                           )}

                            
                            {this.state.hasFiles && this.state.hasFiles.length ? (
                                <>

                                <hr />
                                <h2>Preview Uploads</h2>

                                <Row>
                                    {this.state.hasFiles.map((i, index) => (
                                    <Col key={index} sm={6} className="mb-3">

                                        <div  
                                            className="rounded w-100" 
                                            style={{boxShadow: '0 15px 35px rgba(50, 50, 93, 0.2), 0 5px 15px rgba(0, 0, 0, 0.17)', 
                                                height: this.state.imageHeight / 1.5, 
                                                backgroundImage: 'url("'+i.url+'")', 
                                                backgroundSize: 'cover', 
                                                backgroundPosition: 'center'
                                            }} 
                                            onClick={() => this.onRemove(i.file, index)}
                                        >

                                        </div>
                                    </Col>
                                    ))}
                                </Row>
                                </>
                            ): null}
                           

                        </Col>

                        <Col sm={9}>

                            <h2>My Images</h2>

                            <Row style={{paddingLeft: 7.5, paddingRight: 7.5}}>
                                {current_images && current_images.length ? current_images.map(i => ( this.getImageMarkup(i.url) )): null}
                            </Row>
                        </Col>

                    </Row>

                </div>
            </Modal>

         
        </>
        );
    }
}


const mapStateToProps = state => {


    return {
      
      
    };
};

  
export default connect(mapStateToProps )(Editor);  