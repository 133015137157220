/*

functions here will fire whenever a this plugins containing layout is finished
they must all return a promise

*/

import { aosListen } from '../../database';
import * as actionTypes from './actions';
import store from '../../plugins_core/store/index'

const getModules = () => {
    return new Promise((resolve) => {
        aosListen.listen('/api/live/CoursePlatform__modules/get/all?orderBy=order&order=asc', (data) => {
            

            if(data.success) {

                store.dispatch({
                    type: actionTypes.COURSE_PLATFORM_SET_MODULES,
                    payload: {
                        objects: data.response
                    }
                });

                resolve(true);
                return;

            } else {

                //error reporting here
                resolve(true);
                return;

            }

        }, (e) => {

            console.log(e);
            resolve(true);
            return;
            

        })
    })
}

const getLessons = () => {
    return new Promise((resolve) => {
        aosListen.listen('/api/live/CoursePlatform__lessons/get/all?orderBy=order&order=asc', (data) => {
            
            if(data.success) {

                store.dispatch({
                    type: actionTypes.COURSE_PLATFORM_SET_LESSONS,
                    payload: {
                        objects: data.response
                    }
                });

                resolve(true);
                return;


            } else {

                //error reporting here
                resolve(true);
                return;

            }

        }, (e) => {

            console.log(e);
            resolve(true);
            return;
            
        })
    })
}


const getLessonsFinished = () => {
    return new Promise((resolve) => {
        aosListen.listen('/api/live/CoursePlatform__lessons_finished/get/all', (data) => {
            
            if(data.success) {

                store.dispatch({
                    type: actionTypes.COURSE_PLATFORM_SET_LESSONS_FINISHED,
                    payload: {
                        objects: data.response
                    }
                });

                resolve(true);
                return;


            } else {

                //error reporting here
                resolve(true);
                return;

            }

        }, (e) => {

            console.log(e);
            resolve(true);
            return;
            
        })
    })
}

const getComments = () => {

    return new Promise((resolve) => {

        aosListen.listen('/api/live/CoursePlatform__comments/get/all?populate=user+lesson', (data) => {
      
            if(data.success) {

                store.dispatch({
                    type: actionTypes.COURSE_PLATFORM_SET_COMMENTS,
                    payload: {
                        objects: data.response
                    }
                });

                resolve(true);
                return;


            } else {

                //error reporting here
                resolve(true);
                return;

            }

        }, (e) => {

            console.log(e);
            resolve(true);
            return;
            
        })
    })
}



// const getSupport = () => {
//     return new Promise((resolve) => {
//         aosListen.listen('/api/live/Support__support/get/all?orderBy=_id&order=asc', (data) => {
            
//             console.log('fired')
           
            
//             if(data.success) {

//                 store.dispatch({
//                     type: actionTypes.COURSE_PLATFORM_SET_SUPPORT,
//                     payload: {
//                         objects: data.response
//                     }
//                 });

//                 resolve(true);
//                 return;


//             } else {

//                 //error reporting here
//                 resolve(true);
//                 return;

//             }

//         }, (e) => {

//             console.log(e);
//             resolve(true);
//             return;
            
//         })
//     })
// }



export default [

    {
        func: getModules, //a promise returning function that sets a redux action in this plugin
        plugin_name: 'CoursePlatform', //DON'T CHANGE!  MUST BE SAME AS PLUGIN
        prop: 'modules',
        run_in_background: false,
        should_not_reload: false, //don't reload whenever we mount this plugins containing layout
    },

    {
        func: getLessons, //a promise returning function that sets a redux action in this plugin
        plugin_name: 'CoursePlatform', //DON'T CHANGE!  MUST BE SAME AS PLUGIN
        run_in_background: false,
        prop: 'lessons',
        should_not_reload: false, //don't reload whenever we mount this plugins containing layout
    },

    {
        func: getLessonsFinished, //a promise returning function that sets a redux action in this plugin
        plugin_name: 'CoursePlatform', //DON'T CHANGE!  MUST BE SAME AS PLUGIN
        run_in_background: false,
        prop: 'lessons_finished',
        should_not_reload: false, //don't reload whenever we mount this plugins containing layout
    },

    {
        func: getComments, //a promise returning function that sets a redux action in this plugin
        plugin_name: 'CoursePlatform', //DON'T CHANGE!  MUST BE SAME AS PLUGIN
        prop: 'comments',
        run_in_background: false,
        should_not_reload: false, //don't reload whenever we mount this plugins containing layout
    },
    // {
    //     func: getSupport, //a promise returning function that sets a redux action in this plugin
    //     plugin_name: 'CoursePlatform', //DON'T CHANGE!  MUST BE SAME AS PLUGIN
    //     prop: 'support',
    //     run_in_background: false,
    //     should_not_reload: false, //don't reload whenever we mount this plugins containing layout
    // },
   
]