

import { customerCardAdd, customerCardSetDefaultSource, customerCreate } from '../../universal/functions/stripe';

/*
Documentation

returns object {

    error: null or string
    new_customer: true || false
    customer: stripe customer object or null

}

*/


export default (token_id, customer_id, user_id, user) => {
    return new Promise(async resolve => {

        const r = {
            error: null,
            new_customer: false,
        }

        if(!token_id) {
            r.error = 'Internal Error, no token_id was passed to processAddedCard.';
            resolve(r); return;
        }
                
        if(!customer_id) { //create a new customer

            r.new_customer = true

            const name = user.given_name + ' ' + user.family_name;

            let customer = await customerCreate({
                source: token_id,
                description: name,
                email: user.email,
                metadata: {                    
                    'user_id:': user_id,
                    'primary_user_name:': name,
                    'primary_user_id': user_id,
                }
            });

            if(customer.success) {

                r.customer = customer.customer;
                resolve(r); return;
                
            } else {

                r.error = customer.message;
                resolve(r); return;

            }



        } else { //add card to existing customer

           

            // this.setState({loading: 'Adding Your Card'})
            let addCard = await customerCardAdd(customer_id, token_id);

            if(addCard.success) {


                let addedCard = addCard.card
                let setCard = await customerCardSetDefaultSource(customer_id, addedCard.id);
                
                if(setCard.success) {
        
                    r.customer = setCard.customer;
                    resolve(r); return;
                    
                } else {
                    
                    r.error = setCard.message;
                    resolve(r); return;
    
                }

            } else {

                r.error = addCard.message;
                resolve(r); return;

            }

        }

    })
}