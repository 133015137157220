import React from "react";
import { connect } from 'react-redux';

import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col
} from "reactstrap";

import States from '../components/Inputs/States'
import Header from '../components/Header'
import ReactBSAlert from "react-bootstrap-sweetalert";

import { aosAction } from '../../../database';
import UserUpload from '../../../ad-ons/media/UserUpload';

const required_form_fields = [
    'given_name',
    'family_name',
    'email',
    'phone',
    'address_line_1',

    'city',
    'state',
    'postal_code'
]

class Profile extends React.Component {

    state = {
        user: this.props.viewing_user,
        canSave: true
    }

    fireAlert = (error) => {

        this.setState({
          alert: (
            <ReactBSAlert
              success={error ? false : true}
              danger={!error ? false : true}
              style={{ display: "block", marginTop: "-100px" }}
              title={error ? 'Error' : 'Success'}
              onConfirm={() => this.hideAlert()}
              onCancel={() => this.hideAlert()}
              confirmBtnBsStyle="success"
              confirmBtnText="Ok"
              btnSize=""
            >
              {error ? 
              'Something went wrong updating your profile' :
              'Profile Updated Successfully'
            }
            </ReactBSAlert>
          )
        });
    };

    firePasswordAlert = (error) => {

        this.setState({
          alert: (
            <ReactBSAlert
              success={error ? false : true}
              danger={!error ? false : true}
              style={{ display: "block", marginTop: "-100px" }}
              title={error ? 'Error' : 'Success'}
              onConfirm={() => this.hideAlert()}
              onCancel={() => this.hideAlert()}
              confirmBtnBsStyle="success"
              confirmBtnText="Ok"
              btnSize=""
            >
              {error ? 
              'Something went wrong changing your password.' :
              'Your password has been successfully changed'
            }
            </ReactBSAlert>
          )
        });
    };


    hideAlert = () => {
        this.setState({
          alert: null
        });
    };

    onSave = async () => {

        this.setState({canSave: false})
        //copy state
        let newState = Object.assign({}, this.state.user);
        //assume we have no errors
        let errors = 0;

        required_form_fields.forEach((field) => {

            //check each required field
            if(newState[field]) {
                newState[field + "State"] = 'valid'; 
            } else {
                newState[field + "State"] = 'invalid'; 
                errors++
            }

        })

        //remove new_password fields
        delete newState.new_password
        delete newState.new_password_confirm
        //remove profile picture
        delete newState.picture_url

        this.setState({
          user: newState
        });


        //let the user try again but stop form saving
        if(errors) {
            this.setState({canSave: true})
        } else {

            //after load make sure button work again
            this.setState({canSave: true})

            const updated = await aosAction({
                method: 'post',
                url: '/api/live/users/update/' + this.props.viewing_user._id,
                aos_live_events: ['/api/live/users/get/all'],          
                data: newState,
            });

            this.fireAlert(!updated.success)

        }

    }

    onInputChange = (e, stateName) => {


        let newState = Object.assign({}, this.state.user);
        newState[stateName] = e.target.value;

        if (stateName === "checkbox") {
          if (e.target.value) {
            newState.checkboxState = "valid";
          } else {
            newState.checkboxState = "invalid";
          }
        } else {
          if (e.target.value === "") {
            newState[stateName + "State"] = "invalid";
          } else {
            newState[stateName + "State"] = "valid";
          }
        }

        this.setState({
          user: newState
        });
    };

    onPasswordUpdate = async  () => {

        this.setState({error_password: false})

        const updated = await aosAction({
            method: 'post',
            url: '/auth/credentials/password/change',
            aos_live_events: ['/api/live/users/get/all'],          
            data: {
                password: this.state.user.new_password,
                password_confirm: this.state.user.new_password_confirm,
                _id: this.props.viewing_user._id,
            }
        });

        if(updated.success) {

            this.firePasswordAlert(false)
            this.setState({ user: {
                ...this.state.user,
                new_password: null,
                new_password_confirm: null
            }})

        } else {

            this.setState({error_password: updated.message})

        }



    }

    onMediaSelect = async (url) => {

        const updated = await aosAction({
            method: 'post',
            url: '/api/live/users/update/' + this.props.viewing_user._id,
            aos_live_events: ['/api/live/users/get/all'],          
            data: {
                picture_url: url
            },
        });

        this.fireAlert(!updated.success)

    }
    
  render() {

        return (
            <>
            {this.state.alert}
            <Header icon={true} title={<span>Profile</span>} breadcrumb_1="Settings" />

            <Container className="mt--6" fluid>     

            <Row>
                <Col md={8}>
                    <Card>
                        <CardHeader>
                            <Row className="align-items-center">
                                <Col xs="8">
                                    <h3 className="mb-0">My Account</h3>
                                </Col>
                                <Col className="text-right" xs="4">
                                    <Button
                                        color="primary"
                                        onClick={this.onSave}
                                        size="sm"
                                        disabled={this.state.canSave ? false : true}
                                    >
                                    Save Profile
                                    </Button>
                                </Col>
                            </Row>
                        </CardHeader>

                        <CardBody>
                            <Form>

                                <h6 className="heading-small text-muted mb-4">User information</h6>

                                <div className="pl-lg-4">

                                    <Row>
                                        <Col lg="6">
                                            <FormGroup>
                                            <label className="form-control-label" htmlFor="given_name" > First name </label>

                                            <Input
                                                id="given_name"
                                                value={this.state.user.given_name || ''}
                                                placeholder="First name"
                                                type="text"
                                                valid={ this.state.user.given_nameState === "valid" }
                                                invalid={ this.state.user.given_nameState === "invalid" }
                                                onChange={e => this.onInputChange(e, "given_name") }
                                                
                                            />
                                            <div className="valid-feedback">Looks good!</div>

                                            </FormGroup>
                                        </Col>
                                        <Col lg="6">
                                            <FormGroup>
                                            <label className="form-control-label" htmlFor="family_name" > Last name </label>
                                            
                                            <Input
                                                id="family_name"
                                                value={this.state.user.family_name || ''}
                                                placeholder="Last name"
                                                type="text"
                                                valid={ this.state.user.family_nameState === "valid" }
                                                invalid={ this.state.user.family_nameState === "invalid" }
                                                onChange={e => this.onInputChange(e, "family_name") }
                                                
                                            />
                                            <div className="valid-feedback">Looks good!</div>
                                        
                                            </FormGroup>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col lg="6">
                                            <FormGroup>
                                            <label className="form-control-label" htmlFor="email"> Email address </label>
                                            <Input
                                                id="email"
                                                value={this.state.user.email || ''}
                                                placeholder="email@example.com"
                                                type="email"
                                                valid={ this.state.user.emailState === "valid" }
                                                invalid={ this.state.user.emailState === "invalid" }
                                                onChange={e => this.onInputChange(e, "email") }  
                                            />
                                            <div className="valid-feedback">Looks good!</div>

                                            </FormGroup>
                                        </Col>
                                        <Col lg="6">
                                            <FormGroup>
                                            <label className="form-control-label" htmlFor="phone"> Phone </label>
                                        
                                            <Input
                                                id="phone"
                                                value={this.state.user.phone || ''}
                                                placeholder="555-555-5555"
                                                type="tel"
                                                valid={ this.state.user.phoneState === "valid" }
                                                invalid={ this.state.user.phoneState === "invalid" }
                                                onChange={e => this.onInputChange(e, "phone") }
                                                
                                            />
                                            <div className="valid-feedback">Looks good!</div>

                                            </FormGroup>
                                        </Col>
                                    </Row>

                                </div>

                                <hr className="my-4" />
                                <h6 className="heading-small text-muted mb-4">Address</h6>

                                <div className="pl-lg-4">

                                    <Row>

                                        <Col md="6">
                                            <FormGroup>
                                            <label className="form-control-label" htmlFor="address_line_1" > Address Line 1 </label>

                                            <Input
                                                id="address_line_1"
                                                value={this.state.user.address_line_1 || ''}
                                                placeholder="198 Manhattan Ave"
                                                type="text"
                                                valid={ this.state.user.address_line_1State === "valid" }
                                                invalid={ this.state.user.address_line_1State === "invalid" }
                                                onChange={e => this.onInputChange(e, "address_line_1") }
                                                
                                            />
                                            <div className="valid-feedback">Looks good!</div>

                                            </FormGroup>
                                        </Col>

                                        <Col md="6">
                                            <FormGroup>
                                            <label className="form-control-label" htmlFor="address_line_2" > Address Line 2 </label>
                                            
                                            <Input
                                                id="address_line_2"
                                                value={this.state.user.address_line_2 || ''}
                                                placeholder="Apt D"
                                                type="text"
                                                valid={ this.state.user.address_line_2State === "valid" }
                                                invalid={ this.state.user.address_line_2State === "invalid" }
                                                onChange={e => this.onInputChange(e, "address_line_2") }
                                                
                                            />
                                            <div className="valid-feedback">Looks good!</div>

                                            </FormGroup>
                                        </Col>

                                    </Row>

                                    <Row>
                                        <Col lg="4">
                                            <FormGroup>
                                                <label className="form-control-label" htmlFor="city" > City </label>

                                                <Input
                                                    id="city"
                                                    value={this.state.user.city || ''}
                                                    placeholder="Apt D"
                                                    type="text"
                                                    valid={ this.state.user.cityState === "valid" }
                                                    invalid={ this.state.user.cityState === "invalid" }
                                                    onChange={e => this.onInputChange(e, "city") }
                                                    
                                                />
                                                <div className="valid-feedback">Looks good!</div>

                                            </FormGroup>
                                        </Col>

                                        <Col lg="4">
                                            <FormGroup>
                                                <label className="form-control-label" htmlFor="state" > State </label>


                                                <Input
                                                    id="state" 
                                                    type="select"
                                                    value={this.state.user.state || ''}
                                                    valid={ this.state.user.stateState === "valid" }
                                                    invalid={ this.state.user.stateState === "invalid" }
                                                    onChange={e => this.onInputChange(e, "state") }
                                                    
                                                >
                                                    <option value=""></option>
                                                    <States />
                                                </Input>

                                                <div className="valid-feedback">Looks good!</div>

                                            </FormGroup> 
                                        </Col>

                                        <Col lg="4">
                                            <FormGroup>
                                            <label className="form-control-label" htmlFor="postal_code" > Postal code </label>
                                            
                                                <Input
                                                    id="postal_code"
                                                    value={this.state.user.postal_code || ''}
                                                    placeholder="Apt D"
                                                    type="text"
                                                    valid={ this.state.user.postal_codeState === "valid" }
                                                    invalid={ this.state.user.postal_codeState === "invalid" }
                                                    onChange={e => this.onInputChange(e, "postal_code") }
                                                    
                                                />
                                                <div className="valid-feedback">Looks good!</div>

                                            </FormGroup>
                                        </Col>

                                    </Row>
                                </div>

                            </Form>
                        </CardBody>
                    </Card>
                </Col>

                <Col md={4}>
                    <h6 className="heading-small text-muted mb-2">Upload Profile Picture</h6>
                    <UserUpload  onMediaSelect={(url) => this.onMediaSelect(url)} />
                </Col>

            </Row>
               

                {/* <Card>

                    <CardHeader>
                        <Row className="align-items-center">
                            <Col xs="8">
                                <h3 className="mb-0">Images</h3>
                            </Col>
                        </Row>
                    </CardHeader>

                    <CardBody>
                        <Row>
                            <Col md={8}>
                                <h6 className="heading-small text-muted mb-4">Upload New Picture</h6>
                                <UserUpload  onMediaSelect={(url) => this.onMediaSelect(url)} />
                            </Col>
                            <Col md={4}>

                                <h6 className="heading-small text-muted mb-4">My Profile Picture</h6>
                                <AvatarImage alt="" src={this.props.viewing_user.picture_url} />

                            </Col>
                        </Row>
                    </CardBody>
                </Card> */}


                <Card>

                    <CardHeader>
                        <Row className="align-items-center">
                            <Col xs="8">
                                <h3 className="mb-0">Security</h3>
                            </Col>
                            <Col className="text-right" xs="4">
                                <Button
                                    color="primary"
                                    onClick={this.onPasswordUpdate}
                                    size="sm"
                                    disabled={this.state.canSave ? false : true}
                                >
                                Update Password
                                </Button>
                            </Col>
                        </Row>
                    </CardHeader>

                    <CardBody>
                        <Form>

                            <h6 className="heading-small text-muted mb-4">Password</h6>

                            <div className="pl-lg-4">

                                <Row>
                                    <Col md="6">
                                        <FormGroup>
                                        <label className="form-control-label" htmlFor="new_password" > New Password </label>

                                        <Input
                                            id="new_password"
                                            value={this.state.user.new_password || ''}
                                            placeholder=""
                                            type="password"
                                        
                                            onChange={e => this.onInputChange(e, "new_password") }
                                            
                                        />

                                        {this.state.error_password ? <div className="text-danger">{this.state.error_password}</div> : null}

                                        </FormGroup>
                                    </Col>

                                    <Col md="6">
                                        <FormGroup>
                                        <label className="form-control-label" htmlFor="new_password_confirm" > Confirm New Password </label>
                                        
                                        <Input
                                            id="new_password_confirm"
                                            value={this.state.user.new_password_confirm || ''}
                                            placeholder=""
                                            type="password"
                                         
                                            onChange={e => this.onInputChange(e, "new_password_confirm") }
                                            
                                        />

                                        </FormGroup>
                                    </Col>

                                </Row>

                            </div>

                        </Form>
                    </CardBody>
                </Card>


            </Container>
            </>
        );
    }
}



const mapStateToProps = state => {
    return {
  
      viewing_user: state.SYSTEM.viewing_user,
  
    };
};
  
export default connect(mapStateToProps, '')(Profile);