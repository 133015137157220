import AdminLayout from './index';

/*
Documentation

this determines the order plugins are rendered on a sidebar
add the "name" property used in the components route

*/


export default {
    //the prefix route for this layout
    path: '/admin',
    //the component to render
    component: AdminLayout,

    //the order to render all plugins if this layout calls /plugins/plugins_core/helpers/orderRoutes
    order: [
        'Home',
        'Users',
        'Payments',

        'Course',
        'Payment Page',

        'Profile',
        'Support',

        'Notifications',
        
    ]

} 