import React from "react";
import { connect } from 'react-redux';
import moment from 'moment';

import {
	Button,
	Card,
	CardHeader,
	CardBody,
	FormGroup,
	Form,
	Input,
	Container,
	Row,
	Col,
	Badge
} from "reactstrap";


import Header from '../components/Header'
import ReactBSAlert from "react-bootstrap-sweetalert";

import { aosAction } from '../../../database';

const required_form_fields = [
    'email',
    'phone',
    'message'
]

class Profile extends React.Component {

    state = {
        email: this.props.viewing_user.email,
        phone: this.props.viewing_user.phone,
        given_name: this.props.viewing_user.given_name,
        family_name: this.props.viewing_user.family_name,
        user_id: this.props.viewing_user._id,
    }

    fireAlert = (error) => {
        this.setState({
          alert: (
            <ReactBSAlert
              success={error ? false : true}
              danger={!error ? false : true}
              style={{ display: "block", marginTop: "-100px" }}
              title={error ? 'Error' : 'Success'}
              onConfirm={() => this.hideAlert()}
              onCancel={() => this.hideAlert()}
              confirmBtnBsStyle="success"
              confirmBtnText="Ok"
              btnSize=""
            >
              {error ? 
              'Something went wrong sending your support ticket.' :
              'Support ticket sent.'
            }
            </ReactBSAlert>
          )
        });
    };

    hideAlert = () => {
        this.setState({
          alert: null
        });
	};

	//this handles any other subsequent request
	
	onResponse = async () => {


		if(!this.state.response) {
			this.setState({responseState: 'invalid'})
			return;
		} else {
 
		 this.setState({responseState: 'valid'})
 
		 const newSupport = Object.assign({}, this.state.found_support)
 
		 const messages = newSupport.messages;
 
		 messages.push({
			 created_at:  Math.round((new Date()).getTime() / 1000),
			 read_by_admin: false,
			 read_by_user: true,
			 text: this.state.response,
			 given_name: this.props.viewing_user.given_name,
			 family_name: this.props.viewing_user.family_name,
			 user_id: this.props.viewing_user._id,
			 sent_by: 'user'
		 })
 
		 newSupport.messages = messages;
 
 
		 const updated = await aosAction({
			 method: 'post',
			 url: '/api/live/Support__support/update/' + newSupport._id,
			 aos_live_events: ['/api/live/Support__support/get/all?orderBy=_id&order=asc'],          
			 data: newSupport,
		 });
 
		 this.setState({found_support: newSupport, response: null})
 
		 //send error messages if went wrong
			if(!updated.success) {
				this.fireAlert(!updated.success)
			}
			
		 }

	}

	//this handles the origin submitted request
    onSubmit = async () => {

        this.setState({canSave: false})
        //copy state
        let newState = Object.assign({}, this.state);
        //assume we have no errors
        let errors = 0;

        required_form_fields.forEach((field) => {

            //check each required field
            if(newState[field]) {
                newState[field + "State"] = 'valid'; 
            } else {
                newState[field + "State"] = 'invalid'; 
                errors++
            }

        })

        this.setState({
          	...newState
        });


        if(errors) {
          	console.log('errors')
            this.setState({canSave: true})
        } else {

        
            //after load make sure button work again
            this.setState({canSave: true})

            const created = await aosAction({
				method: 'post',
				url: '/api/live/Support__support/create',
				aos_live_events: ['/api/live/Support__support/get/all?orderBy=_id&order=asc'],          
				data: {
					email: this.state.email,
					phone: this.state.phone,
					messages: [{
						text: this.state.message,
						read_by_user: true,
						read_by_admin: false,
						sent_by: 'user',
						given_name: this.state.given_name,
						family_name: this.state.family_name,
						user_id: this.state.user_id,
						created_at: Math.round((new Date()).getTime() / 1000)
					}],
					given_name: this.props.viewing_user.given_name,
					family_name: this.props.viewing_user.family_name,
					user_id: this.state.user_id,
				
				},
			  
		    });
		  
            this.fireAlert(!created.success)

        }

    }

    onInputChange = (e, stateName) => {

        let newState = Object.assign({}, this.state);
        newState[stateName] = e.target.value;

        if (stateName === "checkbox") {
          if (e.target.value) {
            newState.checkboxState = "valid";
          } else {
            newState.checkboxState = "invalid";
          }
        } else {
          if (e.target.value === "") {
            newState[stateName + "State"] = "invalid";
          } else {
            newState[stateName + "State"] = "valid";
          }
        }

        this.setState({
          ...newState
        });
	};

	fetchData = (props) => {

		let found_support = null

		if(props.viewing_user && props.viewing_user._id) {

			found_support = props.support.find(s => {

				return s.user_id === props.viewing_user._id
			})


			let should_update = false;

			if(found_support && found_support.messages && found_support.messages.length) {
				found_support.messages.forEach(m => {

					if(m.read_by_user !== true) {

						m.read_by_user = true
						should_update = true;
					}
					
		
				})
		
				if(should_update) {
					aosAction({
						method: 'post',
						url: '/api/live/Support__support/update/' + found_support._id,
						aos_live_events: ['/api/live/Support__support/get/all?orderBy=_id&order=asc'],          
						data: found_support,
					});
			
					
				}

				

			}

			this.setState({found_support})
		}

		this.setState({found_support});

	}


	componentWillReceiveProps = (nextProps) => {

		this.fetchData(nextProps)

	}
	
	componentDidMount = () => {

		this.fetchData(this.props)

	}

	

  	render() {


	
	

		const found_support = this.state.found_support;



        return (
            <>
            {this.state.alert}
            <Header icon={true} title={<span>Support</span>} breadcrumb_1="Ask A Question" />

            <Container className="mt--6" fluid>     
              
                       
					   {found_support && found_support.messages && found_support.messages.length ? (
						    <Form className="mb-6">

                            <h6 className="heading-small text-muted text-left mb-4">We value your feedback. Submit your support request below and we'll answer as soon as we can.</h6>

                            <div className="pb-lg-4">
                               
							<h6 className="heading-small text-muted ">Message History</h6>
								{found_support.messages.map((m, index) => {
									return (
										m.sent_by === 'user' ? (
											<Card key={index} className="mb-2">
												<CardHeader>
												{m.given_name + ' ' + m.family_name} &mdash; {' '} 
												{moment.unix(m.created_at).format("MM/DD/YYYY")} At {moment.unix(m.created_at).format("h:mm A")}
												</CardHeader>
												<CardBody>
													<p>{m.text}</p>
												</CardBody>
											</Card>
										) : (
											<Card key={index} className="mb-2">
												<CardHeader>
												{m.given_name + ' ' + m.family_name} <Badge color="success" pill>Admin</Badge> &mdash;{' '}  
												{moment.unix(m.created_at).format("MM/DD/YYYY")} At {moment.unix(m.created_at).format("h:mm A")}
												</CardHeader>
												<CardBody>
													<p>{m.text}</p>
												</CardBody>
											</Card>
										)
									)
								})}

                            </div>

							<div className="py-lg-4">
								<FormGroup>

									<h6 className="heading-small text-muted ">Your Response</h6>
									<Input 
										style={{height: '8rem'}} 
										id="response" 
										rows="6" 
										type="textarea" 

										value={this.state.response || ''}
										placeholder="..."

										valid={ this.state.responseState === "valid" }
										invalid={ this.state.responseState === "invalid" }
										onChange={e => this.onInputChange(e, "response") }
									/>
								</FormGroup>

								<div className="text-right">
									<Button onClick={this.onResponse} color="success">Submit</Button>
								</div>

							</div>


                        </Form>
						
					   ) : (

						<Card>
                    
						<CardBody>
							
						<Form>

							<h6 className="heading-small text-muted text-center mb-4">We value your feedback. Submit your support request below and we'll answer as soon as we can.</h6>

							<div className="pl-lg-4">
								<Row>
									<Col lg="6">
										<FormGroup>
										<label className="form-control-label" htmlFor="email"> First Name </label>
										<Input
											id="given_name"
											value={this.state.given_name || ''}
											placeholder="John"
											type="given_name"
											valid={ this.state.given_nameState === "valid" }
											invalid={ this.state.given_nameState === "invalid" }
											onChange={e => this.onInputChange(e, "given_name") }  
										/>
										<div className="valid-feedback">Looks good!</div>

										</FormGroup>
									</Col>
									<Col lg="6">
										<FormGroup>
										<label className="form-control-label" htmlFor="phone"> Last Name </label>
									
										<Input
											id="family_name"
											value={this.state.family_name || ''}
											placeholder="Doe"
											type="text"
											valid={ this.state.family_nameState === "valid" }
											invalid={ this.state.family_nameState === "invalid" }
											onChange={e => this.onInputChange(e, "family_name") }
											
										/>
										<div className="valid-feedback">Looks good!</div>

										</FormGroup>
									</Col>
								</Row>
								</div>

							<div className="pl-lg-4">
								<Row>
									<Col lg="6">
										<FormGroup>
										<label className="form-control-label" htmlFor="email"> Email address </label>
										<Input
											id="email"
											value={this.state.email || ''}
											placeholder="email@example.com"
											type="email"
											valid={ this.state.emailState === "valid" }
											invalid={ this.state.emailState === "invalid" }
											onChange={e => this.onInputChange(e, "email") }  
										/>
										<div className="valid-feedback">Looks good!</div>

										</FormGroup>
									</Col>
									<Col lg="6">
										<FormGroup>
										<label className="form-control-label" htmlFor="phone"> Phone </label>
									
										<Input
											id="phone"
											value={this.state.phone || ''}
											placeholder="555 555 5555"
											type="tel"
											valid={ this.state.phoneState === "valid" }
											invalid={ this.state.phoneState === "invalid" }
											onChange={e => this.onInputChange(e, "phone") }
											
										/>
										<div className="valid-feedback">Looks good!</div>

										</FormGroup>
									</Col>
								</Row>

								<FormGroup>
									<label className="form-control-label" htmlFor="message" > Your Question </label>
									<Input 
									style={{height: '8rem'}} 
									id="message" 
									rows="6" 
									type="textarea" 

									value={this.state.message || ''}
									placeholder="..."

									valid={ this.state.messageState === "valid" }
									invalid={ this.state.messageState === "invalid" }
									onChange={e => this.onInputChange(e, "message") }
									/>
								</FormGroup>

								<div className="text-right">
									<Button onClick={this.onSubmit} color="success">Submit</Button>
								</div>

							</div>

						</Form>

						</CardBody>
                </Card> 

					   )}


            </Container>
            </>
        );
    }
}



const mapStateToProps = state => {
    return {
  
      viewing_user: state.SYSTEM.viewing_user,
	  support: state.Support.support,

    };
};
  
export default connect(mapStateToProps, '')(Profile);