
import React from "react";
import { connect } from 'react-redux';
import { Container } from "reactstrap";

class Terms extends React.Component {

    state = {
      
    }

    render() {
                
        return (
        <>

            <div className="main-content">

                <section className="section py-7 bg-white">
                    <Container>
                        <h2 className="display-2">Terms of Service</h2>
                        <p>Hello and Welcome to IGRE, an online course for realtors to learn how to enhance their personal branding and social media skills. We designed this online course for real estate professionals who are looking to grow their personal brand and gain more exposure online. The following Terms of Service sets forth your rights and obligations as an IGRE User. By utilizing this software platform, you indicate that you have read and understand these Terms of Service and you will be bound by its contents.</p>

                        <hr />

                        <h2 className="display-4">Definitions</h2>
                        <ol>
                            <li>IGRE is a trademark of IGRE Coaching LLC. and also refers to proprietary IGRE, Ltd. Liability Co. software. In the Terms of Service, references to “IGRE” as a Party mean and refer to IGRE Coaching Ltd. Liability Co. and its owners, parent companies, affiliates, entities, employees, and assigns.</li>
                            <li>Parties means IGRE Coaching Ltd. Liability Co. and You.</li>
                            <li>Terms mean and refer to the Terms of Service set forth herein.</li>
                            <li>User refers to a person who has created an IGRE Account.</li>
                            <li>User Account refers to a User’s IGRE Account.</li>
                            <li>You and Your means the IGRE User who has executed this Agreement by utilizing IGRE software as provided by IGRE Coaching Ltd. Liability Co.</li>
                        </ol>

                        <h2 className="display-4">Cookies Policy</h2>

                        <ol>
                            <li>What is a Cookies Policy? A Cookies Policy is the policy used to inform users about the use of cookies by a website or an app. </li>
                            <li>Who Regulates Cookies Policies? In the United States of America, the Federal Trade Commission (FTC) enforces privacy and data security laws and regulations, but cookies are not explicitly separated from general privacy laws. It is common with US-Based Businesses to have a General Privacy Policy with a section which deals with the use of cookies. As IGRE Coaching Ltd. Liability Co. is formed within the State of New Jersey and the United States of America, we are providing our Cookies Policy in the same format as other US-Based Businesses, within our General Privacy Policy.</li>
                            <li>Does IGRE use Cookies? Yes, we use Cookies. No, they are not the chocolate morsel filled bakery treats which you commonly associate that word with.</li>
                            <li>What are Cookies? Cookies are small text files that websites place on the computers and mobile devices of people who visit those websites. These small text files are read by the website each time you return to the website.</li>
                            <li>What are Cookies Generally Used for? Cookies serve many different purposes, i.e. recognize you, remember you, remember how you interacted with the website, save your username and password for your ease of login, provide custom advertising to users based on searches and personal interests, to enhance the website use based on custom video streaming or volume settings you have selected while using the website.</li>
                            <li>
                                What are the Different Types of Cookies?
                                <ol>
                                    <li>Essential Cookies – cookies that are essential to provide you with services you have requested. For example, these include the cookies that make it possible for you to stay logged into your IGRE account. If you set your browser to block these cookies, then these functions and services will not work for you. In particular, we won’t be able to save your preferences about cookies.</li>
                                    <li>Performance Cookies - cookies which measure how often you visit our sites and how you use them. We use this information to get a better sense of how our users engage with our software platform and to improve our sites and apps, so that users have a better experience.</li>
                                    <li>Functionality Cookies– cookies that are used to recognize you and remember your preferences or settings when you return to our site, so that we can provide you with a more personalized experience.</li>
                                    <li>Advertising Cookies– cookies that are used to collect information about your visit to our site, the content you have viewed, the links you have followed and information about your browser, device and your IP address.</li>
                                </ol>
                            </li>
                            <li>
                                    How does IGRE use Cookies? Currently, we use Two (2) different types of cookies for very limited purposes.
                                <ol>
                                    <li>Essential Cookies- We utilize Essential Cookies to save your username and email address upon accessing your IGRE account and to keep you signed in.</li>
                                    <li>Functionality Cookies- We utilize Functionality Cookies to track the last time you logged in to your IGRE Account and to maintain the settings/preferences you’ve set to optimize and utilize the dashboard to its greatest potential.</li>
                                </ol>
                            </li>
                            <li>How can I Destroy or Disable Cookies? Throughout utilizing IGRE and as we have described, the usage of Cookies is driven toward a better customer experience. Through the simple process of Logging Out of your IGRE Account, you can Destroy the Cookies we have maintained. It is also possible to stop your browser from accepting cookies altogether by changing your browser’s cookie settings within Internet Explorer, Firefox, Chrome, or Safari. However, if you do not accept cookies, you may not be able to use some portions of our website.</li>
                            <li>Who can I talk to about Cookies and your Cookie Policy? If you would like to contact us about Cookies or our Cookie Policy, please email IGREcoaching@gmail.com with the Subject Line “Cookies/Cookie Policy.”</li>
                        
                        </ol>

                        <h2 className="display-4">As-Is Disclaimer</h2>
                        <ol>
                            <li>IGRE Coaching Ltd. Liability Co. and IGRE Services are provided “As Is” and “As Available” without warranties of any kind, either express or implied, including but not limited to, implied warranties of merchantability, fitness for a particular purpose, title, and non-infringement. Additionally, IGRE Coaching Ltd. Liability Co. does not represent or warrant that our services are accurate, complete, reliable, current or error-free. While IGRE attempts to make your access to and use of our services safe, we cannot and do not represent or warrant that our services or servers are free of viruses or other harmful components. You assume the entire risk as to the quality and performance of the services. As between you and IGRE, you will be solely responsible for responding to and honoring all requests relating to the rights of your contacts and their personal data pursuant to and in accordance with applicable data protection laws.</li>
                        </ol>

                        <h2 className="display-4">Legal Compliance</h2>
                        <ol>
                            <li>By utilizing IGRE and agreeing to the Terms of Service herein, you represent and warrant that your access to and use of the services will comply with all applicable laws, rules and regulations, including but not limited to those that relate to privacy and data protection and to the sending of electronic communications. You further represent and warrant that you have a lawful basis for processing and sending Marketing Content and communications to your customers, business contacts, or followers who consent to receiving marketing messages from you or on your behalf, whether though legally appropriate consents or otherwise. You will not provide IGRE or upload to the services, or take any actions with respect to, any marketing content or contact data for which you do not have a lawful basis for processing, permissions, or consents in accordance with applicable data protection laws.</li>
                        </ol>

                        <h2 className="display-4">Data Protection</h2>
                        <ol>
                            <li>By utilizing IGRE and agreeing to the Terms of Service herein, you acknowledge and agree that you, and not IGRE, have sole responsibility for maintaining all records relating thereto. You are solely responsible for determining whether our Services are suitable for use in light of any laws and regulations that govern your entity, industry, or relationship with your own contacts, including but not limited to consumer protection, privacy, advertising, intellectual property, or other laws. You may not use our services for any unlawful or discriminatory activities, including acts prohibited by the Federal Trade Commission Act, Fair Credit Reporting Act, Equal Credit Opportunity Act, or other laws that apply to commerce. Within the same breadth of the Data Protection conversation, you, and not IGRE, are solely responsible for any and all data breaches, losses, and the like. Please take the necessary precautions to protect your data from such incidents by backing up all data.</li>
                        </ol>


                        <h2 className="display-4">Prohibited Conduct and Activities</h2>
                        <ol>
                            <li>By utilizing IGRE and agreeing to the Terms of Service herein, you acknowledge and agree that you will not violate any applicable law, contract, intellectual property, third-party rights, or commit a tort, and that you are solely responsible for your conduct while doing so. In that respect, while utilizing our service, you will not: engage in any harassing, threatening, intimidating, predatory, or stalking conduct; impersonate any person or entity; attempt to reverse engineer any aspect of our services or do anything that might discover source code or bypass or circumvent measures employed to prevent or limit access to any part of our services whether by development or use of any third-party applications.</li>
                        </ol>


                        <h2 className="display-4">Payments Accepted by the User</h2>
                        <ol>
                            <li>By utilizing IGRE and agreeing to the Terms of Service herein, you acknowledge and agree that you, and not IGRE, are solely responsible for any financial payments accepted by your contacts and customers. Through acceptance of said payments, you must agree to the Stripe Terms of Service and acknowledge you are responsible for your customer information throughout said financial payment acceptance.</li>
                        </ol>


                        <h2 className="display-4">Indemnification</h2>
                        <ol>
                            <li>By utilizing IGRE and agreeing to the Terms of Service herein, you acknowledge and agree that, to the fullest extent permitted by applicable law, you will indemnify, defend, and hold harmless IGRE, our parents, subsidiaries, affiliates, officers, directors, agents, partners and employees from and against any loss, liability, claim, demand, damages, expenses or costs arising out of or related to your access to or use of our services, your content or feedback, your violation of these terms, your violation, misappropriation or infringement of any rights of another (inc. intellectual property rights or privacy rights) or your conduct in connection with our services in any form or fashion. You agree to promptly notify IGRE parties of any third party claims, cooperate with IGRE parties in defending such claims, and pay all fees, costs and expenses, and attorney’s fees associated with defending such claims. You also agree that IGRE will have control of the defense or settlement of any third party claims. This indemnity is in addition to, and not in lieu of, any other indemnities set forth in a written agreement between you and IGRE.</li>
                        </ol>

                        <h2 className="display-4">Limited Liability</h2>
                        <ol>
                            <li>By utilizing IGRE and agreeing to the Terms of Service herein, you acknowledge and agree that IGRE and the other IGRE Parties will not be liable to you under any theory of liability – whether based in contract, tort, negligence, warranty, strict liability, or otherwise, for any indirect, consequential, exemplary, incidental, punitive, or special damages or lost profits, even if IGRE or IGRE Parties have been advised of the possibility of such damages. The total liability of IGRE and IGRE Parties, for any claim arising out of or relating to these terms or our services, regardless of the form of the action, is limited to the amount paid, if any, by you to access or use our services. The limitations set forth in this section will not limit or exclude liability for the gross negligence, fraud, or intentional misconduct of IGRE or IGRE Parties for any other matters in which  liability cannot be excluded or limited under applicable law. Additionally, some jurisdictions do not allow the exclusion or limitation of incidental or consequential damages, so the above limitations or exclusions may not apply to you.</li>
                        </ol>


                        <h2 className="display-4">Release</h2>
                        <ol>
                            <li>By utilizing IGRE and agreeing to the Terms of Service herein, you acknowledge and agree that, to the fullest extent permitted by applicable law, you release IGRE and the other IGRE Parties from responsibility, liability, claims, demands, and/or damages (actual and consequential) of every kind and nature, known and unknown (including, but not limited to, claims of negligence), arising out of or related to disputes between users and the acts or omissions of third parties. You expressly waive any rights you may have under California Civil Code § 1542 as well as any other statute or common law principles that would otherwise limit the coverage of this release to include only those claims which you may know or suspect to exist in your favor at the time of agreeing to this release.</li>
                        </ol>


                        <h2 className="display-4">Governing Law and Venue</h2>
                        <ol>
                            <li>By utilizing IGRE and agreeing to the Terms of Service herein, you acknowledge and agree that, these terms and your access to and use of our services will be governed by and construed and enforced in accordance with the laws of the State of New Jersey, without regard to conflict or law, rules, or principles (whether of New Jersey or any other jurisdiction) that would cause the application of the laws of any other jurisdiction. Any dispute between the parties that is not subject to arbitration or cannot be heard in small claims court will be resolved in the State of New Jersey or Federal Courts of New Jersey and the United States, respectively, sitting in Passaic County, New Jersey.</li>
                        </ol>


                        <h2 className="display-4">Changes to Terms of Service</h2>
                        <ol>
                            <li>Please note that we may make changes to these Terms from time to time. If we make changes, we will post the amended Terms to our Services and update the “Last Updated” date above. We may also attempt to notify you by sending an email notification to the address associated with your account or providing notice through our Services. Unless we say otherwise in our notice, the amended Terms will be effective immediately and your continued access to and use of our Services after we provide notice will confirm your acceptance of the changes. If you do not agree to the amended Terms, you must stop accessing and using our Services.</li>
                        </ol>


                        <h2 className="display-4">Termination</h2>
                        <ol>
                            <li>We reserve the right, without notice and in our sole discretion, to terminate your right to access or use our Services. We are not responsible for any loss or harm related to your inability to access or use our Services.</li>
                        </ol>


                        <h2 className="display-4">Entire Agreement</h2>
                        <ol>
                            <li>These Terms constitute the entire agreement between you and IGRE relating to your access to and use of our Services. The failure of IGRE to exercise or enforce any right or provision of these Terms will not operate as a waiver of such right or provision. The section titles in these Terms are for convenience only and have no legal or contractual effect. Except as otherwise provided herein, these Terms are intended solely for the benefit of the parties and are not intended to confer third party beneficiary rights upon any other person or entity. This Agreement may not be assigned or transferred by you except with our prior written consent.</li>
                        </ol>


                        <h2 className="display-4">HOW TO CONTACT US</h2>
                        <p>If you have questions about these Terms of Service, please contact us via email at igrecoaching@gmail.com.</p>
                        <p>Copyright 2019 IGRE Coaching LLC. All rights reserved.</p>

                        
                    </Container>
                </section>
                
            </div>

        </>
        );
    }
}


const mapStateToProps = state => {


    return {
       
      
    };
};

  
export default connect(mapStateToProps )(Terms);  