/*
Documentation

Add all reducers for this plugin here

*/

import paymentsReducer from './reducers/users';

export default {
    AdminPayments: paymentsReducer,
}
