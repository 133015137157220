/*
Documentation

associate all modules into one single array

*/
export const buildModules = async (params) => {

    let modules             = params.modules;
    let lessons           = params.lessons;
    let comments          = params.comments;
    let lessons_finished  = params.lessons_finished;
    let viewing_user         = params.viewing_user;
    let module_id         = params.module_id;

    if(module_id) {
        modules = modules.filter(m => m._id === module_id);
    }





    // comments = await attachUserToComments(comments, app_users);

    lessons = await attachCommentsAndFinsishedToLessons(lessons, comments, lessons_finished, viewing_user)

    modules = await attachLessonsToModules(modules, lessons);

    return modules;

}

/*
Documentation

_id: the id of the module
modules: a list of all platform modules

*/
export const findModule = (_id, modules) => {

    return modules.find(m => m._id === _id);

}

/*
Documentation

_id: the id of the lesson
lessons: a list of all platform lessons

*/
export const findLesson = (_id, lessons, comments) => {

    const found_lesson = lessons.find(l => l._id === _id);


   if(found_lesson) {
        if(comments && comments.length) {

            let lesson_comments = [];
            console.log('comments', comments)
            comments.forEach(c => {
                if(c.lesson_id === _id) {
                    lesson_comments.push(c)
                }
            })

            found_lesson.comments = lesson_comments;

        }

        if(!found_lesson.comments) {
            found_lesson.comments = [];
        }

        return found_lesson
   }

   return {}

}

/*
Documentation

gett all lessons in module

_id: the id of the module
lessons: a list of all platform lessons

*/
export const getModuleLessons = (_id, lessons, comments, lessons_finished, app_users) => {

    // comments = attachUserToComments(comments, app_users)

    const found_lessons = lessons.filter(l => l.module_id === _id);

    found_lessons.forEach(l => {

        if(comments && comments.length) {

            let lesson_comments = [];
            
            comments.forEach(c => {

                if(c.lesson_id === l._id) {



                    lesson_comments.push(c)
                }
            })
    
            l.comments = lesson_comments;
    
        }
    
        if(!l.comments) {
            l.comments = [];
        }

    })

    return found_lessons

} 


// const attachUserToComments = (comments, app_users) => {
//     return new Promise ((resolve) => {

//         let attached_comments = [];

//         if(comments && comments.length) {

//             comments.sort((a,b) => a.created_at - b.created_at)

//             comments.forEach(c => {

//                 let user = app_users.find(u => {

//                     return u._id === c.user_id
//                 });


//                 if(user) {
//                     attached_comments.push({
//                         ...c,
//                         user
//                     })
//                 }

//             })
            
//        }

//         resolve(attached_comments)

//     })
// }

//needs attach user to comments
const attachCommentsAndFinsishedToLessons = (lessons, comments, finished_lessons, viewing_user) => {
    return new Promise (resolve => {


        if(!lessons) {
            resolve([]);
        }
    
        if(!lessons.length) {
            resolve([]);
        }
    
        //get all lessons this user has finished
        const user_finished_lessons = finished_lessons.filter(f => f.user_id === viewing_user._id);
    
        lessons.forEach(l => {
    
            let lesson_comments = comments.filter(c => c.lesson._id === l._id);
    
            if(!lesson_comments) {
                l.comments = []
            } else {
                l.comments = lesson_comments;
            }
    
            //get whether or not this lesson is finished
            l.finished = user_finished_lessons.find(f => f.lesson_id === l._id) ? true : false
            
    
        })

        resolve(lessons)
    })



}

const attachLessonsToModules = (modules, lessons) => {
    return new Promise ((resolve) => {

        //no modules return array
        if(!modules) {
            resolve([]);
        }

        modules.forEach(m => {

            let found_lessons = lessons.filter(l => l.module_id === m._id);

            if(!found_lessons) {
                m.lessons = []
            } else {


                let finished = true;
                let minutes = 0;

                found_lessons.forEach(l => {
                    if(!l.finished) {

                        finished = false;
                    }

                    if(l.minutes){
                        minutes = minutes + l.minutes
                    }

                })

                m.finished = finished
                m.lessons = found_lessons
                m.minutes = minutes
            }
            
        })

        resolve(modules)

    })
}

