/*

This file is a test component for this plugin

*/
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux'; 

import ReactHtmlParser from 'react-html-parser'; 

import { aosAction } from '../../../database';

import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import {
  Card,
  CardBody,
  Row,
  Col,
  Container
} from "reactstrap";

import { getModuleLessons, findModule } from '../functions/modules';

import Header from "../components/Header";


class TestComponent extends Component {


    state = {
        module: {}
    };

    componentDidMount = async () => { this.fetchLessonsAndModules(this.props) }

    componentWillReceiveProps = async (nextProps) => { this.fetchLessonsAndModules(nextProps) }

    fetchLessonsAndModules = (props) => {

        const module_id = props.match.params.module_id;

        //make sure we have an module_id in the params
        if(!module_id) {
            this.setState({shouldRedirect: '/admin/CoursePlatform/modules'});
        }

        const found_module = findModule(module_id, props.modules);

        if(!found_module) {
            this.setState({shouldRedirect: '/admin/CoursePlatform/modules'});
        }

        const module_lessons = getModuleLessons(module_id, props.lessons);

        this.setState({module_lessons, module: found_module})

    }

    getDescription = (description) => {

        const description_length = 150

        if(description && description.length > description_length) {

            return  ReactHtmlParser(description.slice(0,description_length))
        }

        return ReactHtmlParser(description)
       
    }

    onDragEnd = async (result) => {

        if (!result.destination) {
            return;
        }
      
        const items = this.reorder(
            this.state.module_lessons,
            result.source.index,
            result.destination.index
        );

        //for each lesson update the order
        items.forEach(async (item, index) => {

            const order = index + 1

            //fire off the action needed to update the events order
            aosAction({
                method: 'post',
                url: '/api/live/CoursePlatform__lessons/update/' + item._id,
                aos_live_events: ['/api/live/users/get/all'],          
                data: {
                    order
                },
            });

        })
    
        //reorder on screen
        this.setState({
            module_lessons: items
        });

    }

    reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
      
        return result;
      };

    render() {

        const lessons = this.state.module_lessons;

        return (
            <>
            
            <Header 
                icon={true} 
                title={<Link to="/admin/CoursePlatform/modules"><i className="mr-2 fas fa-arrow-left"></i>Modules</Link>} 
                breadcrumb_1={this.state.module && this.state.module.name ? this.state.module.name : null}  
                actionComponent={

                   <>
                    <Link 
                        className="btn btn-info btn-sm" 
                        to={"/admin/CoursePlatform/modules/create/" + this.props.match.params.module_id}
                    >
                        Update This Module
                    </Link>

                    <Link 
                        className="btn btn-success btn-sm" 
                        to={"/admin/CoursePlatform/modules/lessons/"+this.props.match.params.module_id+"/new_entry"}
                    >
                        Add Lesson
                    </Link>
                   </>
                }
            />

            <Container className="mt--6 mb-5" fluid>

                {lessons && lessons.length ? (

                    <DragDropContext onDragEnd={this.onDragEnd}>
                        <Droppable droppableId="droppable">

                        {(provided, snapshot) => (
                            <div {...provided.droppableProps} ref={provided.innerRef} >
                                {lessons.map((l, index) => (
                                    <Draggable key={l._id} draggableId={l._id} index={index}>
                                        {(provided, snapshot) => (
                                            <div
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                            >
                                            
                                                <Card index={index} key={l._id}  style={{marginBottom: 10}} >
                                                    <CardBody>
                                                        <Row className="align-ls-center">
                                                            
                                                            <Col className="col-auto">
                                                                <Link to={'/admin/CoursePlatform/modules/lessons/'+l.module_id+'/' + l._id}
                                                                    className="avatar avatar-xl rounded-cicle"
                                                                    style={{width: 120, height: 60}}
                                                                >
                                                                    <img alt="..." src={l.picture} />
                                                                </Link>
                                                            </Col>
                                                            <div className="col ml--2">

                                                                <Link to={'/admin/CoursePlatform/modules/lessons/'+l.module_id+'/' + l._id}>
                                                                    <h4 className="mb-0"> {l.name} {l.minutes && '(' + l.minutes + ' Minutes)'}  </h4>
                                                                </Link>
                                                                <Link to={'/admin/CoursePlatform/modules/lessons/'+l.module_id+'/' + l._id}>
                                                                    <span className="text-sm text-muted mb-0 "> {this.getDescription(l.description)}</span>
                                                                </Link>


                                                            </div>
                                                        
                                                        </Row>
                                                    </CardBody>
                                                </Card>

                                            </div>
                                        )}
                                    </Draggable>
                                ))}
                            {provided.placeholder}
                            </div>
                        )}
                        </Droppable>
                    </DragDropContext>
                
                ) : (
                    <Card style={{marginBottom: 10}} >
                        <CardBody>
                            <h2>No Lessons Found.</h2>
                            <p>There are no lessons found for this module. 
                                <Link 
                                    className="font-weight-bold" 
                                    to={"/admin/CoursePlatform/modules/lessons/"+this.props.match.params.module_id+"/new_entry"}
                                > CLICK HERE TO ADD ONE!</Link>
                            </p>
                        </CardBody>
                    </Card>
                )}

            </Container>


            </>

        )

    }

}


const mapStateToProps = state => {
    return {
      modules: state.CoursePlatform.modules,
      lessons: state.CoursePlatform.lessons,
      
    };
  };

  
  export default connect(mapStateToProps )(TestComponent);  
  
  


