/*
Documentation

this file holds the base functionality for live updates on our dashboard

currently fires a notification when a user signs up

*/

import React from "react";
import NotificationAlert from "react-notification-alert";
import { connect } from 'react-redux';
import { Link } from "react-router-dom";
import { aosListen } from '../database';




class FunctionalityAdm extends React.Component {

    state = {
        app_users: [],
        app_payments: []
    }

    notify = (place, type, message) => {

        let options = {
            place: place,
            message,
            type,
            icon: "ni ni-bell-55",
            autoDismiss: false
        };
        this.refs.notificationAlert.notificationAlert(options);
    };

    componentDidMount = () => {

        this.setState({
            app_users: this.props.app_users,
            app_payments: this.props.app_payments,
        })

        //if the current user is allowing notifications
        if(this.props.viewing_user.allow_notifications !== false) {



            //create live sign in updates on the admin dashboard
            aosListen.listen('/api/live/users/get/all', (data) => {

                if(data.response.length > this.state.app_users.length) {

                    this.setState({app_users: data.response})



                    this.notify("br", 'success', (
                        <Link className="text-white" to="/admin/users/app">
                            <div className="alert-text">
                            <span className="alert-title" data-notify="title">
                                {" "}
                                New Sign Up
                            </span>
                            <span data-notify="message">
                                A new new user signed up for the platform
                            </span>
                            </div>
                        </Link>
                    ))
                }

            })

            //create live payment updates on the admin dashboard
            aosListen.listen('/api/live/payments/get/all', (data) => {

                
                if(data.response.length > this.state.app_payments.length) {

                    this.setState({app_payments: data.response})

                    this.notify("br", 'success', (
                        <Link className="text-white" to="/admin/payments">
                            <div className="alert-text">
                            <span className="alert-title" data-notify="title">
                                {" "}
                                New Payment
                            </span>
                            <span data-notify="message">
                                A new payment for ${(data.response[0].amount / 100).toFixed(2)} was created.
                            </span>
                            </div>
                        </Link>
                    ))
                }

            })


        }

    }


    render() {
        return (
        <>
            <div className="rna-wrapper">
                <NotificationAlert ref="notificationAlert" />
            </div>

           
        </>

        );
    }
}

const mapStateToProps = state => {
    return {
  
      viewing_user: state.SYSTEM.viewing_user,
      app_users: state.SYSTEM.app_users,
      app_payments: state.SYSTEM.app_payments,
      support: state.Support.support,
  
    };
};
  
export default connect(mapStateToProps, '')(FunctionalityAdm);

