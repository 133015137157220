import React from "react";
import { Link } from 'react-router-dom';

import { 
	NavItem, 
	NavLink, 
	Nav, 
	Container, 
	Row, 
	Col 
} from "reactstrap";

import keys from '../../../keys'

class Footer extends React.Component {
	render() {
		return (
			<>
				<Container fluid>
					<footer className="footer pt-0">
						<Row className="align-items-center justify-content-lg-between">

							<Col lg="6">
								<div className="copyright text-center text-lg-left text-muted">
									© {new Date().getFullYear()}{" "}
									<Link
										className="font-weight-bold ml-1"
										to="/"
										rel="noopener noreferrer"

									>
										{keys.COMPANY}
									</Link>
								</div>
							</Col>

							<Col lg="6">
							<Nav className="nav-footer justify-content-center justify-content-lg-end">
									<NavItem>
										<NavLink
											href="/terms-of-service"
											target="_blank"
										>
										Terms Of Service
										</NavLink>
									</NavItem>
									<NavItem>
										<Link
											className="nav-link"
											to="/dashboard/settings"
										>
											My Profile
										</Link>
									</NavItem>
									<NavItem>
										<a
											className="nav-link"
											href="/auth/logout"
										>
										Logout
										</a>
									</NavItem>
								</Nav>
							</Col>

						</Row>
					</footer>
				</Container>
			</>
		);
	}
}

export default Footer;
