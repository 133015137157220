


import io from 'socket.io-client';
import Axios from 'axios';
import keys from '../keys'


const socket = io(keys.API_URL);


const returnListener = async (url, onSuccess, onError, method) => {

    //used for custom method types
    //defaults to get as this is a listener
    if(!method) {
        method = 'get'
    }

    try {
        const result = await Axios({
            method,
            url: keys.API_URL + url,
            
            headers: {
              
                // withCredentials: "true",

                access_token: keys.AOS_MASTER_KEY
            },
          
        })

        onSuccess(result.data)

    
    } catch(e) {
    
        if(onError) {
            onError(e)
        }
    
    }

}

class aosLive {

    constructor() {

        //this is used to fire off a request when this class is loaded
        this.fired = [];
    }

 

   
    async listen  (url, onSuccess, onError, method)  {

        //if this is our first listen, fire the get request
        if(!this.fired.includes(url)) {


            //run the request
            returnListener(url, onSuccess, onError, method)
            this.fired.push(url);

        }

        socket.on(url, async () => {


            returnListener(url, onSuccess, onError, method)
            
        });

    }

}

/*
Documentation

fire of a request somewhere with an aos_live_events

examples
 const created = await aosAction({
    method: 'post',
    url: '/api/live/users/create',
    aos_live_events: ['/api/live/users/get/all'],          
    data: {
        family_name: 'Maher',
        given_name: 'John'
    },
});

if(created.success) {

    console.log(created.response);

} else {

    console.log(created.message);

}

*/

export const aosAction = async (settings) => {
    return new Promise(async(resolve) => {

        const method = settings.method;
        const url = settings.url;
        let data = settings.data;
        const headers = settings.headers;
        const aos_live_events = settings.aos_live_events;

        if(!aos_live_events) {
            console.log('no live events set a request made to url:' + method + ' ' + url + '. No socket will be fired.')
        }

        if(!data) {
            data = {}
        }

        data.aos_live_events = aos_live_events;

        try {
            const result = await Axios({
                method,
                url: keys.API_URL + url,
                headers: {
                    ...headers,
                    access_token: keys.AOS_MASTER_KEY
                },
                data,
            })

            resolve(result.data)
        
        } catch(e) {
        
            resolve({success: false, message: 'aosAction failed at url: ' + url + '. Error: ' + e.toString()})
        
        }

    })
}
 

export const aosCall = async (settings) => {
    return new Promise(async(resolve) => {



        const method = settings.method;
        const url = settings.url;
        let data = settings.data;
        const headers = settings.headers;
       

        if(!data) {
            data = {}
        }



        try {
            const result = await Axios({
                method,
                url: keys.API_URL + url,
                headers: {
                    ...headers,
                    access_token: keys.AOS_MASTER_KEY
                },
                data,
            })

            resolve(result.data)
        
        } catch(e) {
        
            resolve({success: false, message: 'aosAction failed at url: ' + url + '. Error: ' + e.toString()})
        
        }

    })
}

export const aosListen = new aosLive()
