import consoleError from './consoleError'

export default (routes, settings) => {

    let ordered_routes = [];

    if(!routes) {
        consoleError('You did not pass a routes object to orderRoute(). This is the first parameter and is a list of all routes needed in this layout.');
        return [];
    }

    if(!settings ) {
        consoleError('You did not pass a settings object to orderRoute(). This is the second parameter and must contain an array of names relating to your route names for all plugins in this layout.');
        return routes;
    }

    if(!settings.order || !settings.order.length) {
        consoleError('settings.order is empty. This will create orderRoutes to return your routes as is and therefore is useless.');
        return routes;
    }

    //for each route that is set to be ordered
    settings.order.forEach((o) => {

        //try to find that route by name
        let found_route = routes.find(r => r.name === o)

        //rush route if we have it
        if(found_route) {
            ordered_routes.push(found_route)
        } 

    })

    //for each route
    routes.forEach((r) => {

        //if we cannot find this route in the ordered routes
        let found_route = ordered_routes.find(o => o.name === r.name);

        //push this route to the end of the list
        if(!found_route) {
            ordered_routes.push(r)
        }

    })

    return ordered_routes

}