import React from "react";

import { Route, Switch } from "react-router-dom";


import Navbar from "./components/Navbar";


import routes from "../../plugins_core/routes";


class Site extends React.Component {

	
	componentDidUpdate(e) {
		if (e.history.pathname !== e.location.pathname) {
			document.documentElement.scrollTop = 0;
			document.scrollingElement.scrollTop = 0;
			this.refs.mainContent.scrollTop = 0;
		}
	  }
		
	getRoutes = routes => {
		return routes.map((prop, key) => {

			if (prop.collapse) {
				return this.getRoutes(prop.views);
			}

			if (prop.layout === "") {
				console.log( prop.path === '/' ? false: true)
				return (
					<Route
						// exact={prop.path === '/' ? false: true}
						exact
						path={prop.layout + prop.path}
						component={prop.component}
						key={key}
					/>
				);
			} else {
				return null;
			}

		});
	};

	render() {

	

		return (

		<>
			<div className="main-content" ref="mainContent">
			<Navbar />
			<Switch>{this.getRoutes(routes)}</Switch>
			</div>

		</>

		);
	}
}

export default Site;
