import React from "react";
import { connect } from 'react-redux';

import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col
} from "reactstrap";

import States from '../components/Inputs/States'
import Header from '../components/Header'
import ReactBSAlert from "react-bootstrap-sweetalert";

import { aosAction } from '../../../database';


const required_form_fields = [
    'given_name',
    'family_name',
    'email',
]

class Profile extends React.Component {

    state = {

        canSave: true
        
    }

    confirmDeleteAlert = () => {
        this.setState({
            alert: (
                <ReactBSAlert
                    warning
                    style={{ display: "block", marginTop: "-100px" }}
                    title="Are you sure?"
                    onCancel={() => this.hideAlert()}
                    onConfirm={() => this.confirmedAlert()}
                    showCancel
                    confirmBtnBsStyle="danger"
                    confirmBtnText={"Yes, remove " + this.state.found_user.given_name}
                    cancelBtnBsStyle="secondary"
                    cancelBtnText="Cancel"
                    btnSize=""
                >
                    Are you sure you wish to delete {this.state.found_user.given_name} {this.state.found_user.family_name} from the system? This can not be undone.
                </ReactBSAlert>
            )
        });
      };
      confirmedAlert = async () => {
    
        const deleted = await aosAction({
            method: 'post',
            url: '/api/live/users/delete/' + this.state.found_user._id,
            aos_live_events: ['/api/live/users/get/all'],   
      
        });
    
        this.setState({
            alert: (
                <ReactBSAlert
                    success={deleted.success ? true : false}
                    danger={!deleted.success ? true : false}
                    style={{ display: "block", marginTop: "-100px" }}
                    title={deleted.success ? 'Success!' : 'Error'}
                    onConfirm={() => this.hideAlert()}
                    onCancel={() => {
                        this.hideAlert();
                        this.setState({shouldRedirect: '/admin/users/app'})
                    }}
                    confirmBtnBsStyle="primary"
                    confirmBtnText="Ok"
                    btnSize=""
    
                >
                    {deleted.success ? 
                    `You have successfully removed ${this.state.found_user.given_name} ${this.state.found_user.family_name}.` : 
                    `Something went wrong deleting ${this.state.found_user.given_name} ${this.state.found_user.family_name}.`
                    }
                </ReactBSAlert>
            )
        });
      };
    

    fireAlert = (error) => {

        console.log(error)
        this.setState({
          alert: (
            <ReactBSAlert
              success={error ? false : true}
              danger={!error ? false : true}
              style={{ display: "block", marginTop: "-100px" }}
              title={error ? 'Error' : 'Success'}
              onConfirm={() => this.hideAlert()}
              onCancel={() => this.hideAlert()}
              confirmBtnBsStyle="success"
              confirmBtnText="Ok"
              btnSize=""
            >
              {error ? 
              'Something went wrong updating your profile' :
              'Profile Updated Successfully'
            }
            </ReactBSAlert>
          )
        });
    };

    hideAlert = () => {
        this.setState({
          alert: null
        });
      };

    onSave = async () => {

        this.setState({canSave: false})
        //copy state
        let newState = Object.assign({}, this.state.found_user);
        //assume we have no errors
        let errors = 0;

        required_form_fields.forEach((field) => {

            //check each required field
            if(newState[field]) {
                newState[field + "State"] = 'valid'; 
            } else {
                newState[field + "State"] = 'invalid'; 
                errors++
            }

        })

        this.setState({
            found_user: newState
        });


          //et the user try again but stop form saving
        if(errors) {
            this.setState({canSave: true})
        } else {

            //form is valid lets proceed;
    
    
            //run update call here

            //after load make sure button work again
            this.setState({canSave: true})


            const updated = await aosAction({
                method: 'post',
                url: '/api/live/users/update/' + this.props.match.params._id,
                aos_live_events: ['/api/live/users/get/all'],          
                data: newState,
            });

            this.fireAlert(!updated.success)


           

        }

    }

    onInputChange = (e, stateName) => {

        let newState = Object.assign({}, this.state.found_user);
        newState[stateName] = e.target.value;

        if (stateName === "checkbox") {
          if (e.target.value) {
            newState.checkboxState = "valid";
          } else {
            newState.checkboxState = "invalid";
          }
        } else {
          if (e.target.value === "") {
            newState[stateName + "State"] = "invalid";
          } else {
            newState[stateName + "State"] = "valid";
          }
        }

        this.setState({
            found_user: newState
        });
    };

    componentDidMount = () => {

        const found_user = this.props.app_users.find(u => u._id === this.props.match.params._id);

        this.setState({found_user: found_user})

    }

    render() {

        const found_user = this.state.found_user

        if(!found_user) {
            return <div></div>
        }
   

        return (
            <>
            {this.state.alert}
            <Header icon={true} title={<span>User</span>} breadcrumb_1={found_user.email} />

            <Container className="mt--6" fluid>     
                <Card>

                    <CardHeader>
                        <Row className="align-items-center">
                            <Col xs="8">
                                <h3 className="mb-0">{found_user.given_name + ' ' + found_user.family_name}'s Profile</h3>
                            </Col>
                            <Col className="text-right" xs="4">

                            <Button color="danger" onClick={this.confirmDeleteAlert}  size="sm"  >  Delete User </Button>
                                <Button
                                    color="primary"
                                    onClick={this.onSave}
                                    size="sm"
                                    disabled={this.state.canSave ? false : true}
                                >
                                Save Profile
                                </Button>
                             
                            </Col>
                        </Row>
                    </CardHeader>

                    <CardBody>
                        <Form>

                            <h6 className="heading-small text-muted mb-4">User information</h6>

                            <div className="pl-lg-4">

                                <Row>
                                    <Col lg="6">
                                        <FormGroup>
                                        <label className="form-control-label" htmlFor="given_name" > First name </label>

                                        <Input
                                            id="given_name"
                                            value={this.state.found_user.given_name || ''}
                                            placeholder="First name"
                                            type="text"
                                            valid={ this.state.found_user.given_nameState === "valid" }
                                            invalid={ this.state.found_user.given_nameState === "invalid" }
                                            onChange={e => this.onInputChange(e, "given_name") }
                                            
                                        />
                                        <div className="valid-feedback">Looks good!</div>

                                        </FormGroup>
                                    </Col>
                                    <Col lg="6">
                                        <FormGroup>
                                        <label className="form-control-label" htmlFor="family_name" > Last name </label>
                                        
                                        <Input
                                             id="family_name"
                                             value={this.state.found_user.family_name || ''}
                                             placeholder="Last name"
                                             type="text"
                                            valid={ this.state.found_user.family_nameState === "valid" }
                                            invalid={ this.state.found_user.family_nameState === "invalid" }
                                            onChange={e => this.onInputChange(e, "family_name") }
                                            
                                        />
                                        <div className="valid-feedback">Looks good!</div>
                                       
                                        </FormGroup>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col lg="6">
                                        <FormGroup>
                                        <label className="form-control-label" htmlFor="email"> Email address </label>
                                        <Input
                                            id="email"
                                            value={this.state.found_user.email || ''}
                                            placeholder="email@example.com"
                                            type="email"
                                            valid={ this.state.found_user.emailState === "valid" }
                                            invalid={ this.state.found_user.emailState === "invalid" }
                                            onChange={e => this.onInputChange(e, "email") }  
                                        />
                                         <div className="valid-feedback">Looks good!</div>

                                        </FormGroup>
                                    </Col>
                                    <Col lg="6">
                                        <FormGroup>
                                        <label className="form-control-label" htmlFor="phone"> Phone </label>
                                       
                                        <Input
                                            id="phone"
                                            value={this.state.found_user.phone || ''}
                                            placeholder="555-555-5555"
                                            type="tel"
                                            valid={ this.state.found_user.phoneState === "valid" }
                                            invalid={ this.state.found_user.phoneState === "invalid" }
                                            onChange={e => this.onInputChange(e, "phone") }
                                            
                                        />
                                        <div className="valid-feedback">Looks good!</div>

                                        </FormGroup>
                                    </Col>
                                </Row>

                            </div>

                            <hr className="my-4" />
                            <h6 className="heading-small text-muted mb-4">Address</h6>

                            <div className="pl-lg-4">

                                <Row>

                                    <Col md="6">
                                        <FormGroup>
                                        <label className="form-control-label" htmlFor="address_line_1" > Address Line 1 </label>

                                        <Input
                                            id="address_line_1"
                                            value={this.state.found_user.address_line_1 || ''}
                                            placeholder="198 Manhattan Ave"
                                            type="text"
                                            valid={ this.state.found_user.address_line_1State === "valid" }
                                            invalid={ this.state.found_user.address_line_1State === "invalid" }
                                            onChange={e => this.onInputChange(e, "address_line_1") }
                                            
                                        />
                                        <div className="valid-feedback">Looks good!</div>

                                        </FormGroup>
                                    </Col>

                                    <Col md="6">
                                        <FormGroup>
                                        <label className="form-control-label" htmlFor="address_line_2" > Address Line 2 </label>
                                        
                                        <Input
                                            id="address_line_2"
                                            value={this.state.found_user.address_line_2 || ''}
                                            placeholder="Apt D"
                                            type="text"
                                            valid={ this.state.found_user.address_line_2State === "valid" }
                                            invalid={ this.state.found_user.address_line_2State === "invalid" }
                                            onChange={e => this.onInputChange(e, "address_line_2") }
                                            
                                        />
                                        <div className="valid-feedback">Looks good!</div>

                                        </FormGroup>
                                    </Col>

                                </Row>

                                <Row>
                                    <Col lg="4">
                                        <FormGroup>
                                            <label className="form-control-label" htmlFor="city" > City </label>

                                            <Input
                                                id="city"
                                                value={this.state.found_user.city || ''}
                                                placeholder="Apt D"
                                                type="text"
                                                valid={ this.state.found_user.cityState === "valid" }
                                                invalid={ this.state.found_user.cityState === "invalid" }
                                                onChange={e => this.onInputChange(e, "city") }
                                                
                                            />
                                            <div className="valid-feedback">Looks good!</div>

                                        </FormGroup>
                                    </Col>

                                    <Col lg="4">
                                        <FormGroup>
                                            <label className="form-control-label" htmlFor="state" > State </label>


                                            <Input
                                                 id="state" 
                                                type="select"
                                                value={this.state.found_user.state || ''}
                                                valid={ this.state.found_user.stateState === "valid" }
                                                invalid={ this.state.found_user.stateState === "invalid" }
                                                onChange={e => this.onInputChange(e, "state") }
                                                
                                            >
                                                <option value=""></option>
                                                 <States />
                                            </Input>

                                            <div className="valid-feedback">Looks good!</div>

                                        </FormGroup> 
                                    </Col>

                                    <Col lg="4">
                                        <FormGroup>
                                        <label className="form-control-label" htmlFor="postal_code" > Postal code </label>
                                        
                                            <Input
                                                id="city"
                                                value={this.state.found_user.postal_code || ''}
                                                placeholder="Apt D"
                                                type="text"
                                                valid={ this.state.found_user.postal_codeState === "valid" }
                                                invalid={ this.state.found_user.postal_codeState === "invalid" }
                                                onChange={e => this.onInputChange(e, "postal_code") }
                                                
                                            />
                                            <div className="valid-feedback">Looks good!</div>

                                        </FormGroup>
                                    </Col>

                                </Row>
                            </div>

                        </Form>
                    </CardBody>
                </Card>
            </Container>
            </>
        );
    }
}



const mapStateToProps = state => {
    return {
  
      viewing_user: state.SYSTEM.viewing_user,
      app_users: state.SYSTEM.app_users,
  
    };
};
  
export default connect(mapStateToProps, '')(Profile);