import React from "react";
import { Link } from 'react-router-dom'
import PropTypes from "prop-types";

import {
  Breadcrumb,
  BreadcrumbItem,

  Container,
  Row,
  Col
} from "reactstrap";

class AlternativeHeader extends React.Component {
  render() {

    return (
      <>
        <div className="header pb-6">
            <Container fluid>
                <div className="header-body">
                    <Row className="align-items-center py-4">

                        <Col lg="6" xs="7">
                            <h6 className="h2 d-inline-block mb-0">{this.props.title}</h6>{" "}
                            <Breadcrumb
                                className="d-none d-md-inline-block ml-md-4"
                                listClassName="breadcrumb-links"
                            >
                                
                                {this.props.icon && 
                                    <BreadcrumbItem>
                                        <Link to={window.location.href.includes('/admin/') ? '/admin/dashboard' : '/dashboard'} >
                                            <i className="fas fa-home" />
                                        </Link>
                                    </BreadcrumbItem>
                                }

                                {this.props.breadcrumb_1 && 
                                    <BreadcrumbItem aria-current="page" className="active">
                                    {this.props.breadcrumb_1}
                                    </BreadcrumbItem>
                                }

                                {this.props.breadcrumb_2 && 
                                    <BreadcrumbItem aria-current="page" className="active">
                                    {this.props.breadcrumb_2}
                                    </BreadcrumbItem>
                                }

                                {this.props.breadcrumb_3 && 
                                    <BreadcrumbItem aria-current="page" className="active">
                                    {this.props.breadcrumb_3}
                                    </BreadcrumbItem>
                                }
                            </Breadcrumb>
                        </Col>

                        {this.props.actionComponent && 
                            <Col className="text-right" lg="6" xs="5">
                               {this.props.actionComponent}
                            </Col>
                        }

                        

                    </Row>
                </div>
            </Container>
        </div>
      </>
    );
  }
}

AlternativeHeader.propTypes = {
  title: PropTypes.element.isRequired,
  breadcrumb_1: PropTypes.string,
  breadcrumb_2: PropTypes.string,
  breadcrumb_3: PropTypes.string,
  actionComponent: PropTypes.element,
};


export default AlternativeHeader;
