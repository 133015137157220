import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux'

import NotificationAlert from "react-notification-alert";

class UnreadComments extends Component {


    state = {
        supportNumber: 0
    };

    componentDidMount = () => { this.fetchUnreadCommentsNumber(this.props) }

    componentWillReceiveProps = (nextProps) => {  this.fetchUnreadCommentsNumber(nextProps) }

    fetchUnreadCommentsNumber = (props) => {

        let supportNumber = 0;
        let support = props.support;

        support = support.filter(s => s.user_id === this.props.viewing_user._id)

        if(support && support.length) {

            support.forEach(s => {

                if(s.messages && s.messages.length) {

                    s.messages.forEach(m => {

                        if(!m.read_by_user) {
                            supportNumber++
                        }
                    })

                }

            })

        }

        if(supportNumber > this.state.supportNumber) {


            this.notify("tr", 'success', ( 
                <div className="text-white" onClick={this.onClick}>
                    <div className="alert-text">
                    <span className="alert-title" data-notify="title">
                        {" "}
                        Support Ticket
                    </span>
                    <span data-notify="message">
                        Your support ticket has been answered.
                    </span>
                    </div>
                </div>
            ))

        }

        this.setState({supportNumber})


    }

    notify = (place, type, message) => {

        let options = {
            place: place,
            message,
            type,
            icon: "ni ni-bell-55",
            autoDismiss: false
        };
        this.refs.notificationAlert.notificationAlert(options);
    };

    onClick = () => {

        this.setState({shouldRedirect: '/dashboard/support'})

    }

    render() {

        if(this.state.shouldRedirect) {

            let redirect = this.state.shouldRedirect;
            this.setState({shouldRedirect: false})

            return <Redirect to={redirect} />
        }

        return (

            <>

             <div className="rna-wrapper">
                <NotificationAlert ref="notificationAlert" />
            </div>

            <span style={{width: '100%'}}>
                <span style={{float: 'right'}} className="ml-3 badge badge-info badge-pill">{this.state.supportNumber}</span>
            </span>

            </>

        )

    }

}

const mapStateToProps = state => {
    return {
  
        viewing_user: state.SYSTEM.viewing_user,
	    support: state.Support.support,

    };
};
  
export default connect(mapStateToProps, '')(UnreadComments);
