/*

Add all reducers for this plugin here
If there are no reduces return a blank object

!IMPORTANT!
Preface all reducers with "Auth__" in this file
This prevents future plugin conflicts

*/

// import exampleReducer from './reducers/exampleReducer';

export default {
    // Auth__user: exampleReducer,
}
