
import React from "react";
import ReactBSAlert from "react-bootstrap-sweetalert";
import { connect } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';
//need to switch confirm updates
//need ti make deleting admin work
import { Button, Card, CardBody, CardText, CardTitle, Col, Container, Row } from "reactstrap";
import { aosAction } from '../../../database';
// core components
import Header from "../components/Header";
import UserSelect from '../components/Modals/UserSelect';










class AppAdmins extends React.Component {

    state = {
        shouldRedirect: false,
        modalUsers: false,
        showUserSelect: false
    }

    hideAlert = () => {
        this.setState({
            alert: null
        });
    };

    toggleModal = state => {
        this.setState({
          [state]: !this.state[state]
        });
    };
    
    confirmAlert = (_id, name) => {
        this.setState({
            alert: (
                <ReactBSAlert
                    warning
                    style={{ display: "block", marginTop: "-100px" }}
                    title="Remove Admin"
                    onConfirm={() => this.confirmedAlert(_id, name)} 
                    onCancel={() => this.hideAlert()}
                    showCancel
                    confirmBtnBsStyle="danger"
                    confirmBtnText={"Yes, remove " + name}
                    cancelBtnBsStyle="secondary"
                    cancelBtnText="Cancel"
                    btnSize=""
                >
                    Are you sure you wish to remove {name}  as an admin?
                </ReactBSAlert>
            )
        });
      };

      confirmedAlert = async (_id, name) => {
    

        const updated = await aosAction({
            method: 'post',
            url: '/api/live/users/update/' + _id,
            aos_live_events: ['/api/live/users/get/all'],          
            data: {
               is_admin: false
            },
        });

        this.setState({
            alert: (
                <ReactBSAlert
                    success={updated.success ? true : false}
                    danger={!updated.success ? true : false}
                    style={{ display: "block", marginTop: "-100px" }}
                    title={updated.success ? 'Success!' : 'Error'}
                    onConfirm={() => this.hideAlert()}
                    onCancel={() => this.hideAlert()}
                    confirmBtnBsStyle="primary"
                    confirmBtnText="Ok"
                    btnSize=""
    
                >
                    {updated.success ? 
                    `You have successfully removed ${name}.` : 
                    `Something went wrong deleting ${name}.`
                    }
                </ReactBSAlert>
            )
        });

      };
    



    viewProfile = (_id) => {



    }

   getActionComponent = () => {
        return (
            <>
            <Button
                className="btn-neutral"
                color=""
                onClick={() => this.toggleUserSelect()}
                size="sm"
            >
             Create Admin
            </Button>

            </>
        )
    }

    toggleUserSelect = () => {
        this.setState({showUserSelect: !this.state.showUserSelect})
    }

    onSelect = async (row) => {

        const updated = await aosAction({
            method: 'post',
            url: '/api/live/users/update/' + row._id,
            aos_live_events: ['/api/live/users/get/all'],          
            data: {
               is_admin: true
            },
        });

        this.setState({
            alert: (
                <ReactBSAlert
                    success={updated.success ? true : false}
                    danger={!updated.success ? true : false}
                    style={{ display: "block", marginTop: "-100px" }}
                    title={updated.success ? 'Success!' : 'Error'}
                    onConfirm={() => this.hideAlert()}
                    onCancel={() => this.hideAlert()}
                    confirmBtnBsStyle="primary"
                    confirmBtnText="Ok"
                    btnSize=""
    
                >
                    {updated.success ? 
                    `${row.given_name} ${row.family_name} has been added as an admin.` : 
                    `Something went wrong adding ${row.given_name} ${row.family_name} as an admin.`
                    }
                </ReactBSAlert>
            )
        });

        this.toggleUserSelect()
    }

  render() {

    if(this.state.shouldRedirect) {
        return <Redirect to={this.state.shouldRedirect} />
    }

    const app_admins = this.props.app_admins


    return (
      <>
        {this.state.alert}

        {this.state.showUserSelect && 
        <UserSelect
            hide={() => this.toggleUserSelect()}
            onSelect={(row) => this.onSelect(row)}
            filter={(x) => x.filter(x => !x.is_admin)}
        />}

        <Header icon={true} title={<span>Users</span>} breadcrumb_1="App Admins" actionComponent={this.getActionComponent()} />
        <Container className="mt--6" fluid>
          

            <Row>
                {app_admins && app_admins.length ? app_admins.map(a => (
                    <Col key={a._id} xl="4" md="6">
                        <Card>
                            <CardBody>

                            <CardTitle className="mb-3" tag="h3">
                                {a.given_name + ' ' + a.family_name}
                            </CardTitle>

                            <CardText className="mb-0">
                                <strong style={styles.descriptor}>Email:</strong> {a.email}
                            </CardText>

                            <CardText className="mb-0">
                                <strong style={styles.descriptor}>Phone:</strong> {a.phone || <span className="font-weight-bold">No Phone Found</span>}
                            </CardText>

                            <CardText className="mb-4">
                                <strong style={styles.descriptor}>User ID:</strong> {a._id}
                            </CardText>

                            <div className="text-right">
                                <Link
                                    className="btn btn-primary btn-small btn-outline btn btn-outline-primary btn-sm"
                                    color="primary"

                                    size="sm"
                                   to={'/admin/user/' + a._id}
                                >
                                    View Profile
                                </Link>
                                <Button
                                outline
                                    color="danger"
                                    size="sm"
                                    onClick={() => this.confirmAlert(a._id, a.given_name + ' ' + a.family_name)}
                                >
                                    Remove Admin
                                </Button>
                            </div>

                            </CardBody>
                        </Card>
                    </Col>

                )) : null}
              
                
            </Row>
           
          


        </Container>
      </>
    );
  }
}

const styles = {
    descriptor: {
        display: 'inline-block',
        width: 70
    }
}
const mapStateToProps = state => {
    return {
  

      app_admins: state.SYSTEM.app_admins,
    
  
    };
  };
  
  export default connect(mapStateToProps, '')(AppAdmins);



