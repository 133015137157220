import React from "react";
import { connect } from 'react-redux';
import Coupons from '../../../ad-ons/payments/Coupons';

class AdmCoupons extends React.Component {

    state = {}


    render() {

        return (

            <Coupons 
                type="charge"
                subscription_key="CoursePlatform_standard"
            />
                
        );
    }
}



//not sure why this needs to be in connect but it does
export default connect('')(AdmCoupons);