import React from "react";
import { connect } from 'react-redux';
import { Redirect, Route, Switch } from "react-router-dom";
import { WaveSpinner } from "react-spinners-kit";
import compiled_plugin_on_load_events from '../../plugins_compiler/compiled_on_load_events';
import routes from "../../plugins_core/routes";
import * as actionTypes from '../../plugins_core/store/actions';
import { refreshSystemUsers } from "../../plugins_core/store/reducers/SYSTEM";
import Footer from "./components/Footer";
import Navbar from "./components/Navbar";
import Sidebar from "./components/Sidebar";






//set the name of the routes to check for | in this case '/admin'
const route_to_check_for = 'dashboard';

class Admin extends React.Component {
    state = {
        sidenavOpen: true,
        logged_in: true,
        routes:  routes.filter((r) => r.renderOn === 'dashboard' ),
        is_loading: true,
        show_loader: true,
        faderOpacity: 1,
    };

       //fade our loader out gracefully without settings state to many times and updating components over and over
       fadeLoader = () => {


        const fader = document.getElementById('AosFader')
        let opacity = 1;

        var refreshIntervalId = setInterval(() => {

            if(opacity < 0) {
                clearInterval(refreshIntervalId);
                this.setState({show_loader: false})
            }

            opacity = opacity - .1

            //if page is changed dont through and error if fader is gone
            try {
                fader.style.opacity = opacity
            }catch (e) {

            }
            
        }, 50);
   
    }

    isLoggedIn = () => {

        return new Promise ((resolve) => {

            if(this.props.viewing_user.logged_in ) { 

                resolve(true)

            } else {

                const location = window.location;


                this.setState({should_redirect: '/auth/login/?redirect=' + encodeURIComponent(location.pathname + location.search + location.hash)}, () => {
                    resolve(false);
                    return;
                })
                // this.setState({should_redirect: '/auth/login'}, () => {
                //     resolve(true)
                // })
               
            }

        })

    }

 

    getRoutes = routes => {
        return routes.map((prop, key) => {
        if (prop.collapse) {
            return this.getRoutes(prop.views);
        }
        if (prop.layout === '/' + route_to_check_for) {
            return (
            <Route 
                exact path={prop.layout + prop.path}
                component={prop.component}
                key={key}
            />
            );
        } else {
            return null;
        }
        });
    };
    getBrandText = path => {
        for (let i = 0; i < routes.length; i++) {
        if (
            this.props.location.pathname.indexOf(
            routes[i].layout + routes[i].path
            ) !== -1
        ) {
            return routes[i].name;
        }
        }
        return "Brand";
    };
    // toggles collapse between mini sidenav and normal
    toggleSidenav = e => {
        if (document.body.classList.contains("g-sidenav-pinned")) {
        document.body.classList.remove("g-sidenav-pinned");
        document.body.classList.add("g-sidenav-hidden");
        } else {
        document.body.classList.add("g-sidenav-pinned");
        document.body.classList.remove("g-sidenav-hidden");
        }
        this.setState({
        sidenavOpen: !this.state.sidenavOpen
        });
    };
    getNavbarTheme = () => {
        // return this.props.location.pathname.indexOf(
        //   "admin/alternative-dashboard"
        // ) === -1
        //   ? "dark"
        //   : "light";
        return 'light'
    };

    componentDidMount = async () => {

        console.log('mounted')

        await this.isLoggedIn()
        
        const reducers_fired = this.props.reducers_fired


        compiled_plugin_on_load_events.push({
            func: refreshSystemUsers,
            prop: 'app_users',
            plugin_name: 'system'
        })

        const reducers_to_wait_for = [];
        const reducers_to_fire_in_background = [];

        compiled_plugin_on_load_events.filter((plug) => {

           

            //plugin reducer is meant to run in the background
            if(plug.run_in_background) {

                reducers_to_fire_in_background.push(plug);

            } else {
                
                //plugin reducer has already ran so we have its data. Load it in background 
                if(reducers_fired.includes(plug.plugin_name + '-' + plug.prop)) {

                   

                    reducers_to_fire_in_background.push(plug);

                } else {

                    reducers_to_wait_for.push(plug);

                }

            }

            //return statements are expected, does nothing
            return true;

        })


        //fire all on on load events of plugins
        Promise.all(reducers_to_wait_for.map(async (plug, index) => {

         


            //create a list of plugins that have fired
            reducers_fired.push(plug.plugin_name + '-' + plug.prop)

            return plug.func();

            

        })).then((values) => {
            
            // console.log('after', reducers_fired)
            this.props.setReducersFired(reducers_fired)
            this.setState({is_loading: false});
            this.fadeLoader()

        });

    }

    

    componentDidUpdate = async (e) => {

        await this.isLoggedIn();

        if (e.history.pathname !== e.location.pathname) {
        document.documentElement.scrollTop = 0;
        document.scrollingElement.scrollTop = 0;

        //make sure we have our refs to scroll to the top on
        if(this.refs && this.refs.mainContent) {
            this.refs.mainContent.scrollTop = 0;
        }

        
        }
    }

    render() {
    
        if(this.state.should_redirect) {
            return <Redirect to={this.state.should_redirect} />
        }

        return (
            <>

                {this.state.show_loader ? (
                    <div 
                        id="AosFader"
                        style={{
                            opacity: this.state.faderOpacity,
                            position: 'fixed',
                            top: 0,
                            right: 0, 
                            left: 0,
                            bottom: 0,
                            display: 'flex',
                            zIndex: 9999999999999,
                            background: 'white'
                        }}
                    >
                        <div style={styles.loaderContainer}>
                            <WaveSpinner
                                size={30}
                                color="#fb6340"
                                loading={true}
                            />
                        </div>
                    </div> 
                ) : null}

            {!this.state.is_loading ? (

                <>
                    <Sidebar
                        {...this.props} 
                        routes={this.state.routes}
                        toggleSidenav={this.toggleSidenav}
                        sidenavOpen={this.state.sidenavOpen}
                        logo={{
                            innerLink: "/dashboard",
                            imgSrc: require("assets/img/app/Logo.png"),
                            imgAlt: "..."
                        }}
                    />

                    <div className="main-content" ref="mainContent" onClick={this.closeSidenav} >

                        <Navbar
                            {...this.props}
                            theme={this.getNavbarTheme()}
                            toggleSidenav={this.toggleSidenav}
                            sidenavOpen={this.state.sidenavOpen}
                            brandText={this.getBrandText(this.props.location.pathname)}
                        />
                        <Switch>
                            {this.getRoutes(this.state.routes)}
                             {/* If page not found go back to dashboard */}
                             <Redirect from="*" to="/dashboard" />
                        </Switch>
                        <Footer />

                    </div>

                    {this.state.sidenavOpen ? (
                        <div className="backdrop d-xl-none" onClick={this.toggleSidenav} />
                    ) : null}

                </>

            ) : null}
            </>

        );
    }
}

const styles = {
   
    loaderContainer: {
        alignSelf: 'center',
        margin: 'auto'
    }
}

const mapStateToProps = state => {

    return {

        viewing_user: state.SYSTEM.viewing_user,
        reducers_fired: state.SYSTEM.reducers_fired

    };
};

const mapDispatchToProps = dispatch => {
    return {
 
        //send a user either found or unfound to our reducer
        setReducersFired: (objects) =>  dispatch({type: actionTypes.SYSTEM_SET_REDUCERS_FIRED, payload: {objects} }),
      
    };
};
  

export default connect(mapStateToProps, mapDispatchToProps)(Admin);

