/*

Add all routes for this plugin here.

*/

// import Ghost from './Views/Ghost';


export default [

    //a single sidebar route
    // {
    //     divider: 'Add Ons',
    //     path: "/websites",            //url
    //     name: "Websites",             //sidebar name
    //     icon: "fas fa-laptop text-success",    //sidebar icon
    //     component: Ghost,           //component to show in route
    //     layout: "/dashboard",                   //which layout to use
    //     renderOn: 'dashboard',                   //where to render this component
    //     show_in_sidebar: true
    // },

    ////a collapsable sidebar route
    //{
    //    collapse: true,                     //can be collapsed
    //    name: "Dashboard",                  //Title on sidebar
    //    icon: "ni ni-shop text-primary",    //Icon on sidebar
    //    state: "dashboardsCollapse",        //state of sidebar
    //    renderOn: 'admin',                  //where to render
    //    views: [                            //pages inside this collapse
    //        {
    //            path: "/users/app",             //url
    //            name: "Dashboard",              //sidebar name
    //            component: TestComponent,       //component to show in route
    //            layout: "/admin"                //which layout to use
    //        },
    //    ]
    //},

]