import moment from 'moment';
import React from "react";
import ReactBSAlert from "react-bootstrap-sweetalert";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { CSVExport, Search } from "react-bootstrap-table2-toolkit";
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Card, CardHeader, Col, Container, Row, Badge } from "reactstrap";
import Header from "../components/Header";
import AvatarImage from '../components/Images/Avatars';
import keys from '../../../keys';





const { ExportCSVButton } = CSVExport;


const pagination = paginationFactory({
  page: 1,
  alwaysShowAllBtns: true,
  showTotal: true,
  withFirstAndLast: false,
  sizePerPageRenderer: ({ options, currSizePerPage, onSizePerPageChange }) => (
    <div className="dataTables_length" id="datatable-basic_length">
      <label>
        Show{" "}
        {
          <select
            name="datatable-basic_length"
            aria-controls="datatable-basic"
            className="form-control form-control-sm"
            onChange={e => onSizePerPageChange(e.target.value)}
          >
            <option value="10">10</option>
            <option value="25">25</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </select>
        }{" "}
        entries.
      </label>
    </div>
  )
});

const { SearchBar } = Search;


class AdminPayments extends React.Component {

  state = {}

  hideAlert = () => {
    this.setState({
        alert: null
    });
};

confirmAlert = (row) => {
    this.setState({
        alert: (
            <ReactBSAlert
                warning
                style={{ display: "block", marginTop: "-100px" }}
                title="Are you sure?"
                onConfirm={() => this.hideAlert()}
                onCancel={() => this.confirmedAlert(row)}
                showCancel
                confirmBtnBsStyle="secondary"
                confirmBtnText="Cancel"
                cancelBtnBsStyle="danger"
                cancelBtnText={"Yes, remove " + row.given_name}
                btnSize=""
            >
                Are you sure you wish to delete {row.given_name} {row.family_name} from the system? This can not be undone.
            </ReactBSAlert>
        )
    });
  };
  confirmedAlert = (row) => {

    //fire off delete call here
    const deleted = {success: true}

    this.setState({
        alert: (
            <ReactBSAlert
                success={deleted.success ? true : false}
                danger={!deleted.success ? true : false}
                style={{ display: "block", marginTop: "-100px" }}
                title={deleted.success ? 'Success!' : 'Error'}
                onConfirm={() => this.hideAlert()}
                onCancel={() => this.hideAlert()}
                confirmBtnBsStyle="primary"
                confirmBtnText="Ok"
                btnSize=""

            >
                {deleted.success ? 
                `You have successfully removed ${row.given_name} ${row.family_name}.` : 
                `Something went wrong deleting ${row.given_name} ${row.family_name}.`
                }
            </ReactBSAlert>
        )
    });
  };


  columns = [
    {
        dataField: "given_name",
        text: "User",
        sort: true,
        formatter: (cell, row) => {

            console.log(row)

            const user = this.props.app_users.find( u => u._id === row.user_id )

            if(user) {

                return (
                
                <Link to={cell+"/"+user._id}>
                    <span  className="avatar avatar-sm rounded-circle aligned-image" >
                        <AvatarImage  alt="..." src={user.picture_url} />
                    </span>

                    <span className="ml-3" style={{position: 'relative', top: -11}}>
                    {user.given_name}  {user.family_name}
                    </span>
                </Link>
        

                    )

            } else {
                return 'No Associated User'
            }

        }
    }, 
    
    {
        dataField: "description",
        text: "Description",
        sort: true,
    },{
        dataField: "amount",
        text: "amount",
        sort: true,
        formatter: (cell, row) => {

            return (
                <span>${(row.amount / 100).toFixed(2)}</span>
            )
           
        }
    }, {
        dataField: "refunded",
        text: "Refunded",
        sort: true,
        formatter: (cell, row) => {
            return row.refunded ? (
                <Badge style={{width: 50}} color="danger" pill> yes </Badge> 

            ) : (
                <Badge style={{width: 50}} color="success" pill> no </Badge> 
            )
  
          }
    },{
        dataField: "stripe_charge_id",
        text: "Payment ID",
        sort: true,
        formatter: (cell, row) => {
            return <a target="_blank" rel="noopener noreferrer" href={
                window.location.hostname !== keys.PROD_HOSTNAME ? 
                'https://dashboard.stripe.com/test/payments/' + row.stripe_charge_id : 
                'https://dashboard.stripe.com/payments/' + row.stripe_charge_id
            }
            >{row.stripe_charge_id}</a>
          }
    }, {
        dataField: "stripe_customer_id",
        text: "Customer ID",
        sort: true,
        formatter: (cell, row) => {
            return <a target="_blank" rel="noopener noreferrer" href={
                window.location.hostname !== keys.PROD_HOSTNAME ? 
                'https://dashboard.stripe.com/test/customers/' + row.stripe_customer_id : 
                'https://dashboard.stripe.com/customers/' + row.stripe_customer_id
            }
            >{row.stripe_customer_id}</a>
          }
    },  {
        dataField: "row.stripe_source.last4",
        text: "Card",
        sort: true,
        formatter: (cell, row) => {
            return (
                <>
                <span className="text-muted">{row.stripe_source.brand + ' Ending In: '}</span>
                {row.stripe_source.last4}
                </>
            )
          }
    },{
        dataField: "card",
        text: "Card Expiry",
        sort: true,
        formatter: (cell, row) => {
            return row.stripe_source.exp_month + '/' +row.stripe_source.exp_year
          }
    },{
        dataField: "currency",
        text: "Currenty",
        sort: true,
        formatter: (cell, row) => {
            return <span className="text-uppercase">{row.currency}</span>
          }
    },{
        dataField: "created_at",
        text: "Created ON",
        sort: true,
        formatter: (cell, row) => {
          return moment.unix(row.created_at).format("MM/DD/YYYY") + ' at ' + moment.unix(row.created_at).format("h:mm A")

        }
    }
]
  
  render() {

    // console.log(this.props)



    const app_payments = this.props.app_payments





    return (
      <>
        {this.state.alert}
        <Header icon={true} title={<span>Payments</span>} breadcrumb_1="System Payments"/>
        <Container className="mt--6" fluid>
          

            <Card>
                <CardHeader>
                <h3 className="mb-0">All Payments</h3>
                    <p className="text-sm mb-0">
                        This table shows all of your payments charged by this application.
                    </p>
                </CardHeader>
                <div className="table-vertical-align">

               
                <ToolkitProvider
                    data={app_payments}
                    keyField="_id"
                    columns={this.columns}
                    search
                    exportCSV
                >
                    {props => (
                        <>
                          <div
                            id="datatable-basic_filter"
                            className="dataTables_filter px-4 pb pt-3 pb-2"
                            style={{width: '100%'}}
                        >
                            <Row>

                                <Col>
                                    <label>
                                        Search:
                                        <SearchBar
                                            className="form-control-sm"
                                            placeholder=""
                                            {...props.searchProps}
                                        />
                                    </label>
                                </Col>

                                <Col className="col-auto">
                                    <ExportCSVButton
                                    
                                        className="btn-sm"
                                        { ...props.csvProps }
                                        
                                    >
                                        <span>Export CSV</span>
                                    </ExportCSVButton>
                                </Col>

                            </Row>
                        </div>
                        <div className="pb-4 table-responsive">
                      
                        <BootstrapTable
                            {...props.baseProps}

                            bootstrap4={true}
                            pagination={pagination}
                            bordered={false}
                        />
                        </div>
                        </>
                    )}
                    </ToolkitProvider>
                    </div>
              </Card>
          


        </Container>
      </>
    );
  }
}


const mapStateToProps = state => {
    return {

        viewing_user: state.SYSTEM.viewing_user,
        app_users: state.SYSTEM.app_users,
        app_payments: state.SYSTEM.app_payments,


    };
};

export default connect(mapStateToProps, '')(AdminPayments);