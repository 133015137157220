// import aosCall from 'aosCall';
import { createSystemLog } from 'database/system_logs';
import { aosCall } from 'database';


/////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////
//Charges
/////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////

/*
Documentation

this function sends a request to charge a customers card

objects properties

source: required
a stripe token.id

amount: required
the amount in usd cents to charge, must be greater than or equal to 50

description: required
the description of the charge

metadata: optional
a set of key value pairs to attach

*/

export async function chargeCard(object) {

    return new Promise( async (resolve, reject) => {

        if(!object.source) {
            resolve({success: false, payment: {}, message: 'No stripe token ID was passed as object.source. function chargeCard()'})
            return;
        }

        if(!object.amount) {
            resolve({success: false, payment: {}, message: 'No amount paramter was passed. Must be an Integer. function chargeCard()'})
            return;
        }

        if(object.amount <= 49) {
            resolve({success: false, payment: {}, message: 'Amount must be great then $0.50 or 50. function chargeCard()'})
            return;
        }

        if(!object.description) {
            resolve({success: false, payment: {}, message: 'No description parameter was passed. Must be a string. function chargeCard()'})
            return;
        }

        try {
            let response = await aosCall({
              method:'post',
              url:'/api/stripe/payments/charge',
              data: {
                  ...object
              },
            })
      
            if(response.success) {
                resolve({success: true, payment: response.payment})
                return;
            } else {
                resolve({success: false, payment: {}, message: response.message})
                return;
            }
          
        } catch(e) {
        
            createSystemLog('An error occured charging a customers payment: ' + e.message, 1, 1)
            resolve({success: false, payment: {}, message: e.message})
            return;
        }

    })

}

/*
Documentation

Charge an existing customer

object properties

customer_id: required
the id of the customer you wish to charge

amount: required
must be greater than or equal to 50
The amount in usd cents to charge the customer

description: required
the description of the charge

metadata: optional
a set of key value pairs to attach to the charge


*/

export async function customerCharge(object) {

    return new Promise( async (resolve, reject) => {

        if(!object.customer_id) {
            resolve({success: false, payment: {}, message: 'No customer_id was passed as object.customer_id. function customerCharge()'})
            return;
        }

        if(!object.amount) {
            resolve({success: false, payment: {}, message: 'No amount paramter was passed. Must be an Integer. function customerCharge()'})
            return;
        }

        if(object.amount <= 49) {
            resolve({success: false, payment: {}, message: 'Amount must be great then $0.50 or 50. function customerCharge()'})
            return;
        }

        if(!object.description) {
            resolve({success: false, payment: {}, message: 'No description parameter was passed. Must be a string. function customerCharge()'})
            return;
        }

        try {
            let response = await aosCall({
              method:'post',
              url:'/api/stripe/customer/charge',
              data: {
                  ...object
              },
            })
      
            if(response.success) {
                resolve({success: true, payment: response.payment})
                return;
            } else {
                resolve({success: false, payment: {}, message: response.message})
                return;
            }
          
        } catch(e) {
        
            createSystemLog('An error occured charging a customers payment: ' + e.message, 1, 1)
            resolve({success: false, payment: {}, message: e.message})
            return;
        }

    })

}

/*
Documentation

Create a charge for a customer but don't capture it
Note: uncaptured charges expires after 7 days
use chargeCapture() to capture this payment

object properties

customer_id: required
the id of the customer you wish to charge

amount: required
must be greater than or equal to 50
The amount in usd cents to charge the customer

description: required
the description of the charge

metadata: optional
a set of key value pairs to attach to the charge


*/
export async function customerChargeAuthorize(object) {

    return new Promise( async (resolve, reject) => {

        if(!object.customer_id) {
            resolve({success: false, payment: {}, message: 'No stripe token ID was passed as object.customer_id. function customerCharge()'})
            return;
        }

        if(!object.amount) {
            resolve({success: false, payment: {}, message: 'No amount paramter was passed. Must be an Integer. function customerCharge()'})
            return;
        }

        if(object.amount <= 49) {
            resolve({success: false, payment: {}, message: 'Amount must be great then $0.50 or 50. function customerCharge()'})
            return;
        }

        if(!object.description) {
            resolve({success: false, payment: {}, message: 'No description parameter was passed. Must be a string. function customerCharge()'})
            return;
        }

        try {
            let response = await aosCall({
              method:'post',
              url:'/api/stripe/customer/charge_no_capture',
              data: {
                  ...object
              },
            })
      
            if(response.success) {
                resolve({success: true, payment: response.payment})
                return;
            } else {
                resolve({success: false, payment: {}, message: response.message})
                return;
            }
          
        } catch(e) {
        
            createSystemLog('An error occured charging a customers payment: ' + e.message, 1, 1)
            resolve({success: false, payment: {}, message: e.message})
            return;
        }

    })

}

export async function chargeCapture(charge_id) {

    return new Promise( async (resolve, reject) => {

        if(!charge_id) {
            resolve({success: false, payment: {}, message: 'No charge_id was passed to function chargeCapture()'})
            return;
        }

        try {
            let response = await aosCall({
              method:'post',
              url:'/api/stripe/charge/capture',
              data: {
                    charge_id
              },
            })
      
            if(response.success) {
                resolve({success: true, payment: response.payment})
                return;
            } else {
                resolve({success: false, payment: {}, message: response.message})
                return;
            }
          
        } catch(e) {
        
            createSystemLog('An error occured charging a customers payment: ' + e.message, 1, 1)
            resolve({success: false, payment: {}, message: e.message})
            return;
        }

    })

}

/////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////
//Customers
/////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////


/*
Documentation

This function creates a new stripe customer
NOTE: creating a new customer automatically sets the source they are created with as the default source
objects properties

source: required
a stripe token.id

name: optional STRING
the name of the customer

description: optional STRING
a description of the customer.

email: optional STRING
the customers email

metadata: options OBJECT
a set of key value pairs to assign to this customer

*/

export async function customerCreate(object) {

    return new Promise( async (resolve, reject) => {

        if(!object.source) {
            resolve({success: false, payment: {}, message: 'No stripe token ID was passed as object.source. function customerCreate()'})
            return;
        }

     
        try {
            let response = await aosCall({
              method:'post',
              url:'/api/stripe/customer/create',
              data: {
                  ...object
              },
            })
      
            if(response.success) {
                resolve({success: true, customer: response.customer})
                return;
            } else {
                resolve({success: false, customer: {}, message: response.message})
                return;
            }
          
        } catch(e) {

            createSystemLog('An error occurred creating a customer: ' + e.message, 1, 1)
            resolve({success: false, customer: {}, message: e.message})
            return;
        }

    })

}


export async function customerSubscribe(object) {

    return new Promise( async (resolve, reject) => {

        if(!object.customer_id) {
            resolve({success: false, payment: {}, message: 'No stripe customer_id was passed as object.customer_id. function customerCreate()'})
            return;
        }
        if(!object.plan_id) {
            resolve({success: false, payment: {}, message: 'No stripe plan_id was passed as object.plan_id. function customerCreate()'})
            return;
        }

     
        try {
            let response = await aosCall({
              method:'post',
              url:'/api/stripe/subscriptions/create',
              data: {
                  ...object
              },
            })
      
            if(response.success) {
                resolve({success: true, response: response.response})
                return;
            } else {
                resolve({success: false, response: {}, message: response.message})
                return;
            }
          
        } catch(e) {

            createSystemLog('An error occurred creating a customer: ' + e.message, 1, 1)
            resolve({success: false, customer: {}, message: e.message})
            return;
        }

    })

}

/*
Documentation

Permanently deletes a customer. It cannot be undone. Also immediately cancels any active subscriptions on the customer.

Params
customer_id: required

the id of the customer you wish to delete


*/
export async function customerDelete(customer_id) {

    return new Promise( async (resolve, reject) => {

        if(!customer_id) {
            resolve({success: false, payment: {}, message: 'No customer_id was passed. function customerDelete()'})
            return;
        }

     
        try {
            let response = await aosCall({
              method:'post',
              url:'/api/stripe/customer/delete',
              data: {
                  customer_id
              },
            })
      
            if(response.success) {
                resolve({success: true, customer_id: response.customer_id})
                return;
            } else {
                resolve({success: false, customer_id: {}, message: response.message})
                return;
            }
          
        } catch(e) {

            createSystemLog('An error occurred deleting a customer: ' + e.message, 1, 1)
            resolve({success: false, customer_id: {}, message: e.message})
            return;
        }

    })

}

/*
Documentation

This function updates an existing stripe customer
objects properties

customer_id: required
this is the id of the stripe customer you wish to update

name: optional STRING
the name of the customer

description: optional STRING
a description of the customer.

email: optional STRING
the customers email

metadata: options OBJECT
a set of key value pairs to assign to this customer


*/

export async function customerUpdate(object) {

    return new Promise( async (resolve, reject) => {

       
        try {
            let response = await aosCall({
              method:'post',
              url:'/api/stripe/customer/update',
              data: {
                  ...object
              },
            })
      
            if(response.success) {
                resolve({success: true, customer: response.customer})
                return;
            } else {
                resolve({success: false, customer: {}, message: response.message})
                return;
            }
          
        } catch(e) {

            createSystemLog('An error occurred creating a customer: ' + e.message, 1, 1)
            resolve({success: false, customer: {}, message: e.message})
            return;
        }

    })

}

/*
Documentation

This function retrieves a stripe customer by their customer_id

Params
customer_id: required
this is the stripe customer_id of the customer to get


*/

export async function customerRetrieve(customer_id) {

    return new Promise( async (resolve, reject) => {

        if(!customer_id) {
            resolve({success: false, payment: {}, message: 'No customer_id was passed as parameter. function customerRetrieve()'})
            return;
        }

     
        try {
            let response = await aosCall({
              method:'post',
              url:'/api/stripe/customer/retrieve',
              data: {
                  customer_id
              },
            })
      
            if(response.success) {
                resolve({success: true, customer: response.customer})
                return;
            } else {
                resolve({success: false, customer: {}, message: response.message})
                return;
            }
          
        } catch(e) {
        
            createSystemLog('An error occurred retrieving a customer: ' + e.message, 1, 1)
            resolve({success: false, customer: {}, message: e.message})
            return;

        }

    })

}

/*
Documentation

This function sets a users default source

Params
customer_id: required
the id of the stripe customer you wish to update the source of

source_id: required
the id of the card or source you wish to set as the default


*/

export async function customerCardSetDefaultSource(customer_id, source_id) {

    return new Promise( async (resolve, reject) => {

        if(!customer_id) {
            resolve({success: false, customer: '', message: 'No customer_id was passed as parameter. function customerCardSetDefaultSource()'})
            return;
        }

        if(!source_id) {
            resolve({success: false, customer: '', message: 'No source_id was passed as parameter. function customerCardSetDefaultSource()'})
            return;
        }
 
        try {
            let response = await aosCall({
              method:'post',
              url:'/api/stripe/customer/set_default_source',
              data: {
                  customer_id,
                  source_id
              },
            })
      
            if(response.success) {
                resolve({success: true, customer: response.customer, message: ''})
                return;
            } else {
                resolve({success: false, customer: '', message: response.message})
                return;
            }
          
        } catch(e) {
        
            createSystemLog('An error occurred setting a customers default source: ' + e.message, 1, 1)
            resolve({success: false, customer: '', message: e.message})
            return;

        }

    })

}

/////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////
//Cards
/////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////


/*
Documentation

This function deletes a stripe customer's card

Parameters
customer_id: required
the id of the stripe customer to delete the card of

card_id: required
the id of the stripe card to delete. Must correspond to the id of the customer passed in


*/
export async function customerCardDelete(customer_id, card_id) {

    return new Promise( async (resolve, reject) => {

        if(!customer_id) {
            resolve({success: false, card_id: '', message: 'No customer_id was passed as parameter. function customerGetDefaultSource()'})
            return;
        }

        if(!card_id) {
            resolve({success: false, card_id: '', message: 'No card_id was passed as parameter. function customerGetDefaultSource()'})
            return;
        }

     
        try {
            let response = await aosCall({
              method:'post',
              url:'/api/stripe/card/delete',
              data: {
                  customer_id,
                  card_id
              },
            })
      
            if(response.success) {
                resolve({success: true, card_id: response.card_id, message: ''})
                return;
            } else {
                resolve({success: false, card_id: response.card_id, message: response.message})
                return;
            }
          
        } catch(e) {
        
            createSystemLog('An error occurred deleting a customers card: ' + e.message, 1, 1)
            resolve({success: false, card_id: '', message: e.message})
            return;

        }

    })

}

/*
Documentation

This function adds a card to a stripe customer

Params
customer_id: required
the id of the stripe customer to add this card to

source: required
the id the source created for the card to add. Usually token.id


*/

export async function customerCardAdd(customer_id, source) {

    return new Promise( async (resolve, reject) => {

        if(!customer_id) {
            resolve({success: false, card_id: '', message: 'No customer_id was passed as parameter. function customerCardAdd()'})
            return;
        }

        if(!source) {
            resolve({success: false, card_id: '', message: 'No source was passed as parameter. function customerCardAdd()'})
            return;
        }

     
        try {
            let response = await aosCall({
              method:'post',
              url:'/api/stripe/card/add',
              data: {
                  customer_id,
                  source
              },
            })
      
            if(response.success) {
                resolve({success: true, card: response.card, message: ''})
                return;
            } else {
                resolve({success: false, card: response.card, message: response.message})
                return;
            }
          
        } catch(e) {
        
            createSystemLog('An error occurred adding a customers card: ' + e.message, 1, 1)
            resolve({success: false, card: '', message: e.message})
            return;

        }

    })

}

/*
Documentation

Returns a list of a stripe customers current cards
Currently returns up to 10 cards

Params
customer_id: required
the id of the customer you wish to get a list of cards for


*/
export async function customerCardList(customer_id) {

    return new Promise( async (resolve, reject) => {

        if(!customer_id) {
            resolve({success: false, card_id: '', message: 'No customer_id was passed as parameter. function customerCardList()'})
            return;
        }
 
        try {
            let response = await aosCall({
              method:'post',
              url:'/api/stripe/card/list',
              data: {
                  customer_id
              },
            })
      
            if(response.success) {
                resolve({success: true, cards: response.cards.data, message: ''})
                return;
            } else {
                resolve({success: false, cards: '', message: response.message})
                return;
            }
          
        } catch(e) {
        
            createSystemLog('An error occurred fetching a customers list of cards: ' + e.message, 1, 1)
            resolve({success: false, cards: '', message: e.message})
            return;

        }

    })

}

/*
Documentation

This function adds a new card for a customer and sets it as the default source

Params
customer_id: required
this is the id of the stripe customer you wish to add the card for

source_id: required
this is the id of the stripe source to add: Usually token.id


*/

export async function customerAddCardAsDefault(customer_id, source_id) {

    return new Promise( async (resolve, reject) => {

        //make sure we have a customer_id
        if(!customer_id) {
            resolve({success: false, card_id: '', message: 'No customer_id was passed as parameter. function customerCardAdd()'})
            return;
        }

        //make sure we have a source_id
        if(!source_id) {
            resolve({success: false, card_id: '', message: 'No source_id was passed as parameter. function customerCardAdd()'})
            return;
        }

        //add the card to the customers list
        let addCard = await customerCardAdd(customer_id, source_id);

        //if the card was added
        if(addCard.success) {

            //declare the card
            let addedCard = addCard.card;

            //set this card as the default source
            let setDefaultCard = await customerCardSetDefaultSource(customer_id, addedCard.id);

            if(setDefaultCard.success) {

                //send back success with the updated customer
                resolve({success: true, customer: setDefaultCard.customer})

            } else {
                
                //log out the error as no customer shoulld ever get here
                createSystemLog('An error occurred setting a customer default source at customerAddCardAsDefault(): ' + setDefaultCard.message, 1, 1)
                //send back the error message
                resolve({success: false, message: setDefaultCard.message})

            }

        } else {

            //send back an error message
            resolve({success: false, message: addCard.message})

        }

    })

}


/////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////
//EVENTS
/////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////

/*
Documentation

Get a list of the last 100 events that have happened in stripe


*/
export async function eventsList() {

    return new Promise( async (resolve, reject) => {

        try {
            let response = await aosCall({
                method:'get',
                url:'/api/stripe/events/list',
            })
      
            if(response.success) {
                resolve({success: true, events: response.events, message: ''})
                return;
            } else {
                resolve({success: false, card: '', message: response.message})
                return;
            }
          
        } catch(e) {
        
            createSystemLog('An error occurred fetching a list of stripe events: ' + e.message, 1, 1)
            resolve({success: false, events: '', message: e.message})
            return;

        }

    })

}


/////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////
//EVENTS
/////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////

/*
Documentation

Get a list of the last 100 events that have happened in stripe


*/
export async function plansCreate(data) {
    return new Promise( async (resolve, reject) => {

        try {
            let response = await aosCall({
                method:'post',
                url:'/api/stripe/plans/create',
                data
            })


      
            resolve(response)
            return;
          
        } catch(e) {
        
            createSystemLog('An error occurred fetching a list of stripe events: ' + e.message, 1, 1)
            resolve({success: false, events: '', message: e.message})
            return;

        }

    })
}


/////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////
//Combinations
/////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////

/*
Documentation

This function creates a stripe customer and charges their card
Customer Object: See params for customerCreate()
paymentObject: See params for customerCharge() or customerChargeAuthorize()

settings {
    capture: true / false | should we charge the customer or authorize this payment
    deleteCustomerOnPaymentFailure: true / false | should we delete the created customer if their payment fails
}
*/

export async function customerCreateAndCharge(customerObject, paymentObject, settings) {

    return new Promise( async (resolve, reject) => {

        //make sure we have a customer_id
        if(!customerObject.source) {
            resolve({success: false, card_id: '', message: 'No customer_id was passed as parameter. function customerCreateAndCharge()'})
            return;
        }

        const customer = await customerCreate(customerObject);
        
        //customer was created
        if(customer.success) {
        
            //declare customer object
            let createdCustomer = customer.customer;

            //set charge to blank value
            let charge = '';

            //if we are capturing the payment
            if(settings.capture) {
                charge = await customerCharge({
                    customer_id: createdCustomer.id,
                    ...paymentObject
                });
            } else {

                //if we are just authorizing the payment
                charge = await customerChargeAuthorize({
                    customer_id: createdCustomer.id,
                    ...paymentObject
                });
            }
            
            //customer was charged
            if(charge.success) {
            
                //declare the payment variable
                let payment = charge.payment;
            
                //resolve with success
                resolve({success: true, customer: createdCustomer, payment, message: ''});

            } else {

                //if we should delete the customer on payment failure
                if(settings.deleteCustomerOnPaymentFailure) {

                    //delete the customer
                    const deletedCustomer = await customerDelete(createdCustomer.id);

                    //customer was deleted
                    if(deletedCustomer.success) {

                        resolve({success: false, customer: {customer_id: deletedCustomer.customer_id}, payment: {}, message: charge.message});
                        return;

                    } else {

                        //an error occurred deleting the customer
                        //system log this we should never be here
                        createSystemLog('An error occurred deleting a customer on failure at customerCreateAndCharge(): ' + deletedCustomer.message, 1, 1)
                        resolve({success: false, customer, payment: {}, message: charge.message + ' ' + deletedCustomer.message});
                        return;

                    }

                } else {

                    //we are not deleting the customer however payment still failed
                    //an error occurred charging the customers card
                    resolve({success: false, customer: createdCustomer, payment: {}, message: charge.message})
                    return;

                }

            }
        
        } else {
        
            //an error occurred created the customer
            resolve({success: false, customer: {}, payment: {}, message: customer.message});
            return;
        
        }

    })

}