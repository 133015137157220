
/* 

Do not update this file. It should be generated programmatically through npm run plugins-compile

Copyright 2019 Arsenal OS
Author John Maher

*/

import consoleError from '../plugins_core/helpers/consoleError'; 
import AdminDashboard from '../plugins/AdminDashboard/on_load_events'; 
import AdminPayments from '../plugins/AdminPayments/on_load_events'; 
import AdminSystemLogs from '../plugins/AdminSystemLogs/on_load_events'; 
import AdminUsers from '../plugins/AdminUsers/on_load_events'; 
import Auth from '../plugins/Auth/on_load_events'; 
import CoursePlatform from '../plugins/CoursePlatform/on_load_events'; 
import Ghost from '../plugins/Ghost/on_load_events'; 
import Home from '../plugins/Home/on_load_events'; 
import Settings from '../plugins/Settings/on_load_events'; 
import Support from '../plugins/Support/on_load_events'; 


let compiled_on_load_events = {}; 


AdminDashboard.forEach((elem) => {
    
    if(elem.plugin_name !== 'AdminDashboard') {
        consoleError('On load event must have the same name as its plugin. "' + elem.plugin_name + '"')
    }

    if(!elem.prop) {
        consoleError('On load event must have the "prop" property set to say what redux property it is setting. "' + elem.prop + '"')
    }

})//check we have the required props

AdminPayments.forEach((elem) => {
    
    if(elem.plugin_name !== 'AdminPayments') {
        consoleError('On load event must have the same name as its plugin. "' + elem.plugin_name + '"')
    }

    if(!elem.prop) {
        consoleError('On load event must have the "prop" property set to say what redux property it is setting. "' + elem.prop + '"')
    }

})//check we have the required props

AdminSystemLogs.forEach((elem) => {
    
    if(elem.plugin_name !== 'AdminSystemLogs') {
        consoleError('On load event must have the same name as its plugin. "' + elem.plugin_name + '"')
    }

    if(!elem.prop) {
        consoleError('On load event must have the "prop" property set to say what redux property it is setting. "' + elem.prop + '"')
    }

})//check we have the required props
AdminUsers.forEach((elem) => {
    
    if(elem.plugin_name !== 'AdminUsers') {
        consoleError('On load event must have the same name as its plugin. "' + elem.plugin_name + '"')
    }

    if(!elem.prop) {
        consoleError('On load event must have the "prop" property set to say what redux property it is setting. "' + elem.prop + '"')
    }

})//check we have the required props
Auth.forEach((elem) => {
    
    if(elem.plugin_name !== 'Auth') {
        consoleError('On load event must have the same name as its plugin. "' + elem.plugin_name + '"')
    }

    if(!elem.prop) {
        consoleError('On load event must have the "prop" property set to say what redux property it is setting. "' + elem.prop + '"')
    }

})//check we have the required props
CoursePlatform.forEach((elem) => {
    
    if(elem.plugin_name !== 'CoursePlatform') {
        consoleError('On load event must have the same name as its plugin. "' + elem.plugin_name + '"')
    }

    if(!elem.prop) {
        consoleError('On load event must have the "prop" property set to say what redux property it is setting. "' + elem.prop + '"')
    }

})//check we have the required props
Ghost.forEach((elem) => {
    
    if(elem.plugin_name !== 'Ghost') {
        consoleError('On load event must have the same name as its plugin. "' + elem.plugin_name + '"')
    }

    if(!elem.prop) {
        consoleError('On load event must have the "prop" property set to say what redux property it is setting. "' + elem.prop + '"')
    }

})//check we have the required props
Home.forEach((elem) => {
    
    if(elem.plugin_name !== 'Home') {
        consoleError('On load event must have the same name as its plugin. "' + elem.plugin_name + '"')
    }

    if(!elem.prop) {
        consoleError('On load event must have the "prop" property set to say what redux property it is setting. "' + elem.prop + '"')
    }

})//check we have the required props

Settings.forEach((elem) => {
    
    if(elem.plugin_name !== 'Settings') {
        consoleError('On load event must have the same name as its plugin. "' + elem.plugin_name + '"')
    }

    if(!elem.prop) {
        consoleError('On load event must have the "prop" property set to say what redux property it is setting. "' + elem.prop + '"')
    }

})//check we have the required props
Support.forEach((elem) => {
    
    if(elem.plugin_name !== 'Support') {
        consoleError('On load event must have the same name as its plugin. "' + elem.plugin_name + '"')
    }

    if(!elem.prop) {
        consoleError('On load event must have the "prop" property set to say what redux property it is setting. "' + elem.prop + '"')
    }

})

compiled_on_load_events = [
...AdminDashboard, 
...AdminPayments, 
...AdminSystemLogs, 
...AdminUsers, 
...Auth, 
...CoursePlatform, 
...Ghost, 
...Home, 
...Settings, 
...Support, 

]; 

export default compiled_on_load_events; 
