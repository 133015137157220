/*

This file is a test component for this plugin

*/
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Badge, Card, CardBody, CardImg, Col, Container, Row } from "reactstrap";
import Header from "../components/Header";
import { buildModules } from '../functions/modules';
import Payments from "./Payments";





// import Gateway from "ad-ons/Gateway";


class Courses extends Component {


    state = {
        modules: []
    };


    componentDidMount = () => {
        this.fetchData(this.props);
    }

    componentWillReceiveProps = async (nextProps) => {
        this.fetchData(nextProps);
    }

    fetchData = async (props) => {

        // const modules = compileModules({
        //     modules: props.modules,
        //     lessons: props.lessons,
        // })



        const params = {
            modules: props.modules,
            lessons: props.lessons,
            module_id: props.match.params.module_id,
            comments: props.comments,
            lessons_finished: props.lessons_finished,
            app_users: props.app_users,
            viewing_user: props.viewing_user
        }



        let modules = await buildModules(params);


        this.setState({modules: modules})
    }

    getHoursFromMinutes = (minutes) => {

        if(minutes) {
            return Math.round((minutes) * 100) / 100  
        }

        return 0

    }



    render() {

        return (
            <>

           
            <Payments props={this.props} >
           

            <Header icon={true} title={<span>Courses</span>} breadcrumb_1="All Modules"/>

            <Container className="mt--6" fluid>
                <Row>

                   {this.state.modules.map(m => (
                        <Col key={m._id} xl="4" l="6" md="6" sm="12">
                   
                        <Link to={"/dashboard/modules/" + m._id} >
                            <Card className="card-profile">

                                
                                <CardImg
                                    alt="..."
                                    src={m.picture}
                                    top
                                />
                                {m.finished ? (
                                    <>
                                        <div style={styles.finished}></div>
                                        {m.finished && <Badge style={styles.pill} color="secondary" pill>Finished</Badge>}
                                    </>
                                ) : null}
                                <Row className="justify-content-center">
                                    <Col className="order-lg-2" lg="3">
                                    <div className="card-profile-image">

                                        {/* <img
                                            alt="..."
                                            className="rounded-circle"
                                            src={m.thumbnail}
                                        /> */}

                                    </div>
                                    </Col>
                                </Row>
                                {/* <CardHeader className="text-center border-0 pt-8 pt-md-4 pb-0 pb-md-4">
                                    <div className="d-flex justify-content-between">
                                    
                                    </div>
                                </CardHeader> */}
                                <CardBody className="pt-0">
                                    <Row>
                                    <div className="col">
                                        <div className="card-profile-stats d-flex justify-content-center">
                                        <div>
                                            <span className="heading">{m.lessons && m.lessons.length ? m.lessons.length : 0}</span>
                                            <span className="description">Lessons</span>
                                        </div>
                                        <div>
                                            <span className="heading">{this.getHoursFromMinutes(m.minutes)}</span>
                                            <span className="description">Minutes</span>
                                        </div>
                                        
                                        </div>
                                    </div>
                                    </Row>
                                    <div className="text-center">
                                    <h5 className="h3">
                                        {m.name}
                                    </h5>
                                    <div className="h5 font-weight-300">
                                        <i className="ni location_pin mr-2" />
                                        {m.description}
                                    </div>
                                    </div>
                                </CardBody>
                            
                            </Card>
                        </Link>
                        
                    </Col>
                   ))}



                </Row>
            </Container>

            </Payments>

            </>

        )

    }

}

const styles = {

    finished: {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        background: 'linear-gradient(to bottom left, rgba(255, 255, 255, 0.86) 5%, rgba(255, 255, 255, 0) 10%  ) ',
        borderRadius: 5,
        zIndex: 5
    },


    pill: {
        position: 'absolute',
        top: 15,
        right: 15,
        zIndex: 10
    },
}


const mapStateToProps = state => {


    return {
        modules: state.CoursePlatform.modules,
        lessons: state.CoursePlatform.lessons,
        comments: state.CoursePlatform.comments,
        lessons_finished: state.CoursePlatform.lessons_finished,
        viewing_user: state.SYSTEM.viewing_user,
        app_users: state.SYSTEM.app_users,
      
    };
};


export default connect(mapStateToProps )(Courses);  




