/*
Documentation

this reducer holds all the actions we can dispatch for our global user object

*** make sure to declare all action types in actions.js one folder level back

*/


const initialState = {
   
}

const reducer = (state = initialState, action) => {

    return {
        ...state,
    }

    // switch (action.type) {
    //     case actionTypes.SET_VIEWING_USER:

    //         //set a logged_in prop on the viewing user
    //         if(action.payload.user && action.payload.user._id) {
    //             action.payload.user.logged_in = true
    //         } else {
    //             action.payload.user.logged_in = false;
    //         }

    //         //keep current state and update the viewing_user
    //         return {
    //             ...state,
    //             viewing_user: { ...action.payload.user, }
    //         }

    //     case actionTypes.SET_APP_USERS:


    //         return {
    //             ...state,
    //             app_users: action.payload.objects
    //         }


    //     default:
        

    //         return state;
    // }
}



export default reducer;