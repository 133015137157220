/*

Add all reducers for this plugin here
If there are no reduces return a blank object

!IMPORTANT!
Preface all reducers with "CoursePlatform__" in this file
This prevents future plugin conflicts

*/

// import exampleReducer from './reducers/exampleReducer';

// import on_load_events from './on_load_events'
// import modules from './reducers/modules'


import * as actionTypes from './actions';

const initialState = {
    support: [],
}

const reducer = (state = initialState, action) => {

    switch (action.type) {
        

        case actionTypes.SET_SUPPORT:
        
            return {
                ...state,
                support: action.payload.objects
            }

        
      
        default:
        
            return state;

    }
}






export default { Support: reducer }
