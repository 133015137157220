import Axios from 'axios';
import classnames from "classnames";
import React from "react";
import { connect } from 'react-redux';
import { Link, Redirect } from "react-router-dom";
import { Button, Card, CardBody, CardHeader, Col, Container, Form, FormGroup, Input, InputGroup, InputGroupAddon, InputGroupText, Row } from "reactstrap";
import keys from '../../../keys';
import AuthHeader from "../components/Header";

//not sure why this needs to be imported here
import store from '../../../plugins_core/store'
import * as actionTypes from '../../../plugins_core/store/actions'
import { aosCall } from 'database'

import Cookies from 'universal-cookie'

//TODO: on did mount we need to check for uid and redirect accordingly

function getQueryVariable(variable) {
        var query = window.location.search.substring(1);
        var vars = query.split("&");
        for (var i=0;i<vars.length;i++) {
                    var pair = vars[i].split("=");
        if(pair[0] === variable){return pair[1];}
         }
         return(false);
}

const cookies = new Cookies();


class Login extends React.Component {

	state = {};
 
	//on enter simulate the form being submitted for better UI
	_handleKeyDown = (e) => {
		if (e.key === 'Enter') {

			document.getElementById("loginButton").click();
		}
	}

	getUrlParameter = (name) => {
		name = name.replace(/[[]/, '\\[').replace(/[\]]/, '\\]');
		let regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
		let results = regex.exec(window.location.search);
		return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
	};

	  onLogin = async () => {

		const email = this.state.email;
		const password = this.state.password;
		
		if(!this.state.email) {
			this.setState({error: 'Invalid Email'});
			return;
		}

		if(!this.state.password) {
			this.setState({error: 'Invalid Password'});
			return;
		}

		try {
			const user = await Axios({
				method:'post',
				url: keys.API_URL + '/auth/credentials/validate',
				withCredentials: true, 
				data: {
					password: password,
					email: email
				}
			 })

			if(user.data.success) {



				const foundUser = user.data.user;

				const redirect = this.getUrlParameter('redirect')


				

				//set cookie on this browser for reference
				cookies.set('architeck_uid', foundUser._id, { path: '/' }); 


				store.dispatch({
					type: actionTypes.SYSTEM_SET_VIEWING_USER,
					payload: {
						objects: foundUser
					}
				});

				this.setState({})
				// await refreshViewingUser(foundUser)

				if(redirect) {

					this.setState({shouldRedirect: decodeURIComponent(redirect)})



				} else {

					if(foundUser.is_admin) {
						this.setState({shouldRedirect: '/admin/dashboard'})
					} else {
						this.setState({shouldRedirect: '/dashboard'})

					}

				}
			
			} else {
				this.setState({error: user.data.message})
			}


		
		} catch(e) {
		
			this.setState({error: e.toString()})
			console.log('Error ', e)
		
		}


	

	}
    
    componentDidMount = async () => {

		const redirect = this.getUrlParameter('redirect')

		const viewing_user = this.props.viewing_user;


		if(viewing_user && viewing_user.logged_in) {

			if(getQueryVariable('new_user')) {

				aosCall({
					method: 'post',
					url: '/api/active_campaign/contact/add_email_to_list',
					data: {
						first_name: this.props.viewing_user.given_name,
						last_name: this.props.viewing_user.family_name,
						email: this.props.viewing_user.email,
						_id: this.props.viewing_user._id,
						list: 5
					}
				})

			}

			if(redirect) {

				this.setState({shouldRedirect: redirect})

			} else {
				
				if(viewing_user.is_admin) {
					this.setState({shouldRedirect: '/admin/dashboard'})
				} else {
					this.setState({shouldRedirect: '/dashboard'})
				}

				
			}
		
		} else {

			const uid = getQueryVariable('architeck_uid')
			//see if we have a query variable
			if(uid) {

				if(getQueryVariable('new_user')) {

				

					aosCall({
						method: 'post',
						url: '/api/active_campaign/contact/add_email_to_list',
						data: {
							first_name: this.props.viewing_user.given_name,
							last_name: this.props.viewing_user.family_name,
							_id: this.props.viewing_user._id,
							list: 5
						}
					})

				}

				//set cookie
				cookies.set('architeck_uid', uid, { path: '/' });

				//force page refresh
				const location = window.location.href
				window.location.href = location
			}

		}

	}
	


	render() {

		if(this.state.shouldRedirect) {
			return <Redirect to={this.state.shouldRedirect} />
		}
		

		return (

		

		<>

			<AuthHeader
				title="Welcome!"
				lead="Exploding your brand is just a step away."
			/>

			<Container className="mt--8 pb-5">
				<Row className="justify-content-center">
					<Col lg="5" md="7">
						<Card className="bg-white border-0 mb-0">

							<CardHeader className="bg-transparent pb-5">

								<div className="text-muted text-center mt-2 mb-3">
									<small>Sign in with</small>
								</div>

								<div className="btn-wrapper text-center">
									<Button
										className="btn-neutral btn-icon"
										color="default"
										onClick={() => window.location.href = keys.API_URL + '/auth/google?redirect=' + window.location}
									>

										<span className="btn-inner--icon mr-1">
											<img alt="..." src={require("assets/img/icons/common/google.svg")} />
										</span>

										<span className="btn-inner--text">Google</span>

									</Button>
								</div>
							</CardHeader>

							<CardBody className="px-lg-5 py-lg-5">

								<div className="text-center text-muted mb-4">
									<small>Or sign in with credentials</small>
								</div>

								<Form role="form">
									<FormGroup className={classnames("mb-3", { focused: this.state.focusedEmail })} >

										<InputGroup className="input-group-merge input-group-alternative">

											<InputGroupAddon addonType="prepend">
												<InputGroupText>
													<i className="ni ni-email-83" />
												</InputGroupText>
											</InputGroupAddon>

											<Input
												name="email"
												onKeyDown={this._handleKeyDown}
												placeholder="Email"
												type="email"
												value={this.state.email	 || ''}
												onChange={(e) => this.setState({email: e.target.value})}
												onFocus={() => this.setState({ focusedEmail: true })}
												onBlur={() => this.setState({ focusedEmail: false })}
											/>
											
										</InputGroup>
										
									</FormGroup>

									<FormGroup className={classnames({focused: this.state.focusedPassword})}>

										<InputGroup className="input-group-merge input-group-alternative">

											<InputGroupAddon addonType="prepend">
												<InputGroupText>
													<i className="ni ni-lock-circle-open" />
												</InputGroupText>
											</InputGroupAddon>

											<Input
												name="password"
												onKeyDown={this._handleKeyDown}
												placeholder="Password"
												type="password"
												value={this.state.password || ''}
												onChange={(e) => this.setState({password: e.target.value})}
												onFocus={() => this.setState({ focusedPassword: true }) }
												onBlur={() => this.setState({ focusedPassword: false })}
											/>

										</InputGroup>

									</FormGroup>

									<div className="text-center">
										{this.state.error && <span className="text-danger small">{this.state.error}<br /></span>}
										<Button id="loginButton" onClick={this.onLogin} className="my-4" color="info" type="button">
											Sign in
										</Button>
									</div>
									
								</Form>
							</CardBody>
						</Card>

						<Row className="mt-3">
							
							<Col xs="6">
								<Link className="text-light" to="/auth/forgot" >
									<small>Forgot Password</small>
								</Link>
							</Col>

							<Col className="text-right" xs="6">
								<Link className="text-light" to="/auth/register" >
									<small>Create new account</small>
								</Link>
							</Col>

						</Row>
					</Col>
				</Row>
			</Container>
		</>
		);
	}
}

const mapStateToProps = state => {

	return {

	  viewing_user: state.SYSTEM.viewing_user,
  
	};
};
  
export default connect(mapStateToProps, '')(Login);

