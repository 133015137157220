import React from "react";
import { connect } from 'react-redux';
import { Link } from "react-router-dom";
import { Col, Container, Nav, Navbar, NavbarBrand, NavItem, NavLink, Row, UncontrolledCollapse } from "reactstrap";
import keys from '../../../keys'

class AdminNavbar extends React.Component {

    render() {

        const viewing_user = this.props.viewing_user;


        return ( 
        <> 
        <Navbar
            className="navbar-horizontal navbar-main navbar-light bg-secondary "
            expand="lg"
            id="navbar-main"
            style={{background: '#f8f9fe'}}
        >
            <Container>

                <NavbarBrand to="/" tag={Link}>
                    <img alt="..."  src={require("assets/img/app/Logo.png")} />
                </NavbarBrand>
                <button
                    aria-controls="navbar-collapse"
                    aria-expanded={false}
                    aria-label="Toggle navigation"
                    className="navbar-toggler"
                    data-target="#navbar-collapse"
                    data-toggle="collapse"
                    id="navbar-collapse"
                    type="button">
                    <span className="navbar-toggler-icon"/>
                </button>

                <UncontrolledCollapse
                    className="navbar-custom-collapse"
                    navbar
                    toggler="#navbar-collapse"
                >
                    <div className="navbar-collapse-header">
                        <Row>
                            <Col className="collapse-brand" xs="6">
                                <Link to="/admin/dashboard">
                                    <img alt="..." src={keys.LOGO}/>
                                </Link>
                            </Col>
                            <Col className="collapse-close" xs="6">
                                <button
                                    aria-controls="navbar-collapse"
                                    aria-expanded={false}
                                    aria-label="Toggle navigation"
                                    className="navbar-toggler"
                                    data-target="#navbar-collapse"
                                    data-toggle="collapse"
                                    id="navbar-collapse"
                                    type="button">
                                    <span/>
                                    <span/>
                                </button>
                            </Col>
                        </Row>
                    </div>

                    <Nav className="align-items-lg-center ml-lg-auto" navbar>
                    {viewing_user && viewing_user._id ? null : (
                        <NavItem>
                            <NavLink to="/auth/login" tag={Link}>
                                <span className="nav-link-inner--text">Login</span>
                            </NavLink>
                        </NavItem>
                    )}
                        <NavItem className=" ml-lg-4">

                            {viewing_user && viewing_user._id ? (
                                
                                <Link className="btn btn-neutral btn-icon btn-default" to="/dashboard" >
                                    <span className="btn-inner--icon">
                                        <i className="fas fa-home mr-2"></i>
                                    </span>
                                    <span className="nav-link-inner--text">My Dashboard</span>
                                </Link>

                            ) : (
                                
                                <Link className="btn btn-neutral btn-icon btn-default" to="/auth/register" >
                                    <span className="btn-inner--icon">
                                        <i className="fas fa-door-open mr-2"></i>
                                    </span>
                                    <span className="nav-link-inner--text">Sign Up</span>
                                </Link>
                            
                            )}

                        </NavItem>
                    </Nav>

                </UncontrolledCollapse>
            </Container>
        </Navbar> 
        </>
    );
  }
}

const mapStateToProps = state => {
    return {
  
      viewing_user: state.SYSTEM.viewing_user,
  
    };
};
  
export default connect(mapStateToProps, '')(AdminNavbar);
