import React from "react";
import DefaultAvatar from './DefaultAvatar.jpg'

class AvatarImage extends React.Component {
  
    state = {
        error: false
    }

  onError = () =>  this.setState({ error: true });

  render() {

    if(!this.props.src || this.state.error) {
        return <img className={this.props.className}  src={DefaultAvatar} alt="avatar" /> 
    } else {
        return <img className={this.props.className} src={this.props.src} onError={this.onError} alt="avatar" /> 
    }

    
  }
}
export default AvatarImage;