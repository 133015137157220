/*

Add all routes for this plugin here.

*/

import React from 'react'

import Support from './Views/Support';

import AdmSupport from './Views/AdmSupport';
import AdmSupportView from './Views/AdmSupportView';


import AdmUnreadComments from './components/AdmUnreadComments';
import DashUnreadComments from './components/DashUnreadComments';





export default [


    {
        divider: 'Contact',
        path: "/support",            //url
        name: "Support",             //sidebar name
        icon: "fas fa-comments text-info",    //sidebar icon
        component: Support,           //component to show in route
        layout: "/dashboard",                   //which layout to use
        renderOn: 'dashboard',                   //where to render this component
        show_in_sidebar: true,                   //where to render this component
        extraComponent: <DashUnreadComments />,
    },

    {
        divider: 'Support',
        path: "/support",            //url
        name: "Support",
        icon: "fas fa-comments text-info",    //sidebar icon
        component: AdmSupport,           //component to show in route
        layout: "/admin",                   //which layout to use
        renderOn: 'admin',                   //where to render this component
        show_in_sidebar: true,                   //where to render this component
        extraComponent: <AdmUnreadComments />,
    },
    {
        renderOn: 'admin',                   //where to render this component
        show_in_sidebar: false,          
        path: "/support/:support_id",             //url

        component: AdmSupportView,       //component to show in route
        layout: "/admin"                //which layout to use
    },



  

]