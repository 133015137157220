import AdminLayout from './index';

export default {
    path: '/dashboard',
    component: AdminLayout,

    order: [
        'Modules',
        
        'Settings',
        'Billing',
        'Support',
        
        'Websites',
    ]
} 

