/*

Add all routes for this plugin here.

*/


import Settings from './Views/Settings';
import Billing from './Views/Billing';



export default [

   

    {
        divider: 'My Profile',
        path: "/settings",       
        name: "Settings",         
        icon: "fas fa-cogs text-primary",
        component: Settings,           
        layout: "/dashboard",
        renderOn: 'dashboard',
        show_in_sidebar: true,
    },

    {
        divider: null,
        path: "/billing",
        name: "Billing",
        icon: "fas fa-dollar-sign text-primary",
        component: Billing,
        layout: "/dashboard",
        renderOn: 'dashboard',
        show_in_sidebar: true,
    },

    {
        divider: 'My Profile',
        path: "/settings",
        name: "Settings",
        icon: "fas fa-cogs text-warning",
        component: Settings,
        layout: "/admin",
        renderOn: 'admin',
        show_in_sidebar: true,
    },

  

]