/*

Add all routes for this plugin here.

*/

import Admins from './Views/Admins';
import Users from './Views/Users';
import UserView from './Views/UserView';
import CreateFreeUser from './Views/CreateFreeUser';


export default [

   
    //a collapsable sidebar route
    {
        collapse: true,
        name: "Users",
        icon: "fas fa-users text-primary",
        state: "dashboardsCollapse",
        renderOn: 'admin',
        views: [
          {
            path: "/users/app",
            name: "App Users",
            component: Users,
            layout: "/admin"
          },
          {
            path: "/users/admins",
            name: "App Admins",
            component: Admins,
            layout: "/admin"
          },
          {
            path: "/users/create/free",
            name: "Create Free User",
            component: CreateFreeUser,
            layout: "/admin"
          },
         
        ]
      },

      {
        path: "/user/:_id",            //url
        name: "Profile",             //sidebar name
        icon: "ni ni-shop text-primary",    //sidebar icon
        component: UserView,           //component to show in route
        layout: "/admin",                   //which layout to use
        renderOn: 'admin',
        show_in_sidebar: false                   //where to render this component
    },

]