import moment from 'moment';
import React from "react";
import ReactBSAlert from "react-bootstrap-sweetalert";
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { Badge, Button, Card, CardBody, CardHeader, Col, Container, Form, FormGroup, Input, Row } from "reactstrap";
import { aosAction } from '../../../database';
import Header from '../components/Header';





const required_form_fields = [
    'response',
]

class AdmSupportView extends React.Component {

    state = {
        response: null,
        canSave: true
        
    }

   
	componentWillReceiveProps = (nextProps) => {

        const support_id = nextProps.match.params.support_id;

        const found_support = nextProps.support.find(s => s._id === support_id);

        this.setState({found_support})

	}
	
	

    componentDidMount = () => {

        const support_id = this.props.match.params.support_id;

        const found_support = this.props.support.find(s => s._id === support_id);

        if(!found_support) {
            this.setState({shouldRedirect: '/admin/support'});
            return;
        }

        found_support.messages.forEach(m => {

            m.read_by_admin = true

        })

        aosAction({
            method: 'post',
            url: '/api/live/Support__support/update/' + found_support._id,
            aos_live_events: ['/api/live/Support__support/get/all?orderBy=_id&order=asc'],          
            data: found_support,
        });

        this.setState({found_support})

    }

    fireAlert = (error) => {


        this.setState({
          alert: (
            <ReactBSAlert
              success={error ? false : true}
              danger={!error ? false : true}
              style={{ display: "block", marginTop: "-100px" }}
              title={error ? 'Error' : 'Success'}
              onConfirm={() => this.hideAlert()}
              onCancel={() => this.hideAlert()}
              confirmBtnBsStyle="success"
              confirmBtnText="Ok"
              btnSize=""
            >
              {error ? 
              'Something wrong updating sending the response.' :
              'Response Sent Successfully'
            }
            </ReactBSAlert>
          )
        });
    };

    hideAlert = () => {
        this.setState({
          alert: null
        });
      };

      onSendResponse = async () => {

       if(!this.state.response) {
           this.setState({responseState: 'invalid'})
           return;
       } else {

        this.setState({responseState: 'valide'})

        const newSupport = Object.assign({}, this.state.found_support)

        const messages = newSupport.messages;

        messages.push({
            created_at:  Math.round((new Date()).getTime() / 1000),
            read_by_admin: true,
            read_by_user: false,
            text: this.state.response,
            given_name: this.props.viewing_user.given_name,
            family_name: this.props.viewing_user.family_name,
            user_id: this.props.viewing_user._id,
            sent_by: 'admin'
        })

        newSupport.messages = messages;


        const updated = await aosAction({
            method: 'post',
            url: '/api/live/Support__support/update/' + newSupport._id,
            aos_live_events: ['/api/live/Support__support/get/all?orderBy=_id&order=asc'],          
            data: newSupport,
        });

        this.setState({found_support: newSupport, response: null})

        //send error messages if went wrong
        if(!updated.success) {
            this.fireAlert(!updated.success)
        }
           
        }

    }

    validateForm = () => {
        //copy state
        let newState = Object.assign({}, this.state.user);


        required_form_fields.forEach((field) => {

            if(newState[field]) {
                newState[field + "State"] = 'valid'; 
                
            } else {
                newState[field + "State"] = 'invalid'; 

            }

            

        })


        this.setState({
          user: newState
        });
    };


    onInputChange = (e, stateName) => {


        let newState = Object.assign({}, this.state.user);
        newState[stateName] = e.target.value;

        if (stateName === "checkbox") {
          if (e.target.value) {
            newState.checkboxState = "valid";
          } else {
            newState.checkboxState = "invalid";
          }
        } else {
          if (e.target.value === "") {
            newState[stateName + "State"] = "invalid";
          } else {
            newState[stateName + "State"] = "valid";
          }
        }

        this.setState({
          user: newState
        });
    };

  render() {

    const support = this.state.found_support;

    if(this.state.shouldRedirect) {
        return <Redirect to={this.state.shouldRedirect} />
    }



    if(!support) {
        return <div></div>
    }


        return (
            <>
            {this.state.alert}

            <Header icon={true} title={<span>Support</span>} breadcrumb_1={support.given_name + ' ' + support.family_name} />

            <Container className="mt--6" fluid>     
                <Card>

                    <CardHeader>
                        <Row className="align-items-center">
                            <Col xs="8">
                            <h6 className="heading-small text-muted mb--2">Ticket Information</h6>
                            </Col>
                          
                        </Row>
                    </CardHeader>

                    <CardBody>

                        <Form>

                            <div className="pl-lg-4">
                                <Row>
                                    <Col className="mb-4" lg="4">
                                        <h6 className="heading-small text-muted">User Info</h6>
                                        <p className="mb-0">
                                            <span className="d-inline-block font-weight-bold" style={styles.span}>Name: </span>
                                            {support.given_name + ' ' + support.family_name}
                                        </p>
                                        <p className="mb-0">
                                            <span className="d-inline-block font-weight-bold" style={styles.span}>Email: </span>
                                            {support.email}
                                        </p>
                                        <p className="mb-0">
                                            <span className="d-inline-block font-weight-bold" style={styles.span}>Phone: </span>
                                            {support.phone}
                                        </p>
                                        <p className="mb-0">
                                            <span className="d-inline-block font-weight-bold" style={styles.span}>Sent: </span>
                                            {moment.unix(support.created_at).format("MM/DD/YYYY")} At {moment.unix(support.created_at).format("H:mm A")}
                                        </p>
                                    </Col>

                                    <Col lg="8">
                                        <h6 className="heading-small text-muted ">Messages</h6>
                                        {support.messages.map((m, index) => {
                                            return (
                                                m.sent_by === 'user' ? (
                                                    <Card key={index} className="mb-2">
                                                        <CardHeader>
                                                        {m.given_name + ' ' + m.family_name} &mdash;  {' '}
                                                        {moment.unix(m.created_at).format("MM/DD/YYYY")} At {moment.unix(m.created_at).format("h:mm A")}
                                                        </CardHeader>
                                                        <CardBody>
                                                            <p>{m.text}</p>
                                                        </CardBody>
                                                    </Card>
                                                ) : (
                                                    <Card key={index} className="mb-2">
                                                        <CardHeader>
                                                        {m.given_name + ' ' + m.family_name}  <Badge color="success" pill>Admin</Badge>  &mdash;  {' '}
                                                        {moment.unix(m.created_at).format("MM/DD/YYYY")} At {moment.unix(m.created_at).format("h:mm A")}
                                                        </CardHeader>
                                                        <CardBody>
                                                            <p>{m.text}</p>
                                                        </CardBody>
                                                    </Card>
                                                )
                                            )
                                        })}
                                       

                                      </Col>
                                </Row>
                            </div>

                            <hr className="my-4" />
                            <h6 className="heading-small text-muted mb--2">Response</h6>

                            <div className="pl-lg-4 py-4">

                                <FormGroup>

                                    <Input 
                                        style={{height: '8rem'}} 
                                        id="response" 
                                        rows="6" 
                                        type="textarea" 

                                        value={this.state.response || ''}
                                        placeholder="Great question..."

                                        valid={ this.state.responseState === "valid" }
                                        invalid={ this.state.responseState === "invalid" }
                                        onChange={e => this.setState({response: e.target.value}) }
                                    />
                                    <div className="valid-feedback">Looks good!</div>

                                </FormGroup>

                            </div>

                        </Form>

                        <div className="text-right mt-0">
                            <Button size="sm" color="success" onClick={this.onSendResponse}>Send Response</Button>
                        </div>

                    </CardBody>
                </Card>
            </Container>
            </>
        );
    }
}

const styles = {
    span: {width: 60}
}


const mapStateToProps = state => {
    return {
  
      viewing_user: state.SYSTEM.viewing_user,
      support: state.Support.support,
  
    };
};
  
export default connect(mapStateToProps, '')(AdmSupportView);