/*
Documentation

no redux needed for the plugin, export default reducer

*/
const initialState = {
    
}

const reducer = (state = initialState, action) => {

    return {
        ...state
    }
    
   
}






export default { Settings: reducer }
