/*

Add all routes for this plugin here.

*/



import Courses from './Views/Courses';
import ModuleLessons from './Views/ModuleLessons';
import Lesson from './Views/Lesson';



import AdmModules from './Views/AdmModules';
import AdmModulesCrud from './Views/AdmModulesCrud';
import AdmLessons from './Views/AdmLessons';
import AdmLessonsCrud from './Views/AdmLessonsCrud';

import AdmComments from './Views/AdmComments';
import AdmCoupons from './Views/AdmCoupons';
import AdmCouponsUsed from './Views/AdmCouponsUsed';


// import AdmSupport from './Views/AdmSupport';
// import AdmSupportView from './Views/AdmSupportView';
// import Support from './Views/Support';


export default [

    //a single sidebar route
    {
        divider: 'Course',
        path: "/",            //url
        name: "Modules",             //sidebar name
        icon: "fas fa-door-open text-success",    //sidebar icon
        component: Courses,           //component to show in route
        layout: "/dashboard",                   //which layout to use
        renderOn: 'dashboard',                   //where to render this component
        show_in_sidebar: true
    },

    {
        path: "/modules/:module_id",            //url
        component: ModuleLessons,           //component to show in route
        layout: "/dashboard",                   //which layout to use
        renderOn: 'dashboard',                   //where to render this component
        show_in_sidebar: false,                   //where to render this component
    },


    {
        path: "/lesson/:module_id/:lesson_id",            //url
        name: "Lesson",             //sidebar name
        icon: "ni ni-shop tex-primary",    //sidebar icon
        component: Lesson,           //component to show in route
        layout: "/dashboard",                   //which layout to use
        renderOn: 'dashboard',                   //where to render this component
        show_in_sidebar: false,          
    },

   

    //Admin Side


      {
        divider: 'Course',
        collapse: true,                     //can be collapsed
        name: "Course",                  //Title on sidebar
        icon: "fas fa-door-open text-success",    //Icon on sidebar
        state: "coursesCollapse",        //state of sidebar
        renderOn: 'admin',                  //where to render
        show_in_sidebar: true,       
        views: [                            //pages inside this collapse
            {
                path: "/CoursePlatform/modules",             //url
                name: "Modules",              //sidebar name
                component: AdmModules,       //component to show in route
                layout: "/admin"                //which layout to use
            },
          
            {
                path: "/CoursePlatform/comments",             //url
                name: "Comments",              //sidebar name
                component: AdmComments,       //component to show in route
                layout: "/admin"                //which layout to use
            },

            {
                path: "/CoursePlatform/coupons",             //url
                name: "Coupons",              //sidebar name
                component: AdmCoupons,       //component to show in route
                layout: "/admin"                //which layout to use
            },
            {
                path: "/CoursePlatform/coupons_used",             //url
                name: "Coupons Used",              //sidebar name
                component: AdmCouponsUsed,       //component to show in route
                layout: "/admin"                //which layout to use
            },
        ]
     },

   
   

    {
        path: "/CoursePlatform/modules/create/:_id",            //url
        component: AdmModulesCrud,           //component to show in route
        layout: "/admin",                   //which layout to use
        renderOn: 'admin',                   //where to render this component
        show_in_sidebar: false,          
    },

    {
        path: "/CoursePlatform/modules/lessons/:module_id",            //url
        component: AdmLessons,           //component to show in route
        layout: "/admin",                   //which layout to use
        renderOn: 'admin',                   //where to render this component
        show_in_sidebar: false,          
    },

    {
        path: "/CoursePlatform/modules/lessons/:module_id/:lesson_id",            //url
        component: AdmLessonsCrud,           //component to show in route
        layout: "/admin",                   //which layout to use
        renderOn: 'admin',                   //where to render this component
        show_in_sidebar: false,          
    },

    

  

]