/*
Documentation

Props

hide = the function to hide this modal
onSelect = a function that fires when a user is selected
filter = a function to filter out app users in this modal

*/

import moment from 'moment';
import React from "react";
import ReactBSAlert from "react-bootstrap-sweetalert";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Button, Modal } from "reactstrap";
import AvatarImage from '../Images/Avatar';









const pagination = paginationFactory({
    page: 1,
    alwaysShowAllBtns: true,
    showTotal: true,
    withFirstAndLast: false,
    sizePerPageRenderer: ({ options, currSizePerPage, onSizePerPageChange }) => (
        <div className="dataTables_length" id="datatable-basic_length">
            <label>
                Show{" "}
                {
                <select
                    name="datatable-basic_length"
                    aria-controls="datatable-basic"
                    className="form-control form-control-sm"
                    onChange={e => onSizePerPageChange(e.target.value)}
                >
                    <option value="10">10</option>
                    <option value="25">25</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                </select>
                }{" "}
                entries.
            </label>
        </div>
    )
});

const { SearchBar } = Search;


class Alternative extends React.Component {

    state = {

    }

    columns = [
        {
        dataField: "given_name",
        text: "Name",
        sort: true,
        formatter: (cell, row) => {
    
            return (
            
                <Link to={cell+"/"+row._id}>
                    <span  className="avatar avatar-sm rounded-circle aligned-image" >
                        <AvatarImage  alt="..." src={row.picture_url} />
                    </span>

                    <span className="ml-5" style={{position: 'relative', top: -11}}>
                    {row.given_name}  {row.family_name}
                    </span>
                </Link>
            )
    
            }
        }, {
            dataField: "email",
            text: "Email",
            sort: true
        }, {
            dataField: "phone",
            text: "Phone",
            sort: true
        }, {
            dataField: "created_at",
            text: "Created ON",
            sort: true,
            formatter: (cell, row) => {
              return moment.unix(row.created_at).format("MM/DD/YYYY")
    
            }
        }, {
            dataField: "",
            text: "Select",
            sort: false,
            headerFormatter: (column) => {
                return <div className="text-right">Select</div>
            },
            formatter: (cell, row) => {
                return (
                   <div className="text-right">
                       <Button onClick={() => this.props.onSelect(row)} color="success" size="sm">Select</Button>
                   </div>
                )
    
            }
        }
    ]

    hideAlert = () => {
        this.setState({
            alert: null
        });
    };

    confirmAlert = (row) => {
        this.setState({
            alert: (
                <ReactBSAlert
                    warning
                    style={{ display: "block", marginTop: "-100px" }}
                    title="Are you sure?"
                    onConfirm={() => this.hideAlert()}
                    onCancel={() => this.confirmedAlert(row)}
                    showCancel
                    confirmBtnBsStyle="secondary"
                    confirmBtnText="Cancel"
                    cancelBtnBsStyle="danger"
                    cancelBtnText={"Yes, remove " + row.given_name}
                    btnSize=""
                >
                    Are you sure you wish to delete {row.given_name} {row.family_name} from the system? This can not be undone.
                </ReactBSAlert>
            )
        });
      };
      confirmedAlert = (row) => {

        //fire off delete call here
        const deleted = {success: true}

        this.setState({
            alert: (
                <ReactBSAlert
                    success={deleted.success ? true : false}
                    danger={!deleted.success ? true : false}
                    style={{ display: "block", marginTop: "-100px" }}
                    title={deleted.success ? 'Success!' : 'Error'}
                    onConfirm={() => this.hideAlert()}
                    onCancel={() => this.hideAlert()}
                    confirmBtnBsStyle="primary"
                    confirmBtnText="Ok"
                    btnSize=""

                >
                    {deleted.success ? 
                    `You have successfully removed ${row.given_name} ${row.family_name}.` : 
                    `Something went wrong deleting ${row.given_name} ${row.family_name}.`
                    }
                </ReactBSAlert>
            )
        });
      };


    

    render() {

        let app_users = this.props.app_users;

        if(this.props.filter) {
            app_users = this.props.filter(app_users);
        }



        return (
            <>

            <Modal

                isOpen={true}
                aria-labelledby="example-modal-sizes-title-sm"
                size="lg"
            >
           
                <div className="modal-header pb-0">
                    <h2>Find A User</h2>
                    <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => this.props.hide()}
            >
              <span aria-hidden={true}>×</span>
            </button>
                </div>
               
                <div className="table-vertical-align">
                    <ToolkitProvider
                        data={app_users}
                        keyField="_id"
                        columns={this.columns}
                        search
                        >

                        {props => (
                            <div className="py-4 table-responsive">
                            <div
                                id="datatable-basic_filter"
                                className="dataTables_filter px-4 pb-1"
                                style={{width: '100%'}}
                            >

                                <label>
                                    Search:
                                    <SearchBar
                                        className="form-control-sm"
                                        placeholder="John Doe..."
                                        {...props.searchProps}
                                    />
                                </label>
 
                            </div>
                            <BootstrapTable
                                {...props.baseProps}
                                className="xxx"
                                bootstrap4={true}
                                pagination={pagination}
                                bordered={false}
                            />
                            </div>
                        )}
                        </ToolkitProvider>
                    </div>

                </Modal>
          
            </>
        );
    }
}


const mapStateToProps = state => {
    return {
  

      app_users: state.SYSTEM.app_users,
    
  
    };
  };
  
  export default connect(mapStateToProps, '')(Alternative);

