/*

functions here will fire whenever a this plugins containing layout is finished
they must all return a promise

*/

import { aosListen } from '../../database';
import * as actionTypes from './actions';
import store from '../../plugins_core/store/index'




const getSupport = () => {
    return new Promise((resolve) => {
        aosListen.listen('/api/live/Support__support/get/all?orderBy=_id&order=asc', (data) => {
            
            if(data.success) {

                store.dispatch({
                    type: actionTypes.SET_SUPPORT,
                    payload: {
                        objects: data.response
                    }
                });

                resolve(true);
                return;


            } else {

                //error reporting here
                resolve(true);
                return;

            }

        }, (e) => {

            console.log(e);
            resolve(true);
            return;
            
        })
    })
}



export default [


    {
        func: getSupport, //a promise returning function that sets a redux action in this plugin
        plugin_name: 'Support', //DON'T CHANGE!  MUST BE SAME AS PLUGIN
        prop: 'support',
        run_in_background: false,
        should_not_reload: false, //don't reload whenever we mount this plugins containing layout
    },
   
]