
import React from "react";
import { Redirect } from 'react-router-dom';
import keys from '../../../keys';
import classnames from "classnames";

import { connect } from 'react-redux';

import Axios from 'axios';

import owasp from 'owasp-password-strength-test';

import Cookies from 'universal-cookie';
import { aosCall } from 'database';

import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col
} from "reactstrap";

import store from '../../../plugins_core/store'
import * as actionTypes from '../../../plugins_core/store/actions'

// core components
import AuthHeader from "../components/Header";

function getQueryVariable(variable) {
    var query = window.location.search.substring(1);
    var vars = query.split("&");
    for (var i=0;i<vars.length;i++) {
                var pair = vars[i].split("=");
    if(pair[0] === variable){return pair[1];}
     }
     return(false);
}

const required_form_fields = [
    'given_name',
    'family_name',
    'email',
    'password',
    'password_confirm',
    'terms'
]

const cookies = new Cookies();


class Register extends React.Component {

    state = { };

    //on enter simulate the form being submitted for better UI
	_handleKeyDown = (e) => {
		if (e.key === 'Enter') {

			document.getElementById("createAccountButton").click();
		}
	}


    getUrlParameter = (name) => {
        name = name.replace(/[[]/, '\\[').replace(/[\]]/, '\\]');
        let regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
        let results = regex.exec(window.location.search);
        return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
    };


	getUrlParameter = (name) => {
		name = name.replace(/[[]/, '\\[').replace(/[\]]/, '\\]');
		let regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
		let results = regex.exec(window.location.search);
		return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
	};

    onSignUp = async () => {

        this.setState({canSave: false, termsState: 'valid', errorMessage: null})
        //copy state
        let newState = Object.assign({}, this.state);
        //assume we have no errors
        let errors = 0;

        const pt = this.state.passwordState

       
        required_form_fields.forEach((field) => {

            if(field === 'terms') {

                if(!this.state.terms) {
                    errors++
                    this.setState({termsState: 'invalid'})
                }
                
            }

            //check each required field
            if(newState[field]) {
                newState[field + "State"] = 'valid'; 
            } else {
                newState[field + "State"] = 'invalid'; 
                errors++
            }

        })

        this.setState({
            ...newState
        });

          //et the user try again but stop form saving
        if(errors) {
            this.setState({canSave: true})
        } else {

            if(pt & pt.optionalTestErrors && pt.optionalTestErrors.length) {

                return

            }


            const created = await Axios({
				method:'post',
				url: keys.API_URL + '/auth/credentials/create',
				withCredentials: true, 
				data: newState,
			 })

            if(created.data.success) {

                cookies.set('architeck_uid', created.data.user._id, { path: '/' });

                aosCall({
					method: 'post',
					url: '/api/active_campaign/contact/add_email_to_list',
					data: {
						first_name: created.data.user.given_name,
						last_name: created.data.user.family_name,
                        email: created.data.user.email,
                        _id: created.data.user._id,
						list: 5
					}
                })

                store.dispatch({
					type: actionTypes.SYSTEM_SET_VIEWING_USER,
					payload: {
						objects: created.data.user
					}
				});


                return this.setState({shouldRedirect: '/dashboard'})
    

            } else {

                this.setState({errorMessage: created.data.message})

            }

           

             //after load make sure button work again
             this.setState({canSave: true})

        }

    }


    onInputChange = (e, stateName) => {

        let pt = this.state.passwordTest

        if(stateName === 'password') {
            pt = owasp.test(e.target.value)
        }

      


        let newState = Object.assign({}, {
            ...this.state,
            passwordTest: pt
        });
        newState[stateName] = e.target.value;


       
          if (e.target.value === "") {
            newState[stateName + "State"] = "invalid";
          } else {
            newState[stateName + "State"] = "valid";
          }


        this.setState({
            ...newState
        });
    };

    componentDidMount = async () => {

		const redirect = this.getUrlParameter('redirect')

		const viewing_user = this.props.viewing_user;

        


		if(viewing_user && viewing_user.logged_in) {

            if(getQueryVariable('new_user')) {

                aosCall({
                    method: 'post',
                    url: '/api/active_campaign/contact/add_email_to_list',
                    data: {
                        first_name: this.props.viewing_user.given_name,
                        last_name: this.props.viewing_user.family_name,
                        email: this.props.viewing_user.email,
                        _id: this.props.viewing_user._id,

                        list: 5
                    }
                })
    
            }

			if(redirect) {

				this.setState({shouldRedirect: 'redirect'})

			} else {
				
				if(viewing_user.is_admin) {
					this.setState({shouldRedirect: '/admin/dashboard'})
				} else {
					this.setState({shouldRedirect: '/dashboard'})
				}

				
			}
		
		}

    }

	render() {

		if(this.state.shouldRedirect) {
			return <Redirect to={this.state.shouldRedirect} />
		}

        //get the password test value
        const pt = this.state.passwordTest;


        return (

            <>

            <AuthHeader
                title="Create an account"
                lead="Building your brand is just a step away."
            />

            <Container className="mt--8 pb-5">
                <Row className="justify-content-center">
                    <Col lg="6" md="8">

                        <Card className="bg-white border-0">

                            {/* <CardHeader className="bg-transparent pb-5">
                                
                                <div className="text-muted text-center mt-2 mb-4">
                                    <small>Sign up with</small>
                                </div>

                                <div className="text-center">

                                    <Button
                                        className="btn-neutral btn-icon"
                                        color="default"
                                        onClick={() => window.location.href = keys.API_URL + '/auth/google?redirect=' + window.location}
                                    >
                                        <span className="btn-inner--icon mr-1">
                                            <img alt="..." src={require("assets/img/icons/common/google.svg")} />
                                        </span>
                                        
                                        <span className="btn-inner--text">Google</span>
                                    </Button>
                                </div>

                            </CardHeader> */}

                            <CardBody className="px-lg-5 py-lg-5">

                                <div className="text-center text-muted mb-4">
                                    <small>sign up with credentials</small>
                                </div>

                                <Form role="form">

                                    <FormGroup className={classnames({     focused: this.state.focusedGivenName })} >

                                        {this.state.given_nameState === "invalid" && (
                                            <div className="text-danger font-italic"> <small> Required</small> </div>
                                        )}

                                        <InputGroup className="input-group-merge input-group-alternative mb-3">

                                            <InputGroupAddon addonType="prepend">
                                                <InputGroupText>
                                                    <i className="ni ni-circle-08" />
                                                </InputGroupText>
                                            </InputGroupAddon>
                                       
                                            <Input
                                                onKeyDown={this._handleKeyDown}
                                                name="fname"
                                                id="given_name"
                                                value={this.state.given_name || ''}
                                                placeholder="First Name"
                                                type="text"
                                                onChange={e => this.onInputChange(e, "given_name") }
                                                onFocus={() => this.setState({ focusedGivenName: true })}
                                                onBlur={() => this.setState({ focusedGivenName: false })}
                                            />

                                            <div className="valid-feedback">Looks good!</div>

                                        </InputGroup>

                                    </FormGroup>

                                    <FormGroup className={classnames({     focused: this.state.focusedFamilyName })} >

                                        {this.state.family_nameState === "invalid" && (
                                            <div className="text-danger font-italic"> <small> Required</small> </div>
                                        )}
                                        <InputGroup className="input-group-merge input-group-alternative mb-3">

                                            <InputGroupAddon addonType="prepend">
                                                <InputGroupText>
                                                    <i className="ni ni-circle-08" />
                                                </InputGroupText>
                                            </InputGroupAddon> 
                                       
                                            <Input
                                                onKeyDown={this._handleKeyDown}
                                                id="family_name"
                                                value={this.state.family_name || ''}
                                                name="lname"
                                                placeholder="Last Name"
                                                type="text"
                                                onChange={e => this.onInputChange(e, "family_name") }
                                                onFocus={() => this.setState({ focusedFamilyName: true })}
                                                onBlur={() => this.setState({ focusedFamilyName: false })}
                                            />

                                            <div className="valid-feedback">Looks good!</div>

                                        </InputGroup>

                                    </FormGroup>

                                    <FormGroup className={classnames({     focused: this.state.focusedEmail })} >

                                        {this.state.emailState === "invalid" && (
                                            <div className="text-danger font-italic"> <small> Required</small> </div>
                                        )}

                                        <InputGroup className="input-group-merge input-group-alternative mb-3">

                                            <InputGroupAddon addonType="prepend">
                                                <InputGroupText>
                                                    <i className="ni ni-email-83" />
                                                </InputGroupText>
                                            </InputGroupAddon>

                                            <Input
                                                onKeyDown={this._handleKeyDown}
                                                name="email"
                                                placeholder="Email"
                                                type="email"
                                                value={this.state.email || ''}
                                                onChange={e => this.onInputChange(e, "email") }
                                                onFocus={() => this.setState({ focusedEmail: true })}
                                                onBlur={() => this.setState({ focusedEmail: false })}
                                            />

                                        </InputGroup>
                                    </FormGroup>

                                    <FormGroup className={classnames({     focused: this.state.focusedPassword })} >

                                        {this.state.passwordState === "invalid" && (
                                            <div className="text-danger font-italic"> <small> Required</small> </div>
                                        )}

                                        {pt && pt.optionalTestErrors && pt.optionalTestErrors.length ? (
                                            <>

                                           { pt.optionalTestErrors.map((p, index) => {
                                                return <div key={index} className="text-danger font-italic"> <small> {p}</small> </div>
                                            })}
                                            </>
                                          
                                        ): null}

                                        <InputGroup className="input-group-merge input-group-alternative">

                                            <InputGroupAddon addonType="prepend">
                                                <InputGroupText>
                                                    <i className="ni ni-lock-circle-open" />
                                                </InputGroupText>
                                            </InputGroupAddon>

                                            <Input
                                                onKeyDown={this._handleKeyDown}
                                                name="password"
                                                placeholder="Password"
                                                type="password"
                                                value={this.state.password || ''}
                                                onChange={e => this.onInputChange(e, "password") }
                                                onFocus={() => this.setState({ focusedPassword: true })}
                                                onBlur={() => this.setState({ focusedPassword: false })  } 
                                            />

                                        </InputGroup>
                                    </FormGroup>

                                    <FormGroup className={classnames({ focused: this.state.focusedPassword })} >

                                        {this.state.password_confirmState === "invalid" && (
                                            <div className="text-danger font-italic"> <small> Required</small> </div>
                                        )}


                                        <InputGroup className="input-group-merge input-group-alternative">

                                            <InputGroupAddon addonType="prepend">
                                                <InputGroupText>
                                                    <i className="ni ni-lock-circle-open" />
                                                </InputGroupText>
                                            </InputGroupAddon>

                                            <Input
                                                onKeyDown={this._handleKeyDown}
                                                name="password_confirm"
                                                placeholder="Confirm Password"
                                                type="password"
                                                value={this.state.password_confirm || ''}
                                                onChange={e => this.onInputChange(e, "password_confirm") }
                                                onFocus={() => this.setState({ focusedPassword: true })}
                                                onBlur={() => this.setState({ focusedPassword: false })}
                                            />

                                        </InputGroup>
                                    </FormGroup>

                                    <div className="text-muted font-italic">
                                        <small> 
                                            password strength:{" "} 
                                            {pt ? (
                                                 
                                                pt.strong ?  <span className="text-success font-weight-700"> strong  </span> : 
                                                <span className="text-warning font-weight-700"> medium  </span>
                                             
                                            ): (
                                                null
                                            ) }
                                        </small>
                                    </div>

                                    <Row className="my-4">
                                        <Col xs="12">

                                            {this.state.termsState === "invalid" && (
                                                <div className="text-danger font-italic"> <small> Required</small> </div>
                                            )}
                                        
                                            <div className="custom-control custom-control-alternative custom-checkbox">
                                                <input
                                                    onClick={() => this.setState({terms: !this.state.terms})}
                                                    value={this.state.terms || ''}
                                                    className="custom-control-input"
                                                    id="customCheckRegister"
                                                    type="checkbox"
                                                />
                                                <label className="custom-control-label" htmlFor="customCheckRegister">
                                                    <span className="text-muted">
                                                        I agree with the{" "}
                                                        <a target="_blank" rel="noopener noreferrer" href="/terms-of-service" > Terms Of Service  </a>
                                                    </span>
                                                </label>
                                            </div>
                                        </Col>
                                    </Row>

                                    <div className="text-center">
                                        {this.state.errorMessage && (
                                            <div className="text-danger font-italic">
                                            <small>{this.state.errorMessage}</small>
                                        </div>
                                        )}
                                        <Button id="createAccountButton" onClick={this.onSignUp} className="mt-4" color="info" type="button"> Create account </Button>
                                    </div>
                                </Form>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
            </>

        );
    }
}

const mapStateToProps = state => {

	return {

	  viewing_user: state.SYSTEM.viewing_user,
  
	};
};
  
export default connect(mapStateToProps, '')(Register);
