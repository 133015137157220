import ReactHtmlParser from 'react-html-parser';

export const trimDescription = (description, description_length) => {


    if(!description_length) {
        description_length = 150
    }
   

    description = description.replace(/color:.*?;/g, '');

    if(description && description.length > description_length) {

        return  ReactHtmlParser(description.slice(0,description_length) + '...') 
    }

    return ReactHtmlParser(description)
   
}