/*

This file is a test component for this plugin

*/
import React, { Component } from 'react';
import ReactBSAlert from "react-bootstrap-sweetalert";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import { Link } from 'react-router-dom';
import { Button, Card, CardBody, CardHeader, Col, Container, FormGroup, Input, Row } from "reactstrap";
import { aosAction, aosCall } from '../../../database';
import Header from "./components/Header";



const pagination = paginationFactory({
  page: 1,
  alwaysShowAllBtns: true,
  showTotal: true,
  withFirstAndLast: false,
  sizePerPageRenderer: ({ options, currSizePerPage, onSizePerPageChange }) => (
    <div className="dataTables_length" id="datatable-basic_length">
      <label>
        Show{" "}
        {
          <select
            name="datatable-basic_length"
            aria-controls="datatable-basic"
            className="form-control form-control-sm"
            onChange={e => onSizePerPageChange(e.target.value)}
          >
            <option value="10">10</option>
            <option value="25">25</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </select>
        }{" "}
        entries.
      </label>
    </div>
  )
});

const { SearchBar } = Search;



let required_form_fields = [
    'name',
    'total_uses',
    
]

class Coupons extends Component {


    state = {
        coupons: null,
        identifier_system_static: this.props.type === 'subscription' ? 'stripe_plan_id' : 'amount'
    };


    columns = [
    {
        dataField: "name",
        text: "Coupon Name",
        sort: true,
    },{
        dataField: "_id",
        text: "Code",
        sort: true,
    },{
        dataField: "total_uses",
        text: "Total Uses",
        sort: true,
    },{
        dataField: "times_used",
        text: "Times Uses",
        sort: true,
        formatter: (cell, row) => {

            return (
                <span>{row.times_used ? row.times_used : 0}/{row.total_uses}</span>
            )
           
        }
    }, {
        dataField: "notes",
        text: "Notes",
    },{
        dataField: "x", //doesnt matter
        text: this.state.identifier_system_static,
        sort: true,
        formatter: (cell, row) => {
            return <span>{this.state.identifier_system_static === 'amount' ? <span>${(row[this.state.identifier_system_static] / 100).toFixed(2)}</span> : row[this.state.identifier_system_static]}
            </span>
          }
    },{
        dataField: "xx", //doesnt matter
        text: 'Actions',
        sort: true,
        formatter: (cell, row) => {
            return <div className="text-right"><Button className="btn btn-danger btn-sm" onClick={() => this.fireDeleteConfirmationAlert(row)}>Delete</Button></div>
        },
        headerFormatter: (cell, row) => {
            return <div className="text-right">Actions</div>
        }
    }
]

   
fireDeleteConfirmationAlert = (row) => {
    this.setState({
      alert: (
        <ReactBSAlert

          danger
          style={{ display: "block", marginTop: "-100px" }}
          title="Are You Sure"

          //these are switch to swith button order
          onConfirm={() => this.deleteCoupon(row._id)}
          onCancel={() => this.hideAlert()} 

          cancelBtnBsStyle=""
          cancelBtnText="Cancel"
          confirmBtnBsStyle="danger"
          confirmBtnText="Yes, Delete It"

          btnSize=""
          showCancel

      
        >
          This will stop any more users from using the coupon {row.name}.
        
        </ReactBSAlert>
      )
    });
};

deleteCoupon = async (_id) => {

    //hide the first modal
    this.hideAlert()

    const deleted = await aosCall({
        method: 'post',
        url: '/api/live/system_coupons/delete/' + _id,
    });
    
    if(deleted.success) {

        const coupons = this.state.coupons.filter(c => c._id !== _id);
        this.setState({coupons})
    
    } else {
    
        alert('Something went wrong deleting this module.')
    
    }

}

    fireAlert = (error) => {
        this.setState({
          alert: (
            <ReactBSAlert
              success={error ? false : true}
              danger={!error ? false : true}
              style={{ display: "block", marginTop: "-100px" }}
              title={error ? 'Error' : 'Success'}
              onConfirm={() => this.hideAlert()}
              onCancel={() => this.hideAlert()}
              confirmBtnBsStyle="success"
              confirmBtnText="Ok"
              btnSize=""
            >
              {error ? 
              'Something went saving this coupon' :
              'Coupon Saved'
            }
            </ReactBSAlert>
          )
        });
    };

    hideAlert = () => {
        this.setState({
            alert: null
        });
    };

    onInputChange = (e, stateName) => {


        let newState = Object.assign({}, this.state);
        newState[stateName] = e.target.value;

        if (stateName === "checkbox") {
          if (e.target.value) {
            newState.checkboxState = "valid";
          } else {
            newState.checkboxState = "invalid";
          }
        } else {
          if (e.target.value === "") {
            newState[stateName + "State"] = "invalid";
          } else {
            newState[stateName + "State"] = "valid";
          }
        }

        this.setState({...newState});
    };

    onSave = async () => {


        this.setState({canSave: false})
        //copy state
        let newState = Object.assign({}, this.state);
        //assume we have no erfrors
        let errors = 0;

        required_form_fields.forEach((field) => {

            //check each required field
            if(newState[field]) {
                newState[field + "State"] = 'valid'; 
            } else {
                newState[field + "State"] = 'invalid'; 
                errors++
            }

        })

        this.setState(newState);

          //et the user try again but stop form saving
        if(errors) {
            this.setState({canSave: true})
        } else {

            const identifier_system = this.props.type === 'subscription' ? 'stripe_plan_id' : 'amount'

            const data = {
                name: this.state.name,
                total_uses: this.state.total_uses,
                subscription_key: this.props.subscription_key,
                notes: this.state.notes,
                [identifier_system]: this.state[identifier_system]
            }

            const created = await aosAction({
                method: 'post',
                url: '/api/live/system_coupons/create',
                aos_live_events: ['/api/live/system_coupons/get/all'],          
                data: data
            });
            
            if(created.success) {

                const coupons = [...this.state.coupons];
                coupons.push({
                    name: this.state.name,
                    notes: this.state.notes,
                    total_uses: this.state.total_uses,
                    [identifier_system]: this.state[identifier_system],
                    _id: created.response._id
                })


                this.setState({
                    name: null,
                    total_uses: null,
                    coupons
                })
                this.fireAlert(false)
            
            } else {
            
                this.fireAlert(true)
            
            }

        }


    }

    componentDidMount = async () => {

        required_form_fields.push(this.props.type === 'subscription' ? 'stripe_plan_id' : 'amount')

        const getCoupons = await aosCall({
            method: 'get',
            url: '/api/live/system_coupons/get/all',      
        });

        if(getCoupons.success){

            let coupons = getCoupons.response;
            coupons = coupons.filter(c => c.subscription_key === this.props.subscription_key)

            this.setState({coupons})
        } else {
            console.log(getCoupons)
        }


    }

    render() {

        const coupons = this.state.coupons;
        const identifier = this.props.type === 'subscription' ? 'Stripe Plan Id' : 'Amount (In Cents)'
        const identifier_system = this.props.type === 'subscription' ? 'stripe_plan_id' : 'amount'


        return (
            <>

            {this.state.alert}
            
            <Header 
                icon={true} 
                title={<Link to="/admin/CoursePlatform/modules"><i className="mr-2 fas fa-arrow-left"></i>Coupons</Link>} 
                breadcrumb_1="My Coupons"  
               
            />

            <Container className="mt--6 mb-5" fluid>
                <Card>
                    <CardHeader>
                        <Row className="align-items-center">
                            <Col xs="8">
                                <h3 className="mb-0">Edit profile</h3>
                            </Col>
                            <Col className="text-right" xs="4">
                                <Button className="btn btn-success btn-sm" onClick={this.onSave}>Add Coupon</Button>
                            </Col>
                        </Row>
                    </CardHeader>


                    <CardBody>

                        <div className="pl-lg-4">

                            
                            <Row>
                                <Col lg="4">
                                    <FormGroup>
                                    <label className="form-control-label" htmlFor="name"> Coupon Name </label>
                                    <Input
                                        value={this.state.name || ''}
                                        placeholder="ex: Half Off"
                                        type="name"
                                        valid={ this.state.nameState === "valid" }
                                        invalid={ this.state.nameState === "invalid" }
                                        onChange={e => this.onInputChange(e, "name") }  
                                    />
                                    <div className="valid-feedback">Looks good!</div>

                                    </FormGroup>
                                </Col>
                                <Col lg="4">
                                    <FormGroup>
                                    <label className="form-control-label" htmlFor="phone"> Coupon Uses </label>
                                
                                    <Input
                                        value={this.state.total_uses || ''}
                                        placeholder="ex: 100"
                                        type="number"
                                        valid={ this.state.total_usesState === "valid" }
                                        invalid={ this.state.total_usesState === "invalid" }
                                        onChange={e => this.onInputChange(e, "total_uses") }
                                        
                                    />
                                    <div className="valid-feedback">Looks good!</div>

                                    </FormGroup>
                                </Col>

                                <Col lg="4">
                                    <FormGroup>
                                    <label className="form-control-label" htmlFor="phone"> {identifier} </label>
                                
                                    <Input
                                        value={this.state[identifier_system] || ''}
                                        placeholder="ex: 100 = $1 USD"
                                        type="number"
                                        valid={ this.state[identifier_system + 'State'] === "valid" }
                                        invalid={ this.state[identifier_system + 'State'] === "invalid" }
                                        onChange={e => this.onInputChange(e, identifier_system) }
                                        
                                    />

                                    <p className="small">You must add at least $1 (100) or we will not be able to process this coupon</p>
                                    <div className="valid-feedback">Looks good!</div>

                                    </FormGroup>
                                </Col>
                            </Row>

                            <FormGroup>
                                <label className="form-control-label" htmlFor="name"> Coupon Notes </label>
                                <Input
                                    value={this.state.notes || ''}
                                    placeholder=""
                                    type="textarea"
                                    valid={ this.state.notesState === "valid" }
                                    invalid={ this.state.notesState === "invalid" }
                                    onChange={e => this.onInputChange(e, "notes") }  
                                />
                                <div className="valid-feedback">Looks good!</div>

                            </FormGroup>
                       

                        </div>


                    </CardBody>
                </Card>

                {coupons && coupons.length ? (
                    <Card>
                        <CardHeader>
                            <Row className="align-items-center">
                                <Col xs="8">
                                    <h3 className="mb-0">My Coupons</h3>
                                </Col>
                            </Row>
                        </CardHeader>

                        <CardBody>

                        <div className="table-vertical-align">
        
                            <ToolkitProvider
                                data={coupons}
                                keyField="_id"
                                columns={this.columns}
                                search
                                exportCSV
                            >
                                {props => (
                                    <>
                                    <div
                                        id="datatable-basic_filter"
                                        className="dataTables_filter px-4 pb pt-3 pb-2"
                                        style={{width: '100%'}}
                                    >
                                        <Row>

                                            <Col>
                                                <label>
                                                    Search:
                                                    <SearchBar
                                                        className="form-control-sm"
                                                        placeholder=""
                                                        {...props.searchProps}
                                                    />
                                                </label>
                                            </Col>



                                        </Row>
                                    </div>
                                    <div className="pb-4 table-responsive">
                                
                                    <BootstrapTable
                                        {...props.baseProps}

                                        bootstrap4={true}
                                        pagination={pagination}
                                        bordered={false}
                                    />
                                    </div>
                                    </>
                                )}
                                </ToolkitProvider>
                            </div>
                        </CardBody>
                    </Card>
                ) : null}
            </Container>


            </>

        )

    }
 
}

export default Coupons;  
  
  


