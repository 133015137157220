 import React from "react";
import ReactBSAlert from "react-bootstrap-sweetalert";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { CSVExport, Search } from "react-bootstrap-table2-toolkit";
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Badge, Card, CardHeader, Col, Container, DropdownItem, DropdownMenu, DropdownToggle, Row, UncontrolledDropdown } from "reactstrap";
import Header from "../components/Header";








const { ExportCSVButton } = CSVExport;

const pagination = paginationFactory({
    page: 1,
    alwaysShowAllBtns: true,
    showTotal: true,
    withFirstAndLast: false,
    sizePerPageRenderer: ({ options, currSizePerPage, onSizePerPageChange }) => (
        <div className="dataTables_length" id="datatable-basic_length">
            <label>
                Show{" "}
                {
                <select
                    name="datatable-basic_length"
                    aria-controls="datatable-basic"
                    className="form-control form-control-sm"
                    onChange={e => onSizePerPageChange(e.target.value)}
                >
                    <option value="10">10</option>
                    <option value="25">25</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                </select>
                }{" "}
                entries.
            </label>
        </div>
    )
});

const { SearchBar } = Search;

class Profile extends React.Component {

    state = {
        email: this.props.viewing_user.email,
        phone: this.props.viewing_user.phone,
    }


    columns = [
    {
		dataField: "given_name",
		text: "Name",
		sort: true,
		formatter: (cell, row) => {

			let unread = false

			if(row.messages && row.messages.length) {

				row.messages.forEach(m => {
					if(!m.read_by_admin) {
						unread = true
					}
				})

			}

  
			return (
				<Link to={"/admin/support/" + row._id} >
				
					{row.given_name + ' ' + row.family_name}
					{unread && <Badge className="ml-2" color="success" pill>Unread</Badge>}
				</Link>
			)
  
        }
      }, {
		dataField: "message",
		text: "Message",
		sort: true,
		formatter: (cell, row) => {

			return <div style={{maxWidth: 500, overflow: 'hidden'}}>{row.messages[row.messages.length - 1].text}</div>

		}
      
	}, {
          dataField: "",
          text: "Actions",
          sort: false,
          headerFormatter: (column) => {
              return <div className="text-right">Actions</div>
          },
          formatter: (cell, row) => {
			return (
				<div className="text-right" style={{marginRight: -21}}>
					<UncontrolledDropdown>
						<DropdownToggle
							className="btn-icon-only text-light"
							color=""
							role="button"
							size="sm"
						>
						<i className="fas fa-ellipsis-v" />
						</DropdownToggle>
						<DropdownMenu className="dropdown-menu-arrow" right>

							<Link to={"/admin/support/" + row._id}>
								<DropdownItem >
									View
								</DropdownItem>
							</Link>
 
						
						
						   
						</DropdownMenu>
					</UncontrolledDropdown>
				</div>
			)

		}
      }
  ]

  confirmAlert = (row) => {
	this.setState({
		alert: (
			<ReactBSAlert
				warning
				style={{ display: "block", marginTop: "-100px" }}
				title="Are you sure?"
				onConfirm={() => this.hideAlert()}
				onCancel={() => this.confirmedAlert(row)}
				showCancel
				confirmBtnBsStyle="secondary"
				confirmBtnText="Cancel"
				cancelBtnBsStyle="danger"
				cancelBtnText={"Yes, remove " + row.given_name}
				btnSize=""
			>
				Removing this support ticket will delete its data from the system.
			</ReactBSAlert>
		)
	});
  };
  confirmedAlert = (row) => {

	//fire off delete call here
	const deleted = {success: true}

	this.setState({
		alert: (
			<ReactBSAlert
				success={deleted.success ? true : false}
				danger={!deleted.success ? true : false}
				style={{ display: "block", marginTop: "-100px" }}
				title={deleted.success ? 'Success!' : 'Error'}
				onConfirm={() => this.hideAlert()}
				onCancel={() => this.hideAlert()}
				confirmBtnBsStyle="primary"
				confirmBtnText="Ok"
				btnSize=""

			>
				{deleted.success ? 
				`You have successfully removed ${row.given_name} ${row.family_name}.` : 
				`Something went wrong deleting ${row.given_name} ${row.family_name}.`
				}
			</ReactBSAlert>
		)
	});
  };



    hideAlert = () => {
        this.setState({
          alert: null
        });
      };


  render() {



	return (
		<>
		{this.state.alert}

		<Header icon={true} title={<span>Support</span>} breadcrumb_1="Tickets"/>
		<Container className="mt--6" fluid>
		
		<Card>
			<CardHeader>
			<h3 className="mb-0">Support Tickets</h3>
				<p className="text-sm mb-0">
					This table shows all of your course support tickets.
				</p>
			</CardHeader>
			<div className="table-vertical-align">
				<ToolkitProvider
					data={this.props.support}
					keyField="_id"
					columns={this.columns}
					search
					exportCSV
					>
					{props => (
						<div className="py-4 table-responsive">
						<div
							id="datatable-basic_filter"
							className="dataTables_filter px-4 pb-1"
							style={{width: '100%'}}
						>
							<Row>

								<Col>
									<label>
										Search:
										<SearchBar
											className="form-control-sm"
											placeholder=""
											{...props.searchProps}
										/>
									</label>
								</Col>

								<Col className="col-auto">
									<ExportCSVButton
									
										className="btn-sm"
										{ ...props.csvProps }
										
									>
										<span>Export CSV</span>
									</ExportCSVButton>
								</Col>

							</Row>
						</div>
						<BootstrapTable
							{...props.baseProps}
							className="xxx"
							bootstrap4={true}
							pagination={pagination}
							bordered={false}
						/>
						</div>
					)}
					</ToolkitProvider>
				</div>
		  </Card>
	  
		


		</Container>
		</>
	);
    }
}



const mapStateToProps = state => {
    return {
  
      viewing_user: state.SYSTEM.viewing_user,
      support: state.Support.support,
  
    };
};
  
export default connect(mapStateToProps, '')(Profile);