
import React from "react";
import { connect } from 'react-redux';
import { Link } from "react-router-dom";
import {CardHeader, CardFooter, NavItem, Nav, NavLink, Badge, Card,Table,  CardBody, Col, Container, Row } from "reactstrap";
import { aosCall } from '../../../database';
import AdamContos from 'plugins/Home/assets/AdamContos.jpg';

class Home extends React.Component {

    state = {
        shouldRenderMore: false,

        total_lessons: null,
        total_modules: null,
        total_minutes: null,
    }

    componentDidMount = async () => {
        this.setState({shouldRenderMore: true})

        let lessons;
        let modules;
        let minutes = 0;

        try {
            let lessons_response = await aosCall({
              method:'get',
              url:'/api/live/CoursePlatform__lessons/get/all',
            
            })

            if(lessons_response.success) {

                lessons = lessons_response.response;

                if(lessons && lessons.length) {
                    this.setState({total_lessons: lessons.length})

                    lessons.forEach(l => {
                        if(l.minutes) {
                            console.log(l.name + ': ' + l.minutes)
                            minutes = minutes + parseInt(l.minutes)

                            console.log('total minutes: ' + minutes)
                        }


                    })

                    if(minutes) {

                        console.log(minutes)

                        //this rounds up so we subtract 1 hour
                        minutes =  Math.round((minutes / 60))
                        
                    }

               

                    this.setState({total_minutes: minutes})
                } else {
                    this.setState({total_lessons: 0})
                }

            }
          
        } catch(e) {

            console.log(e)
        }

        try {
            let modules_response = await aosCall({
              method:'get',
              url:'/api/live/CoursePlatform__modules/get/all',
            
            })

            if(modules_response.success) {

                modules = modules_response.response;

                if(modules && modules.length) {
                    this.setState({total_modules: modules.length})

                } else {
                    this.setState({total_modules: 0})
                }

            }
          
        } catch(e) {

            console.log(e)
        }

        this.sizeIframe()
        window.addEventListener("resize", this.sizeIframe.bind(this));

        


    }


    sizeIframe = () => {

        const width = document.getElementById('iframeContainer').offsetWidth;
        console.log(width)
        let height = (width / 16 )* 9 
        // height = height +20
        this.setState({height})

        // this.setState({width, height})


    }

    componentWillUnmount() {
        // window.removeEventListener("resize", this.sizeIframe.bind(this));
    }


    render() {

        const viewing_user = this.props.viewing_user

       
        
        return (
        <>

            <div className="main-content">

                <div className="header bg-secondary pt-5 pb-7">
                    <Container>
                        <div className="header-body">
                            <Row className="align-items-center">

                                <Col lg="6">
                                    <div className="pr-5">

                                        <h1 className="display-2  font-weight-bold mb-0"> Instagram <br /> For Real Estate   </h1>
                                        <h2 className="display-4  font-weight-light"> Online Course </h2>

                                        <p className=" mt-4"> A deep dive into Instagram & Personal Branding that will get you the exposure and social media following that matters to you. </p>

                                        <div className="mt-5">

                                           {viewing_user && viewing_user._id ? (

                                                <Link to="/auth/login" className="btn btn-neutral my-2 btn-default" > My Dashboard </Link>

                                           ) : (
                                                <>
                                                    <Link to="/auth/login" className="btn btn-neutral my-2 btn-default" > Dashboard Login </Link>
                                                    <Link to="/auth/register" className="my-2 btn btn-success"  > Sign Up </Link>
                                                </>
                                           )}
                                            
                                        </div>
                                    </div>
                                </Col>

                                <Col lg="6">
                                    <Row className="pt-5">
                                        <Col md="6">

                                            <Card>
                                                <CardBody>
                                                <div className="icon icon-shape bg-gradient-red text-white rounded-circle shadow mb-4">
                                                <i className="fas fa-laptop"></i>
                                                </div>
                                                <h5 className="h3">{this.state.total_lessons} Lessons</h5> 
                                                <p> Designed to boost your social media & branding skills. </p>
                                                </CardBody>
                                            </Card>

                                            <Card>
                                                <CardBody>
                                                    <div className="icon icon-shape bg-gradient-info text-white rounded-circle shadow mb-4">
                                                    <i className="fas fa-door-open"></i>
                                                    </div>
                                                    <h5 className="h3">{this.state.total_modules} Modules</h5>
                                                    <p> Uniquely tailored to fit your understanding of the world of social media. </p>
                                                </CardBody>
                                            </Card>

                                        </Col>

                                        <Col className="pt-lg-5 pt-4" md="6">

                                            <Card className="mb-4">
                                                <CardBody>
                                                <div className="icon icon-shape bg-gradient-success text-white rounded-circle shadow mb-4">
                                                    <i className="fas fa-hourglass-start"></i>
                                                </div>
                                                <h5 className="h3">Over {this.state.total_minutes} Hours</h5>
                                                <p> Built for beginners and experienced professionals alike. </p>
                                                </CardBody>
                                            </Card>

                                            <Card className="mb-4">
                                                <CardBody>
                                                <div className="icon icon-shape bg-gradient-warning text-white rounded-circle shadow mb-4">
                                                <i className="fas fa-comments"></i>
                                                </div>
                                                <h5 className="h3">Support</h5>
                                                <p> For when you have questions and need answers. </p>
                                                </CardBody>
                                            </Card>

                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </div>
                    </Container>

                    <div className="separator separator-bottom separator-skew zindex-100">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            preserveAspectRatio="none"
                            version="1.1"
                            viewBox="0 0 2560 100"
                            x="0"
                            y="0"
                        >
                        <polygon
                            className="fill-default"
                            points="2560 0 2560 100 0 100"
                        />
                        </svg>
                    </div>
                </div>

                <section className="py-6 pb-9 bg-default">
                    <Container fluid>
                        <Row className="justify-content-center text-center">
                            <Col md="6">
                            <h2 className="display-3 text-white" style={{textTransform: 'uppercase'}}>
                                A complete Instagram and Personal branding solution
                            </h2>
                                <p className="lead text-white">
                                    Designed for a REALTOR ® by a REALTOR ®.  With over eight years of hands-on experience in creating brands and building social media accounts for well known influencers, it is time for a course like this to be created and introduced to the Real Estate world. 
                                </p>

                            </Col>
                        </Row>

                       <Container>
                            <div  className="py-6 pb-5" >
                                <Row>
                                    <Col md={9} className="text-center ml-auto mr-auto">
                                        <div id="iframeContainer" style={{borderRadius: 10, height: this.state.height, overflow: 'hidden', border: 'solid 10px white', margin: '0 auto'}}>
                                            <iframe 
                                                title="intro" 
                                                src="https://www.youtube.com/embed/0_rUZChgwFA" 
                                                width='100%' 
                                                height={this.state.height} 
                                                style={{marginTop: -10}}  
                                                frameborder="0" 
                                                allow="autoplay; fullscreen"
                                                allowfullscreen 
                                            />
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </Container>

                    </Container>


                </section>



                <section className="section section-lg pt-lg-0 mt--7">
                    <Container>
                        <Row className="justify-content-center">
                            <Col lg="12">
                                <Row>

                                    <Col lg="4">
                                        <Card className=" shadow border-0">

                                            <CardBody className="py-5">
                                                <div className="icon icon-shape bg-gradient-info text-white rounded-circle mb-4">
                                                <i className="fab fa-instagram"></i>
                                                </div>

                                                <h4 className="h3 text-info text-uppercase">
                                                    Instagram
                                                </h4>

                                                <p className="description mt-3">
                                                Having an Instagram account and knowing how to properly use it are two different things. You will learn everything you need to know about it in the course. 
                                                </p>

                                                {/* <div>
                                                    <Badge color="info" pill> react </Badge> 
                                                    <Badge color="info" pill> reactstrap </Badge> 
                                                    <Badge color="info" pill> dashboard </Badge> 
                                                    <Badge color="info" pill> template </Badge>
                                                </div> */}
                                            </CardBody>

                                        </Card>
                                    </Col>

                                    <Col lg="4">
                                        <Card className=" shadow border-0">
                                            <CardBody className="py-5">

                                                <div className="icon icon-shape bg-gradient-success text-white rounded-circle mb-4">
                                                <i className="fas fa-user-check"></i>
                                                </div>

                                                <h4 className="h3 text-success text-uppercase">
                                                    Personal Branding
                                                </h4>

                                                <p className="description mt-3">Everyone has a personal brand whether they like it or not. The question is, how do you build and grow it the right way? Module 2 of IGRE is all about that.  </p>

                                            </CardBody>
                                        </Card>
                                    </Col>

                                    <Col lg="4">
                                        <Card className=" shadow border-0">
                                            <CardBody className="py-5">

                                                <div className="icon icon-shape bg-gradient-warning text-white rounded-circle mb-4">
                                                <i className="fas fa-rocket"></i>
                                                </div>

                                                <h4 className="h3 text-warning text-uppercase">
                                                    Leveraging Status
                                                </h4>

                                                <p className="description mt-3">
                                                    After learning all of the lessons, you will be able to leverage your status and bring your brand the right credibility and exposure that it deserves. 
                                                 </p>

                                            </CardBody>
                                        </Card>
                                    </Col>

                                </Row>
                            </Col>
                        </Row>
                    </Container>
                </section>



                <section className="py-6">
                    <Container>

                    <h1 className="display-1">Course Features</h1>
                    <hr />

                        <Row className="row-grid align-items-center">

                            <Col className="order-md-2" md="6">
                                <img alt="..." className="img-fluid" src={require("assets/img/brand/Home3.png")} />
                            </Col>

                            <Col className="order-md-1" md="6">
                                <div className="pr-md-5">
                                    <h1>SIMPLE & USER FRIENDLY LESSONS </h1>
                                    <p>
                                    Not only can you watch this course on your computer, but you can easily access it on your phone while you are at the gym or even on your way to work!
                                    </p>
                                    <ul className="list-unstyled mt-5">
                                        <li className="py-2">
                                            <div className="d-flex align-items-center">
                                                <div>
                                                    <Badge className="badge-circle mr-3" color="success" >
                                                    <i className="fas fa-mobile"></i>
                                                    </Badge>
                                                </div>
                                                <div>
                                                    <h4 className="mb-0">
                                                    Mobile Friendly
                                                    </h4>
                                                </div>
                                            </div>
                                        </li>
                                        <li className="py-2">
                                            <div className="d-flex align-items-center">
                                                <div>
                                                    <Badge className="badge-circle mr-3" color="success" >
                                                        <i className="fas fa-laptop"></i>
                                                    </Badge>
                                                </div>
                                                <div>
                                                    <h4 className="mb-0">Simple to Maneuver Dashboard</h4>
                                                </div>
                                            </div>
                                        </li>
                                        <li className="py-2">
                                            <div className="d-flex align-items-center">
                                                <div>
                                                    <Badge className="badge-circle mr-3" color="success" >
                                                        <i className="fas fa-headset"></i>
                                                    </Badge>
                                                </div>
                                                <div>
                                                    <h4 className="mb-0">Customer Support Team </h4>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>

                <section className="py-6">
                    <Container>
                        <Row className="row-grid align-items-center">
                            <Col md="6">
                            <img
                                alt="..."
                                style={{width: '85%'}}
                                className="img-fluid"
                                src={require("assets/img/brand/Home2.png")}
                            />
                            {/* <img
                                alt="..."
                                className="img-fluid shadow rounded"
                                src={require("assets/img/brand/WebTest.png")}
                            /> */}
                            </Col>
                            <Col md="6">
                            <div className="pr-md-5">
                                <h1>TRACK YOUR PROGRESS  </h1>
                                <p>Stay in line and track your progress to see what you’ve already completed and what is still yet to be tackled. </p>
                            </div>
                            </Col>
                        </Row>
                    </Container>
                </section>

               
                <section className="py-6">
                    <Container>
                        <Row className="row-grid align-items-center">
                            <Col className="order-md-2" md="6">
                            <img
                                alt="..."
                                className="img-fluid"
                                src={require("assets/img/brand/Home1.png")}

                            />
                            </Col>
                            <Col className="order-md-1" md="6">
                            <div className="pr-md-5">
                                <h1>COMMUNITY COMMENT SECTION </h1>
                                <p>Can chat and communicate with other IGRE students around the world and share your thoughts on the latest lessons you watched. </p>
                            </div>
                            </Col>
                        </Row>
                        </Container>
                    </section>

                    <section className="py-6 bg-white">
                        <Container>

                        <div className="text-center">
                            <h1 className="display-1">Testimonials</h1>
                            <p>Don’t take my word for it, hear it from everyone else. </p>

                            <hr />  
                        </div>

                            <Row>
                                <Col sm={4}>
                                    <Card>
                                        <CardBody>

                                            <img
                                            alt="..."
                                            className="rounded-circle img-center img-fluid shadow shadow-lg--hover"
                                            src={'http://res.cloudinary.com/igre-coaching/image/upload/v1570385975/RealEstate/2019-10-06T18:19:35.699Z.jpg'}
                                            style={{ width: "120px" }}
                                            />

                                        <div className="pt-4 text-center">
                                            <h5 className="h3 title">
                                            <span className="d-block mb-1">Ricky Carruth</span>
                                            <small className="h4 font-weight-light text-muted">
                                            Real Estate Agent
                                            </small>
                                            < hr/>
                                            <p>“I've watched Ed grow so much, and he is helping so many Real Estate agents, including myself.” </p>
                                            </h5>
                                        
                                        </div>
                                        </CardBody>
                                    </Card>
                                </Col>
                                <Col sm={4}>
                                    <Card>
                                        <CardBody>

                                            <img
                                            alt="..."
                                            className="rounded-circle img-center img-fluid shadow shadow-lg--hover"
                                            src={'http://res.cloudinary.com/igre-coaching/image/upload/v1570385975/RealEstate/2019-10-06T18:19:35.687Z.jpg'}
                                            style={{ width: "120px" }}
                                            />

                                        <div className="pt-4 text-center">
                                            <h5 className="h3 title">
                                            <span className="d-block mb-1">Ryan Serhant</span>
                                            <small className="h4 font-weight-light text-muted">
                                                Star on Million Dollar Listing New York
                                            </small>
                                            < hr/>
                                            <p>“This guy Ed, I see him on Instagram all the time and he has a social media presence of a champ!” </p>
                                            </h5>
                                        
                                        </div>
                                        </CardBody>
                                    </Card>
                                </Col>
                                <Col sm={4}>
                                    <Card>
                                        <CardBody>

                                            <img
                                                alt="..."
                                                className="rounded-circle img-center img-fluid shadow shadow-lg--hover"
                                                src={AdamContos}
                                                style={{ width: "120px" }}
                                            />

                                        <div className="pt-4 text-center">
                                            <h5 className="h3 title">
                                            <span className="d-block mb-1">Adam Contos</span>
                                            <small className="h4 font-weight-light text-muted">
                                            RE/MAX CEO
                                            </small>
                                            < hr/>
                                            <p>“Ed is really evolving the branding game of Real Estate towards the direction it needs to go.” </p>
                                            </h5>
                                        
                                        </div>
                                        </CardBody>
                                    </Card>
                                </Col>

                            </Row>
                        </Container>
                    </section>

                    <section className="bg-default  py-7  section-nucleo-icons ">
                        <Container>

                            <div className="text-center mb-6">
                                <h1 className="display-1 text-white">Work With Me</h1>
                                <p className="text-secondary ">Services built to take your brand to the next level. </p>

                                <hr />  
                            </div>

                            <Row className="justify-content-center">
                                <Col lg="10">
                                    <div className="pricing card-group flex-column flex-md-row mb-3">
                                        <Card className="card-pricing border-0 text-center mb-4">

                                            <CardHeader className="bg-transparent">
                                                <h4 className="text-uppercase ls-1 text-info py-3 mb-0">
                                                Personal Coaching
                                                </h4>
                                            </CardHeader>

                                            <CardBody className="px-lg-7">
                                                <span className="text-muted small">as little as</span>
                                                <div className="display-2">$97</div>
                                                <span className="text-muted">Consulting Call</span>

                                                <ul className="list-unstyled my-4">
                                                    <li>
                                                        <div className="d-flex align-items-center">
                                                            <div>
                                                                <div className="icon icon-xs icon-shape bg-gradient-info shadow rounded-circle text-white">
                                                                <i className="fas fa-mobile"></i>
                                                                </div>
                                                            </div>
                                                            <div>
                                                                <span className="pl-2">1-on-1 Phone Call</span>
                                                            </div>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div className="d-flex align-items-center">
                                                            <div>
                                                                <div className="icon icon-xs icon-shape bg-gradient-info shadow rounded-circle text-white">
                                                                <i className="fas fa-comments"></i>
                                                                </div>
                                                            </div>
                                                            <div>
                                                                <span className="pl-2"> Q&A Ask Me Anything </span>
                                                            </div>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div className="d-flex align-items-center">
                                                            <div>
                                                                <div className="icon icon-xs icon-shape bg-gradient-info shadow rounded-circle text-white">
                                                                <i className="fas fa-calendar-check"></i>
                                                                </div>
                                                            </div>
                                                            <div>
                                                                <span className="pl-2">Schedule A Call!</span>
                                                            </div>
                                                        </div>
                                                    </li>
                                                </ul>

                                                <a className="mb-3 btn btn-info" href="https://calendly.com/igrecoaching/igrecall" target="_blank" rel="noopener noreferrer" > Schedule Call </a>

                                            </CardBody>

                                            <CardFooter>
                                                <a className="text-light" href="https://calendly.com/igrecoaching/igrecall" target="_blank" rel="noopener noreferrer"> View Calendar </a>
                                            </CardFooter>

                                        </Card>

                                        <Card className="card-pricing bg-gradient-success zoom-in shadow-lg rounded border-0 text-center mb-4">

                                            <CardHeader className="bg-transparent">
                                                <h4 className="text-uppercase ls-1 text-white py-3 mb-0"> IGRE Masterclass </h4>
                                            </CardHeader>

                                            <CardBody className="px-lg-7">
                                            <span className="text-white small">as little as</span>

                                                <div className="display-1 text-white">$397</div>
                                                <span className="text-white">Masterclass</span>

                                                <ul className="list-unstyled my-4">
                                                    <li>
                                                        <div className="d-flex align-items-center">
                                                            <div>
                                                                <div className="icon icon-xs icon-shape bg-white shadow rounded-circle text-muted">
                                                                <i class="fas fa-laptop"></i>
                                                                </div>
                                                            </div>
                                                            <div>
                                                                <span className="pl-2 text-white"> {this.state.total_modules} Modules </span>
                                                            </div>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div className="d-flex align-items-center">
                                                            <div>
                                                                <div className="icon icon-xs icon-shape bg-white shadow rounded-circle text-muted">
                                                                <i class="fas fa-hourglass-half"></i>

                                                                </div>
                                                            </div>
                                                            <div>
                                                                <span className="pl-2 text-white"> Over {this.state.total_minutes} Hours Of Video  </span>
                                                            </div>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div className="d-flex align-items-center">
                                                            <div>
                                                                <div className="icon icon-xs icon-shape bg-white shadow rounded-circle text-muted">
                                                                <i className="fas fa-book" />
                                                                </div>
                                                            </div>
                                                            <div>
                                                                <span className="pl-2 text-white">
                                                                {this.state.total_lessons} Lessons
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </li>
                                                </ul>

                                                <Link to="/auth/register" className="mb-3 btn btn-secondary"> Buy The Course </Link>

                                            </CardBody>

                                            <CardFooter className="bg-transparent">
                                                <Link to="/auth/register"  className="text-white" > Register Now </Link>
                                            </CardFooter>

                                            </Card>
                                        </div>
                                    </Col>
                                </Row>

                                <div className="d-flex justify-content-lg-center px-3 mt-5">
                                    <div>
                                        <div className="icon icon-shape bg-gradient-white shadow rounded-circle text-primary">
                                        <i className="fas fa-users" text-primary/>

                                        </div>
                                    </div>

                                    <Col lg="6">
                                        <p className="text-white">
                                        I am so confident in my masterclass that if you are not completely satisfied, simply reach out to us within 3 days from the date of your purchase and we will begin the process of a refund. 
                                        </p>
                                    </Col>
                                </div>

                                <Row className="row-grid justify-content-center mt-4">
                                    <Col lg="12">
                                    <Table className="table-dark mt-5" responsive>
                                        <thead>
                                        <tr>
                                            <th className="px-0 bg-transparent" scope="col">
                                            <span className="text-light font-weight-700">
                                                Features
                                            </span>
                                            </th>
                                            <th className="text-center bg-transparent" scope="col">
                                            Personal Consulting
                                            </th>
                                            <th className="text-center bg-transparent" scope="col">
                                            IGRE Mastercourse
                                            </th>
                                        </tr>
                                        </thead>
                                        <tbody>

                                            <tr>
                                                <td className="px-0">Customer Support</td>

                                                <td className="text-center">
                                                    {/* &mdash; */}
                                                </td>

                                                <td className="text-center">
                                                    <i className="fas fa-check text-success" />
                                                </td>
                                            </tr>

                                            <tr>
                                                <td className="px-0">In-depth Instagram Lessons </td>
                                                   
                                                <td className="text-center">
                                                    {/* &mdash; */}
                                                </td>

                                                <td className="text-center">
                                                    <i className="fas fa-check text-success" />
                                                </td>
                                            </tr>

                                            <tr>
                                                <td className="px-0">In-depth Personal Branding Lessons  </td>
                                                   
                                                <td className="text-center">
                                                    {/* &mdash; */}
                                                </td>

                                                <td className="text-center">
                                                    <i className="fas fa-check text-success" />
                                                </td>
                                            </tr>

                                            <tr>
                                                <td className="px-0">Money Back Guarantee </td>
                                                    
                                                <td className="text-center">
                                                    {/* &mdash; */}
                                                </td>

                                                <td className="text-center">
                                                    <i className="fas fa-check text-success" />
                                                </td>
                                            </tr>

                                            <tr>
                                                <td className="px-0">Ask Me Anything</td>

                                                <td className="text-center">
                                                    <i className="fas fa-check text-success" />
                                                </td>

                                                <td className="text-center">
                                                    <i className="fas fa-check text-success" />
                                                </td>
                                            </tr>

                                            <tr>
                                                <td className="px-0">Personalized Action Plans</td>

                                                <td className="text-center">
                                                    <i className="fas fa-check text-success" />
                                                </td>

                                                <td className="text-center">
                                                    {/* &mdash; */}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="px-0">1-on-1 Phone </td>

                                                <td className="text-center">
                                                    <i className="fas fa-check text-success" />
                                                </td>

                                                <td className="text-center">
                                                    {/* &mdash; */}
                                                </td>
                                            </tr>
                                      
                                        </tbody>
                                    </Table>
                                    </Col>
                                </Row>

          
                            </Container>
                        </section>

                    

                
                </div>

            <footer className="py-5" id="footer-main">
                <Container>
                    <Row className="align-items-center justify-content-xl-between">
                        <Col xl="6">
                            <div className="copyright text-center text-xl-left text-muted">
                            © {new Date().getFullYear()}{" "}
                            
                                Ed Stulak

                            </div>
                        </Col>
                        <Col xl="6">
                            <Nav className="nav-footer justify-content-center justify-content-xl-end">
                            <NavItem>
                                <NavLink>
                                    <Link to="/auth/login" style={{color: 'inherit'}}>Login</Link>
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink >
                                <Link to="/auth/register" style={{color: 'inherit'}}>Register</Link>
                                </NavLink>
                            </NavItem>
                            
                            </Nav>
                        </Col>
                    </Row>
                </Container>
            </footer>

        </>
        );
    }
}


const mapStateToProps = state => {


    return {
        modules: state.CoursePlatform.modules,
        lessons: state.CoursePlatform.lessons,
        comments: state.CoursePlatform.comments,
        lessons_finished: state.CoursePlatform.lessons_finished,
        viewing_user: state.SYSTEM.viewing_user,
        app_users: state.SYSTEM.app_users,
      
    };
};

  
export default connect(mapStateToProps )(Home);  