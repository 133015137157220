import { aosCall } from './index'

export const createSystemLog = (payload) => {
    return new Promise(async resolve => {

        if(!payload.error_file) {
            console.log('No error_file passed to createSystemLog.')
        }

        if(!payload.text) {
            console.log('No text passed to createSystemLog')
        }

        if(!payload.error_url) {
            console.log('No text passed to createSystemLog')
        }

        const x = await aosCall({
            method: 'post',
            url: '/api/live/system_logs/create',    
            data: {...payload}
        });

        if(!x.success) {
            console.log(x) 
        }

        
    })
}

export default createSystemLog