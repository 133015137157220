import React from "react";
// react library for routing
import { Route, Switch } from "react-router-dom";

// core components
import Navbar from "./components/Navbar";


import routes from "../../plugins_core/routes";
import keys from '../../keys'

import Cookies from 'universal-cookie';
const cookies = new Cookies();


class Auth extends React.Component {
    componentDidMount() {
        document.body.classList.add("bg-default");
    }
    componentWillUnmount() {
        document.body.classList.remove("bg-default");
    }
    componentDidUpdate(e) {
        if (e.history.pathname !== e.location.pathname) {
            document.documentElement.scrollTop = 0;
            document.scrollingElement.scrollTop = 0;
            this.refs.mainContent.scrollTop = 0;
        }
    }
    getRoutes = routes => {
        return routes.map((prop, key) => {
        if (prop.collapse) {
            return this.getRoutes(prop.views);
        }
        if (prop.layout === "/auth") {
            return (
            <Route
                path={prop.layout + prop.path}
                component={prop.component}
                key={key}
            />
            );
        } else {
            return null;
        }
        });
    };


    getRedirectUrl = () => {
        var getUrl = window.location;
        var baseUrl = getUrl.protocol + "//" + getUrl.host + "/" + getUrl.pathname.split('/')[1];

        return baseUrl
    }

    render() {
        return ( 
        <>
            <div className="main-content" ref="mainContent">
            <Navbar />
                <Switch>{this.getRoutes(routes)}</Switch>
                <Route exact path='/auth/logout' component={() => { 
                    cookies.set('architeck_uid', null, { path: '/' });
                    window.location.href = keys.API_URL + '/auth/logout?redirect=' + this.getRedirectUrl() + '/login'; 
                    return null;
                }}/>
            </div>
            {/* <Footer /> */}
        </>
        );
    }
}

export default Auth;
