/*

Add all reducers for this plugin here
If there are no reduces return a blank object

!IMPORTANT!
Preface all reducers with "CoursePlatform__" in this file
This prevents future plugin conflicts

*/

// import exampleReducer from './reducers/exampleReducer';

// import on_load_events from './on_load_events'
// import modules from './reducers/modules'


import * as actionTypes from './actions';

const initialState = {
    modules: [],
    lessons: [],
    comments: [],
    lessons_finished: [],
    // support: [],
}

const reducer = (state = initialState, action) => {

    switch (action.type) {
        
        case actionTypes.COURSE_PLATFORM_SET_MODULES:

            return {
                ...state,
                modules: action.payload.objects
            }

        case actionTypes.COURSE_PLATFORM_SET_LESSONS:

            return {
                ...state,
                lessons: action.payload.objects
            }

        case actionTypes.COURSE_PLATFORM_SET_LESSONS_FINISHED:
           
            return {
                ...state,
                lessons_finished: action.payload.objects
            }

        case actionTypes.COURSE_PLATFORM_SET_COMMENTS:
           
            return {
                ...state,
                comments: action.payload.objects
            }

        // case actionTypes.COURSE_PLATFORM_SET_SUPPORT:
        
        //     return {
        //         ...state,
        //         support: action.payload.objects
        //     }

        
      
        default:
        
            return state;

    }
}






export default { CoursePlatform: reducer }
