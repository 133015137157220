/*
Documentation

create the customer object to pass to stripe

*/

export const createCustomerObject = (state, token_id) => {

    const name = state.user.given_name + ' ' + state.user.family_name;

    const object = {
        address: {
            line1: state.user.address_line_1,
            line2: state.user.address_line_2,
            city: state.user.city,
            state: state.user.state,
            postal_code: state.user.postal_code,
        },
        name: name,
        source: token_id,
        description: name,
        email: state.user.email,
        metadata: {
            'user_id': state.user._id
        }
    }


    return object;

}

export const createPaymentObject = (state, token_id, amount) => {

    let object;

    if(state.user_stripe_cus_id) {

        object = {
            customer_id: state.user_stripe_cus_id, 
            amount,
            description: state.description,
            metadata: {
                'plugin': state.plugin,
                'user_id': state.user._id
            }
        }

    } else {

        object = {
            source: token_id,
            amount,
            description: state.description,
            metadata: {
                'plugin': state.plugin,
                'user_id': state.user_id
            }
        }
        
    }

    return object;

}

//this needs to be moved
export const createInternalSubscriptionObject = (state, stripe_cus_id) => {

    if(!state.user_id)          {  console.log('No user_id passed to createInternalSubscriptionObject.') }
    if(!state.subscription_key) {  console.log('No subscription_key passed to createInternalSubscriptionObject.') }
    if(!stripe_cus_id)          {  console.log('No stripe_cus_id passed to createInternalSubscriptionObject.') }

    const object = {
        method: 'post',
        url: '/api/live/system_subscriptions/create',
        data: {
            user_id: state.user_id,
            stripe_cus_id,
            status: 'active',
            subscription_key: state.subscription_key,
        }     

    };

    return object;

}