/*
Documentation

This file compiles all routes declared in out plugins
NOTE! Do not manually add routes here.
All functionality should be created through a plugin

*/

import compiled_plugin_routes from '../plugins_compiler/compiled_routes';

const routes = [
  ...compiled_plugin_routes,
];

export default routes;
