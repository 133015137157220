import moment from 'moment';
import React from "react";
import ReactBSAlert from "react-bootstrap-sweetalert";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { CSVExport, Search } from "react-bootstrap-table2-toolkit";
import { connect } from 'react-redux';
import { Card, CardHeader, Col, Container, DropdownItem, DropdownMenu, DropdownToggle, Row, UncontrolledDropdown } from "reactstrap";
import { aosCall } from '../../../database';
import Header from "../components/Header";








const { ExportCSVButton } = CSVExport;

const pagination = paginationFactory({
    page: 1,
    alwaysShowAllBtns: true,
    showTotal: true,
    withFirstAndLast: false,
    sizePerPageRenderer: ({ options, currSizePerPage, onSizePerPageChange }) => (
        <div className="dataTables_length" id="datatable-basic_length">
            <label>
                Show{" "}
                {
                <select
                    name="datatable-basic_length"
                    aria-controls="datatable-basic"
                    className="form-control form-control-sm"
                    onChange={e => onSizePerPageChange(e.target.value)}
                >
                    <option value="10">10</option>
                    <option value="25">25</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                </select>
                }{" "}
                entries.
            </label>
        </div>
    )
});

const { SearchBar } = Search;


class Alternative extends React.Component {

    state = {
        logs: []
    }

    columns = [
       {
            dataField: "text",
            text: "Error",
            sort: true,
            formatter: (cell, row) => {

                return <div style={{maxWidth: 800, whiteSpace: 'pre-line'}}>{row.text}</div>
    
            }
        }, {
            dataField: "error_file",
            text: "File",
            sort: true
        }, {
            dataField: "error_url",
            text: "Url",
            sort: true
        },{
            dataField: "error_code",
            text: "Error Code",
            sort: true
        },{
            dataField: "created_at",
            text: "Created ON",
            sort: true,
            formatter: (cell, row) => {
              return moment.unix(row.created_at).format("MM/DD/YYYY") + ' at ' + moment.unix(row.created_at).format("h:mm:ss A")
    
            }
        }, {
            dataField: "",
            text: "Actions",
            sort: false,
            headerFormatter: (column) => {
                return <div className="text-right">Actions</div>
            },
            formatter: (cell, row) => {
                return (
                    <div className="text-right" style={{marginRight: -21}}>
                        <UncontrolledDropdown>
                            <DropdownToggle
                                className="btn-icon-only text-light"
                                color=""
                                role="button"
                                size="sm"
                            >
                            <i className="fas fa-ellipsis-v" />
                            </DropdownToggle>
                            <DropdownMenu className="dropdown-menu-arrow" right>
     
                                <DropdownItem
                                    href="#pablo"
                                    onClick={() => this.confirmAlert(row)}
                                >
                                    Delete Log
                                </DropdownItem>
                               
                            </DropdownMenu>
                        </UncontrolledDropdown>
                    </div>
                )
    
            }
        }
    ]

    hideAlert = () => {
        this.setState({
            alert: null
        });
    };

    confirmAlert = (row) => {
        this.setState({
            alert: (
                <ReactBSAlert
                    warning
                    style={{ display: "block", marginTop: "-100px" }}
                    title="Are you sure?"
                    onConfirm={() => this.hideAlert()}
                    onCancel={() => this.confirmedAlert(row)}
                    showCancel
                    confirmBtnBsStyle="secondary"
                    confirmBtnText="Cancel"
                    cancelBtnBsStyle="danger"
                    cancelBtnText={"Yes, remove Log"}
                    btnSize=""
                >
                    Are you sure you wish to delete this log from the system? This can not be undone.
                </ReactBSAlert>
            )
        });
      };
      confirmedAlert = async (row) => {

        const deleted = await aosCall({
            method: 'post',
            url: '/api/live/system_logs/delete/' + row._id,
        });
    
        if(deleted.success) {
            let logs = [...this.state.logs]
            logs = logs.filter(l => l._id !== row._id)
            this.setState({logs})
        }

        this.hideAlert()

        // this.setState({
        //     alert: (
        //         <ReactBSAlert
        //             success={deleted.success ? true : false}
        //             danger={!deleted.success ? true : false}
        //             style={{ display: "block", marginTop: "-100px" }}
        //             title={deleted.success ? 'Success!' : 'Error'}
        //             onConfirm={() => this.hideAlert()}
        //             onCancel={() => this.hideAlert()}
        //             confirmBtnBsStyle="primary"
        //             confirmBtnText="Ok"
        //             btnSize=""

        //         >
        //             {deleted.success ? 
        //             `You have successfully removed ${row.given_name} ${row.family_name}.` : 
        //             `Something went wrong deleting ${row.given_name} ${row.family_name}.`
        //             }
        //         </ReactBSAlert>
        //     )
        // });
      };


      componentDidMount = async () => {

        const logs = await aosCall({
            method: 'get',
            url: '/api/live/system_logs/get/all',    
        });

        if(logs.success) {
            this.setState({logs: logs.response})
        }


      }
    

    render() {





        return (
            <>
            {this.state.alert}
            <Header icon={true} title={<span>Users</span>} breadcrumb_1="App Users"/>
            <Container className="mt--6" fluid>
            
            <Card>
                <CardHeader>
                <h3 className="mb-0">All Users</h3>
                    <p className="text-sm mb-0">
                        This table shows all of your app users. Use it to search, sort, and export your user data to a csv.
                    </p>
                </CardHeader>
                <div className="table-vertical-align">
                    <ToolkitProvider
                        data={this.state.logs}
                        keyField="_id"
                        columns={this.columns}
                        search
                        exportCSV
                        >
                        {props => (
                            <div className="py-4 table-responsive">
                            <div
                                id="datatable-basic_filter"
                                className="dataTables_filter px-4 pb-1"
                                style={{width: '100%'}}
                            >
                                <Row>

                                    <Col>
                                        <label>
                                            Search:
                                            <SearchBar
                                                className="form-control-sm"
                                                placeholder=""
                                                {...props.searchProps}
                                            />
                                        </label>
                                    </Col>

                                    <Col className="col-auto">
                                        <ExportCSVButton
                                        
                                            className="btn-sm"
                                            { ...props.csvProps }
                                            
                                        >
                                            <span>Export CSV</span>
                                        </ExportCSVButton>
                                    </Col>

                                </Row>
                            </div>
                            <BootstrapTable
                                {...props.baseProps}
                                className="xxx"
                                bootstrap4={true}
                                pagination={pagination}
                                bordered={false}
                            />
                            </div>
                        )}
                        </ToolkitProvider>
                    </div>
              </Card>
          
            


            </Container>
            </>
        );
    }
}


const mapStateToProps = state => {
    return {
  


    
  
    };
  };
  
  export default connect(mapStateToProps, '')(Alternative);

