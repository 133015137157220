import React from "react";
import { connect } from 'react-redux';
import * as actionTypes from '../../plugins_core/store/actions';

import { Route, Switch, Redirect } from "react-router-dom";

import AdminNavbar from "./components/Navbar";
import AdminFooter from "./components/Footer";
import Sidebar from "./components/Sidebar";

import routes from "../../plugins_core/routes";

import compiled_plugin_on_load_events from '../../plugins_compiler/compiled_on_load_events';

import FunctionalityBase from '../../plugins_core/FunctionalityBase';

import { WaveSpinner } from "react-spinners-kit";
import { refreshSystemUsers, refreshSystemPayments } from "../../plugins_core/store/reducers/SYSTEM";

//set the name of the routes to check for | in this case '/admin'
const route_to_check_for = '/admin';

class Admin extends React.Component {
    state = {
        sidenavOpen: true,
        logged_in: true,
        routes:  routes.filter((r) => r.renderOn === 'admin' ),
        is_loading: true,
        show_loader: true,
        faderOpacity: 1,
    };

    //fade our loader out gracefully without settings state to many times and updating components over and over
    fadeLoader = () => {


        const fader = document.getElementById('AosFader')
        let opacity = 1;

        var refreshIntervalId = setInterval(() => {

            if(opacity < 0) {
                clearInterval(refreshIntervalId);
                this.setState({show_loader: false})
            }

            opacity = opacity - .1

            //if page is changed dont through and error if fader is gone
            try {
                fader.style.opacity = opacity
            }catch (e) {

            }

           
        }, 50);
   
    }

    isLoggedIn = () => {

        return new Promise ((resolve) => {

            const location = window.location;

            const loginURL = '/auth/login/?redirect=' + encodeURIComponent(location.pathname + location.search + location.hash)

            if(!this.props.viewing_user.logged_in) {

                this.setState({should_redirect: loginURL }, () => {
                    resolve(false);
                    return;
                })

            }

            if(!this.props.viewing_user.is_admin) {

                this.setState({should_redirect: '/dashboard'}, () => {
                    resolve(false)
                })

                resolve(false)
                return;

            } else {

                resolve(true)
               
            }

        })

    }

   

    getRoutes = routes => {
        return routes.map((prop, key) => {
        if (prop.collapse) {
            return this.getRoutes(prop.views);
        }
        if (prop.layout === route_to_check_for) {

            //may need to remove type here
            if(prop.component.type) {

                return (
                    <Route 
                        exact path={prop.layout + prop.path}
                        component={prop.component}
                        key={key}
                    />
                    );
            }
            
        } 
        
        return null;

        });
    };
    getBrandText = path => {
        for (let i = 0; i < routes.length; i++) {
        if (
            this.props.location.pathname.indexOf(
            routes[i].layout + routes[i].path
            ) !== -1
        ) { 
            return routes[i].name;
        }
        }
        return "Brand";
    };
    // toggles collapse between mini sidenav and normal
    toggleSidenav = e => {
        if (document.body.classList.contains("g-sidenav-pinned")) {
        document.body.classList.remove("g-sidenav-pinned");
        document.body.classList.add("g-sidenav-hidden");
        } else {
        document.body.classList.add("g-sidenav-pinned");
        document.body.classList.remove("g-sidenav-hidden");
        }
        this.setState({
        sidenavOpen: !this.state.sidenavOpen
        });
    };
    getNavbarTheme = () => {
        // return this.props.location.pathname.indexOf(
        //   "admin/alternative-dashboard"
        // ) === -1
        //   ? "dark"
        //   : "light";
        return 'light'
    };

    componentDidMount = async () => {

        //this will fire a redirect if false
        const logged_id = await this.isLoggedIn()

        //if we are not logged in do nothing and return to login page
        if(!logged_id) {
            return;
        }


        compiled_plugin_on_load_events.push({
            func: refreshSystemUsers,
            prop: 'app_users',
            plugin_name: 'system'
        })

        compiled_plugin_on_load_events.push({
            func: refreshSystemPayments,
            prop: 'app_payments',
            plugin_name: 'system'
        })
    
        const reducers_fired = this.props.reducers_fired


        const reducers_to_wait_for = [];
        const reducers_to_fire_in_background = [];

        compiled_plugin_on_load_events.filter((plug) => {

            //plugin reducer is meant to run in the background
            if(plug.run_in_background) {

             

                reducers_to_fire_in_background.push(plug);

            } else {

                //plugin reducer has already ran so we have its data. Load it in background 
                if(reducers_fired.includes(plug.plugin_name + '-' + plug.prop)) {

                    reducers_to_fire_in_background.push(plug);

                } else {

                    reducers_to_wait_for.push(plug);

                }

            }

            return null;

        })

       
        if(reducers_to_wait_for && reducers_to_wait_for.length ) {

            //fire all on on load events of plugins
            Promise.all(reducers_to_wait_for.map((plug) => {

                //create a list of plugins that have fired
                reducers_fired.push(plug.plugin_name + '-' + plug.prop)
                return plug.func();

            })).then((values) => {


                this.onReducersFinishedLoader()

            });

        } else {

            this.onReducersFinishedLoader()
        }

    }

    onReducersFinishedLoader = () => {
          
        this.setState({is_loading: false});
        this.fadeLoader()

    }

    

    componentDidUpdate = async (e) => {

        if (e.history.pathname !== e.location.pathname) {

            document.documentElement.scrollTop = 0;
            document.scrollingElement.scrollTop = 0;

            //make sure we have our refs to scroll to the top on
            if(this.refs && this.refs.mainContent) {
                this.refs.mainContent.scrollTop = 0;
            }

        }
    }

    render() {
    
        if(this.state.should_redirect) {
            return <Redirect to={this.state.should_redirect} />
        }

        return (
            <>

                {this.state.show_loader ? (
                    <div id="AosFader"  style={styles.aosFader}  >
                        <div style={styles.loaderContainer}>
                            <WaveSpinner size={30} color="#fb6340" loading={true}  />
                        </div>
                    </div> 
                ) : null}

            {!this.state.is_loading ? (

                <>


                    <FunctionalityBase />

                    <Sidebar
                        {...this.props} 
                        routes={this.state.routes}
                        toggleSidenav={this.toggleSidenav}
                        sidenavOpen={this.state.sidenavOpen}
                        logo={{
                            innerLink: "/admin/dashboard",
                            imgSrc: require("assets/img/app/Logo.png"),
                            imgAlt: "..."
                        }}
                    />

                    <div className="main-content" ref="mainContent" onClick={this.closeSidenav} >

                        <AdminNavbar
                            {...this.props}
                            theme={this.getNavbarTheme()}
                            toggleSidenav={this.toggleSidenav}
                            sidenavOpen={this.state.sidenavOpen}
                            brandText={this.getBrandText(this.props.location.pathname)}
                        />
                        <Switch>
                            {this.getRoutes(this.state.routes)}
                            {/* If page not found go back to admin dashboard */}
                            <Redirect from="*" to="/admin/dashboard" />
                        </Switch>
                        <AdminFooter />

                    </div>

                    {this.state.sidenavOpen ? (
                        <div className="backdrop d-xl-none" onClick={this.toggleSidenav} />
                    ) : null}

                </>

            ) : null}
            </>

        );
    }
}

const styles = {

    aosFader: {
        opacity: 1,
        position: 'fixed',
        top: 0,
        right: 0, 
        left: 0,
        bottom: 0,
        display: 'flex',
        zIndex: 9999999999999,
        background: 'white',
    },
   
    loaderContainer: {
        alignSelf: 'center',
        margin: 'auto'
    }
}

const mapStateToProps = state => {
    return {

        viewing_user: state.SYSTEM.viewing_user,
        reducers_fired: state.SYSTEM.reducers_fired

    };
};

const mapDispatchToProps = dispatch => {
    return {
 
        setReducersFired: (objects) =>  dispatch({type: actionTypes.SYSTEM_SET_REDUCERS_FIRED, payload: {objects} }),
      
    };
};
  

export default connect(mapStateToProps, mapDispatchToProps)(Admin);

