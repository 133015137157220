
import React from "react";
import { connect } from 'react-redux';

import classnames from "classnames";
import { Link } from 'react-router-dom';
import PropTypes from "prop-types";

import {
  Collapse,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  Navbar,
  NavItem,
  Nav,
  Container,
} from "reactstrap";

import AvatarImage from './Images/Avatar';

class AdminNavbar extends React.Component {

	state = {
		showSearchResults: true
	};

	toggleModal = state => {
		this.setState({
			defaultModal: !this.state.defaultModal
		});

	}

	onSearch = (e) => {
		const value = e.target.value;

		if(value) {

			this.setState({showSearchResults: true})

		}

	}

	// function that on mobile devices makes the search open
	openSearch = () => {
		
		document.body.classList.add("g-navbar-search-showing");

		setTimeout(function() {
			document.body.classList.remove("g-navbar-search-showing");
			document.body.classList.add("g-navbar-search-show");
		}, 150);

		setTimeout(function() {
			document.body.classList.add("g-navbar-search-shown");
		}, 300);

	};
	// function that on mobile devices makes the search close
	closeSearch = () => {
		document.body.classList.remove("g-navbar-search-shown");

		setTimeout(function() {
			document.body.classList.remove("g-navbar-search-show");
			document.body.classList.add("g-navbar-search-hiding");
		}, 150);
		
		setTimeout(function() {
			document.body.classList.remove("g-navbar-search-hiding");
			document.body.classList.add("g-navbar-search-hidden");
		}, 300);

		setTimeout(function() {
			document.body.classList.remove("g-navbar-search-hidden");
		}, 500);
	};

	render() {

    const viewing_user = this.props.viewing_user;

    return (
		<>
			<Navbar
				className={classnames(
					"navbar-top navbar-expand border-bottom",
					{ "navbar-dark bg-info": this.props.theme === "dark" },
					{ "navbar-light bg-secondary": this.props.theme === "light" }
				)} 
			>
			<Container fluid>
				<Collapse navbar isOpen={true}>

				
					<Nav className="align-items-center ml-md-auto" navbar>

						<NavItem className="d-xl-none">
							<div
								className={classnames(
									"pr-3 sidenav-toggler",
									{ active: this.props.sidenavOpen },
									{ "sidenav-toggler-dark": this.props.theme === "dark" }
									)}
									onClick={this.props.toggleSidenav}
								>

								<div className="sidenav-toggler-inner">
									<i className="sidenav-toggler-line" />
									<i className="sidenav-toggler-line" />
									<i className="sidenav-toggler-line" />
								</div>

							</div>
						</NavItem>

						{/* <NavItem className="d-sm-none">
							<NavLink onClick={this.openSearch}>
								<i className="ni ni-zoom-split-in" />
							</NavLink>
						</NavItem> */}

					
					</Nav>
					<Nav className="align-items-center ml-auto ml-md-0" navbar>
						<UncontrolledDropdown nav>
						<DropdownToggle className="nav-link pr-0" color="" tag="a">
							<Media className="align-items-center">
							<span className="avatar avatar-sm rounded-circle">

								<AvatarImage alt="" src={viewing_user.picture_url} />
							</span>
							<Media className="ml-2 d-none d-lg-block">
								<span className="mb-0 text-sm font-weight-bold">
								{viewing_user.given_name} {viewing_user.family_name}
								</span>
							</Media>
							</Media>
						</DropdownToggle>
						<DropdownMenu right>
							<DropdownItem className="noti-title" header tag="div">
							<h6 className="text-overflow m-0">Welcome!</h6>
							</DropdownItem>
							
							<Link to="/admin/settings">
								<DropdownItem >
								<i className="ni ni-single-02" />
								<span>My profile</span>
								</DropdownItem>
							</Link>


							<a href="/dashboard">
								<DropdownItem	>
									<i className="fas fa-home"></i>
									<span>User Dashboard</span>
								</DropdownItem>
							</a>
						
							
							<DropdownItem divider />
								<a href="/auth/logout">

									<DropdownItem>
											<i className="ni ni-user-run" />
											<span>Logout</span>
									</DropdownItem>
								</a>
						</DropdownMenu>
						</UncontrolledDropdown>
					</Nav>
					</Collapse>
				</Container>
			</Navbar>
      		</>
    	);
  	}
}
AdminNavbar.defaultProps = {
  toggleSidenav: () => {},
  sidenavOpen: false,
  theme: "dark"
};
AdminNavbar.propTypes = {
  toggleSidenav: PropTypes.func,
  sidenavOpen: PropTypes.bool,
  theme: PropTypes.oneOf(["dark", "light"])
};


const mapStateToProps = state => {
  return {

    viewing_user: state.SYSTEM.viewing_user,

  };
};

export default connect(mapStateToProps, '')(AdminNavbar);
