/*
Documentation

all dispatch actions should be declared in this file and used

this prevents mis-typing action names as it will through an error
by either the browser or IDE

*/




//app reducer
export const SET_LOADING                        = 'SET_LOADING';

//user reducer
export const SYSTEM_SET_REDUCERS_FIRED          = 'SYSTEM_SET_REDUCERS_FIRED';
export const SYSTEM_SET_VIEWING_USER            = 'SYSTEM_SET_VIEWING_USER';

export const SET_APP_USERS                      = 'SET_APP_USERS';
export const SET_APP_USERS_THIS_WEEK            = 'SET_APP_USERS_THIS_WEEK';
export const SET_APP_ADMINS                     = 'SET_APP_ADMINS';

export const SET_APP_PAYMENTS                   = 'SET_APP_PAYMENTS';
export const SET_APP_PAYMENTS_THIS_WEEK         = 'SET_APP_PAYMENTS_THIS_WEEK';
export const SET_APP_PAYMENTS_TOTAL             = 'SET_APP_PAYMENTS_TOTAL';


