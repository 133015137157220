/*

This file is a test component for this plugin

*/
import React, { Component } from 'react';
import ReactBSAlert from "react-bootstrap-sweetalert";
import { connect } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';
import { Button, Card, CardBody, CardHeader, CardImg, Col, Container, Form, FormGroup, Input, Row } from "reactstrap";
import { aosAction } from '../../../database';
import Header from "../components/Header";
import { findModule } from '../functions/modules';
import Media from '../../../ad-ons/media'



const required_form_fields = [
    'name',
    'description',
    
    
]


class TestComponent extends Component {


    state = {
        user: {},
        module: {},
        canSave: true,
        showMediaSingleUpload: false,
        updating: null

    }

    //toggle the single image uploader
    onMediaSingleToggle = () => this.setState({showMediaSingleUpload: !this.state.showMediaSingleUpload})

    //what to do when a single images is selected
    onMediaSingleSelect = (values) => {

        this.setState({module: {
            ...this.state.module,
            [this.state.updating]: values[0]
        }})

    }

    fireDeleteConfirmationAlert = () => {
        this.setState({
          alert: (
            <ReactBSAlert

              danger
              style={{ display: "block", marginTop: "-100px" }}
              title="Are You Sure"

              //these are switch to swith button order
              onConfirm={() => this.hideAlert()}
              onCancel={() => this.deleteModule()}deleteModule

              cancelBtnBsStyle="danger"
              cancelBtnText="Yes, Delete It"
              confirmBtnBsStyle="info"
              confirmBtnText="Cancel"

              btnSize=""
              showCancel
   
          
            >
              This will wipe all lessons and data associated with {this.state.module.name}.
            
            </ReactBSAlert>
          )
        });
    };

    fireAlert = (error) => {
        this.setState({
          alert: (
            <ReactBSAlert
              success={error ? false : true}
              danger={!error ? false : true}
              style={{ display: "block", marginTop: "-100px" }}
              title={error ? 'Error' : 'Success'}
              onConfirm={() => this.hideAlert()}
              onCancel={() => this.hideAlert()}
              confirmBtnBsStyle="success"
              confirmBtnText="Ok"
              btnSize=""
            >
              {error ? 
              'Something went saving this module' :
              'Module Saved'
            }
            </ReactBSAlert>
          )
        });
    };

    deleteModule = async () => {

        //hide the first modal
        this.hideAlert()

        const deleted = await aosAction({
            method: 'post',
            url: '/api/live/CoursePlatform__modules/delete/' + this.state.module._id,
            aos_live_events: ['/api/live/CoursePlatform__modules/get/all?orderBy=order&order=asc'],          
        });
        
        if(deleted.success) {

            this.setState({shouldRedirect: '/admin/CoursePlatform/modules'})
        
        } else {
        
            alert('Something went wrong deleting this module.')
        
        }

    }


    
    hideAlert = () => {
        this.setState({
          alert: null
        });
      };

    onSave = async () => {

        this.setState({canSave: false})
        //copy state
        let newState = Object.assign({}, this.state.module);
        //assume we have no errors
        let errors = 0;

        required_form_fields.forEach((field) => {

            //check each required field
            if(newState[field]) {
                newState[field + "State"] = 'valid'; 
            } else {
                newState[field + "State"] = 'invalid'; 
                errors++
            }

        })


        this.setState({
          module: newState
        });

          //et the user try again but stop form saving
        if(errors) {
            this.setState({canSave: true})
        } else {

            //if we are creating a new module
            if(this.props.match.params._id === 'new_entry') {

                //create a module
                const created = await aosAction({
                    method: 'post',
                    url: '/api/live/CoursePlatform__modules/create',
                    aos_live_events: ['/api/live/CoursePlatform__modules/get/all?orderBy=order&order=asc'],          
                    data: {
                        name: this.state.module.name,
                        description: this.state.module.description,
                        thumbnail: this.state.module.thumbnail,
                        picture: this.state.module.picture,
                        order: 100
                    },
                });
                
                if(created.success) {

                    //reset the module state
                    this.setState({module: {}, canSave: true})
                    this.fireAlert(false)
                
                } else {
                
                    this.fireAlert(true)
                
                }

            } else {

                //create a module
                const updated = await aosAction({
                    method: 'post',
                    url: '/api/live/CoursePlatform__modules/update/' + this.state.module._id,
                    aos_live_events: ['/api/live/CoursePlatform__modules/get/all?orderBy=order&order=asc'],          
                    data: {
                        name: this.state.module.name,
                        description: this.state.module.description,
                        thumbnail: this.state.module.thumbnail,
                        picture: this.state.module.picture,


                    },
                });

                this.setState({canSave: true})
                
                if(updated.success) {


                    //reset the module state
                    this.setState({module: {
                        ...this.state.module,
                        nameState: null,
                        descriptionState: null,
                        thumbnailState: null,
                        pictureState: null
                    }

                        
                    })

                    this.fireAlert(false)
                
                } else {
                
                    this.fireAlert(true)
                
                }

            }

        }





    }

    validateForm = () => {
        //copy state
        let newState = Object.assign({}, this.state.module);


        required_form_fields.forEach((field) => {



            if(newState[field]) {
                newState[field + "State"] = 'valid'; 
                
            } else {
                newState[field + "State"] = 'invalid'; 

            }

            

        })



        this.setState({
          module: newState
        });
    };


    onInputChange = (e, stateName) => {


        let newState = Object.assign({}, this.state.module);
        newState[stateName] = e.target.value;

        if (stateName === "checkbox") {
          if (e.target.value) {
            newState.checkboxState = "valid";
          } else {
            newState.checkboxState = "invalid";
          }
        } else {
          if (e.target.value === "") {
            newState[stateName + "State"] = "invalid";
          } else {
            newState[stateName + "State"] = "valid";
          }
        }

        this.setState({
          module: newState
        });
    };

    componentDidMount = async () => {

        const module_id = this.props.match.params._id

        //we are updating a module nt creating one
        if(module_id !== 'new_entry') {

            const found_module = findModule(module_id, this.props.modules)

            if(!found_module) {
                this.setState({shouldRedirect: '/admin/CoursePlatform/modules'})
            }
            
            this.setState({module: found_module})

        }
    
        
    
    

    }

    render() {

        if(this.state.shouldRedirect) {
            return <Redirect to={this.state.shouldRedirect} />
        }

        return (
            <>

            {this.state.alert}

            {this.state.showMediaSingleUpload && (
                <Media 
                    onMediaToggle={this.onMediaSingleToggle}
                    onMediaSelect={(values) => this.onMediaSingleSelect(values)}
                    canSelectMultiple={false}
                />
            )}
            <Header 
                icon={true} 
                title={<Link to="/admin/CoursePlatform/modules"><i className="mr-2 fas fa-arrow-left"></i> Modules</Link>} 
                breadcrumb_1={this.state.module.name} 
                actionComponent={this.props.match.params._id !== 'new_entry' && (
                    <Button color="danger" size="sm" onClick={this.fireDeleteConfirmationAlert}>Delete Module</Button>
                )} 
            />

           
           
            <Container className="mt--6" fluid>     

                <Row>

                <Col xl="8" l="6" md="6" sm="12">

                <Card>

                    <CardHeader>
                        <Row className="align-items-center">
                            <Col xs="8">
                                <h3 className="mb-0">Edit profile</h3>
                            </Col>
                            <Col className="text-right" xs="4">
                                <Button
                                    color="success"

                                    onClick={this.onSave}
                                    size="sm"
                                    disabled={this.state.canSave ? false : true}
                                >
                                Save Module
                                </Button>
                            </Col>
                        </Row>
                    </CardHeader>

                    <CardBody>
                        <Form>

                            <h6 className="heading-small text-muted mb-4">Module Text</h6>

                            <div className="pl-lg-4">
                                <Row>
                                    <Col lg="6">
                                        <FormGroup>
                                        <label className="form-control-label" htmlFor="name"> Module Name </label>
                                        <Input
                                            id="name"
                                            value={this.state.module.name || ''}
                                            placeholder="Building A Better Brand"
                                            type="name"
                                            valid={ this.state.module.nameState === "valid" }
                                            invalid={ this.state.module.nameState === "invalid" }
                                            onChange={e => this.onInputChange(e, "name") }  
                                        />
                                        <div className="valid-feedback">Looks good!</div>

                                        </FormGroup>
                                    </Col>
                                    <Col lg="6">
                                        <FormGroup>
                                        <label className="form-control-label" htmlFor="phone"> Module Description </label>
                                    
                                        <Input
                                            id="description"
                                            value={this.state.module.description || ''}
                                            placeholder="Recommended: 30 - 50 characters"
                                            type="tel"
                                            valid={ this.state.module.descriptionState === "valid" }
                                            invalid={ this.state.module.descriptionState === "invalid" }
                                            onChange={e => this.onInputChange(e, "description") }
                                            
                                        />
                                        <div className="valid-feedback">Looks good!</div>

                                        </FormGroup>
                                    </Col>
                                </Row>

                            </div>

                            <hr className="my-4" />
                            <h6 className="heading-small text-muted mb-4">Module Media</h6>

                            <div className="pl-lg-4">

                                <Row>

                                    <Col md="6">
                                        <FormGroup>

                                            
                                        <label className="form-control-label" htmlFor="picture" > Module Picture (1 x 1)</label>

                                        <div className="py-3 pt-2 mt--3">
                                            {this.state.module && this.state.module.picture && (
                                                <img className="w-100 rounded" src={this.state.module.picture} alt=""/>
                                            )}
                                        </div>
                                        
                                        <Button className="btn btn-success btn-sm" onClick={() => {
                                            this.onMediaSingleToggle()
                                            this.setState({updating: 'picture'})
                                        }} >Update Image</Button>

                                        {/* <Input
                                            id="picture"
                                            value={this.state.module.picture || ''}
                                            placeholder="https://website.com/img.jpg"
                                            type="text"
                                            valid={ this.state.module.pictureState === "valid" }
                                            invalid={ this.state.module.pictureState === "invalid" }
                                            onChange={e => this.onInputChange(e, "picture") }
                                            
                                        />
                                        <div className="valid-feedback">Looks good!</div> */}

                                        </FormGroup>
                                    </Col>

                                    <Col md="6">
                                        <FormGroup>
                                        <label className="form-control-label" htmlFor="thumbnail" > Module Thumbnail (1 x 1)</label>
                                        
                                        <div className="py-3 pt-2 mt--3">
                                            {this.state.module && this.state.module.thumbnail && (
                                                <img className="w-100 rounded" src={this.state.module.thumbnail} alt=""/>
                                            )}
                                        </div>
                                        
                                        <Button className="btn btn-success btn-sm" onClick={() => {
                                            this.onMediaSingleToggle()
                                            this.setState({updating: 'thumbnail'})
                                        }} >Update Image</Button>


                                        {/* <Input
                                            id="thumbnail"
                                            value={this.state.module.thumbnail || ''}
                                            placeholder="https://website.com/img.jpg"
                                            type="text"
                                            valid={ this.state.module.thumbnailState === "valid" }
                                            invalid={ this.state.module.thumbnailState === "invalid" }
                                            onChange={e => this.onInputChange(e, "thumbnail") }
                                            
                                        />
                                        <div className="valid-feedback">Looks good!</div> */}

                                        </FormGroup>
                                    </Col>

                                </Row>

                            </div>

                        </Form>
                    </CardBody>
                    </Card>
                
                </Col>

                <Col xl="4" l="6" md="6" sm="12">

                    <h2>Module Preview</h2>
                       
                       <Card className="card-profile">

                           {this.state.module.picture && 
                            <CardImg
                                alt="..."
                                src={this.state.module.picture}
                                top
                            />
                           }
                           <Row className="justify-content-center">
                               <Col className="order-lg-2" lg="3">
                               <div className="card-profile-image">

                                   {this.state.module.thumbnail && 
                                    <img
                                        alt="..."
                                        className="rounded-circle"
                                        src={this.state.module.thumbnail}
                                    />
                                   }

                               </div>
                               </Col>
                           </Row>
                           <CardHeader className="text-center border-0 pt-8 pt-md-4 pb-0 pb-md-4">
                               <div className="d-flex justify-content-between">
                               
                               </div>
                           </CardHeader>
                           <CardBody className="pt-0">
                               <Row>
                               <div className="col">
                                   <div className="card-profile-stats d-flex justify-content-center">
                                   <div>
                                       <span className="heading">-</span>
                                       <span className="description">Lessons</span>
                                   </div>
                                   <div>
                                       <span className="heading">-</span>
                                       <span className="description">Hours</span>
                                   </div>
                                   
                                   </div>
                               </div>
                               </Row>
                               <div className="text-center">
                               <h5 className="h3">
                                   {this.state.module.name}
                               </h5>
                               <div className="h5 font-weight-300">
                                   <i className="ni location_pin mr-2" />
                                   {this.state.module.description}
                               </div>
                               </div>
                           </CardBody>
                       </Card>
                   </Col>

                </Row>
            </Container>


            </>

        )

    }

}

const mapStateToProps = state => {
    return {
      modules: state.CoursePlatform.modules,
      lessons: state.CoursePlatform.lessons,
      
    };
  };

  
  export default connect(mapStateToProps )(TestComponent);  
  
  





// picture: { 
//     type: String,
// }, 

// thumbnail: { 
//     type: String,
// }, 


// description: { 
//     type: String,
// }, 

// order: { 
//     type: Number,
// }, 


// updated_at: { 
//     type: Number,
//     default: Math.round((new Date()).getTime() / 1000)
// }, 

// created_at: { 
//     type: Number,
//     default: Math.round((new Date()).getTime() / 1000)
// }, 
