
/* 

Do not update this file. It should be generated programmatically through npm run plugins-compile

Copyright 2019 Arsenal OS
Author John Maher

*/

import React from 'react';
import { Route } from 'react-router-dom';

import Admin from '../plugins-layouts/Admin/settings'; 
import Dashboard from '../plugins-layouts/Dashboard/settings'; 
import Auth from '../plugins-layouts/Auth/settings'; 

import Site from '../plugins-layouts/Site/settings'; 


const layouts = [
    Admin, 
    Dashboard,
    Auth,
    Site

]; 

const get_layouts = (layouts) => {
    return layouts.map((layout, index) => {


            const Component = layout.component
           return <Route key={index} path={layout.path} render={props => <Component {...props} /> } />
        

    })
}

const compiled_layouts = get_layouts(layouts);

export default compiled_layouts; 
 