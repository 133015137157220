/*
Documentation

this component renders a stripe card checkout
it takes three props

submit = the function to run after we tokenize the card. It is given the stripe "token" element as its sole parameter
formStyles = the styles you wish to use with the stripe element
buttonStyles = the styles you with to use for the checkout button

this component should be wrapped in <div className="checkout"></div>

*/ 

import React, {Component} from 'react';
import {CardElement, injectStripe} from 'react-stripe-elements';


class CheckoutForm extends Component {


    submit = async (ev) => {

        let {token} = await this.props.stripe.createToken({name: "Name"});

        this.props.submit(token);

    
    }

    render() {
        return (
            <>
                <CardElement classNa="card-element" style={{base: { lineHeight: '25px' } } }/>



                <div className="text-right">
                    <button className={this.props.buttonClasses} style={this.props.buttonStyles} onClick={this.submit}>{this.props.buttonText}</button>
                </div>
            </>
        );
    }
}

export default injectStripe(CheckoutForm);