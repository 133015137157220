/*

This file is a test component for this plugin

*/
import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom'
import { connect } from 'react-redux';
import ReactHtmlParser from 'react-html-parser'; 
import { trimDescription } from '../functions/text'
import moment from 'moment';

import {
  Card,
  CardBody,
  Row,
  Col,
  Container,
  Table,
  CardHeader,
  ListGroup,
  ListGroupItem,
  Form, 
  FormGroup, 
  Input,
  Button
} from "reactstrap";


import Payments from "./Payments";
import Header from "../components/Header";
import AvatarImage from '../components/Images/Avatar';

import { buildModules } from '../functions/modules'


import { aosAction } from '../../../database';


class Lesson extends Component {


    state = {
        lesson: null,
        module: null,
        showMarkComplete: true
    };

    onInputChange = (e, stateName) => {

        this.setState({
          commentToAdd: e.target.value
        });
    };

    fetchData = async (props) => {


        const app_users = props.app_users
        const params = {
            modules: props.modules,
            lessons: props.lessons,
            module_id: props.match.params.module_id,
            comments: props.comments,
            lessons_finished: props.lessons_finished,
            app_users: app_users,
            viewing_user: props.viewing_user
        }



        let module = await buildModules(params);
        module = module[0]

        console.log('built module', module)


        const lesson = module.lessons.find(l => l._id === props.match.params.lesson_id)



        this.setState({module, lesson})


    }

    addComment = async () => {



        const text = this.state.commentToAdd;

        if(!text) {
            return;
        }

        const user = this.props.viewing_user._id

        const comment = {
            lesson: this.props.match.params.lesson_id,
            user,
            text,
            created_at: Math.round((new Date()).getTime() / 1000)
        }       

        const created = await aosAction({
            method: 'post',
            url: '/api/live/CoursePlatform__comments/create',
            aos_live_events: ['/api/live/CoursePlatform__comments/get/all?populate=user+lesson'],   
            data: comment     
        });

        if(created.success) {

            this.state.lesson.comments.unshift({
                ...comment,
                user: this.props.viewing_user
            })

        } else {

            console.log(created)

        }

        this.setState({commentToAdd: null})
        


    }

    onMarkComplete = async () => {

        this.setState({showMarkComplete: false})

        //create an entry that this user has completed the lesson
        const created = await aosAction({
            method: 'post',
            url: '/api/live/CoursePlatform__lessons_finished/create',
            aos_live_events: ['/api/live/CoursePlatform__lessons_finished/get/all'],   
            data: {
                user_id: this.props.viewing_user._id,
                lesson_id: this.props.match.params.lesson_id,
            }     
        });

        setTimeout(() => {
            this.setState({showMarkComplete: true})
        }, 1000)

        if(created.success) {

            window.location.href = this.getNextLessonLink();
            // this.setState({shouldRedirect: this.getNextLessonLink()})

        } else {



        }

    }

    //returns next module or dashboard page
    getNextLessonLink = () => {
        const current_lesson_order = this.state.lesson.order;
        const next_lesson = this.state.module.lessons.find(l => {

            console.log(l.order, current_lesson_order)
            return l.order > current_lesson_order
        });

        if(next_lesson) {
            return '/dashboard/lesson/'+next_lesson.module_id+'/' + next_lesson._id;
        } else {
            return '/dashboard'
        }

    }


    sizeIframe = () => {

        let window_width = 0;

        if(window.innerWidth < 768) {
            window_width = window.innerWidth - 30;
        } else if (window.innerWidth > 1200) {
            window_width = window.innerWidth - 250 - 73;
        } else {
            window_width = window.innerWidth - 60;
        }

        let width = window_width;
        let height = (width / 16) * 9

        this.setState({width, height})

    }

    componentDidMount = () => {

        this.fetchData(this.props)
        
        this.sizeIframe()
        window.addEventListener("resize", this.sizeIframe.bind(this));

    }


    componentWillUnmount() {
        window.removeEventListener("resize", this.sizeIframe.bind(this));
    }

    componentWillReceiveProps = (nextProps) => {

       
            if(this.props.match.params.lesson_id !== nextProps.match.params.lesson_id) {
                console.log('updated heer')
                console.log(this.props.match.params.lesson_id, nextProps.match.params.lesson_id)
                this.fetchData(nextProps)
            }

        
       
    }




    render() {

        if(this.state.shouldRedirect) {
            this.setState({shouldRedirect: false})
            return <Redirect to={this.state.shouldRedirect} />
        }

        if(!this.state.lesson) {
            return <div></div>
        }

        const module = this.state.module
        const lesson = this.state.lesson
        const covers = lesson.covers
        const comments = lesson.comments



        return (
            <>
            <Payments props={this.props} >
            <Header 
                icon={true} 
                title={<Link to={"/dashboard/modules/" + lesson.module_id}>{lesson.name}</Link>} 
                breadcrumb_1={ module.name }
                actionComponent={
                    this.state.showMarkComplete ? (
                        this.state.lesson.finished ? (
                            <Link to={this.getNextLessonLink()} className="btn btn-sm btn-info" >Next Lesson</Link>
                        ) : (
                            <Button color="success" size="sm" onClick={this.onMarkComplete}>Mark Complete</Button>
                        )
                    ) : null
                }
            />

            <Container  className="mt--6" fluid>
               {console.log(lesson.name)}
               {console.log(lesson.video)}
                <iframe 
                    className="shadow-lg"
                    style={{borderRadius: 5}}
                    title={lesson.name}
                    src={lesson.video}  
                    width={this.state.width} height={this.state.height}  
                    frameBorder="0" 
                    allow="autoplay; fullscreen" 
                    allowFullScreen 
                    
                />

                <Row className="mt-4 mb-2">

                    <Col xl={covers && covers.length ? 8 : 12} md="12" sm="12">
                        <Card className="mt-0" style={{marginBottom: 0, height: '100%'}}>
                            <CardBody>  
                                <h1>In This Lesson...</h1>
                                {ReactHtmlParser(this.state.lesson.description)}
                            </CardBody>
                        </Card>
                    </Col>

                    {covers && covers.length ? (
                        <Col xl="4" md="12" sm="12">
                            <Card className="mt-4 mt-lg-0" style={{marginBottom: 0, height: '100%'}}>
                                <CardHeader>
                                    <h5 className="h3 mb-0">This Lesson covers</h5>
                                </CardHeader>

                                <CardBody>
                                    <Table className="table " responsive>

                                        <thead></thead>

                                        <tbody>

                                            {covers.map((c, index)=> (
                                                <tr key={index}>
                                                    <td className="px-0 pt-0" style={{borderTop: 'none'}}>{c}</td>
                                                    <td className="text-center pt-0" style={{borderTop: 'none'}}>
                                                        <i className="fas fa-check text-success" />
                                                    </td>
                                                </tr>
                                            ))}

                                        </tbody>
                                    </Table>
                                </CardBody>
                            </Card>
                        </Col>
                    ) : null}

                </Row>

                <h2 className="mt-6 mt-lg-4">Module Lessons</h2>

                <Row className="mb-4">

                    {this.state.module.lessons.map(l => (
                        <Col key={l._id}   lg="6" md="6" sm="12">
                            <Link to={"/dashboard/lesson/" + this.props.match.params.module_id + '/' + l._id}>
                                <Card style={{marginBottom: 10}} className={l._id === this.props.match.params.lesson_id ? 'bg-light' : null}>
                                    <CardBody style={{maxHeight: 110, height: 110}}>
                                        <Row className="align-items-center">
                                            <Col className="col-auto">
                                                
                                                <span className="avatar avatar-xl rounded-cicle"  style={{width: 120, height: 60}} >
                                                    <img alt={l.name} src={l.picture} />
                                                </span>
                                            </Col>

                                            <div className="col ml--2 mt--4">
                                                <h4 className="mb-0">{l.name}</h4>
                                                {/* <span className="text-sm text-muted mb-0 ">{trimDescription(l.description, 50)}</span> */}
                                            </div>
                                        
                                        </Row>
                                    </CardBody>
                                </Card>
                            </Link>
                        </Col>
                    ))}

                </Row>
                <Card className="mt-4">

                    <CardHeader>
                        <h5 className="h3 mb-0">Respond With Your Thoughts</h5>
                    </CardHeader>

                    <CardBody className="p-0">

                        <ListGroupItem className="list-group-item-action flex-column align-items-start py-4 px-4" >
                                <Form>

                                    <FormGroup>
                                        <label className="form-control-label" htmlFor="add-comment" > Add Your Comment </label>
                                        <Input value={this.state.commentToAdd || ''} onChange={(e) => this.onInputChange(e, 'commentToAdd')} style={{height: '8rem'}} id="add-comment" rows="6" type="textarea" />
                                    </FormGroup>

                                </Form>

                                <div className="text-right">
                                    <Button color="success" type="button" onClick={this.addComment} >
                                        Add Comment
                                    </Button>

                                </div>

                            </ListGroupItem>

                        <ListGroup flush>

                            {comments && comments.length ? (
                                
                                comments.map((c, index) => (
                                   c.user && c.user.given_name ? (
                                        <ListGroupItem key={index} className="list-group-item-action flex-column align-items-start py-4 px-4" >

                                            <div className="d-flex w-100 justify-content-between">
                                                <div>
                                                    <div className="d-flex w-100 align-items-center">

                                                        <AvatarImage className="avatar avatar-xs mr-2"  alt="..." src={c.user.picture_url} />

                                                        <h5 className="mb-1">{c.user.given_name} {c.user.family_name}</h5>
                                                    </div>
                                                </div>
                                                <small>{moment.unix(c.created_at).format("MM/DD/YYYY")}</small>
                                            </div>

                                            <p className="text-sm mt-3 mb-1">
                                                {c.text}
                                            </p>

                                        </ListGroupItem>
                                   ) : null
                                ))

                           
                            ): (
                                <ListGroupItem className="list-group-item-action flex-column align-items-start py-4 px-4" >
                                    
                                </ListGroupItem>
                            )}

                           

                        </ListGroup>

                    </CardBody>
                </Card>

               

            </Container>
            </Payments>
            </>

        )

    }

}

const mapStateToProps = state => {

    return {
      modules: state.CoursePlatform.modules,
      lessons: state.CoursePlatform.lessons,
      comments: state.CoursePlatform.comments,
      lessons_finished: state.CoursePlatform.lessons_finished,
      viewing_user: state.SYSTEM.viewing_user,
      app_users: state.SYSTEM.app_users,
     
      
    };
};

  
export default connect(mapStateToProps )(Lesson);  

