
import App from './Views/App';


export default [

    //a single sidebar route
    {
        divider: 'Admin Dashboard',
        path: "/dashboard",                 //url
        name: "Home",                  //sidebar name
        icon: "fas fa-home text-primary",    //sidebar icon
        component: App,           //component to show in route
        layout: "/admin",                   //which layout to use
        renderOn: 'admin',                   //where to render this component
        show_in_sidebar: true
    },

    
    // {
    //     path: "/dashboard",                 //url
    //     name: "Dashboard",                  //sidebar name
    //     icon: "ni ni-shop text-primary",    //sidebar icon
    //     component: App,           //component to show in route
    //     layout: "/admin",                   //which layout to use
    //     renderOn: 'admin'                   //where to render this component
    // },
    // {
    //     path: "/dashboard",                 //url
    //     name: "Dashboard",                  //sidebar name
    //     icon: "ni ni-shop text-primary",    //sidebar icon
    //     component: App,           //component to show in route
    //     layout: "/admin",                   //which layout to use
    //     renderOn: 'admin'                   //where to render this component
    // },

    //a collapsable sidebar route
    // {
    //    collapse: true,                     //can be collapsed
    //    name: "Dashboard",                  //Title on sidebar
    //    icon: "ni ni-shop text-primary",    //Icon on sidebar
    //    state: "dashboardsCollapse",        //state of sidebar
    //    renderOn: 'admin',                  //where to render
    //    views: [                            //pages inside this collapse
    //        {
    //            path: "/users/app",             //url
    //            name: "Dashboard",              //sidebar name
    //            component: App,       //component to show in route
    //            layout: "/admin"                //which layout to use
    //        },
    //    ]
    // },

]