import mastercard from '../../assets/icons/dark/mastercard.png';
import jcb from '../../assets/icons/dark/jcb.png';
import diners_club from '../../assets/icons/dark/diners_club.png';
import discover from '../../assets/icons/dark/discover.png';
import american_express from '../../assets/icons/dark/american_express.png';
import visa from '../../assets/icons/dark/visa.png';
import union_pay from '../../assets/icons/dark/union_pay.png';

export function getBrandIcon (type) {

    switch (type) {

        case "MasterCard": return mastercard
        case "JCB": return jcb
        case "Diners Club": return diners_club
        case "Discover": return discover
        case "American Express": return american_express
        case "Visa": return visa
        case "UnionPay": return union_pay
    
        default:
            return ''
    }

}