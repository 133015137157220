import React from "react";
import { Link } from 'react-router-dom'
import { connect } from 'react-redux';

import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search, CSVExport } from "react-bootstrap-table2-toolkit";

import { aosAction } from '../../../database';

import moment from 'moment';

import AvatarImage from '../components/Images/Avatar';

import { Card, CardHeader, Container, CardBody, CardFooter, Row, Col, FormGroup, Input } from "reactstrap";

import Header from "../components/Header";
import ReactBSAlert from "react-bootstrap-sweetalert";


const required_form_fields = [
    'given_name',
    'family_name',
    'email',
]

class Alternative extends React.Component {

    state = {
        user: {},
        canSave: true,
        err: null,
        passsword: null,
        createdUser: null
    }

    onInputChange = (e, stateName) => {

        let newState = Object.assign({}, this.state.user);
        newState[stateName] = e.target.value;

        if (stateName === "checkbox") {
            if (e.target.value) {
                newState.checkboxState = "valid";
            } else {
                newState.checkboxState = "invalid";
            }
        } else {
            if (e.target.value === "") {
                newState[stateName + "State"] = "invalid";
            } else {
                newState[stateName + "State"] = "valid";
            }
        }

        this.setState({ user: newState });
    };


    onSave = async () => {

        this.setState({canSave: false})
        let newState = Object.assign({}, this.state.user);

        let errors = 0;

        required_form_fields.forEach((field) => {
            if(newState[field]) {
                newState[field + "State"] = 'valid'; 
            } else {
                newState[field + "State"] = 'invalid'; 
                errors++
            }
        })

        console.log(errors)
        this.setState({ user: newState, canSave: errors ? true : false });

        if(errors) return;


        const created = await aosAction({
            method: 'post',
            url: '/api/v1/users/create_free',
            aos_live_events: ['/api/live/users/get/all'],          
            data: newState,
        });

        if(created.success) {

            const subCreated = await aosAction({
                method: 'post',
                url: '/api/live/system_subscriptions/create',
                aos_live_events: ['/api/live/users/get/all'],   
                data: {
                    subscription_key: 'CoursePlatform_standard',
                    status: 'active',
                    // stripe_cus_id,
                    user_id: created.user._id 
                }
            })

            if(subCreated.success) {
                
                return this.setState({canSave: true, user: {}, password: created.password, createdUser: created.user, err: false})

            } else {

                return this.setState({canSave: true, err: subCreated.message})

            }


        } else {

            return this.setState({canSave: true, err: created.message})

        }
         
    }

    render() {

        const { user, canSave, err, password, createdUser } = this.state

        return (
            <>
            {this.state.alert}
            <Header icon={true} title={<span>Users</span>} breadcrumb_1="App Users"/>
            <Container className="mt--6" fluid>
            
            <Card>
                <CardHeader>
                    <h3 className="mb-0">Create Free User</h3>
                </CardHeader>

                <CardBody>
                    <Row>

                        <Col lg="6">
                            <FormGroup>
                                <label className="form-control-label" htmlFor="given_name"> First Name *</label>
                                <Input
                                    id="given_name"
                                    value={user.given_name || ''}
                                    type="tel"
                                    valid={ user.given_nameState === "valid" }
                                    invalid={ user.given_nameState === "invalid" }
                                    onChange={e => this.onInputChange(e, "given_name") }
                                    
                                />
                                <div className="valid-feedback">Looks good!</div>
                            </FormGroup>
                        </Col>

                        <Col lg="6">
                            <FormGroup>
                                <label className="form-control-label" htmlFor="family_name"> Last Name *</label>
                                <Input
                                    id="family_name"
                                    value={user.family_name || ''}
                                    type="tel"
                                    valid={ user.family_nameState === "valid" }
                                    invalid={ user.family_nameState === "invalid" }
                                    onChange={e => this.onInputChange(e, "family_name") }
                                    
                                />
                                <div className="valid-feedback">Looks good!</div>
                            </FormGroup>
                        </Col>

                    </Row>

                    <FormGroup>
                        <label className="form-control-label" htmlFor="family_name"> Email * </label>
                        <Input
                            id="email"
                            value={user.email || ''}
                            type="tel"
                            valid={ user.emailState === "valid" }
                            invalid={ user.emailState === "invalid" }
                            onChange={e => this.onInputChange(e, "email") }
                            
                        />
                        <div className="valid-feedback">Looks good!</div>
                    </FormGroup>


                </CardBody>

                {err ? (
                    <CardFooter>
                        <p className="mb-0 text-danger font-weight-bold"><i className="fas fa-exclamation-triangle mr-2 " /> {err}</p>
                    </CardFooter>
                ) : null}

                {password ? (
                    <CardFooter>
                        <p>User Created Successfully, login credentials:</p>
                        <p className="mb-0">{createdUser.email}</p>
                        <p className="mb-0">{password}</p>
                    </CardFooter>
                ) : null}
                
                <CardFooter className="text-right">
                    <button disabled={!canSave} onClick={this.onSave} className="btn btn-success">Create User</button>
                </CardFooter>
               

              </Card>
          
            


            </Container>
            </>
        );
    }
}


const mapStateToProps = state => {
    return {
        app_users: state.SYSTEM.app_users,  
    };
};
  
export default connect(mapStateToProps, '')(Alternative);

