import React from "react";
import { Link } from 'react-router-dom'
import { connect } from 'react-redux';

import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search, CSVExport } from "react-bootstrap-table2-toolkit";

import { aosAction } from '../../../database';

import moment from 'moment';

import AvatarImage from '../components/Images/Avatar';

import {

  Card,
  CardHeader,
  Container,
  Row,
  Col,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem

} from "reactstrap";

import Header from "../components/Header";
import ReactBSAlert from "react-bootstrap-sweetalert";


const { ExportCSVButton } = CSVExport;

const pagination = paginationFactory({
    page: 1,
    alwaysShowAllBtns: true,
    showTotal: true,
    withFirstAndLast: false,
    sizePerPageRenderer: ({ options, currSizePerPage, onSizePerPageChange }) => (
        <div className="dataTables_length" id="datatable-basic_length">
            <label>
                Show{" "}
                {
                <select
                    name="datatable-basic_length"
                    aria-controls="datatable-basic"
                    className="form-control form-control-sm"
                    onChange={e => onSizePerPageChange(e.target.value)}
                >
                    <option value="10">10</option>
                    <option value="25">25</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                </select>
                }{" "}
                entries.
            </label>
        </div>
    )
});

const { SearchBar } = Search;


class Alternative extends React.Component {

    state = {

    }

    columns = [
        {
        dataField: "given_name",
        text: "Name",
        sort: true,
        formatter: (cell, row) => {
    
            return (
            
                <Link to={"/admin/user/"+row._id}>
                    <span  className="avatar avatar-sm rounded-circle aligned-image" >
                        <AvatarImage  alt="..." src={row.picture_url} />
                    </span>

                    <span className="ml-5" style={{position: 'relative', top: -11}}>
                    {row.given_name}  {row.family_name}
                    </span>
                </Link>

            )
    
            }
        }, {
            dataField: "email",
            text: "Email",
            sort: true
        }, {
            dataField: "phone",
            text: "Phone",
            sort: true
        }, {
            dataField: "created_at",
            text: "Created ON",
            sort: true,
            formatter: (cell, row) => {
              return moment.unix(row.created_at).format("MM/DD/YYYY")
    
            }
        }, {
            dataField: "",
            text: "Actions",
            sort: false,
            headerFormatter: (column) => {
                return <div className="text-right">Actions</div>
            },
            formatter: (cell, row) => {
                return (
                    <div className="text-right" style={{marginRight: -21}}>
                        <UncontrolledDropdown>
                            <DropdownToggle
                                className="btn-icon-only text-light"
                                color=""
                                role="button"
                                size="sm"
                            >
                            <i className="fas fa-ellipsis-v" />
                            </DropdownToggle>
                            <DropdownMenu className="dropdown-menu-arrow" right>
    
                            <Link to={"/admin/user/"+row._id}>
                                    <DropdownItem >
                                        View
                                    </DropdownItem>
                                </Link>
     
                                <DropdownItem
                                    href="#pablo"
                                    onClick={() => this.confirmAlert(row)}
                                >
                                    Delete User
                                </DropdownItem>
                               
                            </DropdownMenu>
                        </UncontrolledDropdown>
                    </div>
                )
    
            }
        }
    ]

    hideAlert = () => {
        this.setState({
            alert: null
        });
    };

    confirmAlert = (row) => {
        this.setState({
            alert: (
                <ReactBSAlert
                    warning
                    style={{ display: "block", marginTop: "-100px" }}
                    title="Are you sure?"
                    onConfirm={() => this.hideAlert()}
                    onCancel={() => this.confirmedAlert(row)}
                    showCancel
                    confirmBtnBsStyle="secondary"
                    confirmBtnText="Cancel"
                    cancelBtnBsStyle="danger"
                    cancelBtnText={"Yes, remove " + row.given_name}
                    btnSize=""
                >
                    Are you sure you wish to delete {row.given_name} {row.family_name} from the system? This can not be undone.
                </ReactBSAlert>
            )
        });
      };
      confirmedAlert = async (row) => {

        const deleted = await aosAction({
            method: 'post',
            url: '/api/live/users/delete/' + row._id,
            aos_live_events: ['/api/live/users/get/all'],   
      
        });
    

        this.setState({
            alert: (
                <ReactBSAlert
                    success={deleted.success ? true : false}
                    danger={!deleted.success ? true : false}
                    style={{ display: "block", marginTop: "-100px" }}
                    title={deleted.success ? 'Success!' : 'Error'}
                    onConfirm={() => this.hideAlert()}
                    onCancel={() => this.hideAlert()}
                    confirmBtnBsStyle="primary"
                    confirmBtnText="Ok"
                    btnSize=""

                >
                    {deleted.success ? 
                    `You have successfully removed ${row.given_name} ${row.family_name}.` : 
                    `Something went wrong deleting ${row.given_name} ${row.family_name}.`
                    }
                </ReactBSAlert>
            )
        });
      };


    

    render() {

        const app_users = this.props.app_users;

        return (
            <>
            {this.state.alert}
            <Header icon={true} title={<span>Users</span>} breadcrumb_1="App Users"/>
            <Container className="mt--6" fluid>
            
            <Card>
                <CardHeader>
                <h3 className="mb-0">All Users</h3>
                    <p className="text-sm mb-0">
                        This table shows all of your app users. Use it to search, sort, and export your user data to a csv.
                    </p>
                </CardHeader>
                <div className="table-vertical-align">
                    <ToolkitProvider
                        data={app_users}
                        keyField="_id"
                        columns={this.columns}
                        search
                        exportCSV
                        >
                        {props => (
                            <div className="py-4 table-responsive">
                            <div
                                id="datatable-basic_filter"
                                className="dataTables_filter px-4 pb-1"
                                style={{width: '100%'}}
                            >
                                <Row>

                                    <Col>
                                        <label>
                                            Search:
                                            <SearchBar
                                                className="form-control-sm"
                                                placeholder=""
                                                {...props.searchProps}
                                            />
                                        </label>
                                    </Col>

                                    <Col className="col-auto">
                                        <ExportCSVButton
                                        
                                            className="btn-sm"
                                            { ...props.csvProps }
                                            
                                        >
                                            <span>Export CSV</span>
                                        </ExportCSVButton>
                                    </Col>

                                </Row>
                            </div>
                            <BootstrapTable
                                {...props.baseProps}
                                className="xxx"
                                bootstrap4={true}
                                pagination={pagination}
                                bordered={false}
                            />
                            </div>
                        )}
                        </ToolkitProvider>
                    </div>
              </Card>
          
            


            </Container>
            </>
        );
    }
}


const mapStateToProps = state => {
    return {
  

      app_users: state.SYSTEM.app_users,
    
  
    };
  };
  
  export default connect(mapStateToProps, '')(Alternative);

