/*
Documentation

This file holds the content for updating a companies billing information and credit cards

*/

import './assets/scss/style.scss'
import PropTypes from 'prop-types';
import React from "react";
import ReactBSAlert from "react-bootstrap-sweetalert";
import { Elements, StripeProvider } from 'react-stripe-elements';
import { Button, Card, CardBody, CardHeader, Col, Form, FormGroup, Input, Row, Table } from "reactstrap";
import createSystemLog from '../../../database/system_logs';
import keys from '../../../keys';
import { customerCardDelete, customerCardList, customerCardSetDefaultSource, customerRetrieve, customerUpdate } from '../universal/functions/stripe';
import States from './components/inputs/States';
import CheckoutForm from './components/payments/Stripe';
import { getBrandIcon } from './functions/helpers/icons';
import processAddedCard from './functions/processAddedCard';



const required_form_fields = [
   
    'given_name',
    'family_name',

    'address_line_1',
    'city',
    'state',
    'postal_code',

]



class Billing extends React.Component {

    state = {
        //set blank values to control the inputs if no user is passed in
        user: {
            given_name: '',
            family_name: '',
            address_line_1: '',
            address_line_2: '',
            city: '',
            state: '',
            postal_code: '',
        },
        canSave: true
    }
    
    fireAlert = (error) => {
        this.setState({
          alert: (
            <ReactBSAlert
              success={error ? false : true}
              danger={!error ? false : true}
              style={{ display: "block", marginTop: "-100px" }}
              title={error ? 'Error' : 'Success'}
              onConfirm={() => this.hideAlert()}
              onCancel={() => this.hideAlert()}
              confirmBtnBsStyle="success"
              confirmBtnText="Ok"
              btnSize=""
            >
              {error ? 
              'Something went wrong updating your information' :
              'Information Updated Successfully'
            }
            </ReactBSAlert>
          )
        });
    };

    fireCustomAlert = (params) => {

        this.setState({
          alert: (
            <ReactBSAlert
              success={params.error ? false : true}
              danger={!params.error ? false : true}
              style={{ display: "block", marginTop: "-100px" }}
              title={params.error ? 'Error' : 'Success'}
              onConfirm={() => this.hideAlert()}
              onCancel={() => this.hideAlert()}
              confirmBtnBsStyle="success"
              confirmBtnText="Ok"
              btnSize=""
            >
             {params.text}
            </ReactBSAlert>
          )
        });
    };

    hideAlert = () =>  this.setState({ alert: null }); 

    onSave = async () => {

        const stripe_cus_id = this.props.stripe_cus_id ? this.props.stripe_cus_id : this.state.stripe_cus_id

        this.setState({canSave: false, loading: 'Updating Info'})
        //copy state
        let newState = Object.assign({}, this.state.user);
        //assume we have no errors
        let errors = 0;

        required_form_fields.forEach((field) => {

            //check each required field
            if(newState[field]) {
                newState[field + "State"] = 'valid'; 
            } else {
                newState[field + "State"] = 'invalid'; 
                errors++
            }

        })

        this.setState({
          user: newState
        });

        //let the user try again but stop form saving
        if(errors) {
            this.setState({canSave: true, loading: null})
        } else {

            //after load make sure button work again
           if(stripe_cus_id) {

                 //update this information in stripe if we have a customer id
                const updateStripe = await customerUpdate({
                    customer_id: stripe_cus_id,
                    description: newState.given_name + ' ' + newState.family_name,
                    email: newState.email,
                    address: {
                        line1:          newState.address_line_1,
                        line2:          newState.address_line_2,
                        city:           newState.city,
                        state:          newState.state,
                        postal_code:    newState.postal_code,
                    },
                    name: newState.given_name + ' ' + newState.family_name,
                })

                if(updateStripe.success) {

                    this.props.onUpdateInfo('',  {user: newState} )

                } else {

                    this.props.onUpdateInfo(updateStripe.message, '' )

                }


           } else {

                this.props.onUpdateInfo('', {user: newState} )

           }

           this.setState({canSave: true, loading: null})

          

        }

    }

    onInputChange = (e, stateName) => {


        let newState = Object.assign({}, this.state.user);
        newState[stateName] = e.target.value;

        if (stateName === "checkbox") {
          if (e.target.value) {
            newState.checkboxState = "valid";
          } else {
            newState.checkboxState = "invalid";
          }
        } else {
          if (e.target.value === "") {
            newState[stateName + "State"] = "invalid";
          } else {
            newState[stateName + "State"] = "valid";
          }
        }

        this.setState({
          user: newState
        });
    };

    fetchUserCards = async () => {

        const stripe_cus_id = this.props.stripe_cus_id ? this.props.stripe_cus_id : this.state.stripe_cus_id


        //only get cards if a customer exists
        if(stripe_cus_id) {

            let customersCards = await customerCardList(stripe_cus_id);

            if(customersCards.success) {
    
                let cards = customersCards.cards;
                this.setState({cards});
    
            } else {
                
                //this will throw an error if a stripe customer id deleted and that same user comes back here.
                console.log(customersCards)
    
            }
        } 

    }

    fetchUserCustomer = async () => {

        const stripe_cus_id = this.props.stripe_cus_id ? this.props.stripe_cus_id : this.state.stripe_cus_id

        //only get customer if one exists
        if(stripe_cus_id) {

            const customer = await customerRetrieve(stripe_cus_id);

            //customer was created
            if(customer.success) {
    
                let foundCustomer = customer.customer;
                this.setState({customer: foundCustomer})
    
            } else {
                
                createSystemLog({
                    error_file: '/Settings/Views/Billing.js',
                    error_url: window.location.href,
                    error_code: 4,
                    text: 'Something went wrong fetching information from stripe in /dashboard/DashAccountSettings. Error: ' + customer.message
                })


            }

        }

    }

    //requires we have a customer
    onDeleteCard = async (card_id) => {

        const stripe_cus_id = this.props.stripe_cus_id ? this.props.stripe_cus_id : this.state.stripe_cus_id

        if(stripe_cus_id) {

            this.setState({loading: 'Deleting Card'})

            let deletedCard = await customerCardDelete(stripe_cus_id, card_id);
    
            if(deletedCard.success) {
    
                this.fetchUserCards()
    
            } else {
                
                //here we should eventually pull the error out to wrapping component
                this.fireCustomAlert({
                    error: true,
                    text: "An error occurred deleting this card. Please try again."
                })
    
            }
    
            this.setState({loading: false})

        }
       

    }

    onAddCard = async (token)  =>{

        this.setState({canSave: false, loading: 'Verifying Card'})
        //copy state
        let newState = Object.assign({}, this.state.user);
        //assume we have no errors
        let errors = 0;

        required_form_fields.forEach((field) => {

            //check each required field
            if(newState[field]) {
                newState[field + "State"] = 'valid'; 
            } else {
                newState[field + "State"] = 'invalid'; 
                errors++
            }

        })

        this.setState({
          user: newState
        });

        //let the user try again but stop form saving
        if(errors) {
            this.setState({canSave: true, loading: null})
        } else {

            if(token) {
    
                //get our stripe_id if we have one
                const stripe_cus_id = this.props.stripe_cus_id ? this.props.stripe_cus_id : this.state.stripe_cus_id
    
                //either create a new stripe customer and add a card to them or add a card to an existing customer
                const response = await processAddedCard(token.id, stripe_cus_id, this.props.user_id, this.state.user)
    
                //send of response
                this.props.onAddCard(response.error, {
                    ...response,
                    user: this.state.user
                })
    
                //if successfull add the customer id and fetch thier cards
                if(!response.error) {
                    console.log('no errors')
                    console.log(response.customer.id)
                    this.setState({ stripe_cus_id: response.customer.id}, () => {

                        this.fetchUserCards();
                        this.fetchUserCustomer()

                    })
                }
    
            }

            this.setState({loading: null, canSave: true})

        }

      

    }

    onSetDefaultSource = async (card_id) => {

        this.setState({loading: 'Updating Your Default Card Info.'})

        let setCard = await customerCardSetDefaultSource(this.props.stripe_cus_id, card_id);

        this.setState({loading: false})

        if(setCard.success) {

            this.fetchUserCustomer()
            
        } else {

           //here we should eventually pull the error out to wrapping component
           this.fireCustomAlert({
               error: true,
               text: "An error occurred updating your card info. Please try again."
           })
            
        }
        

    }

    componentDidMount = () => {

        this.fetchUserCards();
        this.fetchUserCustomer();

        //set the user if they were passed in
        if(this.props.user) {
            this.setState({user: this.props.user})
        }

    }
    
    render() {

        const starting_user = this.props.user

        const c = this.state.user
        const u = starting_user ? starting_user : {};

        return (
            <>
            {this.state.alert}

            {this.state.loading && (
                <div className="circleLoader">
                    <div className="loader-wrapper">
                        <div className="loader"></div>
                        <p>{this.state.loading}</p>
                    </div>
                </div>
            )}

            <Row>
                <Col lg={6}>
                    <Card>

                        <CardHeader>
                            <Row className="align-items-center">
                                <Col xs="8">
                                    <h3 className="mb-0">Billing Information</h3>
                                </Col>
                                <Col className="text-right" xs="4">
                                    <Button
                                        color="primary"
                                        onClick={this.onSave}
                                        size="sm"
                                        disabled={this.state.canSave ? false : true}
                                    >
                                        Update 
                                    </Button>
                                </Col>
                            </Row>
                        </CardHeader>

                        <CardBody>
                            <Form>

                                <h6 className="heading-small text-muted mb-4">Name On Card</h6>

                                <div className="py-lg-0">

                                    <Row>
                                        <Col lg="6">
                                            <FormGroup>

                                                <label className="form-control-label" htmlFor="given_name" >First Name</label>
                                                <Input
                                                    id="given_name"
                                                    value={c.given_name || u.given_name}
                                                    placeholder="First Name"
                                                    type="text"
                                                    valid={ c.given_nameState === "valid" }
                                                    invalid={ c.given_nameState === "invalid" }
                                                    onChange={e => this.onInputChange(e, "given_name") }
                                                />
                                                <div className="valid-feedback">Looks good!</div>

                                            </FormGroup>
                                        </Col>
                                        
                                        <Col lg="6">
                                            <FormGroup>

                                                <label className="form-control-label" htmlFor="family_name" > Last Name </label>
                                                
                                                <Input
                                                    id="email"
                                                    value={c.family_name || u.family_name}
                                                    placeholder="Last Name"
                                                    type="text"
                                                    valid={ c.family_nameState === "valid" }
                                                    invalid={ c.family_nameState === "invalid" }
                                                    onChange={e => this.onInputChange(e, "family_name") }
                                                />
                                                <div className="valid-feedback">Looks good!</div>

                                            </FormGroup>
                                        </Col>

                                        
                                    </Row>

                                </div>

                                <hr className="my-4" />
                                <h6 className="heading-small text-muted mb-4">Billing Address</h6>

                                <div className="py-lg-0">

                                    <Row>
                                        <Col md="12">
                                            <FormGroup>

                                                <label className="form-control-label" htmlFor="address_line_1" > Address Line 1 </label>

                                                <Input
                                                    id="address_line_1"
                                                    value={c.address_line_1 || u.address_line_1}
                                                    placeholder="198 Manhattan Ave"
                                                    type="text"
                                                    valid={ c.address_line_1State === "valid" }
                                                    invalid={ c.address_line_1State === "invalid" }
                                                    onChange={e => this.onInputChange(e, "address_line_1") }
                                                />
                                                <div className="valid-feedback">Looks good!</div>

                                            </FormGroup>
                                        </Col>

                                        <Col md="12">
                                            <FormGroup>

                                                <label className="form-control-label" htmlFor="address_line_2" > Address Line 2 </label>
                                                
                                                <Input
                                                    id="address_line_2"
                                                    value={c.address_line_2 || u.address_line_2}
                                                    placeholder="Apt D"
                                                    type="text"
                                                    valid={ c.address_line_2State === "valid" }
                                                    invalid={ c.address_line_2State === "invalid" }
                                                    onChange={e => this.onInputChange(e, "address_line_2") }
                                                    
                                                />
                                                <div className="valid-feedback">Looks good!</div>
                                            </FormGroup>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col lg="4">
                                            <FormGroup>
                                                <label className="form-control-label" htmlFor="city" > City </label>

                                                <Input
                                                    id="city"
                                                    value={c.city || u.city}
                                                    placeholder="Apt D"
                                                    type="text"
                                                    valid={ c.cityState === "valid" }
                                                    invalid={ c.cityState === "invalid" }
                                                    onChange={e => this.onInputChange(e, "city") }  
                                                />
                                                <div className="valid-feedback">Looks good!</div>

                                            </FormGroup>
                                        </Col>

                                        <Col lg="4">
                                            <FormGroup>

                                                <label className="form-control-label" htmlFor="state" > State </label>

                                                <Input
                                                    id="state" 
                                                    type="select"
                                                    value={c.state || u.state}
                                                    valid={ c.stateState === "valid" }
                                                    invalid={ c.stateState === "invalid" }
                                                    onChange={e => this.onInputChange(e, "state") }
                                                >
                                                    <option value=""></option>
                                                    <States />
                                                </Input>

                                                <div className="valid-feedback">Looks good!</div>

                                            </FormGroup> 
                                        </Col>

                                        <Col lg="4">
                                            <FormGroup>
                                                <label className="form-control-label" htmlFor="postal_code" > Postal code </label>
                                            
                                                <Input
                                                    id="postal_code"
                                                    value={c.postal_code || u.postal_code}
                                                    placeholder="Apt D"
                                                    type="text"
                                                    valid={ c.postal_codeState === "valid" }
                                                    invalid={ c.postal_codeState === "invalid" }
                                                    onChange={e => this.onInputChange(e, "postal_code") }
                                                />
                                                <div className="valid-feedback">Looks good!</div>

                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </div>

                            </Form>
                        </CardBody>
                    </Card>
                </Col>

                <Col lg={6}>
                    <Card>

                        <CardHeader>
                            <Row className="align-items-center">
                                <Col xs="8">
                                    <h3 className="mb-0">Add A Card</h3>
                                </Col>
                                
                            </Row>
                        </CardHeader>

                        <CardBody>
                            <StripeProvider apiKey={keys.STRIPE_PUBLISHABLE_KEY}>

                                <div className="stripe">
                                    <Elements>

                                        <div className="checkout">

                                            {this.props.textInstruction ? this.props.textInstruction : <p>This updates your card on file, you <b>WILL NOT</b> be charged by adding or changing your cards here.</p>}
                                            {this.state.chargeError ? <p className="text-danger">{this.state.chargeError}</p> : null }

                                            <CheckoutForm 
                                                submit={(token) => this.onAddCard(token)}
                                                formStyles={{color: 'white'}}
                                                buttonClasses="btn btn-sm btn-primary mt-3 text-right"
                                                buttonText="Add Card"
                                            />

                                            <hr/>

                                            {this.props.textDisclaimer ? this.props.textDisclaimer :  <p  className="small">It is your responsibility to make sure you have your most current form of payment information on file if you account has a recurring subscription.</p>}

                                        </div>

                                    </Elements>
                                </div>

                            </StripeProvider> 
                        </CardBody>

                    </Card>
                </Col>

            </Row>

            {this.state.cards && this.state.customer && (
            <Card>

                <CardHeader>
                    <Row className="align-items-center">
                        <Col xs="8">
                            <h3 className="mb-0">My Cards</h3>
                        </Col>
                    
                    </Row>
                </CardHeader>

                <CardBody>

                    <Table className="align-items-center" responsive>
                        <thead>
                            <tr>
                                <th>Brand</th>
                                <th>Number</th>
                                <th>Expires</th>
                                <th>Country</th>
                                <th>Actions</th>
                            </tr>
                        </thead>

                        <tbody>
                            {this.state.cards ? this.state.cards.map((card, index) => {
                            return (
                                <tr key={index}>
                                    <td><img style={{width: 50}} src={getBrandIcon(card.brand)} alt="" /></td>
                                    <td>{card.last4} {card.funding !== 'unknown' ? card.funding : ''}</td>
                                    <td>{card.exp_month}/{card.exp_year}</td>
                                    <td>{card.country}</td>
                                    <td>

                                        {this.state.customer.default_source === card.id ? (
                                            <i style={styles.iconStyles} className="fas fa-check text-success" ></i> 
                                        ): (
                                            <>
                                                <i style={styles.iconStyles} className="fas fa-toggle-on text-info" onClick={() => this.onSetDefaultSource(card.id)}></i>
                                                <i style={styles.iconStyles} className="fas fa-trash text-danger"  onClick={() => this.onDeleteCard(card.id)}></i>
                                            </>
                                        )}

                                    </td>
                                </tr>
                            )
                            }) : <tr><td>You have no payment sources at this time.</td><td></td><td></td><td></td><td></td></tr>}
                        </tbody>
                    </Table>

                </CardBody>

            </Card>
            )}

            </>
        );
    }
}

const styles = {
    iconStyles: {
        fontSize: '1.1rem',
        marginRight: 8
    }
}

Billing.propTypes = {
    
    onUpdateInfo:                   PropTypes.func,
    onAddCard:                      PropTypes.func,
    onRemoveSubscription:           PropTypes.func,

    active_stripe_subscriptions:    PropTypes.array,
    stripe_cus_id:                  PropTypes.string,

    user_id:                        PropTypes.string.isRequired,
    user:                           PropTypes.object,

    textInstruction:                PropTypes.element,
    textDisclaimer:                 PropTypes.element,

};


  
  
export default Billing