

let keys = {
    COMPANY: 'IGRE COACHING',
    LOGO: 'http://res.cloudinary.com/igre-coaching/image/upload/v1569632353/RealEstate/2019-09-28T00:59:13.362Z.png',
    PROD_HOSTNAME: 'igrecoaching.com',
};

const HOSTNAME = window.location.hostname

//using production keys
if(HOSTNAME === keys.PROD_HOSTNAME) {

    keys = {

        ...keys, 

        API_URL: 'https://edstulak-course-server-prod.herokuapp.com',
        
        STRIPE_PUBLISHABLE_KEY: 'pk_live_CsxpFfBBI6y8WvgMCzobHH7j00r9CrQgl5',

        AOS_MASTER_KEY: 'aos_master_8465846589' 

    }

//using staging keys
} else if(HOSTNAME !== 'localhost') { 

    keys = {

        ...keys, 

        API_URL: 'https://edstulak-course-server-staging.herokuapp.com',
        
        STRIPE_PUBLISHABLE_KEY: 'pk_test_VvgKLURF0GuFBCU7RDEF2C9Y00B5K3CLlq',

        AOS_MASTER_KEY: 'aos_master_5957744324' 

        //

    }

//using development keys 
} else {

    keys = {

        ...keys, 
        
        API_URL: 'http://localhost:5000',
        
        STRIPE_PUBLISHABLE_KEY: 'pk_test_VvgKLURF0GuFBCU7RDEF2C9Y00B5K3CLlq',
    
        AOS_MASTER_KEY: 'aos_master_5957744324'
        
    }

}

export default keys
