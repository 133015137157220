/*

Add all reducers for this plugin here
If there are no reduces return a blank object

!IMPORTANT!
Preface all reducers with "AdminUsers__" in this file
This prevents future plugin conflicts

*/

// import exampleReducer from './reducers/exampleReducer';

export default {
    // AdminUsers__user: exampleReducer,
}
