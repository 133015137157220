
import classnames from "classnames";
import PropTypes from "prop-types";
import React from "react";
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Col, Collapse, Container, DropdownItem, DropdownMenu, DropdownToggle, Media, Nav, Navbar, NavItem, Row, UncontrolledDropdown } from "reactstrap";
import AvatarImage from './Images/Avatars';





class AdminNavbar extends React.Component {

	state = {
		showSearchResults: true
	};

	toggleModal = state => {
		this.setState({
			defaultModal: !this.state.defaultModal
		});

	}

	onSearch = (e) => {
		const value = e.target.value;

		if(value) {

			this.setState({showSearchResults: true})

		}

	}

	// function that on mobile devices makes the search open
	openSearch = () => {
		
		document.body.classList.add("g-navbar-search-showing");

		setTimeout(function() {
			document.body.classList.remove("g-navbar-search-showing");
			document.body.classList.add("g-navbar-search-show");
		}, 150);

		setTimeout(function() {
			document.body.classList.add("g-navbar-search-shown");
		}, 300);

	};
	// function that on mobile devices makes the search close
	closeSearch = () => {
		document.body.classList.remove("g-navbar-search-shown");

		setTimeout(function() {
			document.body.classList.remove("g-navbar-search-show");
			document.body.classList.add("g-navbar-search-hiding");
		}, 150);
		
		setTimeout(function() {
			document.body.classList.remove("g-navbar-search-hiding");
			document.body.classList.add("g-navbar-search-hidden");
		}, 300);

		setTimeout(function() {
			document.body.classList.remove("g-navbar-search-hidden");
		}, 500);
	};

	render() {

    const viewing_user = this.props.viewing_user;


    return (
		<>
			<Navbar
				className={classnames(
					"navbar-top navbar-expand border-bottom",
					{ "navbar-dark bg-info": this.props.theme === "dark" },
					{ "navbar-light bg-secondary": this.props.theme === "light" }
				)} 
			>
			<Container fluid>
				<Collapse navbar isOpen={true}>



				
					{/* <Form
						className={classnames(
							"navbar-search form-inline mr-sm-3",
							{ "navbar-search-light": this.props.theme === "dark" },
							{ "navbar-search-dark": this.props.theme === "light" }
							)}
						>

						<FormGroup className="mb-0">
							<InputGroup className="input-group-alternative input-group-merge">
								<InputGroupAddon addonType="prepend">
								<InputGroupText>
									<i className="fas fa-search" />
								</InputGroupText>
								</InputGroupAddon>
								<Input placeholder="Search Lessons" type="text" onChange={this.onSearch} />
							</InputGroup>
						</FormGroup>

						<button
							aria-label="Close"
							className="close"
							type="button"
							onClick={this.closeSearch}
						>
						<span aria-hidden={true}>×</span>
						</button>
					</Form> */}



					<Nav className="align-items-center ml-md-auto" navbar>

						<NavItem className="d-xl-none">
							<div
								className={classnames(
									"pr-3 sidenav-toggler",
									{ active: this.props.sidenavOpen },
									{ "sidenav-toggler-dark": this.props.theme === "dark" }
									)}
									onClick={this.props.toggleSidenav}
								>

								<div className="sidenav-toggler-inner">
									<i className="sidenav-toggler-line" />
									<i className="sidenav-toggler-line" />
									<i className="sidenav-toggler-line" />
								</div>

							</div>
						</NavItem>

						{/* <UncontrolledDropdown nav>

							<DropdownToggle className="nav-link" color="" tag="a">
								<i className="ni ni-bell-55" />
							</DropdownToggle>

							<DropdownMenu className="dropdown-menu-xl py-0 overflow-hidden" right >
								<div className="px-3 py-3">
									<h6 className="text-sm text-muted m-0">
										You have <strong className="text-info">13</strong>{" "}
										notifications.
									</h6>
								</div>

								<ListGroup flush>
									<ListGroupItem
										className="list-group-item-action"
										href="#pablo"
										onClick={e => e.preventDefault()}
										tag="a"
									>
										<Row className="align-items-center">
										<Col className="col-auto">
											<AvatarImage
											className="avatar rounded-circle"
											src={viewing_user.picture_url}
											/>
										</Col>
										<div className="col ml--2">
											<div className="d-flex justify-content-between align-items-center">
											<div>
												<h4 className="mb-0 text-sm">John Snow</h4>
											</div>
											<div className="text-right text-muted">
												<small>2 hrs ago</small>
											</div>
											</div>
											<p className="text-sm mb-0">
											Let's meet at Starbucks at 11:30. Wdyt?
											</p>
										</div>
										</Row>
									</ListGroupItem>
							
								</ListGroup>

								<DropdownItem className="text-center text-info font-weight-bold py-3" >
									View all
								</DropdownItem>

							</DropdownMenu>
						</UncontrolledDropdown> */}
						
						<UncontrolledDropdown nav>
						<DropdownToggle className="nav-link" color="" tag="a">
							<i className="ni ni-ungroup" />
						</DropdownToggle>
						
						{/* <DropdownMenu className="dropdown-menu-xl py-0 overflow-hidden" right > */}
						<DropdownMenu className="dropdown-menu-lg dropdown-menu-dark bg-default" right >
							<Row className="shortcuts px-4">

								<Col className="shortcut-item" xs="4" 	 >
									<Link to="/dashboard/settings">
										<span className="shortcut-media avatar rounded-circle bg-gradient-success">
										<i className="fas fa-cogs"></i>
										</span>
										<small>Settings</small>
									</Link>
								</Col>

								<Col className="shortcut-item" xs="4" 	 >
									<Link to="/dashboard/support">
										<span className="shortcut-media avatar rounded-circle bg-gradient-red">
										<i className="fas fa-comments"></i>
										</span>
										<small>Support</small>
									</Link>
								</Col>

							</Row>
						</DropdownMenu>

						</UncontrolledDropdown>
					</Nav>

					<Nav className="align-items-center ml-auto ml-md-0" navbar>

						<UncontrolledDropdown nav>

						<DropdownToggle className="nav-link pr-0" color="" tag="a">
							<Media className="align-items-center">
								<span className="avatar avatar-sm rounded-circle">
									<AvatarImage alt="" src={viewing_user.picture_url} />
								</span>

								<Media className="ml-2 d-none d-lg-block">
									<span className="mb-0 text-sm font-weight-bold">
									{viewing_user.given_name} {viewing_user.family_name}
									</span>
								</Media>

							</Media>
						</DropdownToggle>
						
						<DropdownMenu right>

							<DropdownItem className="noti-title" header tag="div">
								<h6 className="text-overflow m-0">Welcome!</h6>
							</DropdownItem>

							<Link to="/dashboard/settings">
								<DropdownItem	>
									<i className="ni ni-single-02" />
									<span>My profile</span>
								</DropdownItem>
							</Link>

							{viewing_user.is_admin && 
							<a href="/admin/dashboard">
								<DropdownItem	>
									<i className="fas fa-home"></i>
									<span>Admin Dashboard</span>
								</DropdownItem>
							</a>}
						
							<DropdownItem divider />

							<a href="/auth/logout">

								<DropdownItem>
										<i className="ni ni-user-run" />
										<span>Logout</span>
								</DropdownItem>
							</a>

						</DropdownMenu>
						</UncontrolledDropdown>
					</Nav>
					</Collapse>
				</Container>
			</Navbar>
      		</>
    	);
  	}
}
AdminNavbar.defaultProps = {
  toggleSidenav: () => {},
  sidenavOpen: false,
  theme: "dark"
};
AdminNavbar.propTypes = {
  toggleSidenav: PropTypes.func,
  sidenavOpen: PropTypes.bool,
  theme: PropTypes.oneOf(["dark", "light"])
};


const mapStateToProps = state => {
  return {

    viewing_user: state.SYSTEM.viewing_user,

  };
};

export default connect(mapStateToProps, '')(AdminNavbar);
