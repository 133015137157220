/*
Documentation

This file sets up the store for our applications


*/

import { createStore, combineReducers, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';

import systemsReducer from './reducers/SYSTEM';

import compiled_plugins_reducer from '../../plugins_compiler/compiled_reducers';


//tell redux to match the following properties to each reducer
const rootReducer = combineReducers({
    SYSTEM: systemsReducer,
    ...compiled_plugins_reducer
  
})
  
  
  
  //export the store
  const store = createStore(rootReducer, applyMiddleware(thunk));;
export default store