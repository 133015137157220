/*

functions here will fire whenever a this plugins containing layout is finished
they must all return a promies

*/

export default [

    // {
    //     //a promise returning function that sets a redux action in this plugin
    //     func: onLoadEvent,
    //     //the property that this function sets
    //     prop: 'viewing_user',
    //     //the plugin name for this property
    //     //DON'T CHANGE!  MUST BE SAME AS PLUGIN
    //     plugin_name: 'AdminDashboard',
    //     //should this plugin run in the background. False means we wait for it on plugin load
    //     //this will default to true once it has been run once
    //     run_in_background: false,
    //     //don't reload whenever we mount this plugins containing layout
    //     should_not_reload: false,
    // },
    // {
    //     func: anotherFunction,
    //     prop: 'testing',
    //     plugin_name: 'AdminDashboard',
    //     run_in_background: false,
    //     should_not_reload: false,
    // },
    

]