
/* 

Do not update this file. It should be generated programmatically through npm run plugins-compile

Copyright 2019 Arsenal OS
Author John Maher

*/

import consoleError from '../plugins_core/helpers/consoleError'; 
import AdminDashboard from '../plugins/AdminDashboard/routes'; 
import AdminPayments from '../plugins/AdminPayments/routes'; 
import AdminSystemLogs from '../plugins/AdminSystemLogs/routes'; 
import AdminUsers from '../plugins/AdminUsers/routes'; 
import Auth from '../plugins/Auth/routes'; 
import CoursePlatform from '../plugins/CoursePlatform/routes'; 
import Ghost from '../plugins/Ghost/routes'; 
import Home from '../plugins/Home/routes';  
import Settings from '../plugins/Settings/routes'; 
import Support from '../plugins/Support/routes'; 


const paths = [];
const duplicate_paths = [];

//function to filter route paths
const filterRoutePaths = (Component) => {
    
    Component.forEach(elem => {
        //push the path to the array
        if(elem.path) {
            paths.push(elem.path)
        } else {
            //if this has sub routes loop them
            elem.views.forEach((view) => {
                paths.push(view.path)
            })
        }
    });
}
 
//make sure we have no duplicates routes in our plugins
const checkRouteDuplicates = (array) => {
    var valuesSoFar = Object.create(null);
    for (var i = 0; i < array.length; ++i) {
        var value = array[i];
        if (value in valuesSoFar) {
            duplicate_paths.push(value)
        } else {
            valuesSoFar[value] = true;
        }
       
    }
}

//filter route paths
filterRoutePaths(AdminDashboard); 
filterRoutePaths(AdminPayments); 
filterRoutePaths(AdminSystemLogs); 
filterRoutePaths(AdminUsers); 
filterRoutePaths(Auth); 
filterRoutePaths(CoursePlatform); 
filterRoutePaths(Ghost); 
filterRoutePaths(Home); 
filterRoutePaths(Settings); 
filterRoutePaths(Support); 


//check for route duplicates
checkRouteDuplicates(paths);

//send error message if we have duplicate routes
if(duplicate_paths && duplicate_paths.length > 0) {

    let duplicate_paths_string = '';

    duplicate_paths.forEach((path) => {
        duplicate_paths_string += '"' + path + '", ' 
    })
    consoleError('Plugin route conflict. The following routes are duplicated in this apps plugins: ' + duplicate_paths_string)
}

let compiled_plugins = []; 

//push all imports into compiled plugins array
compiled_plugins.push(...AdminDashboard); 
compiled_plugins.push(...AdminPayments); 
compiled_plugins.push(...AdminSystemLogs); 
compiled_plugins.push(...AdminUsers); 
compiled_plugins.push(...Auth); 
compiled_plugins.push(...CoursePlatform); 
compiled_plugins.push(...Ghost); 
compiled_plugins.push(...Home); 
compiled_plugins.push(...Settings); 
compiled_plugins.push(...Support); 

export default compiled_plugins; 
