import Axios from 'axios';
import classnames from "classnames";
import React from "react";
import { connect } from 'react-redux';
import { Link, Redirect } from "react-router-dom";
import { Button, Card, CardBody, Col, Container, FormGroup, Input, InputGroup, InputGroupAddon, InputGroupText, Row } from "reactstrap";
import keys from '../../../keys';
import AuthHeader from "../components/Header";
import owasp from 'owasp-password-strength-test';






class Login extends React.Component {

	state = {
        error: false,
        showEmail: true,
        showCodeVerification: false,
        showPasswordUpdate: false,
        success: null
    };

    //on enter simulate the form being submitted for better UI
	_handleKeyDown = (e, type) => {
		if (e.key === 'Enter') {

            if(type === 'showEmail') {
                document.getElementById("showEmailButton").click();
            }

            if(type === 'showCodeVerification') {
                document.getElementById("showCodeVerificationButton").click();
            }

            if(type === 'showPasswordUpdate') {
                document.getElementById("showPasswordUpdateButton").click();
            }
			
		}
	}

    showEmail = () => {
        this.setState({
            showEmail: true,
            showCodeVerification: false,
            showPasswordUpdate: false
        })
    }

    showCodeVerification = () => {
        this.setState({
            showEmail: false,
            showCodeVerification: true,
            showPasswordUpdate: false
        })
    }

    showPasswordUpdate = () => {
        this.setState({
            showEmail: false,
            showCodeVerification: false,
            showPasswordUpdate: true
        })
    }


    onSendCode = async () => {

        this.setState({error: null})

        const email = this.state.email;

        
        if(!this.state.email) {
            this.setState({error: 'Please Add Your Email'});
            return;
        }

		try {
			const user = await Axios({
				method:'post',
				url: keys.API_URL + '/auth/security_code/create',
				withCredentials: true, 
				data: { email }
			 })

			if(user.data.success) {

                this.showCodeVerification()

			} else {

                this.setState({error: user.data.message})
                
			}
		
		} catch(e) {
		
			this.setState({error: e.toString()})
			console.log('Error ', e)
		
		}

    }
    
    onConfirmedCode = async  () => {

        this.setState({error: null})

        const code = this.state.code;

        
        if(!this.state.code) {
            this.setState({error: 'Please enter the code sent to your email'});
            return;
        }

		try {
			const confirmedCode = await Axios({
				method:'post',
				url: keys.API_URL + '/auth/security_code/validate',
				withCredentials: true, 
				data: { 
                    code,
                    email: this.state.email
                }
			 })

			if(confirmedCode.data.success) {

                this.setState({user_id: confirmedCode.data._id})
                this.showPasswordUpdate()

			} else {

                this.setState({error: confirmedCode.data.message})
                
			}
		
		} catch(e) {
		
			this.setState({error: e.toString()})
			console.log('Error ', e)
		
		}

    }

    onPasswordSet = async () => {

        this.setState({error: null})

        if(this.state.password_confirm !== this.state.password) {

            this.setState({error: 'Passwords do not match'})
            return;
        }

        const updatePassword = await Axios({
            method:'post',
            url: keys.API_URL + '/auth/credentials/password/change',
            withCredentials: true, 
            data: { 
                password: this.state.password,
                password_confirm: this.state.password_confirm,
                _id: this.state.user_id,
            }
        })

        if(updatePassword.data.success) {

           this.setState({success: true})
        
        } else {

            this.setState({error: updatePassword.data.message})
            
        }

    }



    onPasswordChange = (e, stateName) => {

        let pt = this.state.passwordTest

        if(stateName === 'password') {
            pt = owasp.test(e.target.value)
        }

      


        let newState = Object.assign({}, {
            ...this.state,
            passwordTest: pt
        });
        newState[stateName] = e.target.value;


       
          if (e.target.value === "") {
            newState[stateName + "State"] = "invalid";
          } else {
            newState[stateName + "State"] = "valid";
          }


        this.setState({
            ...newState
        });
    };
 

	render() {

		if(this.state.shouldRedirect) {
			return <Redirect to={this.state.shouldRedirect} />
        }
        
        const pt = this.state.passwordTest;

		return (

		

		<>

			<AuthHeader
				title="Forgot You Password?"
				lead="No worries, just fill out the form below."
			/>

			<Container className="mt--8 pb-5">
				<Row className="justify-content-center">
					<Col lg="5" md="7">
						<Card className="bg-white border-0 mb-0">

					
							<CardBody className="px-lg-5 py-lg-5">

								<div className="text-center text-muted mb-4">
                                    <small>
                                        {this.state.showEmail && 'Enter your email'}
                                        {this.state.showCodeVerification && 'Enter the code sent to your email.'}
                                        {this.state.showPasswordUpdate && 'Enter your new password.'}
                                        {this.state.success && <> <br/> <Link to="/auth/login" className="text-success">Your password has been updated, please log in here.</Link> </>}
                                    </small>
								</div>



                                    {this.state.showEmail && (
                                        <>                          
                                        <FormGroup className={classnames("mb-3", { focused: this.state.focusedEmail })} >
                                            <InputGroup className="input-group-merge input-group-alternative">

                                                <InputGroupAddon addonType="prepend">
                                                    <InputGroupText>
                                                        <i className="ni ni-email-83" />
                                                    </InputGroupText>
                                                </InputGroupAddon>

                                                <Input
                                                    onKeyDown={(e) => this._handleKeyDown(e, 'showEmail')}
                                                    placeholder="Email"
                                                    type="email"
                                                    name="email"
                                                    value={this.state.email	 || ''}
                                                    onChange={(e) => this.setState({email: e.target.value})}
                                                    onFocus={() => this.setState({ focusedEmail: true })}
                                                    onBlur={() => this.setState({ focusedEmail: false })}
                                                />
                                                
                                            </InputGroup>
                                        </FormGroup>

                                        <div className="text-center">
                                            {this.state.error && <span className="text-danger small">{this.state.error}<br /></span>}
                                            <Button id="showEmailButton" onClick={this.onSendCode} className="my-4" color="info" type="button">
                                                Send Code
                                            </Button>
                                        </div>
                                        </>
                                    )}

                                    {this.state.showCodeVerification && (
                                        <>                          
                                        <FormGroup className={classnames("mb-3", { focused: this.state.focusedCode })} >
                                            <InputGroup className="input-group-merge input-group-alternative">

                                                <InputGroupAddon addonType="prepend">
                                                    <InputGroupText>
                                                        <i className="ni ni-lock-circle-open" />
                                                    </InputGroupText>
                                                </InputGroupAddon>

                                                <Input
                                                    onKeyDown={(e) => this._handleKeyDown(e, 'showCodeVerification')}
                                                    placeholder="Code"
                                                    type="number"
                                                    value={this.state.code || ''}
                                                    onChange={(e) => this.setState({code: e.target.value})}
                                                    onFocus={() => this.setState({ focusedCode: true }) }
                                                    onBlur={() => this.setState({ focusedCode: false })}
                                                />

                                            </InputGroup>
                                        </FormGroup>

                                        <div className="text-center">
                                            {this.state.error && <span className="text-danger small">{this.state.error}<br /></span>}
                                            <Button id="showCodeVerificationButton" onClick={this.onConfirmedCode} className="my-4" color="info" type="button">
                                                Verify Code
                                            </Button>
                                        </div>
                                        </>
                                    )}

                                    {this.state.showPasswordUpdate && (
                                         <>
                                         <FormGroup className={classnames({     focused: this.state.focusedPassword })} >

                                                {this.state.passwordState === "invalid" && (
                                                    <div className="text-danger font-italic"> <small> Required</small> </div>
                                                )}

                                                {pt && pt.optionalTestErrors && pt.optionalTestErrors.length ? (
                                                    <>

                                                { pt.optionalTestErrors.map((p, index) => {
                                                        return <div key={index} className="text-danger font-italic"> <small> {p}</small> </div>
                                                    })}
                                                    </>
                                                
                                                ): null}

                                                <InputGroup className="input-group-merge input-group-alternative">

                                                    <InputGroupAddon addonType="prepend">
                                                        <InputGroupText>
                                                            <i className="ni ni-lock-circle-open" />
                                                        </InputGroupText>
                                                    </InputGroupAddon>

                                                    <Input
                                                        onKeyDown={(e) => this._handleKeyDown(e, 'showPasswordUpdate')}
                                                        placeholder="Password"
                                                        type="password"
                                                        value={this.state.password || ''}
                                                        onChange={e => this.onPasswordChange(e, "password") }
                                                        onFocus={() => this.setState({ focusedPassword: true })}
                                                        onBlur={() => this.setState({ focusedPassword: false })  } 
                                                    />

                                                </InputGroup>

                                            </FormGroup>

                                             <FormGroup className={classnames({ focused: this.state.focusedPassword })} >

                                                {this.state.password_confirmState === "invalid" && (
                                                    <div className="text-danger font-italic"> <small> Required</small> </div>
                                                )}


                                             <InputGroup className="input-group-merge input-group-alternative">

                                                <InputGroupAddon addonType="prepend">
                                                    <InputGroupText>
                                                        <i className="ni ni-lock-circle-open" />
                                                    </InputGroupText>
                                                </InputGroupAddon>

                                                <Input
                                                    onKeyDown={(e) => this._handleKeyDown(e, 'showPasswordUpdate')}
                                                    placeholder="Confirm Password"
                                                    type="password"
                                                    value={this.state.password_confirm || ''}
                                                    onChange={e => this.onPasswordChange(e, "password_confirm") }
                                                    onFocus={() => this.setState({ focusedPassword: true })}
                                                    onBlur={() => this.setState({ focusedPassword: false })}
                                            />

                                             </InputGroup>

                                         </FormGroup>

                                        <div className="text-center">
                                            {this.state.error && <span className="text-danger small">{this.state.error}<br /></span>}
                                            <Button id="showPasswordUpdateButton" onClick={this.onPasswordSet} className="my-4" color="info" type="button">
                                                Update Password
                                            </Button>
                                        </div>


                                         </>

                                    )}

									

							</CardBody>
						</Card>

						<Row className="mt-3">
							
                            <Col xs="6">
								<Link className="text-light" to="/auth/login" >
									<small>Login</small>
								</Link>
							</Col>

							<Col className="text-right" xs="6">
								<Link className="text-light" to="/auth/register" >
									<small>Create New Account</small>
								</Link>
							</Col>

						</Row>
					</Col>
				</Row>
			</Container>
		</>
		);
	}
}

const mapStateToProps = state => {

	return {

	  viewing_user: state.SYSTEM.viewing_user,
  
	};
};
  
export default connect(mapStateToProps, '')(Login);

