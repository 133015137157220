/*

This file is a test component for this plugin

*/
import React, { Component } from 'react';
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import { aosAction } from '../../../database';
import Header from "../components/Header";
import { buildModules } from '../functions/modules';









class TestComponent extends Component {


    state = {
        modules: this.props.modules
    };

    componentDidMount = () => {
        this.fetchData(this.props);
    }

    componentWillReceiveProps = async (nextProps) => {
        this.fetchData(nextProps);
    }

    fetchData = async (props) => {

        const params = {
            modules: props.modules,
            lessons: props.lessons,
            module_id: props.match.params.module_id,
            comments: props.comments,
            lessons_finished: props.lessons_finished,
            app_users: props.app_users,
            viewing_user: props.viewing_user
        }

        const modules = await buildModules(params)

        this.setState({modules: modules})
    }

    onDragEnd = async (result) => {

        if (!result.destination) {
            return;
        }
      
        const items = this.reorder(
            this.state.modules,
            result.source.index,
            result.destination.index
        );

        const final_array = [];

        //for each lesson update the order
        items.forEach(async (item, index) => {

            const order = index + 1

            //fire off the action needed to update the events order
            aosAction({
                method: 'post',
                url: '/api/live/CoursePlatform__modules/update/' + item._id,
                aos_live_events: ['/api/live/users/get/all'],          
                data: {
                    order
                },
            });

            final_array.push({
                ...item,
                order
            })

        })
    
        //reorder on screen
        this.setState({
            modules: final_array
        });

    }

    reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
      
        return result;
      };

    render() {


        return (
            <>
            
            <Header icon={true} title={<span>Modules</span>} breadcrumb_1="Update / Create"  actionComponent={<Link className="btn btn-success btn-sm" to="/admin/CoursePlatform/modules/create/new_entry">Add Module</Link>}/>

           
           
            <Container className="mt--6" fluid>
             

                <DragDropContext onDragEnd={this.onDragEnd}>
                        <Droppable droppableId="droppable" direction="vertical">

                        {(provided, snapshot) => (
                            <div {...provided.droppableProps} ref={provided.innerRef} > 

                                {this.state.modules && this.state.modules.length ? this.state.modules.map((m, index) => (

                                    <Draggable key={m._id} draggableId={m._id} index={index}>
                                    {(provided, snapshot) => (
                                        <div
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                        >
                                        
                                            <Card index={index} key={m._id}  style={{marginBottom: 10}} >
                                                <CardBody>
                                                    <Row className="align-ls-center">
                                                        
                                                        <Col className="col-auto">
                                                            <Link to={"/admin/CoursePlatform/modules/lessons/" + m._id}
                                                                className="avatar avatar-xl rounded-cicle"
                                                                style={{width: 120, height: 60}}
                                                            >
                                                                <img alt="..." src={m.picture} />
                                                            </Link>
                                                        </Col>
                                                        <div className="col ml--2">

                                                            <Link to={"/admin/CoursePlatform/modules/lessons/" + m._id}>
                                                                <h4 className="mb-0"> {m.name} ({m.lessons ? m.lessons.length : 0} Lessons) </h4>
                                                                <h5 className="mt--1 mb-0 font-weight-normal text-muted">Video Length: {m.minutes} Minutes </h5>
                                                            </Link>
                                                            <Link to={"/admin/CoursePlatform/modules/lessons/" + m._id}>
                                                                <span className="text-sm text-muted mb-0 "> {m.description}</span>
                                                            </Link>


                                                        </div>
                                                    
                                                    </Row>
                                                </CardBody>
                                            </Card>

                                        </div>
                                    )}
                                    </Draggable>
                      
                                )) : null}
                    

                                {provided.placeholder}

                             </div>
                         )}

                         </Droppable>
                     </DragDropContext> 
                   
            </Container>


            </>

        )

    }

}

const mapStateToProps = state => {
    return {
        modules: state.CoursePlatform.modules,
        lessons: state.CoursePlatform.lessons,
        comments: state.CoursePlatform.comments,
        lessons_finished: state.CoursePlatform.lessons_finished,
        viewing_user: state.SYSTEM.viewing_user,
        app_users: state.SYSTEM.app_users,
      
    };
  };

  
  export default connect(mapStateToProps )(TestComponent);  
  
  


