
/* 

Do not update this file. It should be generated programmatically through npm run plugins-compile

Copyright 2019 Arsenal OS
Author John Maher

*/

import consoleError from '../plugins_core/helpers/consoleError'; 
import AdminDashboard from '../plugins/AdminDashboard/redux'; 
import AdminPayments from '../plugins/AdminPayments/redux'; 
import AdminSystemLogs from '../plugins/AdminSystemLogs/redux'; 
import AdminUsers from '../plugins/AdminUsers/redux'; 
import Auth from '../plugins/Auth/redux'; 
import CoursePlatform from '../plugins/CoursePlatform/redux'; 
import Ghost from '../plugins/Ghost/redux'; 
import Home from '../plugins/Home/redux'; 
import Settings from '../plugins/Settings/redux'; 
import Support from '../plugins/Support/redux'; 


let reducerName = null


//check that this reducer is name spaced correctly
reducerName = Object.getOwnPropertyNames(AdminDashboard)[0];
if(reducerName !== undefined) {
    if(reducerName.split('_')[0] !== 'AdminDashboard' ) {
        consoleError('Reducer not named correctly "'+reducerName+'". Must be prefixed to start with "AdminDashboard__" or else plugin conflicts may arise.')
    }
}

//check that this reducer is name spaced correctly
reducerName = Object.getOwnPropertyNames(AdminPayments)[0];
if(reducerName !== undefined) {
    if(reducerName.split('_')[0] !== 'AdminPayments' ) {
        consoleError('Reducer not named correctly "'+reducerName+'". Must be prefixed to start with "AdminPayments__" or else plugin conflicts may arise.')
    }
}

//check that this reducer is name spaced correctly
reducerName = Object.getOwnPropertyNames(AdminSystemLogs)[0];
if(reducerName !== undefined) {
    if(reducerName.split('_')[0] !== 'AdminSystemLogs' ) {
        consoleError('Reducer not named correctly "'+reducerName+'". Must be prefixed to start with "AdminSystemLogs__" or else plugin conflicts may arise.')
    }
}
//check that this reducer is name spaced correctly
reducerName = Object.getOwnPropertyNames(AdminUsers)[0];
if(reducerName !== undefined) {
    if(reducerName.split('_')[0] !== 'AdminUsers' ) {
        consoleError('Reducer not named correctly "'+reducerName+'". Must be prefixed to start with "AdminUsers__" or else plugin conflicts may arise.')
    }
}
//check that this reducer is name spaced correctly
reducerName = Object.getOwnPropertyNames(Auth)[0];
if(reducerName !== undefined) {
    if(reducerName.split('_')[0] !== 'Auth' ) {
        consoleError('Reducer not named correctly "'+reducerName+'". Must be prefixed to start with "Auth__" or else plugin conflicts may arise.')
    }
}
//check that this reducer is name spaced correctly
reducerName = Object.getOwnPropertyNames(CoursePlatform)[0];
if(reducerName !== undefined) {
    if(reducerName.split('_')[0] !== 'CoursePlatform' ) {
        consoleError('Reducer not named correctly "'+reducerName+'". Must be prefixed to start with "CoursePlatform__" or else plugin conflicts may arise.')
    }
}
//check that this reducer is name spaced correctly
reducerName = Object.getOwnPropertyNames(Ghost)[0];
if(reducerName !== undefined) {
    if(reducerName.split('_')[0] !== 'Ghost' ) {
        consoleError('Reducer not named correctly "'+reducerName+'". Must be prefixed to start with "Ghost__" or else plugin conflicts may arise.')
    }
}
//check that this reducer is name spaced correctly
reducerName = Object.getOwnPropertyNames(Home)[0];
if(reducerName !== undefined) {
    if(reducerName.split('_')[0] !== 'Home' ) {
        consoleError('Reducer not named correctly "'+reducerName+'". Must be prefixed to start with "Home__" or else plugin conflicts may arise.')
    }
}

//check that this reducer is name spaced correctly
reducerName = Object.getOwnPropertyNames(Settings)[0];
if(reducerName !== undefined) {
    if(reducerName.split('_')[0] !== 'Settings' ) {
        consoleError('Reducer not named correctly "'+reducerName+'". Must be prefixed to start with "Settings__" or else plugin conflicts may arise.')
    }
}
//check that this reducer is name spaced correctly
reducerName = Object.getOwnPropertyNames(Support)[0];
if(reducerName !== undefined) {
    if(reducerName.split('_')[0] !== 'Support' ) {
        consoleError('Reducer not named correctly "'+reducerName+'". Must be prefixed to start with "Support__" or else plugin conflicts may arise.')
    }
}


const compiled_reducers = {
...AdminDashboard, 
...AdminPayments, 
...AdminSystemLogs, 
...AdminUsers, 
...Auth, 
...CoursePlatform, 
...Ghost, 
...Home, 
...Settings, 
...Support, 

}; 

export default compiled_reducers; 
