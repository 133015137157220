import React, { Component } from 'react';

import { BrowserRouter,  Switch, Redirect } from "react-router-dom";
//get all compiled plugin routes
import compiled_layouts from './plugins_compiler/compiled_layouts';

import { refreshViewingUser } from './plugins_core/store/reducers/SYSTEM'

import fetchWebsiteData from './fetchWebsiteData'


class App extends Component {


    state = {
        loaded: false,
        error: false
    };

    componentDidMount = async () => {

        fetchWebsiteData()

        Promise.all([

            refreshViewingUser(),

        ]).then((values) => {

            this.setState({loaded: true})

        }).catch((e) => {

            this.setState({loaded: true, error: true})

        })


    }

    render() {

        if(!this.state.loaded) {
            return <div></div>
        }

        if(this.state.error) {
            return <div className="text-center pt-6"><h2>AN ERROR HAS OCCURRED WITH OUR SERVERS.</h2></div>
        }

        return (

            <BrowserRouter>
                <Switch>
               
                    {compiled_layouts}
                    {/* <Redirect to={this.state.shouldRedirect} /> */}
                    <Redirect from="*" to="/" />

                </Switch>
            </BrowserRouter>

        )

    }

}

export default App;