/*

This file is a test component for this plugin

*/
import React, { Component } from 'react';
import ReactBSAlert from "react-bootstrap-sweetalert";
import ReactHtmlParser from 'react-html-parser';
import ReactQuill from 'react-quill';
import { connect } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';
import { Button, Card, CardBody, CardHeader, Col, Container, Form, FormGroup, Input, Row, Table } from "reactstrap";
import { aosAction } from '../../../database';
import Header from "../components/Header";
import { findLesson } from '../functions/modules';
import Media from '../../../ad-ons/media'




const required_form_fields = [
    'name',
    'description',
    'video',
]


class TestComponent extends Component {


    state = {
        user: {},
        lesson: {},
        canSave: true,
        reactQuillText: "",
        showMediaSingleUpload: false
        
    }

    //toggle the single image uploader
    onMediaSingleToggle = () => this.setState({showMediaSingleUpload: !this.state.showMediaSingleUpload})

    //what to do when a single images is selected
    onMediaSingleSelect = (values) => {

        this.setState({lesson: {
            ...this.state.lesson,
            picture: values[0]
        }})

    }

    fireDeleteConfirmationAlert = () => {
        this.setState({
          alert: (
            <ReactBSAlert

              danger
              style={{ display: "block", marginTop: "-100px" }}
              title="Are You Sure"

              //these are switch to swith button order
              onConfirm={() => this.hideAlert()}
              onCancel={() => this.deleteModule()}deleteModule

              cancelBtnBsStyle="danger"
              cancelBtnText="Yes, Delete It"
              confirmBtnBsStyle="info"
              confirmBtnText="Cancel"

              btnSize=""
              showCancel
   
          
            >
              This will wipe all lessons and data associated with {this.state.lesson.name}.
            
            </ReactBSAlert>
          )
        });
    };

    fireAlert = (error) => {
        this.setState({
          alert: (
            <ReactBSAlert
              success={error ? false : true}
              danger={!error ? false : true}
              style={{ display: "block", marginTop: "-100px" }}
              title={error ? 'Error' : 'Success'}
              onConfirm={() => this.hideAlert()}
              onCancel={() => this.hideAlert()}
              confirmBtnBsStyle="success"
              confirmBtnText="Ok"
              btnSize=""
            >
              {error ? 
              'Something went saving this lesson' :
              'Lesson Saved'
            }
            </ReactBSAlert>
          )
        });
    };

   

    deleteModule = async () => {

        //hide the first modal
        this.hideAlert()

        const deleted = await aosAction({
            method: 'post',
            url: '/api/live/CoursePlatform__lessons/delete/' + this.state.lesson._id,
            aos_live_events: ['/api/live/CoursePlatform__lessons/get/all?orderBy=order&order=asc'],          
        });
        
        if(deleted.success) {


            this.setState({shouldRedirect: '/admin/CoursePlatform/modules/lessons/' + this.props.match.params.module_id})
        
        } else {

            alert('Something went wrong deleting this lesson.')
        
        }

    }

    hideAlert = () => {
        this.setState({
            alert: null
        });
    };

    handleReactQuillChange = value => {
        this.setState({
            reactQuillText: value,
            lesson: {
                ...this.state.lesson,
                description: value,
                descriptionState: value
            }
        });
    };

      addCovers = () => {

        const item = this.state.lesson.coversItem

       
        let covers = this.state.lesson.covers ? [...this.state.lesson.covers] : [];

        if(item) {
                covers.push(item)
        }

        this.setState({
            lesson: {
                ...this.state.lesson,
                coversItem: null,
                covers
            },

        })


      }

    onSave = async () => {

        this.setState({canSave: false})
        //copy state
        let newState = Object.assign({}, this.state.lesson);
        //assume we have no erfrors
        let errors = 0;

        required_form_fields.forEach((field) => {

            //check each required field
            if(newState[field]) {
                newState[field + "State"] = 'valid'; 
            } else {
                newState[field + "State"] = 'invalid'; 
                errors++
            }

        })


        this.setState({
          lesson: newState
        });

          //et the user try again but stop form saving
        if(errors) {
            this.setState({canSave: true})
        } else {

    

            //if we are creating a new lesson
            if(this.props.match.params.lesson_id === 'new_entry') {



                //create a lesson
                const created = await aosAction({
                    method: 'post',
                    url: '/api/live/CoursePlatform__lessons/create',
                    aos_live_events: ['/api/live/CoursePlatform__lessons/get/all?orderBy=order&order=asc'],          
                    data: {
                        name: this.state.lesson.name,
                        description: this.state.lesson.description,
                        thumbnail: this.state.lesson.thumbnail,
                        picture: this.state.lesson.picture,
                        module_id: this.props.match.params.module_id,
                        minutes: this.state.lesson.minutes,
                        video: this.state.lesson.video,
                        covers: this.state.lesson.covers,
                        order: 100 //set the default order to be back last

                    },
                });
                
                if(created.success) {

                    //reset the lesson state
                    this.setState({lesson: {}, canSave: true})
                    this.fireAlert(false)
                
                } else {
                
                    this.fireAlert(true)
                
                }

            } else {


                //create a lesson
                const updated = await aosAction({
                    method: 'post',
                    url: '/api/live/CoursePlatform__lessons/update/' + this.state.lesson._id,
                    aos_live_events: ['/api/live/CoursePlatform__lessons/get/all?orderBy=order&order=asc'],          
                    data: {
                        name: this.state.lesson.name,
                        description: this.state.lesson.description,
                        thumbnail: this.state.lesson.thumbnail,
                        picture: this.state.lesson.picture,
                        minutes: this.state.lesson.minutes,
                        video: this.state.lesson.video,
                        covers: this.state.lesson.covers,


                    },
                });

                this.setState({canSave: true})
                
                if(updated.success) {


                    //reset the lesson state
                    this.setState({lesson: {
                        ...this.state.lesson,
                        nameState: null,
                        descriptionState: null,
                        thumbnailState: null,
                        pictureState: null
                    }

                        
                    })

                    this.fireAlert(false)
                
                } else {
                
                    this.fireAlert(true)
                
                }

            }

        }





    }

    validateForm = () => {
        //copy state
        let newState = Object.assign({}, this.state.lesson);


        required_form_fields.forEach((field) => {



            if(newState[field]) {
                newState[field + "State"] = 'valid'; 
                
            } else {
                newState[field + "State"] = 'invalid'; 

            }

            

        })

        this.setState({
          lesson: newState
        });
    };


    removeCover = (c) => {

        let new_covers = [...this.state.lesson.covers];

        new_covers = new_covers.filter(cover => {
            return cover !== c;
        });

        this.setState({lesson: {
            ...this.state.lesson,
            covers: new_covers
        }})

    }

    onInputChange = (e, stateName) => {

     
       let value = e.target.value

//<iframe src="https://player.vimeo.com/video/363924846" width="640" height="360" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>
        //account for vimeo videos
        if(stateName === 'video') {
            if(value) {
                var str = value,
            re = /\ssrc=(?:(?:'([^']*)')|(?:"([^"]*)")|([^\s]*))/i, // match src='a' OR src="a" OR src=a
            res = str.match(re)

            if(res) {
                value  = res[1]||res[2]||res[3]; 
            }
            
           
            }
        }



        let newState = Object.assign({}, this.state.lesson);
        newState[stateName] = value;

        if (stateName === "checkbox") {
          if (value) {
            newState.checkboxState = "valid";
          } else {
            newState.checkboxState = "invalid";
          }
        } else {
          if (value === "") {
            newState[stateName + "State"] = "invalid";
          } else {
            newState[stateName + "State"] = "valid";
          }
        }

        this.setState({
          lesson: newState
        });
    };

    componentDidMount = async () => {

        const lesson_id = this.props.match.params.lesson_id

        //we are updating a lesson nt creating one
        if(lesson_id !== 'new_entry') {

            const found_lesson = findLesson(lesson_id, this.props.lessons)

            if(!found_lesson) {
                this.setState({shouldRedirect: '/admin/CoursePlatform/modules'})
            }
            
            this.setState({lesson: found_lesson, reactQuillText: found_lesson.description})

        }
    
        this.sizeIframe()
        window.addEventListener("resize", this.sizeIframe.bind(this));
    
    

    }



    componentWillUnmount() {
        window.removeEventListener("resize", this.sizeIframe.bind(this));
    }

    sizeIframe = () => {

        let window_width = 0;

        if(window.innerWidth < 768) {
            window_width = window.innerWidth - 30;
        } else if (window.innerWidth > 1200) {
            window_width = window.innerWidth - 250 - 73;
        } else {
            window_width = window.innerWidth - 60;
        }
       

        let width = window_width;
        let height = (width / 16) * 9

        this.setState({width, height})


    }

    render() {

       



        

        if(this.state.shouldRedirect) {
            // console.log(redirect)
            return <Redirect to={this.state.shouldRedirect} />
        }

        if(!this.state.lesson) {
            return <div></div>
        }

        const covers = this.state.lesson.covers;

        return (
            <>

             {this.state.alert}

             {this.state.showMediaSingleUpload && (
                <Media 
                    onMediaToggle={this.onMediaSingleToggle}
                    onMediaSelect={(values) => this.onMediaSingleSelect(values)}
                    canSelectMultiple={false}
                />
            )}

            <Header 
                icon={true}  
                title={<Link to={"/admin/CoursePlatform/modules/lessons/" + this.props.match.params.module_id}> <i className="mr-2 fas fa-arrow-left"></i>Lessons</Link>} 
                breadcrumb_1={this.state.lesson.name} 
                actionComponent={this.props.match.params.lesson_id !== 'new_entry' && (
                    <Button color="danger" size="sm" onClick={this.fireDeleteConfirmationAlert}>Delete Lesson</Button>
                )} 
            />

           
           
            <Container className="mt--6" fluid>     


                <Card>

                    <CardHeader>
                        <Row className="align-items-center">
                            <Col xs="8">
                                <h3 className="mb-0">Create / Update Lesson</h3>
                            </Col>
                            <Col className="text-right" xs="4">
                                <Button
                                    color="success"

                                    onClick={this.onSave}
                                    size="sm"
                                    disabled={this.state.canSave ? false : true}
                                >
                                Save Lesson
                                </Button>
                            </Col>
                        </Row>
                    </CardHeader>

                    <CardBody>
                        <Form>

                            <h6 className="heading-small text-muted mb-4">Lesson Text</h6>

                            <div className="pl-lg-4">
                                <Row>
                                    <Col lg="6">
                                        <FormGroup>
                                        <label className="form-control-label" htmlFor="name"> Lesson Name </label>
                                        <Input
                                            id="name"
                                            value={this.state.lesson.name || ''}
                                            placeholder="Building A Better Brand"
                                            type="name"
                                            valid={ this.state.lesson.nameState === "valid" }
                                            invalid={ this.state.lesson.nameState === "invalid" }
                                            onChange={e => this.onInputChange(e, "name") }  
                                        />
                                        <div className="valid-feedback">Looks good!</div>

                                        </FormGroup>
                                    </Col>
                                    <Col lg="6">
                                        <FormGroup>
                                        <label className="form-control-label" htmlFor="phone"> Video Length (Minutes) </label>
                                    
                                        <Input
                                            id="minutes"
                                            value={this.state.lesson.minutes || ''}
                                            placeholder="ex: 60 "
                                            type="number"
                                            valid={ this.state.lesson.minutesState === "valid" }
                                            invalid={ this.state.lesson.minutesState === "invalid" }
                                            onChange={e => this.onInputChange(e, "minutes") }
                                            
                                        />
                                        <div className="valid-feedback">Looks good!</div>

                                        </FormGroup>
                                    </Col>
                                </Row>
                           

                                <Row>
                                    <Col lg="12">

                                        <label className="form-control-label" htmlFor="description"> Lesson Description </label>

                                        <div  >
                                        <ReactQuill
                                            value={this.state.reactQuillText}
                                            onChange={this.handleReactQuillChange}
                                            className={this.state.lesson.descriptionState === 'invalid' ? "form-control is-invalid p-0" : null}
                                            theme="snow"
                                            style={{height: 'auto', padding: 'none'}}
                                            modules={{
                                                toolbar: [
                                                ["bold", "italic"],
                                                
                                                ["link", "blockquote", "code", "image"],
                                                [
                                                    {
                                                    list: "ordered"
                                                    },
                                                    {
                                                    list: "bullet"
                                                    }
                                                ]
                                                ]
                                            }}
                                        />
                                        </div>
                                        
                                    </Col>
                                </Row>

                            

                                <Row className="mt-4">
                                    <Col lg="12">

                                        <label className="form-control-label" htmlFor="coversItem"> What This Lesson Covers </label>
                                        <Input
                                            id="coversItem"
                                            value={this.state.lesson.coversItem || ''}
                                            placeholder="ex: Cold Calling Prospects "
                                            type="text"
                                            valid={ this.state.lesson.coversItemState === "valid" }
                                            invalid={ this.state.lesson.coversItemState === "invalid" }
                                            onChange={e => this.onInputChange(e, "coversItem") }
                                            
                                        />
                                        <div className="valid-feedback">Looks good!</div>

                                        <div className="text-right mt-3">
                                            <Button onClick={this.addCovers} size="sm" color="info">Add</Button>
                                        </div>

                                    </Col>
                                </Row>

                            </div>

                            <hr className="my-4" />
                            <h6 className="heading-small text-muted mb-4">Lesson Media</h6>

                            <div className="pl-lg-4">

                                <Row>

                                    <Col md="6">
                                        <FormGroup>
                                        <label className="form-control-label" htmlFor="picture" > Lesson Picture (1 x 1)</label>

                                        <div className="py-3 pt-2 mt--3">
                                            {this.state.lesson && this.state.lesson.picture && (
                                                <img className="w-100 rounded" src={this.state.lesson.picture} alt=""/>
                                            )}
                                        </div>
                                        
                                        <Button className="btn btn-success btn-sm" onClick={() => this.onMediaSingleToggle()} >Update Image</Button>

                                        {/* <Input
                                            id="picture"
                                            value={this.state.lesson.picture || ''}
                                            placeholder="https://website.com/img.jpg"
                                            type="text"
                                            valid={ this.state.lesson.pictureState === "valid" }
                                            invalid={ this.state.lesson.pictureState === "invalid" }
                                            onChange={e => this.onInputChange(e, "picture") }
                                            
                                        /> */}
                                        <div className="valid-feedback">Looks good!</div>

                                        </FormGroup>
                                    </Col>

                                    <Col md="6">
                                        <FormGroup>
                                        <label className="form-control-label" htmlFor="video" > Lesson Video</label>
                                        
                                        <Input
                                            id="video"
                                            value={this.state.lesson.video || ''}
                                            placeholder="https://vimeo.com/..."
                                            type="text"
                                            valid={ this.state.lesson.videoState === "valid" }
                                            invalid={ this.state.lesson.videoState === "invalid" }
                                            onChange={e => this.onInputChange(e, "video") }
                                            
                                        />
                                        <div className="valid-feedback">Looks good!</div>

                                        </FormGroup>
                                    </Col>

                                </Row>

                            </div>

                        </Form>
                    </CardBody>
                </Card>

                <hr />

                <h2>Preview: {this.state.lesson.name}</h2>

                {this.state.lesson.video && 
                    <iframe 
                        className="shadow-lg"
                        style={{borderRadius: 5}}
                        title="lesson"
                        src={this.state.lesson.video}
                        width={this.state.width} height={this.state.height}  

                        allow="autoplay; fullscreen" 
                        allowFullScreen={true}
                    />

                }

                <Row className="mt-4 mb-6">

                    <Col xl="8" md="12" sm="12">

                        <Card className="mt-0" style={{marginBottom: 0, height: '100%'}}>
                            <CardBody>  
                                <h1>In This Lesson...</h1>
                               {ReactHtmlParser(this.state.lesson.description)}
                            </CardBody>
                        </Card>

                    
                    </Col>

                    <Col xl="4" md="12" sm="12">

                        <Card style={{marginBottom: 0, height: '100%'}}>
                            <CardHeader>
                                <h5 className="h3 mb-0">This Lesson covers</h5>
                            </CardHeader>

                            <CardBody>
                                <Table className="table " responsive>

                                    <thead></thead>

                                    <tbody>
                                    {covers && covers.length ? covers.map(c => {
                                        return (
                                            <tr key={c._id}>
                                                <td className="px-0 pt-0" style={{borderTop: 'none'}}><i onClick={() => this.removeCover(c)} className="fas fa-trash text-danger mr-2"></i> {c}</td>
                                                <td className="text-center pt-0" style={{borderTop: 'none'}}>
                                                    <i className="fas fa-check text-success" />
                                                </td>
                                            </tr>
                                        )
                                        
                                    }) : null}

                                    </tbody>
                                </Table>
                            </CardBody>
                        </Card>
                    
                        
                    
                    </Col>

                

                </Row>
   
            </Container>


            </>

        )

    }

}



const mapStateToProps = state => {
    return {
      modules: state.CoursePlatform.modules,
      lessons: state.CoursePlatform.lessons,
      
    };
  };

  
  export default connect(mapStateToProps )(TestComponent);  
  
  

