/*
Documentation

this reducer holds all the actions we can dispatch for our global user object

*** make sure to declare all action types in actions.js one folder level back

*/

import * as actionTypes from './actions';

const initialState = {
    // viewing_user:   {},
    viewing_user:   {
        _id: 'asdfasdf',
        family_name: 'Maher',
        given_name: 'John',
        is_admin: true,
        is_dev: true
    },

    app_users:      [],
    app_admins:     [],
}

const reducer = (state = initialState, action) => {

    switch (action.type) {
        case actionTypes.SET_VIEWING_USER:

            //set a logged_in prop on the viewing user
            if(action.payload.user && action.payload.user._id) {
                action.payload.user.logged_in = true
            } else {
                action.payload.user.logged_in = false;
            }

            //keep current state and update the viewing_user
            return {
                ...state,
                viewing_user: { ...action.payload.user, }
            }

        case actionTypes.SET_APP_USERS:


            return {
                ...state,
                app_users: action.payload.objects
            }


        default:
        

            return state;
    }
}



export default reducer;