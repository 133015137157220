/*
Documentation

This file holds the content for updating a companies billing information and credit cards
need to add success, error and loading messages for updating default sources

*/

import React from "react";
import { connect } from 'react-redux';
import { Container } from "reactstrap";
import BillingController from '../../../ad-ons/payments/BillingController';
import Header from '../components/Header';

import { aosAction } from '../../../database'

class Billing extends React.Component {

    onUpdateInfo = async (err, response) => {

        if(err) {
            console.log('error at onUpdateInfo', err)
            return;
        }

        const updated = await aosAction({
            method: 'post',
            url: '/api/live/users/update/' + this.props.viewing_user._id,
            aos_live_events: ['/api/live/users/get/all'],          
            data: {
                ...response.user
            },
        })

        if(!updated.success) {
            console.log(updated)
            //error log here
        }

    }

    onAddCard = async (err, response) => {

        if(err) {
            console.log('error at onAddCard', err)
            return;
        }

        console.log(response)
        console.log(response.customer.id)

        const updated = await aosAction({
            method: 'post',
            url: '/api/live/users/update/' + this.props.viewing_user._id,
            aos_live_events: ['/api/live/users/get/all'],          
            data: {
                stripe_cus_id: response.customer.id,
                ...response.user
            },
        })

        if(!updated.success) {
            console.log(updated)
            //error log here
        }
        
    }

    onRemoveSubscription = (err, response) => {

        if(err) {
            console.log('error at onRemoveSubscription', err)
            return;
        }

        //success
        console.log(response)
        
    }

    render() {

        return (
            <>
          
                <Header icon={true} title={<span>user</span>} breadcrumb_1="Billing" />

                <Container className="mt--6" fluid>     
                
                    <BillingController 

                        //returns error or the updated user object fields under response.user
                        onUpdateInfo = {(err, response) => this.onUpdateInfo(err, response)}
                        //return error or stripe customer
                        onAddCard={(err, response) => this.onAddCard(err, response)}
                        //returns error or the deleted subscription
                        onRemoveSubscription={(err, response) => this.onRemoveSubscription(err, response)}

                        //a list of our active subscriptions
                        active_stripe_subscriptions={[]}

                        stripe_cus_id={this.props.viewing_user.stripe_cus_id}
                        user_id={this.props.viewing_user._id}

                        //used for prefilling fields
                        user={{
                            given_name: this.props.viewing_user.given_name,
                            family_name: this.props.viewing_user.family_name,
                            address_line_1: this.props.viewing_user.address_line_1,
                            address_line_2: this.props.viewing_user.address_line_2,
                            city: this.props.viewing_user.city,
                            state: this.props.viewing_user.state,
                            postal_code: this.props.viewing_user.postal_code,
                        }}

                        textInstruction={<p>This updates your card on file, you <b>WILL NOT</b> be charged by adding or changing your cards here.</p>}
                        textDisclaimer={ <p  className="small">It is your responsibility to make sure you have your most current form of payment information on file if you account has a recurring subscription.</p>}

                    />

                </Container>

            </>
        );
    }
}


const mapStateToProps = state => {
    return {
  
        viewing_user: state.SYSTEM.viewing_user,

  
    };
};
  
export default connect(mapStateToProps, '')(Billing);