/*
Documentation

this reducer holds all the actions we can dispatch for our global user object

*** make sure to declare all action types in actions.js one folder level back

*/

import * as actionTypes from '../actions';
import store from '../index';
import Axios from 'axios';
import keys from '../../../keys';
// import { createSystemLogNotification } from '../../functions/db-helpers/system-logs';
import { aosListen, aosCall } from '../../../database';

import Cookies from 'universal-cookie';
const cookies = new Cookies();

export const refreshSystemUsers = async() => {
    return new Promise( async (resolve, reject) => {


        aosListen.listen('/api/live/users/get/all', (data) => {
            

            if(data.success) {

                refreshViewingUser() 

                store.dispatch({
                    type: actionTypes.SET_APP_USERS,
                    payload: {
                        objects: data.response
                    }
                });

                store.dispatch({
                    type: actionTypes.SET_APP_USERS_THIS_WEEK,
                    payload: {
                        objects: data.response.filter(o =>   o.created_at > ((new Date()).getTime() / 1000) - (7 * 86400) )
                    }
                });

               

                store.dispatch({
                    type: actionTypes.SET_APP_ADMINS,
                    payload: {
                        objects: data.response.filter(o => o.is_admin)
                    }
                });

                

                resolve(true);
                return;

            } else {

                //error reporting here
                resolve(true);
                return;

            }

        }, (e) => {

            console.log(e);
            resolve(true);
            return;
            

        })

    })

}


export const refreshSystemPayments = async() => {
    return new Promise( async (resolve, reject) => {


        aosListen.listen('/api/live/payments/get/all', (data) => {
            
            if(data.success) {

                store.dispatch({
                    type: actionTypes.SET_APP_PAYMENTS,
                    payload: {
                        objects: data.response
                    }
                });

                let payments_this_week = 0;
                data.response.forEach(r => {
                    if(r.created_at > ((new Date()).getTime() / 1000) - (7 * 86400)) {
                        payments_this_week = payments_this_week + r.amount
                    }
                })

               

                store.dispatch({
                    type: actionTypes.SET_APP_PAYMENTS_THIS_WEEK,
                    payload: {
                        objects: payments_this_week
                    }
                });

                let payments_total = 0;
                data.response.forEach(r => {
                    if(!r.refunded) {
                        payments_total = payments_total + r.amount
                    }
                })

                store.dispatch({
                    type: actionTypes.SET_APP_PAYMENTS_TOTAL,
                    payload: {
                        objects: payments_total
                    }
                });




                

                resolve(true);
                return;

            } else {

                //error reporting here
                resolve(true);
                return;

            }

        }, (e) => {

            console.log(e);
            resolve(true);
            return;
            

        })

    })

}


export const setViewingUser = async(user) => {
    return new Promise( async (resolve, reject) => {

        const subscriptions = await aosCall({
            method: 'get',
            url: '/api/system_subscriptions/user/' + user._id,
        })

        if(subscriptions.response && subscriptions.response.length) {
            user.subscriptions = subscriptions.response;
        } else {
            user.subscriptions = []
        }
        
        store.dispatch({
            type: actionTypes.SYSTEM_SET_VIEWING_USER,
            payload: {
                objects: user
            }
        });

        resolve(true)
       

    })

}

export const refreshViewingUser = async() => { 
    return new Promise( async (resolve, reject) => {

        try {


            let user = await Axios.get( keys.API_URL + '/auth/current_user?architeck_uid=' + cookies.get('architeck_uid'), {
                withCredentials: true, 
            })

            user = user.data

            if(user) {

                const subscriptions = await aosCall({
                    method: 'get',
                    url: '/api/system_subscriptions/user/' + user._id,
                })

                if(subscriptions.response && subscriptions.response.length) {
                    user.subscriptions = subscriptions.response;
                } else {
                    user.subscriptions = []
                }

                 
                store.dispatch({
                    type: actionTypes.SYSTEM_SET_VIEWING_USER,
                    payload: {
                        objects: user
                    }
                });

            }

        

            resolve(true)

        } catch (e) {

            resolve(false);
            // createSystemLogNotification('An error occurred loading system users. Please contact support', 1, 1)
            console.log('Error ', e)

        }

    })

}

const initialState = {

    viewing_user: {},

    app_users:      [],
    app_user_this_week:      [],

    app_admins:     [],

    app_payments:     [],
    app_payments_this_week:     0,
    app_payments_total:     0,

    reducers_fired:   [],
}

const reducer = (state = initialState, action) => {

    switch (action.type) {
        case actionTypes.SYSTEM_SET_VIEWING_USER:

            //set a logged_in prop on the viewing user
            if(action.payload.objects && action.payload.objects._id) {
                action.payload.objects.logged_in = true
            } else {
                // action.payload.objects.logged_in = false;
            }

            //keep current state and update the viewing_user
            return {
                ...state,
                viewing_user: { ...action.payload.objects, }
            }

        case actionTypes.SYSTEM_SET_REDUCERS_FIRED:


            return {
                ...state,
                reducers_fired: action.payload.objects
            }

        case actionTypes.SET_APP_USERS:

            return {
                ...state,
                app_users: action.payload.objects
            }

        case actionTypes.SET_APP_USERS_THIS_WEEK:

                return {
                    ...state,
                    app_users_this_week: action.payload.objects
                }

        case actionTypes.SET_APP_ADMINS:


            return {
                ...state,
                app_admins: action.payload.objects
            }

        case actionTypes.SET_APP_PAYMENTS:


            return {
                ...state,
                app_payments: action.payload.objects
            }

        case actionTypes.SET_APP_PAYMENTS_THIS_WEEK:


            return {
                ...state,
                app_payments_this_week: action.payload.objects
            }

        case actionTypes.SET_APP_PAYMENTS_TOTAL:


            return {
                ...state,
                app_payments_total: action.payload.objects
            }

        default:
        

            return state;
    }
}



export default reducer;