import Gateway from 'ad-ons/payments/Gateway';
import keys from 'keys';
import React, { Component } from 'react';
import ReactBSAlert from "react-bootstrap-sweetalert";
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { aosAction, aosCall } from '../../../database';

//items to show on the payment screen
const items = [
    {name: 'Subtotal', amount: '$397'},
    {name: 'Shipping', amount: '$0'},
    {name: 'Tax', amount: '$0'},
    {name: 'Extra Fees', amount: '$0'},
    {name: 'Ongoing Costs', amount: '$0'},
]

class Payments extends Component {


    state = {
        coupons: [],
        paid: null
    };

    hideAlert = () => this.setState({ alert: null }); 

    //make bigger
    //
    fireAlert = () => {
        this.setState({
          alert: (
            <ReactBSAlert
                success={true}
                style={{ display: "block" }}
                title={'Welcome!'}
                onConfirm={() => this.hideAlert()}
                onCancel={() => this.hideAlert()}
                confirmBtnBsStyle="success"
                confirmBtnText="Okay!"
                btnSize=""
            >
              Welcome to IGRE! Thank you for signing up and welcome to the course.
            </ReactBSAlert>
          )
        });
    };

    onSuccess = async (response) => {

        //add to active campaign

        // aosCall({
        //     method: 'post',
        //     url: '/api/active_campaign/contact/add_email_to_list',
        //     data: {
        //         first_name: this.props.viewing_user.given_name,
        //         last_name: this.props.viewing_user.family_name,
        //         list: 4
        //     }
        // })

        console.log(this.props.viewing_user)

        aosCall({
            method: 'post',
            url: '/api/active_campaign/contact/send_to_paid_list',
            data: {
                user_id: this.props.viewing_user._id,
            }
        })
        


        this.fireAlert()

        const user = {
            address_line_1: response.user.address_line_1,
            address_line_2: response.user.address_line_2,
            city: response.user.city,
            state: response.user.state,
            postal_code: response.user.postal_code,
            email: response.user.email,
        }


        const user_id = this.props.viewing_user._id
        //set stripe_cus_id if new customer
        const stripe_cus_id = this.props.viewing_user.stripe_cus_id ? this.props.viewing_user.stripe_cus_id : response.customer.id;
        user.stripe_cus_id = stripe_cus_id;

        //update the users address, email, and stripe_cus_id if new customer
        aosCall({
            method: 'post',
            url: '/api/live/users/update/' + user_id,
            data: user
        })

        //this will trigger a refresh due to our socket integration
        aosAction({
            method: 'post',
            url: '/api/live/system_subscriptions/create',
            aos_live_events: ['/api/live/users/get/all'],   
            data: {
                subscription_key: 'CoursePlatform_standard',
                status: 'active',
                stripe_cus_id,
                user_id: user_id 
            }
        })

        this.setState({paid: true})

        const coupon = response.couponUsed

        //if a coupon update its use times
        if(coupon) {

            const updatedCoupon = await aosAction({

                method: 'post',
                url: '/api/live/system_coupons/update/' + coupon._id,
                aos_live_events: ['/api/live/system_coupons/get/all'],   
                data: {
                    times_used: coupon.times_used + 1,
                }     
        
            });

            const createCouponUses = await aosAction({

                method: 'post',
                url: '/api/live/system_coupons_used/create/',
                aos_live_events: ['/api/live/system_coupons/get/all'],   
                data: {
                    coupon: coupon._id,
                    user: user_id,
                    amount: coupon.amount,
                    created_at: Math.floor(new Date() / 1000)
                }     
        
            });

            console.log('coupons used ')
            console.log(createCouponUses)

        }
        
    }

    //fetch any coupons we are using
    componentDidMount = async () => {

        const getCoupons = await aosCall({
            method: 'get',
            url: '/api/live/system_coupons/get/all',      
        })

        if(getCoupons.success) {

            let coupons = getCoupons.response;

            //only get the coupons for this payment gateway
            if(coupons && coupons.length) {
                coupons = coupons.filter(c => c.subscription_key === 'CoursePlatform_standard')
            }

            //if not coupons found set to false
            if(!coupons && coupons.length) {
                coupons = false;
            }

            this.setState({coupons})
           
        } 

    }

    render() {

        //user has a subscriptions
        let has_subscriptions = false;
        const subscriptions = this.props.viewing_user.subscriptions;

        if(subscriptions && subscriptions.length) {
            if(subscriptions.find(s =>  s.subscription_key === 'CoursePlatform_standard' )) {
                has_subscriptions = true;
            }
        }

        if(this.props.viewing_user.email === 'johnmaher1245@gmail.com') {
            has_subscriptions = true
        }

        if(has_subscriptions) {
            return (
                <>
                    {this.props.children}
                    {this.state.alert}
                </>
            )
        }

        // this fires on complete of purchase to immediately close the window
        if(this.state.paid) {
            return (
                <>
                    {this.props.children}
                    {this.state.alert}
                </>
            )
        }

        return (

            <div className="payment-content">
                <div className="payment-body">

                    <Gateway
                        stripe_public_key=          {keys.STRIPE_PUBLISHABLE_KEY}
                        logo=                       {keys.LOGO}
                        user_id=                    {this.props.viewing_user._id}
                        user=                       {this.props.viewing_user}
                        one_time=                   {true}

                        //if one_time amount and description are required
                        amount=                     {39700}
                        description=                 "IGRE"
                        //if not one_time stripe_plan_id is required
                        stripe_plan_id=             {''}

                        //add any coupons here
                        coupons=                    {this.state.coupons}

                        items=                      {items}
                        onSuccess={                 (response) => this.onSuccess(response) }
                    />
                    
                
                    <div className="bg-white py-3 text-center">
                        <Link to="/dashboard/settings">Account Settings</Link>
                    </div>
                    
                </div>
            </div>
            
        )

    }

}
const mapStateToProps = state => {
    return {
        viewing_user: state.SYSTEM.viewing_user,
    };
};


export default connect(mapStateToProps )(Payments);  


// subscription_key=           "CoursePlatform_standard"

// plugin=                      "Course Platform"