/*
Documentation

Add all reducers for this plugin here

*/

import userReducer from './reducers/users';

export default {
    AdminDashboard__user: userReducer,
}
